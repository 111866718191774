export const wrapperIdentifier = 'financial';

export type OnOtpRequest = (
  message: string,
  setOtp: (otp: string) => void,
  discard: () => void
) => void;

export type Events = {
  onDiscard?: () => void;
  onSuccess?: () => void;
  onFailure?: () => void;
};
