import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { getCoreFetch } from './functions';

import { wrapperBuilder } from './build';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(({ componentProps: { enableNativeBridge } }) => {
    return {
      enableNativeBridge,
      coreFetch: getCoreFetch({ nativeBridge: enableNativeBridge || false }),
    };
  });
