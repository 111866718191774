import { TransportDefinition, TRANSPORTS } from './transportDefinitions';
export interface GTMEventProps {
  dataLayer: any;
  dataLayerName?: string;
}
export interface GtmDefinition
  extends TransportDefinition<
    { gtmId: string; auth?: string; preview?: string },
    GTMEventProps
  > {
  engine: TRANSPORTS.GTM;
}
var inited = false;

const Snippets = {
  tags: function({
    id,
    events,
    dataLayer,
    dataLayerName,
    preview,
    auth,
  }: {
    [k: string]: any;
  }) {
    let gtmAuth = ``;
    if (auth) {
      gtmAuth = `&gtm_auth=${auth}`;
    }

    let gtmPreview = ``;
    if (preview) {
      gtmPreview = `&gtm_preview=${preview}`;
    }

    if (!id) {
      throw new Error(
        'GTM Id is required. Provide it from env var "CLIENT_ENV_GTM_ID" or TagManager initialize method'
      );
    }

    const iframe = `<iframe src="https://www.googletagmanager.com/ns.html?id=${id}${gtmAuth}${gtmPreview}" height="0" width="0" style="display:none;visibility:hidden" id="tag-manager"></iframe>`;

    const script = `(function(w,d,s,l,i){w[l]=w[l]||[];
            w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js', ${JSON.stringify(
              events
            ).slice(1, -1)}});
            var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
            j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'${gtmAuth}${gtmPreview}';
            f.parentNode.insertBefore(j,f);
          })(window,document,'script','${dataLayerName}','${id}');`;

    const dataLayerVar = this.dataLayer(dataLayer, dataLayerName);

    return {
      iframe,
      script,
      dataLayerVar,
    };
  },
  dataLayer: function(dataLayer: string, dataLayerName: string) {
    return `
          window.${dataLayerName} = window.${dataLayerName} || [];
          window.${dataLayerName}.push(${JSON.stringify(dataLayer)})`;
  },
};

export const TagManager = {
  dataScript: function(dataLayer: string) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },
  gtm: function(args: { [k: string]: any }) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = snippets.iframe;
      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;
      return script;
    };

    const dataScript = this.dataScript(snippets.dataLayerVar);

    return {
      noScript,
      script,
      dataScript,
    };
  },
  initialize: function({
    gtmId,
    events = {},
    dataLayer,
    dataLayerName = 'dataLayer',
    auth = '',
    preview = '',
  }: {
    gtmId?: string;
    events?: object;
    dataLayer?: any;
    dataLayerName?: string;
    auth?: string;
    preview?: string;
  }) {
    if (!inited) {
      inited = true;
      try {
        const gtm = this.gtm({
          id:
            gtmId ||
            (window &&
              window.__CLIENT_ENV &&
              window.__CLIENT_ENV.CLIENT_ENV_GTM_ID) ||
            undefined,
          events: events,
          dataLayer: dataLayer || undefined,
          dataLayerName: dataLayerName,
          auth,
          preview,
        });
        if (dataLayer) document.head.appendChild(gtm.dataScript);
        document.head.insertBefore(gtm.script(), document.head.childNodes[0]);
        document.body.insertBefore(gtm.noScript(), document.body.childNodes[0]);
      } catch (e) {
        console.warn(e.message || 'Tag manager initialization failed');
      }
    }
  },
  dataLayer: function({
    dataLayer,
    dataLayerName = 'dataLayer',
  }: GTMEventProps) {
    if (window[dataLayerName as any]) {
      return ((window[dataLayerName as any] as unknown) as Array<any>).push(
        dataLayer
      );
    }
    const snippets = Snippets.dataLayer(dataLayer, dataLayerName);
    const dataScript = this.dataScript(snippets);
    document.head.appendChild(dataScript);
    return undefined;
  },
};
