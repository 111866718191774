/* eslint-disable no-undef */
export const init = () => {
  cordova.define('cordova-plugin-camera.CameraPopoverOptions', function(
    require,
    exports,
    module
  ) {
    /*
     *
     * Licensed to the Apache Software Foundation (ASF) under one
     * or more contributor license agreements.  See the NOTICE file
     * distributed with this work for additional information
     * regarding copyright ownership.  The ASF licenses this file
     * to you under the Apache License, Version 2.0 (the
     * "License"); you may not use this file except in compliance
     * with the License.  You may obtain a copy of the License at
     *
     *   http://www.apache.org/licenses/LICENSE-2.0
     *
     * Unless required by applicable law or agreed to in writing,
     * software distributed under the License is distributed on an
     * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
     * KIND, either express or implied.  See the License for the
     * specific language governing permissions and limitations
     * under the License.
     *
     */

    var Camera = require('./Camera');

    /**
     * @namespace navigator
     */

    /**
     * iOS-only parameters that specify the anchor element location and arrow
     * direction of the popover when selecting images from an iPad's library
     * or album.
     * Note that the size of the popover may change to adjust to the
     * direction of the arrow and orientation of the screen.  Make sure to
     * account for orientation changes when specifying the anchor element
     * location.
     * @module CameraPopoverOptions
     * @param {Number} [x=0] - x pixel coordinate of screen element onto which to anchor the popover.
     * @param {Number} [y=32] - y pixel coordinate of screen element onto which to anchor the popover.
     * @param {Number} [width=320] - width, in pixels, of the screen element onto which to anchor the popover.
     * @param {Number} [height=480] - height, in pixels, of the screen element onto which to anchor the popover.
     * @param {module:Camera.PopoverArrowDirection} [arrowDir=ARROW_ANY] - Direction the arrow on the popover should point.
     * @param {Number} [popoverWidth=0] - width of the popover (0 or not specified will use apple's default width).
     * @param {Number} [popoverHeight=0] - height of the popover (0 or not specified will use apple's default height).
     */
    var CameraPopoverOptions = function(
      x,
      y,
      width,
      height,
      arrowDir,
      popoverWidth,
      popoverHeight
    ) {
      // information of rectangle that popover should be anchored to
      this.x = x || 0;
      this.y = y || 32;
      this.width = width || 320;
      this.height = height || 480;
      this.arrowDir = arrowDir || Camera.PopoverArrowDirection.ARROW_ANY;
      this.popoverWidth = popoverWidth || 0;
      this.popoverHeight = popoverHeight || 0;
    };

    module.exports = CameraPopoverOptions;
  });
};
