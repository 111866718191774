import { CoinscrapApi } from '../../../generic/Apis';

export const defaultValidation = (
  sessionData: any,
  _?: any,
  _1?: any,
  enabledRoles: string[] = []
) => {
  const isValid = sessionData && sessionData.token;
  if (isValid && enabledRoles) {
    try {
      const token = JSON.parse(
        Buffer.from(sessionData.token.split('.')[1], 'base64').toString()
      );
      const rolNotFounded = enabledRoles.find(
        rol =>
          !(token?.roles || []).find(
            (tokenRol: { identifier: string }) => tokenRol.identifier === rol
          )
      );
      return !rolNotFounded ? true : null;
    } catch (e) {
      return false;
    }
  }
  return isValid;
};

export const defaultBackendCheck = async (
  sessionData: any,
  coinscrapApi: CoinscrapApi,
  _1?: any,
  enabledRoles: string[] = []
) => {
  let isValid = sessionData && sessionData.token;
  try {
    if (sessionData && sessionData.token) {
      const user = await coinscrapApi.checkUser(sessionData.token);
      if (user && (user.id || user._id)) {
        isValid = true;
      } else {
      }
    }
  } catch (e) {
    isValid = false;
  }

  if (isValid && enabledRoles) {
    try {
      const token = JSON.parse(
        Buffer.from(sessionData.token.split('.')[1], 'base64').toString()
      );
      const rolNotFounded = enabledRoles.find(
        rol =>
          !(token?.roles || []).find(
            (tokenRol: { identifier: string }) => tokenRol.identifier === rol
          )
      );
      return !rolNotFounded ? true : null;
    } catch (e) {
      return false;
    }
  }
  return isValid;
};

export enum SESSION_STATUSES {
  'NOT_CREATED' = 'NOT_CREATED',
  'CHEKING' = 'CREATING',
  'CREATED' = 'CREATED',
}
