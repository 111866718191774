import React, { FunctionComponent } from 'react';

import MuiTypography from '@material-ui/core/Typography';

import { Logger } from '../../../../generic/utils';
import { InternationalizationWrapper } from '../../../InternationalizationWrapper';
import { Trans } from '../../../InternationalizationWrapper/export';
import {
  applyFunctionalityStyles,
  checkStyleNames,
  generateProperties,
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
} from '../common';
import { componentsOverrides } from '../userOverrides';
import { ClassProperties, ComponentStyle, enumKey, Props } from './interface';

const console = new Logger('text');

if (console) {
  //Just for usage
}

export const TextContext = React.createContext<{ keyColor: string }>({
  keyColor: 'primary',
});

const e = React.createElement;

export const Text: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  children,
  onClick,
  noT = false,
  ...props
}) => {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      style,
      children,
      onClick,
      noT,
      ...props,
    });
  }
  checkStyleNames(enumKey, props);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Inyectar estilos ad hoc -----------------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { clickable: !!onClick }
  );

  //Others --------------------------------------------------
  const i18nContext = InternationalizationWrapper.use();
  if (i18nContext) {
    console.log(
      'ºº Text i18n CHILDRENS ' + id,
      /*children,*/ React.Children.toArray(children)
    );
  }

  // Return --------------------------------------------------
  if (overrides?.component) {
    return overrides.component({
      _componentStyle: componentStyle,
      styleNames,
      id,
      clickable: !!onClick,
      style,
      children:
        i18nContext && !noT ? children && e(Trans, { id }, children) : children,
      onClick,
      ...props,
    });
  }
  return e(
    TextContext.Provider,
    { value: { keyColor: 'primary' } },
    e(
      MuiTypography,
      {
        ...generateProperties(
          parentReference,
          enumKey,
          componentReference,
          coreId,
          id,
          true
        ),
        color: 'primary',
        style: componentStyle,
        onClick,
        className: `${(styleNames || '').trim()}`,
      },
      i18nContext && !noT ? children && e(Trans, { id }, children) : children
    )
  );
};
