export const wrapperIdentifier = 'vinculations';

export interface Vinculation {
  id: string;
  createDate: string;
  generatedUser: boolean;
  mainUserId: string;
  secondaryUserId: string;
}

export interface UserVinculationPayload {
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
  metadata: string;
}
