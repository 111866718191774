/* eslint-disable react-hooks/rules-of-hooks */
import React, { FunctionComponent, useEffect } from 'react';
import { useProvider } from '../generic/use';
import {
  wrapProvider,
  extractValue,
  generateVariables,
  generateContext,
  bindReducers,
  WrapperVariablesDefinition,
  WrapperReducersDefinition,
  WrapperFunctionsDefinition,
  Logger,
  LogicFunction,
  RenderFunction,
  VirtualsGenerator,
} from '../generic/utils';
import { ApplicationWrapper } from './ApplicationWrapper';
let i = 0;

export const wrapperCreator = <
  ComponentProperties,
  Variables,
  ExternalVariables,
  Functions,
  ExternalFunctions,
  State,
  BindedWrapperProperties,
  Virtuals
>(
  wrapperName: string,
  wrapperVariables: WrapperVariablesDefinition<Variables & ExternalVariables>,
  wrapperFunctions: WrapperFunctionsDefinition<
    Variables,
    ExternalVariables,
    State,
    Functions,
    ExternalFunctions,
    ComponentProperties,
    BindedWrapperProperties
  >,
  wrapperReducers: WrapperReducersDefinition<
    State,
    ExternalVariables & Variables,
    Functions & ExternalFunctions
  >,
  logic?: LogicFunction<
    ComponentProperties,
    Variables &
      ExternalVariables &
      Functions &
      ExternalFunctions &
      State &
      Virtuals,
    BindedWrapperProperties,
    Variables & ExternalVariables
  >,
  customReturn?: RenderFunction<
    ComponentProperties,
    Variables &
      ExternalVariables &
      Functions &
      ExternalFunctions &
      State &
      Virtuals,
    BindedWrapperProperties
  >,
  wrappersToBindind?: {
    use: (requester?: string) => Partial<BindedWrapperProperties>;
  }[],
  virtualsGenerator: VirtualsGenerator<
    Functions,
    ExternalFunctions,
    Variables,
    ExternalVariables,
    State,
    ComponentProperties,
    BindedWrapperProperties,
    Virtuals
  > = (c, _) =>
    c as Functions &
      ExternalFunctions &
      Variables &
      ExternalVariables &
      State &
      ComponentProperties &
      Virtuals,
  contextToUse = React.createContext<
    Variables &
      ExternalVariables &
      Functions &
      ExternalFunctions &
      State &
      ComponentProperties &
      Virtuals
  >(undefined as any)
) => {
  const console = new Logger(wrapperName);

if (console) {
  //Just for usage
}
  const pathToLocalState = [wrapperName];
  const wrapperContext = contextToUse;
  const wrapperComponent: FunctionComponent<ComponentProperties> & {
    use: (
      requester?: string
    ) => Variables &
      ExternalVariables &
      Functions &
      ExternalFunctions &
      State &
      ComponentProperties;
  } & {
    bindedContext: Variables &
      ExternalVariables &
      Functions &
      ExternalFunctions &
      Virtuals &
      ComponentProperties;
  } = ({ children, ...props }) => {
    const applicationContext = ApplicationWrapper.use();
    const { registerReducer, state, dispatch: dp } = applicationContext || {};
    const dispatch = dp || (() => console.warn('Dispatch not present'));

    const appState: State = extractValue(state || {}, ...pathToLocalState);
    const variables = generateVariables<State, Variables & ExternalVariables>(
      wrapperName,
      wrapperVariables,
      [appState, dispatch]
    );
    const bindedContext = (wrappersToBindind || []).reduce(
      (acc, act) => ({ ...acc, ...act.use() }),
      {} as BindedWrapperProperties
    );
    let temporalContext = generateContext<
      Functions,
      ExternalFunctions,
      Variables,
      ExternalVariables,
      State,
      ComponentProperties,
      BindedWrapperProperties
    >(
      wrapperName,
      variables,
      wrapperFunctions,
      [appState, dispatch],
      props as ComponentProperties,
      bindedContext
    );

    const context = virtualsGenerator(temporalContext, bindedContext);
    useEffect(() => {
      if (registerReducer) {
        bindReducers(
          variables,
          [
            ...wrapperReducers,
            {
              name: wrapperName.toUpperCase() + ' context propert persistor',
              persistState: true,
              reduceFunction: (perState, _, action) => {
                if (action.type === wrapperName + '_propertyPersist') {
                  return {
                    ...perState,
                    [action.payload.name]: action.payload.value,
                  };
                }
                return perState;
              },
            },
          ],
          pathToLocalState,
          registerReducer,
          context
        );
      }
    }, [context, registerReducer, variables]);

    logic &&
      logic(
        wrapperName,
        props as ComponentProperties,
        [context, varName => variables.getVariableSetter(varName)],
        bindedContext
      );
    console.log(
      'WRAPPING ' + wrapperName + ' Wrapper',
      /*JSON.stringify*/ context
    );
    return customReturn
      ? customReturn(
          props as ComponentProperties,
          wrapperContext,
          context,
          typeof children === 'function' ? children(context) : children,
          React.createElement,
          bindedContext
        )
      : wrapProvider(
          wrapperContext,
          context
        )(typeof children === 'function' ? children(context) : children);
  };

  wrapperComponent.use = (requester?: string) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const context = useProvider(wrapperContext, requester);
    console.log(
      wrapperName + (wrapperName === 'navigation' ? ' inner' : ''),
      i++,
      context
    );
    if (
      wrapperName !== 'internationalization' &&
      (!context || context === {})
    ) {
      console.warn(
        'Provider not present in ' +
          requester +
          '. See stack trace for more info'
      );

      /*throw new Error(
        'Provider not present in ' +
          requester +
          '. See stack trace for more info'
      );*/
    }
    return context as Extract<
      typeof context,
      ExternalVariables & ExternalFunctions & State
    >;
  };

  wrapperComponent.bindedContext = {} as any;
  return wrapperComponent;
};
