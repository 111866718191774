import { Logger } from '../../generic/utils';
import { wrapperIdentifier, UserService } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadSessionUser = () => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadSessionUser',
    ({
      componentProps: { enableRoles },
      variablesHandler: { variablesSetter },
      bindedContexts: { sessionData, getInstanceApi, coreFetch },
    }) => async () => {
      let user = await UserService.instance.getter(
        sessionData,
        getInstanceApi(),
        coreFetch,
        { enableRoles }
      );
      variablesSetter('user')(user);
    }
  );

export type UpdateUser = (userData: {
  username?: {
    name: string;
    surname?: string;
  };
  phoneNumber?: string;
  email?: string;
  metadata?: { [k: string]: string | number | any[] };
}) => Promise<void>;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'updateUser',
    ({
      variablesHandler: {
        variablesSetter,
        variables: { user },
      },
      bindedContexts: { getInstanceApi },
    }) => async data => {
      if (user) {
        const { username, email, metadata, phoneNumber } = data || {};
        const usernameComputed = {
          name: user.name,
          surname: user.surname,
          ...username,
        };
        let u: boolean, p: boolean, e: boolean, m: boolean;
        let error;
        try {
          if (username && username.name) {
            await getInstanceApi().updateUserName(
              user.id || user._id,
              usernameComputed
            );
            u = true;
          }
          if (phoneNumber) {
            await getInstanceApi().updateUserPhoneNumber(
              user.id || user._id,
              phoneNumber
            );
            p = true;
          }
          if (email) {
            await getInstanceApi().updateUserEmail(user.id || user._id, email);
            e = true;
          }
          if (metadata) {
            await getInstanceApi().updateUserMetadata(
              user.id || user._id,
              metadata
            );
            m = true;
          }
        } catch (e) {
          error = e;
        }
        variablesSetter('user')(userold => {
          if (!userold) {
            return userold;
          }
          const userNew = { ...userold };
          if (u) {
            userNew.name = usernameComputed.name;
            userNew.surname = usernameComputed.surname;
          }
          if (p) {
            userNew.phoneNumber = data.phoneNumber || user.phoneNumber;
          }
          if (e) {
            userNew.email = data.email || user.email;
          }
          if (m) {
            userNew.metadata = data.metadata;
          }
          return userNew;
        });
        if (error) {
          throw error;
        }
      }
    }
  );
