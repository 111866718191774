import {
  OnChange,
  InputValue,
  InputSliderStyle,
  Disabled,
  InputSliderExpectedStyles,
  GenericComponentProps,
  InputSliderMax,
  InputSliderMin,
  InputSliderStep,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputSlider;

export interface ClassProperties {}

export type ComponentStyle = InputSliderStyle;
export type ExpectedStyles = InputSliderExpectedStyles;
export type Props = GenericComponentProps<InputSliderStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  max?: InputSliderMax;
  min?: InputSliderMin;
  step?: InputSliderStep;
};
