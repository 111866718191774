/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Props, enumKey, ClassProperties } from './interface';
import { checkStyleNames, generateProperties } from '../common';

const e = React.createElement;

export const SwipeItem: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  children,
  noT = false,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Return -----------------------------------------------------
  return e(
    'option',
    {
      ...generateProperties(
        parentReference,
        enumKey,
        'container',
        coreId,
        id,
        true
      ),
      value,
      disabled,
    },
    children
  );
};
