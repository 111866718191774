import { policyTag } from './policy';
import { tapTag } from './tap';
import { formatDetectionTag } from './formatDetection';
import { display } from './display';
import { colorScheme } from './colorScheme';
import { favicon } from './favicon';

export const tagCreation = (fav?: string) => {
  if (fav) {
    favicon.insert(fav);
  } else {
    const tags = [display, policyTag, colorScheme, tapTag, formatDetectionTag];
    tags.forEach(t => t.insert());
  }
};
