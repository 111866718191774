import {
  ModalStyle,
  ModalExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.modal;

export interface ClassProperties {}

export type ComponentStyle = ModalStyle;
export type ExpectedStyles = ModalExpectedStyles;
export type Props = GenericComponentProps<ModalStyle> & {
  open?: boolean;
  nested?: boolean;
  fullScreen?: boolean;
  transition?: boolean;
  onClose?: () => void;
  disableClickAway?: boolean;
};
