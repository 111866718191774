import {
  OnChange,
  InputValue,
  InputLabel,
  InputCheckStyle,
  Disabled,
  InputCheckExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputCheck;

export interface ClassProperties {}

export type ComponentStyle = InputCheckStyle;
export type ExpectedStyles = InputCheckExpectedStyles;
export type Props = GenericComponentProps<InputCheckStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  checkedIcon?: any;
  label?: InputLabel;
};
