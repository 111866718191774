import { Logger, ExternalVar } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

declare var Fingerprint: ExternalVar;
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('isAvailable', _ => async () => {
    return await new Promise(res => {
      const s = (...args: ExternalVar[]) => {
        console.log('SUCCESS IsAvailable RESPONSE ARGS', args);
        res(true);
      };
      const e = (...args: ExternalVar[]) => {
        console.log('ERROR IsAvailable RESPONSE ARGS', args);
        res(false);
      };
      // TODO Checkear si existe Fingerprint
      try {
        if (Fingerprint) {
          Fingerprint.isAvailable(s, e);
        } else {
          throw new Error('No present');
        }
      } catch (error) {
        e(error);
      }
    });
  });

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('show', _ => async optionsOrBuilder => {
    return await new Promise(async res => {
      let availableMethod: string;
      try {
        availableMethod = await new Promise((resolve, reject) => {
          const availableOk = (result: string) => {
            resolve(result);
          };

          const availableError = (error: ExternalVar) => {
            reject(error);
          };
          Fingerprint.isAvailable(availableOk, availableError);
        });
      } catch (e) {
        res(false);
        return;
      }
      console.log('AVAILABLE METHOD', availableMethod);

      const s = (...args: ExternalVar[]) => {
        console.log('SUCCESS Show RESPONSE ARGS', args);
        res(true);
      };
      const e = (...args: ExternalVar[]) => {
        console.log('ERROR Show RESPONSE ARGS', args);
        res(false);
      };

      let generatedOptions = {};
      if (typeof optionsOrBuilder === 'function') {
        generatedOptions = await optionsOrBuilder(availableMethod);
      } else {
        generatedOptions = optionsOrBuilder;
      }

      Fingerprint.show(generatedOptions, s, e);
    });
  });
