import React, { FunctionComponent } from 'react';

import { Snackbar } from '@material-ui/core';
import { Transition } from './others/Transition';
import { Props, enumKey, ComponentStyle, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
} from '../common';

const e = React.createElement;

export const Alert: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  alertOptions,
  closeAlert,
  alertMessage,
  ...styleKeys
}) => {
  //checkStyleNames(enumKey, props);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = mergeStylesWithInlineStyles<ComponentStyle>(
    mainStyle,
    style || {}
  );
  if (componentStyle) {
    // TODO: Apply correctly the component style
  }
  // Return ---------------------------------------------------
  return e(Snackbar, {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    style: { paddingTop: 40, position: 'absolute' },
    open: true,
    autoHideDuration: (alertOptions && alertOptions.duration) || 2000,
    onClose: closeAlert,
    TransitionComponent: Transition as any, // TODO: check this
    ContentProps: {
      'aria-describedby': 'message-id',
    },
    message: e('span', { id: 'message-id' }, alertMessage),
    onClick: closeAlert,
  });
};
