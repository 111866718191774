import {
  OnDateChange,
  InputDateStyle,
  InputDateExpectedStyles,
  OnClick,
  GenericComponentProps,
  InputDateDefaultValue,
  InputDateIcon,
  InputDateValue,
  InputDateLabel,
  Disabled,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputDate;

export interface ClassProperties {
  variant: 'filled' | 'outlined';
  mode: 'dialog' | 'inline' | 'static';
}

export type ComponentStyle = InputDateStyle;
export type ExpectedStyles = InputDateExpectedStyles;
export type Props = GenericComponentProps<InputDateStyle> & {
  onClick?: OnClick;
  onChange?: OnDateChange;
  fullClickable?: boolean;
  disabled?: Disabled;
  disableUnderline?: boolean;
  format?: string;
  error?: string;
  value?: InputDateValue;
  label?: InputDateLabel;
  defaultValue?: InputDateDefaultValue;
  placeholder?: string;
  maxDate?: any;
  minDate?: any;
  pickerRef?: any;
  pickOnSelect?: boolean;
  dateIcon?: InputDateIcon;
  toolbarComponent?: any;
  onOpen?: any;
  readOnly?: boolean;
  onClose?: any;
  open?: any;
};
