import { ComponentStyles, CommonUiConfiguration } from '../interfaces';
import { FullWidth, FullHeight, ExpectedStyle } from './propsDefinition';
import { Logger } from '../../../generic/utils';

import button from './Button/baseStyle';
import enfatize from './Enfatize/baseStyle';
import image from './Image/baseStyle';
import imageSelector from './ImageSelector/baseStyle';
import input from './Input/baseStyle';
import inputCheck from './InputCheck/baseStyle';
import inputDate from './InputDate/baseStyle';
import inputImage from './InputImage/baseStyle';
import inputRadio from './InputRadio/baseStyle';
import inputSelect from './InputSelect/baseStyle';
import inputSlider from './InputSlider/baseStyle';
import inputSuggest from './InputSuggest/baseStyle';
import inputSwipe from './InputSwipe/baseStyle';
import inputSwitch from './InputSwitch/baseStyle';
import layout from './Layout/baseStyle';
import layoutAlert from './LayoutAlert/baseStyle';
import layoutModal from './Modal/baseStyle';
import layoutLoading from './LayoutLoading/baseStyle';
import radioOption from './RadioOption/baseStyle';
import selectItem from './SelectItem/baseStyle';
import sidebar from './Sidebar/baseStyle';
import slider from './Slider/baseStyle';
import softSwitcher from './SoftSwitcher/baseStyle';
import swipeItem from './SwipeItem/baseStyle';
import text from './Text/baseStyle';
import textCurrency from './TextCurrency/baseStyle';
import textField from './TextField/baseStyle';
import tooltip from './Tooltip/baseStyle';
import view from './View/baseStyle';

import merge from 'lodash/merge';
import { UiWrapper } from '..';
import { componentsConfiguration } from './userDefinitions';
import { getMatchedQuerys } from '../common';

const console = new Logger('common');

if (console) {
  //Just for usage
}
const bsa: {
  [key in COMPONENTS]: <E>(
    styles: CommonUiConfiguration | undefined,
    styleKeys: { [k: string]: ExpectedStyle<E> }
  ) => any;
} = {
  button,
  enfatize,
  image,
  imageSelector,
  input,
  inputCheck,
  inputDate,
  inputImage,
  inputRadio,
  inputSelect,
  inputSlider,
  inputSuggest,
  inputSwipe,
  inputSwitch,
  layout,
  radioOption,
  selectItem,
  sidebar,
  slider,
  softSwitcher,
  swipeItem,
  text,
  textCurrency,
  textField,
  tooltip,
  view,
  alert: layoutAlert,
  modal: layoutModal,
  loading: layoutLoading,
};
export interface CommonComponentsProps {
  fullWidth: FullWidth;
  fullHeight: FullHeight;
}

export enum COMPONENTS {
  button = 'button',
  enfatize = 'enfatize',
  image = 'image',
  imageSelector = 'imageSelector',
  input = 'input',
  inputCheck = 'inputCheck',
  inputDate = 'inputDate',
  inputImage = 'inputImage',
  inputRadio = 'inputRadio',
  inputSelect = 'inputSelect',
  inputSlider = 'inputSlider',
  inputSuggest = 'inputSuggest',
  inputSwipe = 'inputSwipe',
  inputSwitch = 'inputSwitch',
  selectItem = 'selectItem',
  layout = 'layout',
  radioOption = 'radioOption',
  sidebar = 'sidebar',
  slider = 'slider',
  softSwitcher = 'softSwitcher',
  swipeItem = 'swipeItem',
  text = 'text',
  textCurrency = 'textCurrency',
  textField = 'textField',
  tooltip = 'tooltip',
  view = 'view',

  modal = 'modal',
  alert = 'alert',
  loading = 'loading',
}

/*const ComponentValuesWithSufix =*/ Object.values(COMPONENTS).map(
  style => style + 'Component'
);

export const getCommonConfiguration = (): CommonUiConfiguration | undefined => {
  const uiContext = UiWrapper.use();
  if (!uiContext) {
    return {};
  }
  console.log('º UI CONTEXT', uiContext);
  const { config } = uiContext;
  return config;
};

export const checkStyleNames = (_key: COMPONENTS, _props: any) => {
  const invalidStyleKeys = Object.keys(_props)
    .filter(styleKey => !/.St$/.exec(styleKey))
    .filter(key => key !== 'cleared');

  if (invalidStyleKeys && invalidStyleKeys.length) {
    console.warn(
      'El componente ' +
        _key +
        ' esta recibiendo propiedades inválidas: [' +
        invalidStyleKeys +
        ']. Si intentas configurar un estilo recuerda que debe terminar en St, si es una propiedad necesaria para el funcionamiento recuerda no dejarlo por defecto en "...props"'
    );
  }
};
export const getComponentUserStyles = <T>(
  key: COMPONENTS
): ComponentStyles<T, {}> => {
  const { styles: globalUiDefinition } = { styles: {} };
  console.log(
    key + ' - USER UI CONFIGURATION',
    JSON.parse(JSON.stringify(globalUiDefinition))
  );
  const def = componentsConfiguration[key];
  if (def) {
    const componentDefinition = def;
    console.log(
      key + ' - USER UI CONFIGURATION FOR COMPONENT',
      JSON.parse(JSON.stringify(componentDefinition))
    );
    const { styles: componenStylesDefinition } = componentDefinition || {
      styles: { default: {} },
    };
    if (componenStylesDefinition) {
      console.log(
        key + ' - USER UI CONFIGURATION STYLES DEFINITION FOR COMPONENT',
        JSON.parse(JSON.stringify(componenStylesDefinition))
      );
      return applyQuerys({
        ...componenStylesDefinition,
        default: { ...componenStylesDefinition?.default },
      }) as ComponentStyles<T, {}>;
    }
  }
  return {
    default: {},
  } as ComponentStyles<T, {}>;
};

const applyQuerys = <T extends { [k: string]: any }>(object: T): T => {
  const matchedQuerys = getMatchedQuerys();
  let objectAplied: any = {};

  Object.keys(object).forEach(propName => {
    if (
      /default$/.exec(propName) ||
      /.*St$/.exec(propName) ||
      /.*Mode$/.exec(propName) ||
      /.*Component$/.exec(propName)
    ) {
      objectAplied[propName] = applyQuerys(object[propName]);
    }
  });
  if (object.$mediaQueries) {
    Object.keys(object.$mediaQueries)
      .filter(x => matchedQuerys[x])
      .forEach(query => {
        objectAplied = merge(objectAplied, object.$mediaQueries[query]);
      });
  }
  return merge({}, object, objectAplied);
};

export const getStylesOfUserMergedWithBase = <T extends { [k: string]: any }>(
  componentKey: COMPONENTS,
  styleKeys: { [k: string]: any },
  supressWarnings: boolean = false
  //baseStyles: (configuration: CommonUiConfiguration | undefined) => T
): [T, string] => {
  console.log(componentKey + ' - RECEIVED PROPS', styleKeys);
  const componentUserStyles = applyQuerys(
    getComponentUserStyles<T>(componentKey)
  );
  console.log(
    componentKey + ' - USER STYLES OBTAINED',
    JSON.parse(JSON.stringify(componentUserStyles))
  );
  let generatedStyle: { [k: string]: any } = {};
  let names = '';
  const base = bsa[componentKey](getCommonConfiguration(), styleKeys);

  // Do not merge with user styles
  const clearComponent = !!(styleKeys as any)['cleared'];
  // Config de Estilo -----------------------------------------------------
  //console.log('Text', props);
  if (!clearComponent) {
    Object.keys(styleKeys)
      // Filtrado de claves por terminacion St
      .filter(propName => /.*St$/.exec(propName))
      // Filtrado de valores positivos
      .filter(propName => styleKeys[propName])
      // Aplicando cada St recibido
      .forEach(styleKey => {
        names = names + ' ' + styleKey;
        const styleKeydefinition: T = componentUserStyles[styleKey];
        console.log(
          componentKey + ' CHEKING STYLE KEY ' + styleKey,
          styleKeydefinition
        );
        if (styleKeydefinition) {
          // Mergeado del St recibido a los estilos
          Object.entries(styleKeydefinition).forEach(([styleProperty]) => {
            if (
              /(.*Component)|(.*Mode)$/.exec(
                styleProperty
              ) /*ComponentValuesWithSufix.includes(styleProperty as COMPONENTS)*/
            ) {
              // Propiedad de estilo de subComponentes
              const subStyles: any = styleKeydefinition[styleProperty] as any;
              // Remove replacing sufix
              //const propertyWithoutSufix = styleProperty.replace('Component', '');
              if (typeof subStyles === 'object' && subStyles !== null) {
                generatedStyle[styleProperty] = merge(
                  {},
                  generatedStyle[styleProperty],
                  subStyles as { [k: string]: any }
                );
              } else {
                console.error(
                  componentKey +
                    ', se esta enviado una propiedad de sub estilo incorrecta: ',
                  styleProperty
                );
              }
            } else {
              // Propiedad estandar
              const property = styleKeydefinition[styleProperty];
              if (typeof property === 'object' && property !== null) {
                generatedStyle[styleProperty] = merge(
                  {},
                  generatedStyle[styleProperty],
                  property as { [k: string]: any }
                );
              } else {
                generatedStyle[styleProperty] =
                  styleKeydefinition[styleProperty];
              }
            }
          });
        } else {
          if (!supressWarnings) {
            console.error(
              componentKey +
                ', se esta enviado una prop de estilo no definida:',
              styleKey +
                '. Asegurate que existe el estilo ' +
                styleKey +
                ' en el fichero de configuracion de estilos de ' +
                componentKey
            );
          }
        }
      });
    console.log(
      componentKey + ' - USER STYLES PROCESED',
      names,
      JSON.parse(JSON.stringify(generatedStyle))
    );
    generatedStyle = merge({}, componentUserStyles.default, generatedStyle);
    console.log(
      componentKey + ' - STYLES WITH DEFAULT',
      names,
      JSON.parse(JSON.stringify(generatedStyle))
    );

    console.log(componentKey + ' - BASE PREV UNION STYLES', base);
  }

  const toret = merge({}, base, generatedStyle);
  console.log(
    componentKey + ' - GLOBAL STYLES',
    JSON.parse(JSON.stringify(base)),
    JSON.parse(JSON.stringify(componentUserStyles))
  );
  console.log(
    componentKey + ' - STYLES RESULT',
    JSON.parse(JSON.stringify(toret))
  );
  return [toret as T, names];
};

/*const availableTokens = ['margin', 'padding']; // Basically, numeric values that must be parametrized
const advicedProperties = [];*/

export const mergeStylesWithInlineStyles = <T>(
  containerStyles: T,
  inlineStyles: T
): T & { [k: string]: any } => {
  // TODO: Set here a check for properties that are no permitted in inlineStyles
  //   I think only margins and paddins are able to set inline
  return merge({}, containerStyles, inlineStyles) as any;
};

export const applyFunctionalityStyles = <T>(
  containerStyles: T,
  functionalities: { [k: string]: boolean | undefined }
): T & { [k: string]: any } => {
  let toret = { ...containerStyles };
  Object.keys(functionalities)
    .filter(k => functionalities[k])
    .forEach(funName => {
      toret = merge(
        {},
        toret,
        (containerStyles as any)[funName + 'Mode'] as any
      );
    });
  return toret;
};

export const extractSubchildStyles = <T>(
  componentKey: COMPONENTS,
  containerStyle: { [k: string]: any }
): T => {
  return containerStyle[componentKey.toString() + 'Component'];
};

export const generateSubChildStyles = <T>(
  componentKey: COMPONENTS,
  styleKeys: { [k: string]: any },
  containerStyle: { [k: string]: any },
  style: { [k: string]: any } | undefined,
  mergeWithStyles = true
): [T, string] => {
  if (mergeWithStyles) {
    const [
      subChildStyleConfig,
      subChildStyleNames,
    ] = getStylesOfUserMergedWithBase<T>(componentKey, styleKeys, true);
    const subStyle = merge(
      {},
      subChildStyleConfig,
      containerStyle[componentKey.toString() + 'Component'],
      (style || {})[componentKey.toString() + 'Component']
    );
    return [subStyle, subChildStyleNames];
  } else {
    const subStyle = merge(
      {},
      bsa[componentKey](getCommonConfiguration(), styleKeys),
      containerStyle[componentKey.toString()],
      (style || {})[componentKey.toString()]
    );
    return [subStyle, ''];
  }
};

export const generateProperties = (
  parentReference?: any,
  component?: COMPONENTS,
  componentReference?: any,
  coreId?: any,
  id?: any,
  toNative?: boolean,
  toExternal?: boolean
) => {
  let toret;
  if (toNative) {
    toret = {
      corereference: parentReference
        ? parentReference
        : componentReference === 'root'
        ? componentReference
        : component + '-' + componentReference + 'Component',
      coreid: coreId,
      id,
      coretype: `Core-${(component || 'ExternalComponent').toString()}`,
    };
  } else if (toExternal) {
    toret = {
      corereference: parentReference
        ? parentReference + '-' + componentReference + 'Component'
        : componentReference === 'root'
        ? componentReference
        : component + '-' + componentReference + 'Component',
      coreid: coreId,
      id,
      coretype: `Core-ExternalComponent`,
    };
  } else {
    toret = {
      id,
      coreId,
      componentReference: componentReference,
      parentReference:
        (parentReference || component) +
        '-' +
        componentReference +
        (componentReference === 'root' ? '' : 'Component'),
    };
  }
  return toret;
};
