import { Logger } from '../../generic/utils';
import { Builder, CoreStateHandler, CoreVariablesHandler } from '../common';
const console = new Logger('wrapperBuilder');

if (console) {
  //Just for usage
}
export class LogicBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes
> implements Builder {
  public wrapperName!: string;

  public stateHandler!: CoreStateHandler<State, DispatchTypes>;
  public variablesHandler!: CoreVariablesHandler<
    InternalVariables,
    Variables,
    ExternalVariables
  >;
  public functions!: InternalFunctions & Functions & ExternalFunctions;
  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;
  public virtuals!: Virtuals;

  private _logic!: (context: {
    stateHandler: CoreStateHandler<State, DispatchTypes>;
    variablesHandler: CoreVariablesHandler<
      InternalVariables,
      Variables,
      ExternalVariables
    >;
    functions: InternalFunctions & Functions & ExternalFunctions;
    componentProps: ComponentPropsDefinition;
    bindedContexts: BindedContexts;
    virtuals: Virtuals;
  }) => any;

  setLogic(
    value: (context: {
      stateHandler: CoreStateHandler<State, DispatchTypes>;
      variablesHandler: CoreVariablesHandler<
        InternalVariables,
        Variables,
        ExternalVariables
      >;
      functions: InternalFunctions & Functions & ExternalFunctions;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
    }) => any
  ) {
    console.log('ºº Setting logic');
    this._logic = value;
  }
  isConfigured() {
    return !!this._logic;
  }
  apply() {
    console.log('ºº Appling logic');
    return this._logic(this);
  }
}
