import {
  OnSelectImage,
  InputImageStyle,
  Disabled,
  InputImageExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputImage;

export interface ClassProperties {}

export type ComponentStyle = InputImageStyle;
export type ExpectedStyles = InputImageExpectedStyles;
export type Props = GenericComponentProps<InputImageStyle> & {
  name?: string;
  text?: string;
  onSelectImage?: OnSelectImage;
  disabled?: Disabled;
  defaultImage?: any;
};
