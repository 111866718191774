import { DOCUMENTS_SIGN_MICROSERVICE_GATEWAY_PREFIX } from '../../generic/Apis';
import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { Envelope, wrapperIdentifier } from './common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const functionsRegister = wrapperBuilder.getFunctionsRegisterInstance();

functionsRegister.addFunction(
  'loadUserEnvelopes',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async userId => {
      const envelopes: Envelope[] = await api.getUserEnvelopes(userId);
      console.log('Envelopes', envelopes);
      variablesSetter('envelopes')(envelopes);
      /*contextModifier({
      testCompletions,
    })*/
    };
  }
);

functionsRegister.addFunction(
  'refresh',
  ({ coreDynamicFunctionGetter, bindedContexts: { user } }) => {
    return async () => {
      if (user) {
        await coreDynamicFunctionGetter('loadUserEnvelopes')(user.id);
      }
    };
  }
);

functionsRegister.addFunction(
  'getViewSignedUrl',
  ({ bindedContexts: { getInstanceApi } }) => {
    return envelopeId => {
      const opts = getInstanceApi().getCurrentOptions();
      return (
        opts.baseUrl +
        '/' +
        opts.prefix +
        '/' +
        DOCUMENTS_SIGN_MICROSERVICE_GATEWAY_PREFIX +
        '/envelopes/' +
        envelopeId +
        '/documents/signed/view'
      ).replace(/([^:]\/)\/+/g, '$1');
    };
  }
);

functionsRegister.addFunction(
  'getSignUrl',
  ({ bindedContexts: { getInstanceApi } }) => {
    return envelopeId => {
      const opts = getInstanceApi().getCurrentOptions();
      return (
        opts.baseUrl +
        '/' +
        opts.prefix +
        '/' +
        DOCUMENTS_SIGN_MICROSERVICE_GATEWAY_PREFIX +
        '/envelopes/' +
        envelopeId +
        '/signRequest/sign'
      ).replace(/([^:]\/)\/+/g, '$1');
    };
  }
);

functionsRegister.addFunction(
  'getPreSignedCombinedSignedEnvelopeUrl',
  ({ bindedContexts: { getInstanceApi } }) => async envelopeId => {
    const api = getInstanceApi();
    return await api.getPreSignedCombinedSignedEnvelopeUrl(envelopeId);
  }
);

functionsRegister.addFunction(
  'getPreSignedDocumentUrl',
  ({ bindedContexts: { getInstanceApi } }) => async documentId => {
    const api = getInstanceApi();
    return await api.getPreSignedDocumentUrl(documentId);
  }
);
