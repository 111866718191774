import { ResolveInjection } from '../../generic/contextInjection';
import { HookResponse } from '../../generic/interfaces';
import { useEffect, useState } from 'react';
import { wrapperBuilder } from './build';
import { RuleDefinitionsWrapper } from '../RuleDefinitionsWrapper';
import { Rule } from './common';
import { FieldDefinition } from 'entityWrappers/RuleDefinitionsWrapper/common';

export type UseRule = (
  identifier: string,
  entityId?: string
) => HookResponse<{
  rule: Rule | undefined | null;
  values: { [k: string]: FieldDefinition & { value: any } };
  setNewValue: (propName: string, val: any) => void;
}>;
wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addHook('useRule', (identifier, entityId) => {
    const { getRule, rules } = ResolveInjection<
      typeof wrapperBuilder.generatedComponent
    >('rulesWrapper').use();
    const { ruleDefinitions } = RuleDefinitionsWrapper.use();
    const [values, setValues] = useState({});
    const rule = entityId ? getRule(identifier, entityId) : undefined;
    const ruleId = rule?.id;
    useEffect(() => {
      if (ruleDefinitions && rules) {
        const ruleDefinition = ruleDefinitions.find(
          rd => rd.identifier === identifier
        );
        setValues(
          ruleDefinition?.fieldDefinitions?.reduce((acc, act) => {
            const fieldValue = rule?.value?.fieldValues?.find(
              fd => fd.identifier === act.identifier
            );
            return {
              ...acc,
              [act.identifier]: {
                ...act,
                value: fieldValue?.data?.value || act?.metadata?.defaultValue,
              },
            };
          }, {}) || {}
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ruleId, ruleDefinitions?.length, rules?.length]);

    return {
      payload: {
        rule: rules && ruleDefinitions && rule,
        values,
        setNewValue: (pname, val) =>
          setValues(currVals => ({
            ...currVals,
            [pname]: { ...(currVals as any)[pname], value: val },
          })),
      },
      loading: rules === undefined || ruleDefinitions === undefined,
    };
  });
