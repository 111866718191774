export const mediaQueryRegularExpresion = new RegExp(/[azA-Z0-9]*Mq/);

export let querysConfigured: {
  [k: string]: { query: string } | MediaQueryEstructure;
} = {};

export interface MediaQueryEstructure {
  minHeight: string | number;
  maxHeight: string | number;
  minDeviceHeight: string | number;
  maxDeviceHeight: string | number;

  minWidth: string | number;
  maxWidth: string | number;
  minDeviceWidth: string | number;
  maxDeviceWidth: string | number;
}

export const defineMedia = (
  identifier: string,
  query: string | MediaQueryEstructure
) => {
  if (!mediaQueryRegularExpresion.exec(identifier)) {
    throw new Error('Los identificadores de mediaQueries deben terminar en Mq');
  }
  querysConfigured[identifier] = typeof query === 'string' ? { query } : query;
};
