import { Rule } from '../../entityWrappers/RulesWrapper/common';
import { CoreConnectionError, CoreFetch, CoreRequestError, RequestOptions } from '../../wrappers/BackendWrapper/common';
import { HandlersStack, Logger } from '../utils';
import { PayContributionResponse } from './interfaces';

const console = new Logger('api');

if (console) {
  //Just for usage
}

export enum OPERATION_TYPES {
  VALIDATE_CREDENTIALS = 'VALIDATE_CREDENTIALS',
  GET_INSTITUTIONS = 'GET_INSTITUTIONS',
  GET_PRODUCT_DEFINITIONS = 'GET_PRODUCT_DEFINITIONS',
  CREATE_INSTITUTION_ACCOUNT = 'CREATE_INSTITUTION_ACCOUNT',
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
}

export enum OPERATION_MODES {
  SYNC = 'SYNC',
  ASYNC = 'ASYNC',
  DEFERRED = 'DEFERRED',
}

export enum ACTION_TYPES {
  OTP = 'OTP',
  DISCARD_OPERATION = 'DISCARD_OPERATION',
}

export enum ACTION_PAYLOAD_TYPES {
  OTP = 'OTP',
}

export type ErrorHandlers = HandlersStack<ErrorHandler>; // { [k: string]: ErrorHandler | undefined };
export interface ApiOptions {
  client: CoreFetch;
  prefix: string;
  baseUrl: string;
  token?: string;
}

export type ErrorHandler = (error: Error) => void;
export const NOTIFICATIONS_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_NS || '/notifications/';
export const DOCUMENTS_SIGN_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_DSS || '/sign-envelopes/';
export const FINANCIAL_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_FS || '/financial/';
export const RULES_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_RS || '/rules/';
export const USERS_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_US || '/users/';
export const CUESTIONAIRES_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_QS || '/cuestionaires/';
export const IDENTITY_DOCUMENTS_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_IDS || '/idocuments/';
export const ANALYTICS_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_ANALYTICS || '/anltcs/';
export const ALLIANZ_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_ALLIANZ || '/allianz/';
export const TARGETS_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_TS || '/targets/';
export const INDICATORS_MICROSERVICE_GATEWAY_PREFIX =
  window.__CLIENT_ENV.CLIENT_ENV_GATEWAY_URL_IS || '/indicators/';

export class CoinscrapApi {
  private prefix: string;
  private baseUrlApi: string;
  private baseUrlAllianz: string;
  private baseUrlCs: string;
  private baseUrlNs: string;
  private baseUrlTs: string;
  private baseUrlRs: string;
  private baseUrlQs: string;
  private baseUrlFs: string;
  private baseUrlIs: string;
  private baseUrlUs: string;
  private baseUrlDss: string;
  private baseUrlIds: string;
  private baseUrlAnalytics: string;
  private baseUrl: string;
  public token: string | undefined;

  private client: CoreFetch;

  constructor({ client, prefix, baseUrl, token }: ApiOptions) {
    console.log('ºº API CONFIGURED WITH', baseUrl);
    if (!baseUrl) {
      console.log('ºº Bad API URL SPECIFICATION', baseUrl);
      throw new Error('Bad Url');
    }
    this.client = client;
    this.baseUrlApi = (' ' + baseUrl + prefix)
      .replace('\r', '')
      .replace(/([^:]\/)\/+/g, '$1')
      .trim();
    this.prefix = prefix;
    this.token = token;

    this.baseUrlAllianz = this.baseUrlApi + ALLIANZ_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlCs = this.baseUrlApi + 'caser/';
    this.baseUrlNs =
      this.baseUrlApi + NOTIFICATIONS_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlTs = this.baseUrlApi + TARGETS_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlIs = this.baseUrlApi + INDICATORS_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlUs = this.baseUrlApi + USERS_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlFs = this.baseUrlApi + FINANCIAL_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlQs =
      this.baseUrlApi + CUESTIONAIRES_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlRs = this.baseUrlApi + RULES_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlDss =
      this.baseUrlApi + DOCUMENTS_SIGN_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlIds =
      this.baseUrlApi + IDENTITY_DOCUMENTS_MICROSERVICE_GATEWAY_PREFIX;
    this.baseUrlAnalytics =
      this.baseUrlApi + ANALYTICS_MICROSERVICE_GATEWAY_PREFIX;
    console.log('baseUrlAnalytics', this.baseUrlAnalytics);
    this.baseUrl = baseUrl;
  }

  microserviceCall(servicename: string) {
    return async (
      url: string,
      method: string = 'GET',
      requestData: { [k: string]: any } | undefined = undefined,
      options: RequestOptions & {
        resetHeaders?: boolean;
        token?: string;
      } = {
        formData: false,
        resetHeaders: false,
      }
    ) => {
      return await this.generate<any>(
        `${(this as any)['baseUrl' + servicename]}${url}`,
        method,
        requestData,
        options
      )();
    };
  }

  buildError(e: CoreRequestError | CoreConnectionError) {
    // New Services Error
    if ((e as any)?.data?.error?.referenceCode) {
      return (e as any).data.error;
    }
    return {
      status: (e as CoreRequestError).status || 0,
      message:
        (e as CoreConnectionError).reason ||
        ((e as CoreRequestError).data?.error?.message ||
          (e as CoreRequestError).data?.error_message ||
          'No message') +
          ((e as CoreRequestError).status
            ? ' | STATUS:' + (e as CoreRequestError).status
            : ''),
      //TODO: ñaped for QS validation endpoint errors
      reasons: (e as any).data?.error?.reasons,
    };
  }

  getCurrentOptions(): ApiOptions {
    return {
      client: this.client,
      prefix: this.prefix,
      baseUrl: this.baseUrl,
      token: this.token,
    };
  }

  private generate<T>(
    url: string,
    method: string = 'GET',
    requestData: { [k: string]: any } | undefined = undefined,
    options: RequestOptions & {
      resetHeaders?: boolean;
      token?: string;
    } = {
      formData: false,
      resetHeaders: false,
    }
  ) {
    const { token } = options;
    const headers: HeadersInit = {};
    const accessToken = token || this.token;
    if (accessToken) {
      headers['Authorization'] = 'Bearer ' + accessToken;
    }
    const fixedUrl = url.replace('\r', '').replace(/([^:]\/)\/+/g, '$1');
    return async () => {
      try {
        const r = await this.client<T>(
          method,
          fixedUrl,
          {
            ...options,
            headers: { ...options.headers, ...headers },
          },
          ['get', 'head', 'delete'].indexOf(method.toLowerCase().trim()) >= 0
            ? { queryParams: requestData }
            : { bodyParams: requestData }
        );
        return r.data;
      } catch (e) {
        throw this.buildError(e);
      }
    };
  }

  async call(
    url: string,
    method: string = 'GET',
    requestData: { [k: string]: any } | undefined = undefined,
    options: RequestOptions & {
      resetHeaders?: boolean;
      token?: string;
    } = {
      formData: false,
      resetHeaders: false,
    }
  ) {
    return await this.generate<any>(
      `${options?.noPrefix ? this.baseUrl : this.baseUrlApi}${url}`,
      method,
      requestData,
      options
    )();
  }

  async registerUserDevice(deviceToken: string, os: string, version: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'users/me/devices',
      'POST',
      {
        appVersion: version,
        platform: os.toLowerCase() === 'android' ? 'android' : 'ios',
        token: deviceToken,
      }
    )();
  }

  async loginUser(
    data: any,
    identifier = 'email-pass'
  ): Promise<{ user: { token: string } }> {
    return (await this.generate<any>(
      `${this.baseUrlUs}identity-providers/identifier/${identifier}/${
        identifier.trim().replace('-', '').toLowerCase() === 'emailpass'
          ? 'login'
          : 'access-token'
      }`,
      'POST',
      data
    )()) as { user: { token: string } };
  }
  async registerUser(data: any, identifier = 'email-pass') {
    if (identifier !== 'email-pass') {
      throw new Error('Not implemented');
    }
    return (await this.generate<any>(
      `${this.baseUrlUs}identity-providers/identifier/${identifier}/signup`,
      'POST',
      data
    )()) as { user: { token: string } };
  }

  async resetIdentityProvider(
    data: any,
    identifier = 'email-pass'
  ): Promise<{ user: { token: string } }> {
    if (identifier !== 'email-pass') {
      throw new Error('Not implemented');
    }
    return (await this.generate<any>(
      `${this.baseUrlUs}identity-providers/identifier/${identifier}/reset`,
      'POST',
      data
    )()) as { user: { token: string } };
  }

  async getInternalUser(idUser: string, accessToken: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'internal/users/' + idUser,
      'GET',
      undefined,
      {
        token: accessToken,
      }
    )();
  }
  async getUser(idUser: string, accessToken: string, relations?: string[]) {
    return await this.generate<any>(
      this.baseUrlUs +
        'users/' +
        idUser +
        '?filter=' +
        JSON.stringify({
          include: [
            {
              relation: 'identityProviders',
              scope: {
                include: [
                  {
                    relation: 'externalTokens',
                    scope: { order: 'createDate DESC', limit: 1 },
                  },
                ],
              },
            },
            ...(relations?.map((x) => ({ relation: x })) || []),
          ],
        }),
      'GET',
      undefined,
      {
        token: accessToken,
      }
    )();
  }
  async checkUser(accessToken: string) {
    return await this.generate<any>(
      this.baseUrlUs + 'users/me',
      'GET',
      undefined,
      {
        token: accessToken,
      }
    )();
  }
  async createVinculation(
    idUser: string,
    vinculationType: string,
    newUserData: any
  ) {
    return await this.generate<any>(
      `${this.baseUrlUs}users/${idUser}/vinculations`,
      'POST',
      {
        vinculationType,
        secondaryUserPayload: newUserData,
      }
    )();
  }
  async getVinculation(idVinculation: string) {
    return await this.generate<any>(
      `${this.baseUrlUs}vinculations/${idVinculation}`,
      'GET'
    )();
  }
  async getVinculationToken(idVinculation: string) {
    return await this.generate<any>(
      `${this.baseUrlUs}vinculations/${idVinculation}/access-token`,
      'POST',
      {}
    )();
  }
  async updateUserName(
    idUser: string,
    username: {
      name: string;
      surname?: string;
    }
  ) {
    return await this.generate<any>(
      `${this.baseUrlUs}users/${idUser}/username`,
      'PATCH',
      { payload: username }
    )();
  }
  async updateUserPhoneNumber(idUser: string, phoneNumber: string) {
    return await this.generate<any>(
      `${this.baseUrlUs}users/${idUser}/phoneNumber`,
      'PATCH',
      { payload: phoneNumber }
    )();
  }
  async updateUserEmail(idUser: string, email: string) {
    return await this.generate<any>(
      `${this.baseUrlUs}users/${idUser}/email`,
      'PATCH',
      { payload: email }
    )();
  }
  async updateUserMetadata(idUser: string, metadata: any) {
    return await this.generate<any>(
      `${this.baseUrlUs}users/${idUser}/metadata`,
      'PATCH',
      { payload: metadata }
    )();
  }

  async getTests() {
    return await this.generate<any>(`${this.baseUrlQs}tests`, 'GET', {
      filter: {
        include: [
          {
            relation: 'testQuestions',
            // scope: { include: [{ relation: 'test' }] },
          },
          {
            relation: 'testRestrictions',
          },
        ],
      },
    })();
  }

  async getUserTestCompletions(idUser: string) {
    return await this.generate<any>(
      `${this.baseUrlQs}users/${idUser}/testCompletions`,
      'GET'
    )();
  }

  async getUserLatestResponses(idUser: string) {
    return await this.generate<any>(
      `${this.baseUrlQs}users/${idUser}/responses/latests`,
      'GET'
    )();
  }
  async responseQuestion(
    userId: string,
    questionId: string,
    responseData: any
  ) {
    return await this.generate<any>(
      `${this.baseUrlQs}users/${userId}/testQuestions/${questionId}/responses`,
      'POST',
      responseData
    )();
  }

  async addTestCompletion(testId: string, userId: string) {
    return await this.generate<any>(
      `${this.baseUrlQs}users/${userId}/testCompletions`,
      'POST',
      {
        testId,
      }
    )();
  }

  async validateTest(testId: string, userId: string) {
    return await this.generate<any>(
      `${this.baseUrlQs}users/${userId}/tests/${testId}/validation`,
      'GET',
      {}
    )();
  }

  async getTargetTypes(filter?: any) {
    return await this.generate<any>(
      this.baseUrlTs + 'target-types',
      'GET',
      filter && {
        filter: {
          where: filter,
        },
      }
    )();
  }
  async getUserTargets(idUser: string, targetsId?: string[]) {
    const url = 'users/' + idUser + '/targets';
    return await this.generate<any>(this.baseUrlTs + url, 'GET', {
      filter: {
        where: targetsId ? { id: { inq: targetsId } } : undefined,
        include: [
          {
            relation: 'parentTarget',
            scope: {
              include: [
                { relation: 'targetProperties' },
                { relation: 'invitations' },
                { relation: 'childTargets' },
              ],
            },
          },
          { relation: 'targetProperties' },
          { relation: 'targetStatusChanges' },
          {
            relation: 'movements',
            scope: {
              include: [{ relation: 'movementLines' }],
            },
          },
          {
            relation: 'contributions',
          },
        ],
      },
    })();
  }
  async getTargetParticipations() {
    const url = 'targetParticipations';
    return await this.generate<any>(this.baseUrlApi + url, 'GET', {
      filter: {
        include: [
          {
            relation: 'target',
            scope: {
              include: [
                'targetProperties',
                'invitations',
                'targetParticipations',
              ],
            },
          },
          'targetParticipationProperties',
        ],
      },
    })();
  }

  async getUsers() {
    const url = 'internal/users';
    return await this.generate<any>(this.baseUrlApi + url, 'GET')();
  }

  async getUserTargetParticipations(idUser: string) {
    const url = 'targetParticipations/user/' + idUser;
    return await this.generate<any>(this.baseUrlApi + url, 'GET', {
      filter: {
        include: [
          {
            relation: 'target',
            scope: { include: ['targetProperties', 'invitations'] },
          },
          'targetParticipationProperties',
        ],
      },
    })();
  }

  async getUserContributions(idUser: string) {
    const url = 'targets/userId/' + idUser;
    const targets = await this.generate<any>(this.baseUrlTs + url, 'GET', {
      filter: {
        include: [
          {
            relation: 'contributions',
          },
        ],
      },
    })();
    return targets.reduce(
      (acc: any[], act: any) => [...acc, ...act.contributions],
      []
    );
  }

  async createContribution(productParticipationId: string, payload: any) {
    const url =
      'productParticipations/' +
      productParticipationId +
      '/methods/contributions';
    return await this.generate(this.baseUrlApi + url, 'POST', payload)();
  }

  async payContribution(
    productParticipationId: string,
    contributionId: string,
    additionalData = {}
  ) {
    const url =
      'productParticipations/' +
      productParticipationId +
      '/methods/custom/contributionPayment';
    return await this.generate<PayContributionResponse>(
      this.baseUrlApi + url,
      'POST',
      {
        ...additionalData,
        idContribution: contributionId,
      }
    )();
  }
  async createTargetForUser(
    idUser: string,
    options: {
      parentTargetId?: string;
      targetTypeId?: string;
    }
  ) {
    const url = 'users/' + idUser + '/targets';
    return await this.generate<any>(this.baseUrlTs + url, 'POST', {
      targetId: options?.parentTargetId,
      targetTypeId: options?.targetTypeId,
    })();
  }

  async getTargetPropertyChanges(targetId: string) {
    const url = 'targetPropertyChanges';
    return await this.generate<any>(this.baseUrlApi + url, 'GET', {
      filter: { where: { entityId: targetId } },
    })();
  }

  async getTargetStatusChanges(targetId: string) {
    const url = 'targetStatusChanges';
    return await this.generate<any>(this.baseUrlApi + url, 'GET', {
      filter: { where: { entityId: targetId } },
    })();
  }

  async getBankEntities() {
    return await this.generate<any>(this.baseUrlApi + 'bankEntities', 'GET')();
  }

  async postFinancialOperation(
    idUser: string,
    type: OPERATION_TYPES,
    data: any = {},
    operationMode?: OPERATION_MODES
  ) {
    delete data.type;
    return await this.generate<any>(
      this.baseUrlFs + 'users/' + idUser + '/operations',
      'POST',
      {
        type,
        actionCreationMode: operationMode,
        ...data,
      }
    )();
  }

  async getOperation(idOperation: string) {
    return await this.generate<any>(
      this.baseUrlFs + 'operations/' + idOperation,
      'GET',
      { filter: { include: [{ relation: 'currentAction' }] } }
    )();
  }
  async getOperations(idUser: string, filters?: any) {
    const includes = { include: [{ relation: 'currentAction' }] };
    return await this.generate<any>(
      this.baseUrlFs + 'users/' + idUser + '/operations',
      'GET',
      { filter: { ...filters, ...includes } }
    )();
  }

  async sendAction(idOperation: string, actionPayload: any) {
    return await this.generate<any>(
      this.baseUrlFs + 'operations/' + idOperation + '/actions',
      'POST',
      actionPayload
    )();
  }

  async loginBank(id: string, data: any) {
    return await this.generate<any>(
      this.baseUrlApi + 'banks/identifier/' + id + '/login',
      'POST',
      data
    )();
  }

  async bankDataAvailable(id: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'users/' + id + '/banks/last/dataAvailable',
      'GET'
    )();
  }

  async getBanks() {
    const url = 'internal/banks';
    return await this.generate<any>(this.baseUrlApi + url, 'GET')();
  }

  async getAccounts() {
    const url = 'internal/accounts';
    return await this.generate<any>(this.baseUrlApi + url, 'GET')();
  }

  async getUserBanks(idUser: string) {
    const url = 'users/' + idUser + '/banks';
    return await this.generate<any>(this.baseUrlApi + url, 'GET')();
  }

  async getInstitutionAccount(idInstitutionAccount: string) {
    const url = 'institutionAccounts/' + idInstitutionAccount;
    return await this.generate<any>(this.baseUrlFs + url, 'GET', {
      filter: {
        include: [{ relation: 'products' }, { relation: 'credentials' }],
      },
    })();
  }

  async enableInstitutionAccount(idInstitutionAccount: string) {
    const url = 'institutionAccounts/' + idInstitutionAccount;
    return await this.generate<any>(this.baseUrlFs + url, 'PATCH', {
      isDisabled: false,
    })();
  }

  async disableInstitutionAccount(idInstitutionAccount: string) {
    const url = 'institutionAccounts/' + idInstitutionAccount;
    return await this.generate<any>(this.baseUrlFs + url, 'PATCH', {
      isDisabled: true,
    })();
  }

  async enableDisableProduct(idProduct: string, isDisabled: boolean) {
    const url = 'products/' + idProduct;
    return await this.generate<any>(this.baseUrlFs + url, 'PATCH', {
      isDisabled,
    })();
  }

  async getUserInstitutionAccounts(idUser: string) {
    const url = 'users/' + idUser + '/institutionAccounts';
    return await this.generate<any>(this.baseUrlFs + url, 'GET', {
      filter: {
        include: [{ relation: 'products' }, { relation: 'credentials' }],
      },
    })();
  }

  async getUserInstitutionAccountsLean(idUser: string) {
    const url = 'users/' + idUser + '/institutionAccounts';
    return await this.generate<any>(this.baseUrlFs + url, 'GET', {
      filter: {
        include: [{ relation: 'products' }, { relation: 'credentials' }],
      },
    })();
  }

  async getParticipationAvailableBanks(idProductParticipation: string) {
    const url =
      'productParticipations/' +
      idProductParticipation +
      '/methods/custom/availableBanks';
    return await this.generate<any>(this.baseUrlApi + url, 'GET')();
  }

  async saveTargetData(idTarget: string, name?: string, image?: string) {
    const data = {
      name,
      image,
    };
    const url = 'targets/' + idTarget + '/';
    return await this.generate<any>(this.baseUrlTs + url, 'PATCH', data)();
  }

  async saveTargetCreationData(idTarget: string, data: any) {
    const url = 'targets/' + idTarget + '/methods/creation/data';
    return await this.generate<any>(this.baseUrlTs + url, 'PUT', data)();
  }

  async saveTargetFinalizationData(idTarget: string, data: any) {
    const url = 'targets/' + idTarget + '/methods/finalization/data';
    return await this.generate<any>(this.baseUrlTs + url, 'PUT', data)();
  }

  async saveTargetContributionData(idTarget: string, data: any) {
    const url = 'targets/' + idTarget + '/methods/contribution/data';
    return await this.generate<any>(this.baseUrlTs + url, 'PUT', data)();
  }

  async saveTargetCustomPropertyData(
    idTarget: string,
    name: string,
    data: any
  ) {
    const url = 'targets/' + idTarget + '/target-properties';
    return await this.generate<any>(this.baseUrlTs + url, 'PUT', {
      name,
      value: data,
    })();
  }

  async saveProductCreationData(idProduct: string, data: any) {
    const url = 'products/' + idProduct + '/methods/creation/data';
    return await this.generate<any>(this.baseUrlApi + url, 'PATCH', data)();
  }

  async saveProductSignData(idProduct: string, data: any) {
    const url = 'products/' + idProduct + '/methods/custom/updateSignData';
    return await this.generate<any>(this.baseUrlApi + url, 'POST', data)();
  }

  async saveProductPolicyData(idProduct: string, data: any) {
    const url = 'products/' + idProduct + '/methods/custom/updatePolicyData';
    return await this.generate<any>(this.baseUrlApi + url, 'POST', data)();
  }

  async activateTarget(idTarget: string) {
    const url = 'targets/' + idTarget + '/methods/creation';
    return await this.generate<any>(this.baseUrlTs + url, 'POST')();
  }
  async reactivateTarget(idTarget: string, payload?: any) {
    const url = 'targets/' + idTarget + '/methods/reactivation';
    return await this.generate<any>(
      this.baseUrlTs + url,
      'POST',
      payload || {}
    )();
  }

  async finalizeTarget(idTarget: string) {
    const url = 'targets/' + idTarget + '/methods/finalization';
    return await this.generate<any>(this.baseUrlTs + url, 'POST')();
  }

  async pauseTarget(idTarget: string) {
    const url = 'targets/' + idTarget + '/methods/pausation';
    return await this.generate<any>(this.baseUrlTs + url, 'POST')();
  }

  async cancelTarget(idTarget: string) {
    const url = 'targets/' + idTarget + '/methods/cancellation';
    return await this.generate<any>(this.baseUrlTs + url, 'DELETE')();
  }

  async getUserIdentityDocuments(idUser: string) {
    const url = 'users/' + idUser + '/identity-documents';
    return await this.generate<any>(this.baseUrlIds + url, 'GET', {
      filter: { include: [{ relation: 'identityStatusChanges' }] },
    })();
  }
  async getIdentityDocuments() {
    const url = 'identity-documents';
    return await this.generate<any>(this.baseUrlIds + url, 'GET', {
      filter: { include: [{ relation: 'identityStatusChanges' }] },
    })();
  }

  async createIdentityDocument(idUser: string, type: string, main = false) {
    const url = 'users/' + idUser + '/identity-documents';
    return await this.generate<any>(this.baseUrlIds + url, 'POST', {
      type,
      main,
    })();
  }

  async uploadIdentityDocumentImage(
    idIdentity: string,
    file: any,
    side: string
  ) {
    const url = 'identity-documents/' + idIdentity + '/images/' + side;
    return await this.generate<any>(
      this.baseUrlIds + url,
      'POST',
      { image: file },
      {
        formData: true,
      }
    )();
  }

  async changeIdentityDocumentValidationType(
    idIdentity: string,
    isManual: boolean
  ) {
    const url = 'identity-documents/' + idIdentity + '/isManual';
    return await this.generate<any>(this.baseUrlIds + url, 'PATCH', {
      value: isManual,
    })();
  }

  async changeIdentityDocumentVersion(idIdentity: string, version: string) {
    const url = 'identity-documents/' + idIdentity + '/version';
    return await this.generate<any>(this.baseUrlIds + url, 'PATCH', {
      value: version,
    })();
  }

  async setMainIdentityDocument(idIdentity: string) {
    const url = 'identity-documents/' + idIdentity + '/main';
    return await this.generate<any>(this.baseUrlIds + url, 'PATCH', {
      main: true,
    })();
  }

  async validateIdentityDocument(idIdentity: string, reason: string) {
    const auxReason = (reason || '').trim();
    return await this.generate<any>(
      this.baseUrlIds + 'identity-documents/' + idIdentity + '/status',
      'PATCH',
      {
        status: 'VALID',
        reason: auxReason ? auxReason : undefined,
      },
      {
        headers: {
          Authorization:
            'Basic ' + new Buffer('coinscrap:"%#e)G3V8W').toString('base64'),
        },
      }
    )();
  }
  async invalidateIdentityDocument(idIdentity: string, reason: string) {
    const auxReason = (reason || '').trim();
    return await this.generate<any>(
      this.baseUrlIds + 'identity-documents/' + idIdentity + '/status',
      'PATCH',
      {
        status: 'NOT_VALID',
        reason: auxReason ? auxReason : undefined,
      },
      {
        headers: {
          Authorization:
            'Basic ' + new Buffer('coinscrap:"%#e)G3V8W').toString('base64'),
        },
      }
    )();
  }

  async uploadIdentityDocumentData(idIdentity: string, data: any) {
    const url = 'identity-documents/' + idIdentity + '/data';
    return await this.generate<any>(this.baseUrlIds + url, 'PATCH', {
      ...data,
    })();
  }
  async createScan(idIdentity: string, data: any) {
    const url = 'identity-documents/' + idIdentity + '/scans';
    return await this.generate<any>(this.baseUrlIds + url, 'POST', {
      ...data,
    })();
  }

  async updateScan(idIdentity: string, scanId: any) {
    const url =
      'identity-documents/' + idIdentity + '/scans/' + scanId + '/update';
    return await this.generate<any>(this.baseUrlIds + url, 'PUT', {})();
  }

  async createInvitation(idTarget: string, data: any) {
    return await this.generate<any>(
      this.baseUrlApi + 'targets/' + idTarget + '/invitations',
      'POST',
      data
    )();
  }

  async deleteInvitation(idInvitation: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'invitations/' + idInvitation,
      'DELETE'
    )();
  }

  async setStatus(idInvitation: string, status: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'invitations/' + idInvitation + '/status',
      'PUT',
      { status }
    )();
  }

  async linkInvitation(idInvitation: string, idUser: string) {
    return await this.generate<any>(
      this.baseUrlApi +
        'invitations/' +
        idInvitation +
        '/user/' +
        idUser +
        '/status',
      'PUT',
      { status: 'LINKED' }
    )();
  }

  async acceptInvitation(idInvitation: string, idUser: string) {
    return await this.generate<any>(
      this.baseUrlApi +
        'invitations/' +
        idInvitation +
        '/user/' +
        idUser +
        '/status',
      'PUT',
      { status: 'ACCEPTED' }
    )();
  }

  async rejectInvitation(idInvitation: string, idUser: string) {
    return await this.generate<any>(
      this.baseUrlApi +
        'invitations/' +
        idInvitation +
        '/user/' +
        idUser +
        '/status',
      'PUT',
      { status: 'REJECTED' }
    )();
  }

  async getTargetByCode(code: string) {
    const basicTarget: any = await this.generate<any>(
      this.baseUrlApi + 'targets/code/' + code,
      'GET'
    )();
    return await this.getTarget(basicTarget.id);
  }

  async getTarget(idTarget: string) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + idTarget,
      'GET',
      {
        filter: {
          include: [
            {
              relation: 'targetProperties',
            },
            {
              relation: 'invitations',
            },
          ],
        },
      }
    )();
  }

  async getMovementDetails(
    userId: string,
    idMov: string,
    options?: { includeRuleExecution: boolean }
  ) {
    const mov = await this.generate<any>(
      this.baseUrlTs + 'movements/' + idMov,
      'GET',
      {
        filter: {
          include: [
            {
              relation: 'movementLines',
              scope: {
                include: [{ relation: 'movementLineDetails' }],
              },
            },
          ],
        },
      }
    )();

    const ruleExecutionsToRequest: string[] | undefined = mov.movementLines
      ?.map((ml: any) => ml.metadata?.value?.ruleExecutionSource)
      .filter((x: any) => x);

    let executions: any[] = [];
    if (ruleExecutionsToRequest && options?.includeRuleExecution) {
      executions = await this.generate<any>(
        this.baseUrlRs + 'users/' + userId + '/rule-executions',
        'GET',
        {
          filter: {
            where: {
              id: { inq: ruleExecutionsToRequest },
            },
            include: [
              {
                relation: 'ruleValue',
              },
              {
                relation: 'rule',
              },
            ],
          },
        }
      )();
    }
    mov.movementLines = mov.movementLines.map((ml: any) => {
      ml.ruleExecution = executions.find(
        (ex) =>
          ml.metadata?.value?.ruleExecutionSource &&
          ex.id === ml.metadata?.value?.ruleExecutionSource
      );
      return ml;
    });
    return mov;
  }

  async getMovementsDetails(
    userId: string,
    idMovs: string[],
    options?: { includeRuleExecution: boolean }
  ) {
    const movs: any[] = await this.generate<any>(
      this.baseUrlTs + 'movements',
      'GET',
      {
        filter: {
          where: {
            id: { inq: idMovs },
          },
          include: [
            {
              relation: 'movementLines',
              scope: { include: [{ include: 'movementLineDetails' }] },
            },
          ],
        },
      }
    )();

    const ruleExecutionsToRequest: string[] | undefined = movs
      .reduce((acc: any[], act: any) => [...acc, ...act.movementLines], [])
      ?.map((ml: any) => ml.metadata?.value?.ruleExecutionSource)
      .filter((x: any) => x);

    let executions: any[] = [];
    if (ruleExecutionsToRequest && options?.includeRuleExecution) {
      executions = await this.generate<any>(
        this.baseUrlRs + 'users/' + userId + '/rule-executions',
        'GET',
        {
          filter: {
            where: {
              id: { inq: ruleExecutionsToRequest },
            },
            include: [
              {
                relation: 'ruleValue',
              },
              {
                relation: 'rule',
              },
            ],
          },
        }
      )();
    }
    movs.forEach((mov) => {
      mov.movementLines = mov.movementLines.map((ml: any) => {
        ml.ruleExecution = executions.find(
          (ex) =>
            ml.metadata?.value?.ruleExecutionSource &&
            ex.id === ml.metadata?.value?.ruleExecutionSource
        );
        return ml;
      });
    });
    return movs;
  }

  async getRuleExecutions(userId: string, idRuleExecutions: string[]) {
    const ruleExecutionsToRequest: string[] | undefined = idRuleExecutions;

    let executions: any[] = await this.generate<any>(
      this.baseUrlRs + 'users/' + userId + '/rule-executions',
      'GET',
      {
        filter: {
          where: {
            id: { inq: ruleExecutionsToRequest },
          },
          include: [
            {
              relation: 'ruleValue',
            },
            {
              relation: 'rule',
            },
          ],
        },
      }
    )();
    return executions;
  }

  async getProductParticipation(idProductParticipation: string) {
    const [target] = (await this.generate<any>(
      this.baseUrlApi + 'productParticipations',
      'GET',
      {
        filter: {
          where: {
            id: idProductParticipation,
          },
          include: [
            {
              relation: 'product',
              scope: { include: 'productProperties' },
            },
            'productParticipationProperties',
          ],
        },
      }
    )()) as any[];
    if (!target) {
      throw this.buildError(
        new CoreRequestError(
          404,
          { error_message: 'ProductParticipation not found' },
          undefined
        )
      );
    }

    return target;
  }

  async getProductParticipationContributions(id: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'productParticipations/' + id + '/contributions',
      'GET'
    )();
  }

  async getRuleDefinitions() {
    return await this.generate<any>(
      this.baseUrlRs +
        'rule-definitions?filter=' +
        JSON.stringify({
          include: [
            { relation: 'fieldDefinitions' },
            {
              relation: 'eventDefinitions',
              scope: { include: [{ relation: 'fieldDefinitions' }] },
            },
          ],
        }),
      'GET'
    )();
  }

  async getMovements(date: Date) {
    return await this.generate<any>(
      this.baseUrl.replace('api.', 'ts.') +
        'createdMovements?endDate=' +
        date.toISOString(),
      'GET'
    )();
  }

  async getMovementsSimulation() {
    return await this.generate<any>(
      this.baseUrl.replace('api.', 'ts.') + 'simulateMovements/',
      'GET'
    )();
  }

  async createMovements() {
    return await this.generate<any>(
      this.baseUrl.replace('api.', 'ts.') + 'createMovements/',
      'POST'
    )();
  }

  async createSpecifiedMovements(targets: string[]) {
    return await this.generate<any>(
      this.baseUrl.replace('api.', 'ts.') + 'createSpecifiedMovements/',
      'POST',
      { targets }
    )();
  }

  async getTargetParticipationMovements(id: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'targetParticipations/' + id + '/movements',
      'GET'
    )();
  }
  async getTargetParticipationNextMovement(id: string) {
    return await this.generate<any>(
      this.baseUrlApi +
        'targetParticipations/' +
        id +
        '/movements/next?endDate=' +
        new Date().toISOString(),
      'GET'
    )();
  }

  async getTargetParticipationCreationData(id: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'targetParticipations/' + id + '/methods/creation/data',
      'GET'
    )();
  }

  async getProductParticipationCreationData(id: string) {
    return await this.generate<any>(
      this.baseUrlApi +
        'productParticipations/' +
        id +
        '/methods/creation/data',
      'GET'
    )();
  }
  // CUSTOM METHODS
  async getTargetCustom(
    idTarget: string,
    methodName: string,
    payload: object | undefined
  ) {
    return await this.generate<any>(
      this.baseUrlTs +
        'targets/' +
        idTarget +
        '/methods/custom/' +
        methodName +
        '?' +
        Object.entries(payload || {}).reduce(
          (acc, [key, value]) => acc + `&${key}=${value}`,
          ''
        ),
      'GET'
    )();
  }

  async postTargetCustom(
    idTarget: string,
    methodName: string,
    payload: object | undefined
  ) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + idTarget + '/methods/custom/' + methodName,
      'POST',
      payload
    )();
  }

  //  <-- END CUSTOM METHODS

  async getTargetCreationData(id: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'targets/' + id + '/methods/creation/data',
      'GET'
    )();
  }
  async getProductCreationData(id: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'products/' + id + '/methods/creation/data',
      'GET'
    )();
  }
  async getProgress(id: string) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + id + '/methods/progress',
      'GET'
    )();
  }
  async getNextSaving(id: string) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + id + '/methods/next-saving',
      'GET'
    )();
  }

  async createTargetTransfer(
    id: string,
    transfer: { sourceTargetId: string; amount: number }
  ) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + id + '/methods/transfer',
      'POST',
      transfer
    )();
  }

  async bindProductMovementToTarget(
    targetId: string,
    productMovementId: string
  ) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + targetId + '/products/movements',
      'PATCH',
      { productMovementId }
    )();
  }

  async getRecommended(id: string) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + id + '/methods/recommended-saving',
      'GET'
    )();
  }

  async getAvailableProductDefinitions(id: string) {
    return await this.generate<any>(
      this.baseUrlTs +
        'targets/' +
        id +
        '/methods/creation/available-definition-products',
      'GET'
    )();
  }

  async setTargetProductDefinition(id: string, productIdentifier: string) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + id + '/definition-product',
      'PATCH',
      { productDefinitionIdentifier: productIdentifier }
    )();
  }

  async setTargetProduct(id: string, productId: string) {
    return await this.generate<any>(
      this.baseUrlTs + 'targets/' + id + '/product',
      'PATCH',
      { productId }
    )();
  }

  async getRegistriesByIdenfitier(identifier: string) {
    return await this.generate<any>(
      this.baseUrlCs + '/management/registry/identifier/' + identifier,
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }
  async getRegistriesByPolicy(policy: string) {
    return await this.generate<any>(
      this.baseUrlCs + '/management/registry/policies/' + policy,
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }
  async getCaserPolicy(policyId: string) {
    return await this.generate<any>(
      this.baseUrlCs + '/policies/' + policyId,
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }
  async getCaserPolicyProduct(policyId: string) {
    return await this.generate<any>(
      this.baseUrlCs + '/policies/' + policyId + '/product',
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }
  async getCaserPolicyMovements(policyId: string) {
    return await this.generate<any>(
      this.baseUrlCs + '/policies/' + policyId + '/movements',
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }
  async getSupportedVehicles() {
    return await this.generate<any>(
      this.baseUrlCs + 'vehicles',
      'GET',
      undefined,
      {
        resetHeaders: true,
      }
    )();
  }
  async getSupportedProducts() {
    return await this.generate<any>(
      this.baseUrlCs + 'products',
      'GET',
      undefined,
      {
        resetHeaders: true,
      }
    )();
  }
  async getProtocolsAssociated(productNumber: string) {
    return await this.generate<any>(
      this.baseUrlCs + 'management/products/' + productNumber + '/protocols',
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }

  async getProtocolDetails(productNumber: string, protocol: string) {
    return await this.generate<any>(
      this.baseUrlCs +
        'management/products/' +
        productNumber +
        '/protocols/' +
        protocol,
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }
  async getConfiguration() {
    return await this.generate<any>(
      this.baseUrlCs + 'management/configuration',
      'GET',
      undefined,
      { resetHeaders: true }
    )();
  }

  async getTriggers() {
    return await this.generate<any>(this.baseUrlNs + 'triggers', 'GET')();
  }

  async getTriggerCalls() {
    return await this.generate<any>(this.baseUrlNs + 'trigger-calls', 'GET')();
  }

  async executeTrigger(idTrigger: string, payload: object | undefined) {
    return await this.generate<any>(
      this.baseUrlNs + 'triggers/' + idTrigger + '/executions',
      'POST',
      payload
    )();
  }

  async cancelUserData(idUser: string) {
    return await this.generate<any>(
      this.baseUrlApi + 'admin/users/' + idUser,
      'DELETE'
    )();
  }

  async executeIndicator(
    indicatorDefinitionIdentifier: string,
    userId?: string
  ) {
    return await this.generate<any>(
      this.baseUrlIs +
        'indicatorDefinitions/identifier/' +
        indicatorDefinitionIdentifier +
        (userId ? '/user/' + userId : '') +
        '/output',
      'GET',
      { format: 'json' }
    )();
  }

  async addIndicatorToReport(
    idReportDefinition: string,
    idIndicatorDefinition: string
  ) {
    return await this.generate<any>(
      this.baseUrlIs +
        'reportDefinitions/' +
        idReportDefinition +
        '/reportIndicatorDefinitions/',
      'POST',
      {
        indicatorDefinitionId: idIndicatorDefinition,
      }
    )();
  }

  async removeReportIndicatorDefinition(idReportIndicatorDefinition: string) {
    return await this.generate<any>(
      this.baseUrlIs +
        'reportIndicatorDefinitions/' +
        idReportIndicatorDefinition,
      'DELETE'
    )();
  }

  async createReportDefinition(payload: object | undefined) {
    return await this.generate<any>(
      this.baseUrlIs + 'reportDefinitions',
      'POST',
      payload
    )();
  }
  async deleteReportDefinition(idReportDefinition: string) {
    return await this.generate<any>(
      this.baseUrlIs + 'reportDefinitions/' + idReportDefinition,
      'DELETE'
    )();
  }

  async getReportIndicatorDefinitions() {
    return await this.generate<any>(
      this.baseUrlIs + 'reportIndicatorDefinitions',
      'GET'
    )();
  }
  async getReportDefinitions() {
    return await this.generate<any>(
      this.baseUrlIs + 'reportDefinitions',
      'GET'
    )();
  }
  async getIndicatorDefinitions() {
    return await this.generate<any>(
      this.baseUrlIs + 'indicatorDefinitions',
      'GET'
    )();
  }

  // ALLIANZ
  async getAllianzSupportedProducts() {
    return await this.generate<any>(
      this.baseUrlAllianz + 'products',
      'GET',
      undefined,
      {
        resetHeaders: true,
      }
    )();
  }
  async createAllianzPolicy(idTarget: string, productId: string) {
    const url = 'targets/' + idTarget + '/methods/custom/create-policy';
    return await this.generate<any>(this.baseUrlTs + url, 'POST', {
      productId,
    })();
  }
  async getAllianzPolicy(targetId: string) {
    const url = 'targets/' + targetId + '/methods/custom/get-policy';
    return await this.generate<any>(this.baseUrlTs + url, 'POST', {})();
  }
  async getTowns(idTarget: string, postalCode: string) {
    const url = 'targets/' + idTarget + '/methods/custom/get-towns';
    return await this.generate<any>(this.baseUrlTs + url, 'POST', {
      postalCode,
    })();
  }
  async getProfessionQuestions(idTarget: string, professionCode: string) {
    const url =
      'targets/' + idTarget + '/methods/custom/get-profession-questions';
    return await this.generate<any>(this.baseUrlTs + url, 'POST', {
      professionCode,
    })();
  }
  async tarificate(idTarget: string, deathCapitalValue: number) {
    const url = 'targets/' + idTarget + '/methods/custom/tarificate';
    return await this.generate<any>(this.baseUrlTs + url, 'POST', {
      deathCapitalValue,
    })();
  }
  async preemite(idTarget: string) {
    const url = 'targets/' + idTarget + '/methods/custom/start-activation';
    return await this.generate<any>(this.baseUrlTs + url, 'POST', {})();
  }

  //TEMPORARY
  async dssRequestMaker(
    endpoint: string,
    method: string,
    payload: object | undefined
  ) {
    return await this.generate<any>(
      this.baseUrlDss + endpoint,
      method,
      payload
    )();
  }

  async getUserEnvelopes(idUser: string) {
    const url = 'users/' + idUser + '/envelopes';
    return await this.generate<any>(this.baseUrlDss + url, 'GET', {
      filter: {
        include: [
          { relation: 'hookExecutions' },
          { relation: 'urlGenerations' },
          { relation: 'envelopeCreations' },
          {
            relation: 'documents',
            scope: { include: [{ relation: 'fileUploads' }] },
          },
        ],
      },
    })();
  }

  async getPreSignedDocumentUrl(documentId: string) {
    const url = 'documents/' + documentId + '/file-url/public-url';
    return await this.generate<any>(this.baseUrlDss + url, 'GET')();
  }

  async getPreSignedCombinedSignedEnvelopeUrl(envelopeId: string) {
    const url = 'envelopes/' + envelopeId + '/combined-signed-url/public-url';
    return await this.generate<any>(this.baseUrlDss + url, 'GET')();
  }

  async uploadFileFromFile(idDocument: string, file: any) {
    const url = 'documents/' + idDocument + '/file/fromContent';
    return await this.generate<any>(
      this.baseUrlDss + url,
      'PATCH',
      { file: file },
      {
        formData: true,
      }
    )();
  }

  async createTargetRulesConfiguration(
    userId: string,
    targetId: string,
    data: any
  ) {
    return await this.generate<any>(
      this.baseUrlRs +
        'users/' +
        userId +
        '/targets/' +
        targetId +
        '/target-rules-configurations',
      'POST',
      { metadata: { ...data } }
    )();
  }

  async getTargetRulesConfigurations(userId: string, idTarget: string) {
    return await this.generate<any>(
      this.baseUrlRs +
        'users/' +
        userId +
        '/targets/' +
        idTarget +
        '/target-rules-configurations',
      'GET'
    )();
  }

  async getLastTargetRulesConfiguration(userId: string, idTarget: string) {
    return await this.generate<any>(
      this.baseUrlRs +
        'users/' +
        userId +
        '/targets/' +
        idTarget +
        '/target-rules-configurations/last',
      'GET'
    )();
  }

  async upsertRule(
    userId: string,
    idTarget: string,
    idRuleDefinition: string,
    active: boolean = true
  ) {
    return await this.generate<any>(
      this.baseUrlRs + 'users/' + userId + '/rules',
      'POST',
      {
        ruleDefinitionId: idRuleDefinition,
        targetId: idTarget,
        isActive: active,
      }
    )();
  }

  async updateRuleValue(
    idRule: string,
    fieldValues: { identifier: string; data: { value: any } }[]
  ) {
    return await this.generate<any>(
      this.baseUrlRs + 'rules/' + idRule + '/rule-values',
      'POST',
      {
        fieldValues,
      }
    )();
  }

  async desactivateRule(idRule: string) {
    return await this.generate<any>(
      this.baseUrlRs + 'rules/' + idRule + '/isActive',
      'PATCH',
      {
        status: false,
      }
    )();
  }
  async activateRule(idRule: string) {
    return await this.generate<any>(
      this.baseUrlRs + 'rules/' + idRule + '/isActive',
      'PATCH',
      {
        status: true,
      }
    )();
  }

  async createRuleEvent(
    ruleId: string,
    eventDefinitionId: string,
    payload: any
  ) {
    return await this.generate<any>(
      this.baseUrlRs + 'rules/' + ruleId + '/events',
      'POST',
      { ...payload, ruleId, eventDefinitionId }
    )();
  }

  async configurateTargetParticipationRulesAccounts(
    idTargetParticipation: string,
    accounts: any
  ) {
    return await this.generate<any>(
      this.baseUrl +
        'targetParticipations/' +
        idTargetParticipation +
        '/accounts',
      'PUT',
      accounts
    )();
  }
  async getTargetParticipationRulesAccounts(idTargetParticipation: string) {
    return await this.generate<any>(
      this.baseUrl +
        'targetParticipations/' +
        idTargetParticipation +
        '/accounts',
      'GET'
    )();
  }

  async autocompleteRuleField(fieldIdentifier: string, search: string) {
    return await this.generate<any>(
      this.baseUrlRs +
        'field-definitions/identifier/' +
        fieldIdentifier +
        '/autocomplete?search=' +
        search,
      'GET'
    )();
  }
  async getUserRules(idUser: string) {
    return await this.generate<any>(
      this.baseUrlRs +
        'users/' +
        idUser +
        '/rules?filter=' +
        JSON.stringify({
          include: [
            {
              relation: 'ruleValues',
              scope: {
                order: 'createDate DESC',
                /*limit: 1,*/
                include: [{ relation: 'fieldValues' }],
              },
            },
          ],
        }),
      'GET'
    )();
  }
  /*async getTargetRules(idTarget: string): Promise<Rule[]> {
    return await this.generate<any>(
      this.baseUrlRs +
        'rules?filter=' +
        JSON.stringify({ where: { targetId: idTarget } }),
      'GET'
    )();
  }*/
  async getTargetsRules(idTargets: string[]): Promise<Rule[]> {
    return await this.generate<any>(
      this.baseUrlRs +
        'rules?filter=' +
        JSON.stringify({
          where: { targetId: { inq: idTargets } },
          include: [
            {
              relation: 'ruleValues',
              scope: {
                order: 'createDate DESC',
                /*limit: 1,*/
                include: [{ relation: 'fieldValues' }],
              },
            },
          ],
        }),
      'GET'
    )();
  }
}
