import { CoinscrapApi, OPERATION_TYPES } from '../../generic/Apis';

export const wrapperIdentifier = 'operations';

export type RESOLUTION_MODE =
  | 'AWAIT_CREATION_ASYNC'
  | 'AWAIT_CREATION'
  | 'AWAIT_FULL_PROCESS_ASYNC'
  | 'AWAIT_FULL_PROCESS';

export interface Handlers {
  [k: string]: OperationHandler;
}

export interface Operations {
  [k: string]: Operation;
}
export interface OperationHandler {
  onPending?: OnPendingOperationKnowed;
  onSuccess?: OnSuccessOperation;
  onDiscard?: OnDiscardOperation;
  onFailure?: OnFailureOperation;
  instance?: CoinscrapApi;
}
export type OperationsHandled = string[] | undefined;

// Especifies the level of operations that are handled in the Operations Wrapper
export enum AutomaticOperationsProcessMode {
  // No operations processed, (Stop lifecicle, testing)
  NONE = 'none',

  // Only operations generated internally are procesed (Just for wrappers functionality)
  INTERNAL = 'internal',

  // Includes operations generated by functions called by user
  USER = 'user', // DEFAULT

  // Includes operations received from backend
  BACKEND = 'bakend',
}
export interface OperationError {
  type: string;
  code: string;
  message: string;
  payload: any;
}
export interface Action {
  id: string;
  type: string;
  output: any;
  status: 'PENDING' | 'SUCCESS' | 'FAILURE' | 'CONTINUE';
}
export interface Operation {
  createDate: string;
  institutionAccountId: string;
  id: string;
  status: 'PENDING' | 'SUCCESS' | 'FAILURE' | 'DISCARDED';
  actionCreationMode: 'SYNC' | 'ASYNC' | 'DEFERRED';
  currentAction?: Action;
  type: OPERATION_TYPES;
  error?: OperationError;
}

export interface ActionContinue {
  type: 'OTP';
  payload: {
    message: string;
  };
}
export type OnPendingOperationKnowed = (
  actionContinue: ActionContinue,
  onUserHanlded: () => void
) => void;
export type OnPendingOperation = (
  actionContinue: ActionContinue,
  onUserHanlded: () => void,
  operationId: string
) => void;
export type OnSuccessOperation = (operation: Operation) => void;
export type OnFailureOperation = (error: OperationError) => void;
export type OnDiscardOperation = () => void;
