import {
  Logger,
  UserInput,
  DeferredPromise,
  InternacionalizationManager,
} from '../../generic/utils';
import { FunctionBuilded, WrapperFunctionsDefinited } from './interfaces';
import {
  wrapperIdentifier,
  OnPendingOperation,
  OnSuccessOperation,
  OnFailureOperation,
  OnDiscardOperation,
  Operation,
  OnPendingOperationKnowed,
  RESOLUTION_MODE,
} from './common';
import {
  OPERATION_TYPES,
  ACTION_TYPES,
  ACTION_PAYLOAD_TYPES,
  OPERATION_MODES,
  CoinscrapApi,
} from '../../generic/Apis';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

/* Loads all user operations

Its used on app logic user Session start to load all user operations;

The operations will entry on the operations lifecicle process

The non-pending operation are marked as hanlded (Do not handle a failed operation of 3 days ago)

*/

export type LoadOperations = (filters?: UserInput) => void;
const loadOperationsBuilder: FunctionBuilded<LoadOperations> = (
  [_, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi, user }
) => {
  return async filters => {
    if (!user) {
      throw new Error('No user defined');
    }
    let operations: Operation[] | null;
    try {
      // TODO: Handle this with a pooling generic system
      operations = await getInstanceApi().getOperations(
        user && (user._id || user.id),
        filters
      );

      console.log('ºº Loaded operations ', operations);
    } catch (e) {
      console.log('ºº FAILED operations ', e);
      operations = null;
    }
    // All non pending operation marked as hanlded (No lifecicle process)
    contextSetter('operationsHandled')(hanlded => [
      ...(hanlded || []),
      ...(operations || [])
        .filter(op => op.status !== 'PENDING')
        .map(op => op.id),
    ]);

    // Set all user operations
    contextSetter('operations')(existentOperations =>
      operations
        ? {
            ...(operations || []).reduce(
              (acc, act) => ({ ...acc, [act.id]: act }),
              {}
            ),
            ...existentOperations,
          }
        : existentOperations
    );
  };
};

// Creates a operation to handle in the lifecicle

export type CreateOperation = (
  type: OPERATION_TYPES,
  data?: UserInput,
  resolutionMode?: RESOLUTION_MODE,
  statusHandlers?: {
    onPending?: OnPendingOperation;
    onSuccess?: OnSuccessOperation;
    onDiscard?: OnDiscardOperation;
    onFailure?: OnFailureOperation;
  },
  inNameOf?: { instance: CoinscrapApi; userId: string }
) => Promise<Operation>;
const createOperationBuilder: FunctionBuilded<CreateOperation> = (
  [{ operations }, contextSetter],
  _1,
  _2,
  build,
  { getInstanceApi, user }
) => {
  return async (
    type,
    data = {},
    resolutionMode = 'AWAIT_FULL_PROCESS',
    { onPending, onSuccess, onDiscard, onFailure } = {},
    inNameOf
  ) => {
    const userId = inNameOf?.userId || user?._id || user?.id;
    const apiInstance = inNameOf?.instance || getInstanceApi();
    if (!userId) {
      throw new Error('No user defined');
    }
    // if (
    //   resolutionMode === 'AWAIT_FULL_PROCESS' &&
    //   (!onPending || !onSuccess || !onDiscard || !onFailure)
    // ) {
    //   throw new Error(
    //     'To handle internally a operation all handlers must be provided'
    //   );
    // }
    const setOperationHandlers = build('setOperationHandlers');
    const operationCreation = async () => {
      let operation: Operation;

      operation = await apiInstance.postFinancialOperation(
        userId,
        type,
        data,
        (resolutionMode || '').indexOf('ASYNC') > 0
          ? OPERATION_MODES.ASYNC
          : OPERATION_MODES.SYNC
      );

      console.log(
        'ºº CREATED OPERATION ' + type,
        JSON.stringify(operation.id),
        JSON.stringify(operation.status)
      );

      return operation;
    };

    return new Promise(async (re, rj) => {
      const creation = async function(
        resolve: (op: Operation | undefined) => void,
        reject: (error: any) => void
      ) {
        console.log(
          'ºº REQUESTING OPERATION CREATION',
          type,
          resolutionMode,
          onPending,
          onDiscard,
          onFailure,
          onSuccess
        );

        // Creates the operation
        const operation = await operationCreation();

        // Set handlers for operation lifecicle statuses
        setOperationHandlers(
          operation.id,
          // The on pendingContinue handler
          onPending
            ? (actionContinue, onUserHandled) => {
                console.log(
                  'ºº EXECUTTING SETTED HANDLER PENDING ' + operation.id
                );
                onPending &&
                  onPending(actionContinue, onUserHandled, operation.id);
              }
            : undefined,
          // The on success handler
          operation => {
            console.log('ºº EXECUTTING SETTED HANDLER SUCCESS ' + operation.id);
            if (onSuccess) {
              onSuccess(operation);
            }
            if (
              resolutionMode === 'AWAIT_FULL_PROCESS' ||
              resolutionMode === 'AWAIT_FULL_PROCESS_ASYNC'
            ) {
              resolve(operation);
            }
          },
          () => {
            console.log('ºº EXECUTTING SETTED HANDLER DISCARD ' + operation.id);
            if (onDiscard) {
              onDiscard();
            }
            if (
              resolutionMode === 'AWAIT_FULL_PROCESS' ||
              resolutionMode === 'AWAIT_FULL_PROCESS_ASYNC'
            ) {
              reject(
                new Error(
                  InternacionalizationManager.instance.t('Operacion cancelada')
                )
              );
            }
          },
          // The on failure handler
          error => {
            console.log(
              'ºº EXECUTTING SETTED SUCCESS HANDLER FAILURE ' + operation.id
            );
            if (onFailure) {
              onFailure(error);
            }
            if (
              resolutionMode === 'AWAIT_FULL_PROCESS' ||
              resolutionMode === 'AWAIT_FULL_PROCESS_ASYNC'
            ) {
              reject(error);
            }
          },
          apiInstance
        );
        console.log(
          'ºº OPERATION ADDED TO THE LIFECICLE AWAITING THE PICK ON STATUS CHANGE'
        );

        // saves the created operation
        contextSetter('operations')(operations => ({
          ...operations,
          [operation.id]: operation,
        }));

        // Resolves the promise that is specified
        if (
          resolutionMode !== 'AWAIT_FULL_PROCESS' &&
          resolutionMode !== 'AWAIT_FULL_PROCESS_ASYNC'
        ) {
          // The user do not wants to await to the SUCCESS/FAILURE/DISCARDED final status of the operation
          console.log(
            'ºº PROCESS OF OPERATION CREATION FINISHED. Returned the promise without the awaits.'
          );
          resolve(operation);
        } else {
          console.log(
            'ºº PROCESS OF OPERATION CREATION FINISHED. The promise is not resolved yet, will be resolved in the FAILURE/DISCARD (with an exception) or in SUCCESS (with normal resolution) statuses.'
          );
        }
      };
      const promise = new DeferredPromise<Operation, any>(creation);
      promise.resolver = re;
      promise.rejecter = rj;

      if (operations) {
        console.log('ºº >>>>>>>>>> OPERATION ' + type + ' HANDLED NOW');
        promise.run();
      } else {
        console.log(
          'ºº >>>>>>>>>> OPERATION ' + type + ' MUST BE HANLDED DEFERRED'
        );
        contextSetter('operationsPendingToRun')(promises => [
          ...(promises || []),
          promise,
        ]);
      }
    });
  };
};

export type RunOperation = (
  operationId: string,
  data?: UserInput,
  resolutionMode?: RESOLUTION_MODE,
  statusHandlers?: {
    onPending?: OnPendingOperation;
    onSuccess?: OnSuccessOperation;
    onDiscard?: OnDiscardOperation;
    onFailure?: OnFailureOperation;
  }
) => Promise<Operation>;
const runOperationBuilder: FunctionBuilded<RunOperation> = (
  [{ operations }, contextSetter],
  _1,
  _2,
  build,
  { user }
) => {
  return async (
    operationId,
    data: {},
    resolutionMode = 'AWAIT_FULL_PROCESS',
    { onPending, onSuccess, onDiscard, onFailure } = {}
  ) => {
    if (!user) {
      throw new Error('No user defined');
    }
    console.log('## OPERATIONS IN RUN MOMENT', operationId, operations);
    // if (
    //   resolutionMode === 'AWAIT_FULL_PROCESS' &&
    //   (!onPending || !onSuccess || !onDiscard || !onFailure)
    // ) {
    //   throw new Error(
    //     'To handle internally a operation all handlers must be provided'
    //   );
    // }
    const setOperationHandlers = build('setOperationHandlers');
    const sendAction = build('sendAction');
    const updateOperation = build('updateOperation');
    const runOperation = async () => {
      let operation: Operation;

      console.log('HERE USE DATA TO INITIATE OPERATION', data);

      const localOperation = (operations || {})[operationId];
      if (!localOperation) {
        return localOperation;
      }
      if (!localOperation.currentAction) {
        await sendAction(operationId, {});
      }
      operation = await updateOperation(operationId);

      console.log(
        'FINDED OPERATION',
        JSON.stringify(operation?.id),
        JSON.stringify(operation?.status)
      );

      return operation;
    };

    return new Promise(async (re, rj) => {
      const run = async function(
        resolve: (op: Operation | undefined) => void,
        reject: (error: any) => void
      ) {
        console.log(
          'ºº REQUESTING OPERATION RUN',
          onPending,
          onDiscard,
          onFailure,
          onSuccess
        );

        // Creates the operation
        const operation = await runOperation();
        if (!operation) {
          rj(new Error('Operation Not found'));
          return;
        }

        // Set handlers for operation lifecicle statuses
        setOperationHandlers(
          operation.id,
          // The on pendingContinue handler
          onPending
            ? (actionContinue, onUserHandled) => {
                console.log(
                  'ºº EXECUTTING SETTED HANDLER PENDING ' + operation.id
                );
                onPending &&
                  onPending(actionContinue, onUserHandled, operation.id);
              }
            : undefined,
          // The on success handler
          operation => {
            console.log('ºº EXECUTTING SETTED HANDLER SUCCESS ' + operation.id);
            if (onSuccess) {
              onSuccess(operation);
            }
            if (
              resolutionMode === 'AWAIT_FULL_PROCESS' ||
              resolutionMode === 'AWAIT_FULL_PROCESS_ASYNC'
            ) {
              resolve(operation);
            }
          },
          () => {
            console.log('ºº EXECUTTING SETTED HANDLER DISCARD ' + operation.id);
            if (onDiscard) {
              onDiscard();
            }
            if (
              resolutionMode === 'AWAIT_FULL_PROCESS' ||
              resolutionMode === 'AWAIT_FULL_PROCESS_ASYNC'
            ) {
              reject(
                new Error(
                  InternacionalizationManager.instance.t('Operacion cancelada')
                )
              );
            }
          },
          // The on failure handler
          error => {
            console.log(
              'ºº EXECUTTING SETTED SUCCESS HANDLER FAILURE ' + operation.id
            );
            if (onFailure) {
              onFailure(error);
            }
            if (
              resolutionMode === 'AWAIT_FULL_PROCESS' ||
              resolutionMode === 'AWAIT_FULL_PROCESS_ASYNC'
            ) {
              reject(error);
            }
          }
        );
        console.log(
          'ºº OPERATION ADDED TO THE LIFECICLE AWAITING THE PICK ON STATUS CHANGE'
        );

        // saves the created operation
        contextSetter('operations')(operations => ({
          ...operations,
          [operation.id]: operation,
        }));

        // Resolves the promise that is specified
        if (
          resolutionMode !== 'AWAIT_FULL_PROCESS' &&
          resolutionMode !== 'AWAIT_FULL_PROCESS_ASYNC'
        ) {
          // The user do not wants to await to the SUCCESS/FAILURE/DISCARDED final status of the operation
          console.log(
            'ºº PROCESS OF OPERATION CREATION FINISHED. Returned the promise without the awaits.'
          );
          resolve(operation);
        } else {
          console.log(
            'ºº PROCESS OF OPERATION CREATION FINISHED. The promise is not resolved yet, will be resolved in the FAILURE/DISCARD (with an exception) or in SUCCESS (with normal resolution) statuses.'
          );
        }
      };
      const promise = new DeferredPromise<Operation, any>(run);
      promise.resolver = re;
      promise.rejecter = rj;

      if (operations) {
        console.log(
          'ºº >>>>>>>>>> OPERATION TO RUN' + operationId + ' HANDLED NOW'
        );
        promise.run();
      } else {
        console.log(
          'ºº >>>>>>>>>> OPERATION ' + operationId + ' MUST BE HANLDED DEFERRED'
        );
        contextSetter('operationsPendingToRun')(promises => [
          ...(promises || []),
          promise,
        ]);
      }
    });
  };
};

export type SetOperationHandlers = (
  operationId: string,
  onPending?: OnPendingOperationKnowed,
  onSuccess?: OnSuccessOperation,
  onDiscard?: OnDiscardOperation,
  onFailure?: OnFailureOperation,
  apiInstance?: CoinscrapApi
) => void;
const setOperationHandlersBuilder: FunctionBuilded<SetOperationHandlers> = ([
  _,
  contextSetter,
]) => {
  return async (
    idOperation,
    onPending = undefined,
    onSuccess = undefined,
    onDiscard = undefined,
    onFailure = undefined,
    apiInstance
  ) => {
    console.log('ºº SETTING HANLDERS FOR OPERATION', idOperation);
    contextSetter('operationsHandlers')(handlers => ({
      ...handlers,
      [idOperation]: {
        onPending,
        onSuccess,
        onDiscard,
        onFailure,
        instance: apiInstance,
      },
    }));
  };
};

export type ClearOperationHandlers = (operationId: string) => void;
const clearOperationHandlersBuilder: FunctionBuilded<ClearOperationHandlers> = ([
  _,
  contextSetter,
]) => {
  return async idOperation => {
    contextSetter('operationsHandlers')(handlers => {
      delete handlers[idOperation];
      return handlers;
    });
  };
};

export type UpdateOperation = (operationId: string) => Promise<Operation>;
const updateOperationBuilder: FunctionBuilded<UpdateOperation> = (
  [{ operationsHandlers }, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi }
) => {
  return async operationId => {
    // TODO Handle better many promises
    console.log('## HANDLERS ACTUAL', operationsHandlers);
    const instance =
      operationsHandlers[operationId]?.instance || getInstanceApi();
    const newOperation: Operation = await instance.getOperation(operationId);
    contextSetter('operations')(operations => ({
      ...operations,
      [operationId]: newOperation,
    }));

    return newOperation;
  };
};

export type UpdateOperations = (operationId: string[]) => void;
const updateOperationsBuilder: FunctionBuilded<UpdateOperations> = (
  [{ operationsHandlers }, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi }
) => {
  return async operationIds => {
    // TODO Handle better many promises
    console.log('## HANDLERS ACTUAL', operationsHandlers);
    const newOperations: Operation[] = await Promise.all(
      operationIds.map(id => {
        const instance = operationsHandlers[id]?.instance || getInstanceApi();
        return instance.getOperation(id);
      })
    );
    contextSetter('operations')(operations => ({
      ...operations,
      ...newOperations.reduce((acc, act) => ({ ...acc, [act.id]: act }), {}),
    }));
  };
};

export type SendAction = (
  operationId: string,
  typeEspecification: {
    actionType?: ACTION_TYPES;
    payloadType?: ACTION_PAYLOAD_TYPES;
  },
  actionPayload?: UserInput
) => void;
const sendActionBuilder: FunctionBuilded<SendAction> = (
  _,
  _1,
  _2,
  _3,
  { getInstanceApi }
) => {
  return async (operationId, typeEspecification, actionPayload) => {
    // TODO Handle better many promises
    const data: UserInput = {};
    if (actionPayload) {
      data.input = {
        type: typeEspecification.payloadType,
        payload: actionPayload,
      };
    }
    if (typeEspecification.actionType) {
      data.type = typeEspecification.actionType;
    }
    await getInstanceApi().sendAction(operationId, data);
  };
};

export const wrapperFunctions: WrapperFunctionsDefinited = [
  {
    name: 'loadOperations',
    builder: loadOperationsBuilder,
  },
  {
    name: 'createOperation',
    builder: createOperationBuilder,
  },
  {
    name: 'runOperation',
    builder: runOperationBuilder,
  },
  {
    name: 'sendAction',
    builder: sendActionBuilder,
  },
  {
    name: 'setOperationHandlers',
    builder: setOperationHandlersBuilder,
  },
  {
    name: 'clearOperationHandlers',
    builder: clearOperationHandlersBuilder,
  },
  {
    name: 'updateOperations',
    builder: updateOperationsBuilder,
  },
  {
    name: 'updateOperation',
    builder: updateOperationBuilder,
  },
];
