export const wrapperIdentifier = 'ui';

let matchedQuerys: { [k: string]: boolean } = {};

export const setMatchedQuerys = (m: { [k: string]: boolean }) =>
  (matchedQuerys = m);
export const getMatchedQuerys = () => matchedQuerys;

export type ContentGeneratorResponse = React.ReactNode;
export type ContentGeneratorModalHandlerFuntionCreator = (
  closeModalFunction: () => void,
  modalContext: any,
  contextSetter: (newStatus: any) => void
) => ContentGeneratorResponse;
export type ExecuteOnCloseModalHandler = () => void;
export interface PropsGeneratorModalHandler {
  executeOnClose?: ExecuteOnCloseModalHandler;
  fullScreen?: boolean;
  transition?: boolean;
  disableClickAway?: boolean;
}

export type ContentGeneratorModalHandler =
  | React.ReactNode
  | ContentGeneratorModalHandlerFuntionCreator;

export interface ModalHandler {
  contentGenerator: ContentGeneratorModalHandler;
  options?: PropsGeneratorModalHandler;
}

export type ModalGenerator = (
  closeFunction: () => void,
  children?: any,
  options?: PropsGeneratorModalHandler
) => any;

export type AlertGenerator = (
  closeFunction: () => void,
  alertMessage: string,
  alertOptions?: ShowAlertOptions
) => any;

export type ShowAlertOptions = {
  duration: number;
};

export type LoadingGenerator = (props?: any) => any;

export const generateMaterialStyles = <T>(
  name: keyof T,
  componentStyle: T | undefined,
  ignoreMuiLabels = false,
  labelsToGenerateWithBase: (string | string[])[] = []
) => {
  if (!componentStyle) return {};
  const functionalities = Object.keys(componentStyle)
    .filter(mode => /.*Mode$/.exec(mode))
    .map(mode => (/(.*)Mode$/.exec(mode) || [])[1]);
  const toret: T = {
    ...componentStyle[name],
    ...(ignoreMuiLabels
      ? {}
      : functionalities.reduce(
          (acc, act) => ({
            ...acc,
            ['&.Mui-' + act]: ((componentStyle as any)[act + 'Mode'] as T)
              ? ((componentStyle as any)[act + 'Mode'] as T)[name]
              : {},
          }),
          {
            ...labelsToGenerateWithBase.reduce((labs, lab) => {
              if (Array.isArray(lab)) {
                return {
                  ...labs,
                  [lab[1]]: (componentStyle as any)[lab[0]],
                };
              } else {
                return {
                  ...labs,
                  [lab]: (componentStyle as any)[name],
                };
              }
            }, {}),
          } as any
        )),
  };
  return toret;
};
