import { useEffect } from 'react';
import qs from 'qs';
// import { ApplicationWrapper } from '../ApplicationWrapper';
import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useLocation } from 'react-router-dom';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder.getCustomLogicRegisterInstance().setLogic(context => {
  const {
    variablesHandler: { variablesSetter },
  } = context;
  console.log(' context navigation AFTER ', context);

  const location = useLocation();

  useEffect(() => {
    variablesSetter('location')(location);
    variablesSetter('query')(
      qs.parse(location.search, {
        ignoreQueryPrefix: true,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
});
