import { Logger } from '../../generic/utils';
import { wrapperIdentifier, ProductDefinition } from './common';
import { OPERATION_TYPES } from '../../generic/Apis';
import { Operation } from '../OperationsWrapper/common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadProductDefinitions = () => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadProductDefinitions',
    ({
      componentProps: { mock },
      variablesHandler: { variablesSetter },
      bindedContexts: { createOperation },
    }) => async () => {
      if (mock) {
        variablesSetter('productDefinitions')(mock);
        return;
      }
      let productDefinitions: ProductDefinition[] | null;
      try {
        // TODO: Handle this with a pooling generic system
        const operationResp: Operation = await createOperation(
          OPERATION_TYPES.GET_PRODUCT_DEFINITIONS,
          undefined,
          'AWAIT_FULL_PROCESS'
        );
        console.log('ºº PRODUCT CREATOR OPERATION', operationResp);
        try {
          productDefinitions = operationResp.currentAction?.output?.payload;
        } catch (e) {
          throw new Error('Bad response');
        }

        console.log('ººLoaded productDefinitions ', productDefinitions);
      } catch (e) {
        console.log('ººERROR productDefinitions ', e);
        productDefinitions = null;
      }
      variablesSetter('productDefinitions')(productDefinitions);
    }
  );
