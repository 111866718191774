import { ExternalVar, Logger } from '../../../generic/utils';
import {
  wrapperIdentifier,
  CoreFetch,
  CoreRequestError,
  CoreConnectionError,
} from '../common';
import qs from 'qs';
// import qs from 'qs';
// import queryString from 'query-string';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
declare var cordova: ExternalVar;
export const cordovaFetchBuilder: () => CoreFetch = () => {
  return async (method, url, options, rD) => {
    const headers: any = {};
    const { queryParams, bodyParams } = rD || {};

    let procesedUrl = url.split('?')[0];
    let params: any = (url.split('?')[1] || '').trim()
      ? { ...queryParams, ...qs.parse(url.split('?')[1]) }
      : queryParams;
    let body: any = bodyParams;

    if (
      (['get', 'head', 'delete', 'upload', 'download'].indexOf(
        method.toLowerCase().trim()
      ) >= 0 &&
        body) ||
      (['post', 'patch', 'put'].indexOf(method.toLowerCase().trim()) >= 0 &&
        params)
    ) {
      throw new CoreConnectionError(
        `Cannot specify ${
          params ? 'queryParams' : 'requestBody'
        } in a ${method.toLowerCase().trim()}`
      );
    }

    try {
      const data: any = {
        method: method.toLowerCase().trim(),
        repsonseType: 'json',
        serializer:
          options?.formData || options?.urlencoded
            ? options?.formData
              ? 'multipart'
              : 'urlencoded'
            : 'json',
        headers: {
          ...headers,
          ...options?.headers,
        },
      };
      if (
        ['get', 'head', 'delete', 'upload', 'download'].indexOf(
          method.toLowerCase().trim()
        ) >= 0
      ) {
        if (params) {
          data.params = Object.keys(params).reduce(
            (acc, act) => ({
              ...acc,
              [act]:
                (typeof (params as any)[act] as any) === 'object'
                  ? JSON.stringify((params as any)[act] as any)
                  : ((params as any)[act] as any),
            }),
            {}
          );
        }
      } else {
        // post, patch, put
        if (options?.formData && body) {
          const formData = new global.FormData();
          Object.keys(body).forEach(k => {
            formData.append(k, body[k]);
          });
          body = formData;
        }
        data.data = body || {};
      }

      console.log(
        'ºº CoreFetchCall',
        {
          method,
          url,
          options,
          requesData: rD,
        },
        {
          method,
          url: procesedUrl,
          headers: data.headers,
          requesData: { queryParams: params, bodyParams: body },
        }
      );
      console.log('ºº ELEMENTO', cordova.plugin.http);
      const response: any = await new Promise(res =>
        cordova.plugin.http.sendRequest(procesedUrl, data, res, res)
      );
      console.log('ºº RESPONSE', response);
      let rawData;
      if (response.status < 0) {
        throw new CoreConnectionError(response.error || 'Unknow reason');
      }
      if (response.status < 200 || response.status > 299) {
        try {
          rawData = JSON.parse(response.error);
        } catch (e) {}
        throw new CoreRequestError(response.status, rawData, response.headers);
      } else {
        if (!response.data || response.status === 204) {
          return {
            status: response.status,
            data: {},
            headers: response.headers,
          };
        }

        console.log('ºº ' + method + '  ' + url + ' PREV JSON');
        try {
          return {
            status: response.status,
            data: JSON.parse(response.data),
            headers: response.headers,
          };
        } catch (e) {
          console.log('ºº ' + method + '  ' + url + ' PARSING ERROR', e);
          return {
            status: response.status,
            data: {},
            headers: response.headers,
          };
        }
      }
    } catch (e) {
      console.log('ºº ' + method + '  ' + url + ' API ERROR', e);
      throw e;
    }
  };
};
