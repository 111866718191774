import {
  OnChange,
  InputValue,
  InputSelectStyle,
  Disabled,
  InputSelectExpectedStyles,
  GenericComponentProps,
  InputLabel,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputSelect;

export interface ClassProperties {
  variant: 'outlined' | 'filled';
}

export type ComponentStyle = InputSelectStyle;
export type ExpectedStyles = InputSelectExpectedStyles;
export type Props = GenericComponentProps<InputSelectStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  label?: InputLabel;
  autoFocus?: boolean;
  error?: string;
  selectIcon?: any;
};
