/* eslint-disable no-undef */
export const init = () => {
  cordova.define('cordova-plugin-file.resolveLocalFileSystemURI', function(
    require,
    exports,
    module
  ) {
    /*
     *
     * Licensed to the Apache Software Foundation (ASF) under one
     * or more contributor license agreements.  See the NOTICE file
     * distributed with this work for additional information
     * regarding copyright ownership.  The ASF licenses this file
     * to you under the Apache License, Version 2.0 (the
     * "License"); you may not use this file except in compliance
     * with the License.  You may obtain a copy of the License at
     *
     *   http://www.apache.org/licenses/LICENSE-2.0
     *
     * Unless required by applicable law or agreed to in writing,
     * software distributed under the License is distributed on an
     * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
     * KIND, either express or implied.  See the License for the
     * specific language governing permissions and limitations
     * under the License.
     *
     */
    (function() {
      // For browser platform: not all browsers use overrided `resolveLocalFileSystemURL`.
      function checkBrowser() {
        if (cordova.platformId === 'browser' && require('./isChrome')()) {
          // eslint-disable-line no-undef
          module.exports.resolveLocalFileSystemURL =
            window.resolveLocalFileSystemURL ||
            window.webkitResolveLocalFileSystemURL;
          return true;
        }
        return false;
      }
      if (checkBrowser()) {
        return;
      }

      var argscheck = require('cordova/argscheck');
      var DirectoryEntry = require('./DirectoryEntry');
      var FileEntry = require('./FileEntry');
      var FileError = require('./FileError');
      var exec = require('cordova/exec');
      var fileSystems = require('./fileSystems');

      /**
       * Look up file system Entry referred to by local URI.
       * @param {DOMString} uri  URI referring to a local file or directory
       * @param successCallback  invoked with Entry object corresponding to URI
       * @param errorCallback    invoked if error occurs retrieving file system entry
       */
      module.exports.resolveLocalFileSystemURL =
        module.exports.resolveLocalFileSystemURL ||
        function(uri, successCallback, errorCallback) {
          argscheck.checkArgs('sFF', 'resolveLocalFileSystemURI', arguments);
          // error callback
          var fail = function(error) {
            if (errorCallback) {
              errorCallback(new FileError(error));
            }
          };
          // sanity check for 'not:valid:filename' or '/not:valid:filename'
          // file.spec.12 window.resolveLocalFileSystemURI should error (ENCODING_ERR) when resolving invalid URI with leading /.
          if (!uri || uri.split(':').length > 2) {
            setTimeout(function() {
              fail(FileError.ENCODING_ERR);
            }, 0);
            return;
          }
          // if successful, return either a file or directory entry
          var success = function(entry) {
            if (entry) {
              if (successCallback) {
                // create appropriate Entry object
                var fsName =
                  entry.filesystemName ||
                  (entry.filesystem && entry.filesystem.name) ||
                  (entry.filesystem === window.PERSISTENT
                    ? 'persistent'
                    : 'temporary'); // eslint-disable-line no-undef
                fileSystems.getFs(fsName, function(fs) {
                  // This should happen only on platforms that haven't implemented requestAllFileSystems (windows)
                  if (!fs) {
                    fs = new FileSystem(fsName, { name: '', fullPath: '/' }); // eslint-disable-line no-undef
                  }
                  var result = entry.isDirectory
                    ? new DirectoryEntry(
                        entry.name,
                        entry.fullPath,
                        fs,
                        entry.nativeURL
                      )
                    : new FileEntry(
                        entry.name,
                        entry.fullPath,
                        fs,
                        entry.nativeURL
                      );
                  successCallback(result);
                });
              }
            } else {
              // no Entry object returned
              fail(FileError.NOT_FOUND_ERR);
            }
          };

          exec(success, fail, 'File', 'resolveLocalFileSystemURI', [uri]);
        };

      module.exports.resolveLocalFileSystemURI = function() {
        console.log(
          'resolveLocalFileSystemURI is deprecated. Please call resolveLocalFileSystemURL instead.'
        );
        module.exports.resolveLocalFileSystemURL.apply(this, arguments);
      };
    })();
  });
};
