import i18n from 'i18next';
import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'changeLang',
    ({ variablesHandler: { variablesSetter } }) => newLang => {
      console.log('ºº REQUEST LANGUAGE CHANE');
      i18n.changeLanguage(newLang).then(() => {
        variablesSetter('currentLang')(newLang);
      });
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    't',
    ({
      variablesHandler: {
        variables: { inited },
      },
    }) => (key, def, options) => {
      if (!inited || !key) {
        return key as any;
      }
      return i18n.t(key, def, options);
    }
  );
