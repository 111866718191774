import { SessionWrapper } from '../../wrappers/SessionWrapper';
import { BackendWrapper } from '../../wrappers/BackendWrapper';
import { wrapperIdentifier } from './common';
import { WrapperBuilder } from '../../builders/wrapperBuilder';
import {
  ComponentWrapperProps,
  InternalContextVariables,
  ExternalContextVariables,
  InternalContextFunctions,
  ExternalContextFunctions,
  ContextState,
  Virtuals,
  Statics,
  BindedWrapperProperties,
} from './interfaces';

export const wrapperBuilder = new WrapperBuilder<
  ComponentWrapperProps,
  InternalContextVariables,
  {},
  ExternalContextVariables,
  InternalContextFunctions,
  {},
  ExternalContextFunctions,
  ContextState,
  Virtuals,
  Statics,
  BindedWrapperProperties,
  {}
>(wrapperIdentifier, wrapperIdentifier, [SessionWrapper, BackendWrapper]);
