import React, { FunctionComponent } from 'react';
import { View } from '../../View';
import { generateProperties, COMPONENTS } from '../../common';

const e = React.createElement;

export const Body: FunctionComponent<{
  style?: React.CSSProperties;
  predefinedLayout?: string;
}> = ({ children, style, predefinedLayout }) => {
  let props = {};
  let component: any = React.Fragment;
  switch (predefinedLayout) {
    case 'HeaderFooter':
      component = View;
      props = {
        ...generateProperties(
          undefined,
          COMPONENTS.layout,
          'body',
          'Layout_Body'
        ),
        cleared: true,
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexBasis: '100%',
          overflow: 'hidden visible',
          ...style,
        },
      };
      break;
    case 'Simple':
      component = View;
      props = {
        ...generateProperties(
          undefined,
          COMPONENTS.layout,
          'body',
          'Layout_Body'
        ),
        cleared: true,
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          flexBasis: '100%',
          overflow: 'hidden visible',
          ...style,
        },
      };
      break;
    default:
  }

  return e(component, props, children);
};
