import _ from 'lodash';
import { Logger } from '../../../generic/utils';
import { wrapperIdentifier } from '../common';
import { TransportDefinition, TRANSPORTS } from './transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare var utag_data: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare var utag: any;

export interface TEALIUMEventProps {
  [k: string]: any;
}
export interface TealiumDefinition
  extends TransportDefinition<
    {
      account: string;
      profile: string;
      environment: string;
    },
    TEALIUMEventProps
  > {
  engine: TRANSPORTS.TEALIUM;
}

var inited = false;

const Snippets = {
  tags: function({
    account,
    profile,
    environment,
    dataLayer: utagDataLayer,
    utagConfig,
  }: {
    [k: string]: any;
  }) {
    if (!account || !profile || !environment) {
      throw new Error(
        'Tealium props ire required. Provide it from env var "CLIENT_ENV_TEALIUM_ACCOUNT", "CLIENT_ENV_TEALIUM_PROFILE", "CLIENT_ENV_TEALIUM_ENVIRONMENT" or Tealium initialize method'
      );
    }

    const utagSyncScript = `//tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.sync.js`;

    const utagScript = `
    (function(a, b, c, d) {
      a = '//tags.tiqcdn.com/utag/${account}/${profile}/${environment}/utag.js';
      b = document;
      c = 'script';
      d = b.createElement(c);
      d.src = a;
      d.type = 'text/java' + c;
      d.async = true;
      a = b.getElementsByTagName(c)[0];
      a.parentNode.insertBefore(d, a);
    })();`;

    const utagConfigScript = this.utagConfigScript({
      noview: utagConfig?.noview || false, //Disable the automatic tracking call on initial page load.
    });

    const utagDataScript = this.utagDataScript(utagDataLayer);

    return {
      utagSyncScript,
      utagScript,
      utagConfigScript,
      utagDataScript,
    };
  },
  utagDataScript: function(utagDataLayer?: string) {
    return `var utag_data = ${JSON.stringify(utagDataLayer || {})}`;
  },
  utagConfigScript: function(utagConfig?: object) {
    return `var utag_cfg_ovrd = ${JSON.stringify(utagConfig || {})}`;
  },
};

export const Tealium = {
  srcScript: function(src: string) {
    const script = document.createElement('script');
    script.src = src;
    return script;
  },
  dataScript: function(dataLayer: string) {
    const script = document.createElement('script');
    script.innerHTML = dataLayer;
    return script;
  },
  tealium: function(args: { [k: string]: any }) {
    const snippets = Snippets.tags(args);

    const utagSyncScript = this.srcScript(snippets.utagSyncScript);
    const utagScript = this.dataScript(snippets.utagScript);
    const utagConfigScript = this.dataScript(snippets.utagConfigScript);
    const utagDataScript = this.dataScript(snippets.utagDataScript);

    return {
      utagSyncScript,
      utagScript,
      utagConfigScript,
      utagDataScript,
    };
  },
  initialize: function({
    account,
    profile,
    environment,
    dataLayer,
    utagConfig,
  }: {
    account?: string;
    profile?: string;
    environment?: string;
    dataLayer?: any;
    utagConfig?: any;
  }) {
    // console.log(`## ${TRANSPORTS.TEALIUM} inited`, inited);
    if (!inited) {
      inited = true;
      try {
        const tealium = this.tealium({
          account:
            account ||
            (window &&
              window.__CLIENT_ENV &&
              window.__CLIENT_ENV.CLIENT_ENV_TEALIUM_ACCOUNT) ||
            undefined,
          profile:
            profile ||
            (window &&
              window.__CLIENT_ENV &&
              window.__CLIENT_ENV.CLIENT_ENV_TEALIUM_PROFILE) ||
            undefined,
          environment:
            environment ||
            (window &&
              window.__CLIENT_ENV &&
              window.__CLIENT_ENV.CLIENT_ENV_TEALIUM_ENVIRONMENT) ||
            undefined,
          dataLayer,
          utagConfig,
        });

        // HEAD
        document.head.prepend(tealium.utagSyncScript);

        // BODY
        document.body.prepend(tealium.utagScript);
        document.body.prepend(tealium.utagConfigScript);
        if (dataLayer) {
          document.body.prepend(tealium.utagDataScript);
        }
      } catch (e) {
        console.warn(
          e.message || `${TRANSPORTS.TEALIUM} manager initialization failed`
        );
      }
    }
  },
  utag_data: function({ data }: TEALIUMEventProps) {
    const newData = _.merge({}, utag_data, data);
    utag_data = newData;
    return newData;
  },
  emit: async function(event_type: string, options: any) {
    let cont = 0;
    let utagLoaded = false;

    try {
      utagLoaded = !!utag;
    } catch (e) {}

    while (!utagLoaded && cont < 3000) {
      try {
        utagLoaded = !!utag;
      } catch (e) {}

      await new Promise(res => setTimeout(res, 50));
      cont += 50;
    }

    return await new Promise<void>((res, rej) => {
      try {
        if (utag) {
          utag.track(event_type, options, res);
        } else {
          throw new Error('No definición para utag');
        }
      } catch (e) {
        rej(String(e));
      }
    });
  },
};
