import {
  OnChange,
  InputValue,
  Disabled,
  GenericComponentProps,
  InputLabel,
  InputSuggestStyle,
  InputSuggestExpectedStyles,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputSuggest;

export interface ClassProperties {}

export type ComponentStyle = InputSuggestStyle;
export type ExpectedStyles = InputSuggestExpectedStyles;
export type Props = GenericComponentProps<InputSuggestStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  label?: InputLabel;
  error?: string;
  placeholder?: string;
  alwaysOpen?: boolean;
  autoFocus?: boolean;
  minLengthToSuggest?: number;
  suggestionsSource?:
    | Suggestion[]
    | Section[]
    | ((inputValue: string) => Promise<Suggestion[] | Section[]>);
};

export interface Section {
  title: string;
  suggestions: Suggestion[];
}

export interface Suggestion {
  id: string;
  label: string;
}
