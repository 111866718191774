export const getMobileOperatingSystem = () => {
  var userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream) {
    return 'ios';
  } else {
    if (
      /Mobile/.test(userAgent) &&
      /Macintosh/.test(userAgent) &&
      !(window as any).MSStream
    ) {
      return 'ios';
    }
  }
  return null;
};
