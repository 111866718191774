export class Controller<T> {
  private _hocs: { instance: any; args?: any[] }[] = [];
  constructor(public logic: () => T) {}
  public getHOCS() {
    return this._hocs;
  }
  public addHOC(instance: any, args?: any[]) {
    this._hocs.push({ instance, args });
  }
}
