import { Operation } from '../OperationsWrapper/common';

export const wrapperIdentifier = 'institutionAccounts';

export interface Product {
  id: string;
  identifier: string;
  type: 'checking' | 'saving' | 'debit' | 'securities';
  name: string;
  iban?: string;
  countableBalance: number;
  valueBalance: number;
  arrangedBalance: number;
  creditGrantedBalance: number;
  balance: number;
  currency: 'EUR';
  isOwner: boolean;
  institutionAccountId: string;
  isDeleted: boolean;
  isDisabled: boolean;
  createDate: string;
  lastImportDate: string;
}

export interface InstitutionAccount {
  pendingOperations?: number | undefined;
  currentPendingOperation?: Operation | undefined;
  id: string;
  createDate: string;
  institutionIdentifier: string;
  name: string;
  isDeleted: boolean;
  isDisabled: boolean;
  userId: string;
  products: Product[];
}
