import { wrapperBuilder } from './build';

const functionsRegister = wrapperBuilder.getFunctionsRegisterInstance();

functionsRegister.addFunction('constructMessage', () => val => {
  console.log('Constructing message', val);
  return val;
});

functionsRegister.addFunction(
  'getAdditionalMessage',
  wrapperEnvironment => () => {
    return wrapperEnvironment.variablesHandler.variables.aditionalMessage;
  }
);

functionsRegister.addFunction(
  'showPersonName',
  wrapperEnvironment => (name, additional) => {
    // functionality to inject other wrapper functions
    const construct = wrapperEnvironment.coreDynamicFunctionGetter(
      'constructMessage'
    );

    // normal setting
    wrapperEnvironment.variablesHandler.variablesSetter('name')(
      construct(name)
    );

    // using react previous value setting
    wrapperEnvironment.variablesHandler.variablesSetter('aditionalMessage')(
      prevAdditional => {
        const val = additional || prevAdditional;
        console.log(
          'Previous value is ',
          prevAdditional,
          '. The receibed value is',
          additional,
          '. The final value is',
          val
        );
        return val;
      }
    );
  }
);
