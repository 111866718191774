import React, { FunctionComponent } from 'react';
import { COMPONENTS, generateProperties } from '../../common';
import { SimpleLayoutStyle } from '../../propsDefinition';
import { View } from '../../View';
import { getCurrentAnimationData } from '../../../../NavigationWrapper/others/page';
import { Body } from './Body';

const e = React.createElement;

export const LayoutKey = 'Simple';
export type SimpleLayoutPredefinedKey = typeof LayoutKey;

export interface SimpleLayoutProps {
  style: SimpleLayoutStyle;
}

export const SimpleLayout: FunctionComponent<SimpleLayoutProps> = ({
  children,
  style,
}) => {
  const currentPageAnimation = getCurrentAnimationData();
  return e(
    React.Fragment,
    {},
    e(
      View,
      {
        ...generateProperties(
          undefined,
          COMPONENTS.layout,
          'bodyContent',
          'Layout_S-Body-Content'
        ),
        animation: currentPageAnimation && currentPageAnimation[1],
        style: style.bodyContentComponent,
      },
      e(
        Body,
        {
          predefinedLayout: LayoutKey,
          style: style.bodyComponent,
        },
        children
      )
    )
  );
};
