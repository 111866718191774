import React from 'react';

import { stateVariables } from './variables';
import { wrapperFunctions } from './functions';
import {
  ContextFunctions,
  ComponentWrapperProps,
  BindedWrapperProperties,
  ContextState,
  ContextVariables,
  Context,
} from './interfaces';
import { wrapperIdentifier } from './common';
import { FunctionComponent } from 'react';
import {
  Logger,
  generateVariables,
  generateContext,
  wrapProvider,
} from '../../../../generic/utils';
import { ApplicationWrapper } from '../../../ApplicationWrapper';
import { useProvider } from '../../../../generic';
import { useFormik, FormikConfig } from 'formik';
import { Handler } from './others';
import * as Yup from 'yup';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const FormContext = React.createContext<Context>(undefined as any);

export const Form: FunctionComponent<ComponentWrapperProps> & {
  use: <T>(
    props: FormikConfig<T>
  ) => { context: Context; handler: Handler<any> };
  V: typeof Yup;
} = function Component({ children, ...props }) {
  const { state, dispatch } = ApplicationWrapper.use();

  // TODO: implement a system to handle status in a tree form repetable wrappers
  const appState = {}; // extractValue(state, ...[wrapperIdentifier]);

  const variables = generateVariables(wrapperIdentifier, stateVariables, [
    appState,
    dispatch,
  ]);
  const context = generateContext<
    {},
    ContextFunctions,
    {},
    ContextVariables,
    ContextState,
    ComponentWrapperProps,
    BindedWrapperProperties
  >(
    wrapperIdentifier,
    variables,
    wrapperFunctions,
    [state, dispatch],
    props,
    {}
  );

  // Logic with FORMIK HERE
  console.log('FORMIK');

  return wrapProvider(FormContext, context)(children);
};

Form.use = <T>(props: FormikConfig<T>) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return { context: useProvider(FormContext), handler: useFormik<T>(props) };
};

Form.V = Yup;
