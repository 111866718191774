import React, { FunctionComponent } from 'react';
import { wrapProvider, extractValue } from '../../generic/utils';
import { useProvider } from '../../generic/use';
import { Page } from '../../wrappers/NavigationWrapper/others/page';
import { UiWrapper } from '../../wrappers';
import { HookResponse } from '../../generic/interfaces';

export type SourcePath = string;

export type ArrayItemMatcher =
  | {
      allowNoExistence: boolean;
      type: 'param';
      paramName: string;
      itemPropertyPath: string;
    }
  | {
      allowNoExistence: boolean;
      type: 'value';
      value: any;
      itemPropertyPath: string;
    };

export type ArrayItemFilter =
  | {
      type: 'param';
      paramName: string;
      itemPropertyPath: string;
    }
  | {
      type: 'value';
      value: any;
      itemPropertyPath: string;
    };

export interface PickData {
  [k: string]: {
    arrayItemMatcher?: ArrayItemMatcher;
    arrayItemFilter?: ArrayItemFilter;
    sourcePath: SourcePath;
    sourceWrapper?: FunctionComponent & { use: () => any };
    mockData?: any;
    hook?: (...args: any) => HookResponse<any>;
    useHook?: (...args: any) => HookResponse<any>;
  };
}

const SimpleComponentTriggeringLoader = () =>
  (UiWrapper.use() || { useLoading: () => null }).useLoading(true);

const hocContext = React.createContext<any>({});

export const PickerHOC = (
  pickData: PickData,
  DefaultView: FunctionComponent = SimpleComponentTriggeringLoader
) => (WrappedComponent: FunctionComponent) => ({ children, ...props }: any) => {
  const pageContext = Page.use();
  const parentHoc = PickerHOC.use() || {};
  let somethingUndefined = false;

  const data = {
    ...parentHoc,
    ...Object.entries(pickData || {}).reduce(
      (
        acc,
        [
          key,
          {
            sourcePath,
            sourceWrapper,
            arrayItemMatcher,
            arrayItemFilter,
            mockData,
            hook,
            useHook,
          },
        ]
      ) => {
        const currentContext = sourceWrapper ? sourceWrapper.use() : acc;
        const hookComputed = hook || useHook;
        if (mockData) {
          return { ...acc, [key]: mockData };
        } else if (hookComputed) {
          const { payload, loading } = hookComputed(acc);
          let val = payload;
          if (loading || val === undefined) {
            somethingUndefined = true;
          }
          return { ...acc, [key]: val };
        } else {
          let val = extractValue(currentContext, ...sourcePath.split('.'));

          if (val === undefined) {
            somethingUndefined = true;
          }

          if (
            val &&
            Array.isArray(val) &&
            (arrayItemMatcher || arrayItemFilter)
          ) {
            if (arrayItemMatcher) {
              const { itemPropertyPath, type } = arrayItemMatcher;
              const elemntFinded = val.find(
                elem =>
                  extractValue(elem, ...itemPropertyPath.split('.')) ===
                  (type === 'value'
                    ? (arrayItemMatcher as any).value
                    : (pageContext || { params: {} }).params[
                        (arrayItemMatcher as any).paramName
                      ])
              );
              if (elemntFinded) {
                val = elemntFinded;
              } else {
                val = undefined;
                if (!arrayItemMatcher.allowNoExistence) {
                  somethingUndefined = true;
                }
              }
            } else if (arrayItemFilter) {
              const { itemPropertyPath, type } = arrayItemFilter;
              const elemntFinded = val.filter(
                elem =>
                  extractValue(elem, ...itemPropertyPath.split('.')) ===
                  (type === 'value'
                    ? (arrayItemFilter as any).value
                    : (pageContext || { params: {} }).params[
                        (arrayItemFilter as any).paramName
                      ])
              );
              val = elemntFinded;
            }
          }
          return { ...acc, [key]: val };
        }
      },
      {}
    ),
  };

  return wrapProvider(
    hocContext,
    data
  )(
    somethingUndefined
      ? DefaultView
        ? React.createElement(DefaultView, {})
        : null
      : React.createElement(WrappedComponent, { ...props }, children)
  );
};

PickerHOC.use = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useProvider(hocContext);
  return context;
};
