import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { Delio, DelioDefinition } from './others/Delio';
import { GtmDefinition, TagManager } from './others/TagManager';
import { Tealium, TealiumDefinition } from './others/Tealium';
import { TRANSPORTS } from './others/transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('regEvent', ({ componentProps }) => (name, payload) => {
    console.log('Event props', name, payload);
    const transports = componentProps.transports || [
      { engine: TRANSPORTS.GTM },
    ];
    for (let i = 0; i < transports.length; i++) {
      const t = transports[i];
      switch (t.engine) {
        case TRANSPORTS.GTM:
          const mapperGtm = (t as GtmDefinition).eventMapper;
          if (mapperGtm) {
            const mapperResponse = mapperGtm({
              name,
              payload,
            });
            console.log(
              TRANSPORTS.GTM + ' Event props adapted',
              mapperResponse
            );
            TagManager.dataLayer(mapperResponse);
          } else {
            TagManager.dataLayer({
              dataLayer: {
                ...payload,
                event: name,
              },
            });
          }
          break;
        case TRANSPORTS.TEALIUM:
          const mapperTealium = (t as TealiumDefinition).eventMapper;
          if (mapperTealium) {
            const mapperResponse = mapperTealium({ name, payload });
            console.log(
              TRANSPORTS.TEALIUM + ' Event props adapted',
              mapperResponse
            );
            Tealium.emit('link', mapperResponse);
          } else {
            Tealium.emit('link', {
              ...payload,
              event_action: payload.label,
              event_type: payload.category,
              event_target: name || payload.action,
            });
          }
          break;
        case TRANSPORTS.DELIO:
          console.log(`Method not implemented for ${TRANSPORTS.DELIO}`);
          break;
        default:
          throw new Error('Event tracker transport not supported');
      }
    }
  });

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'regPageView',
    ({ componentProps }) => (path, title, additionalPayload) => {
      console.log('PageView props', path, title, additionalPayload);
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.GTM },
      ];
      for (let i = 0; i < transports.length; i++) {
        const t = transports[i];
        switch (t.engine) {
          case TRANSPORTS.GTM:
            const mapperGtm = (t as GtmDefinition).pageMapper;
            if (mapperGtm) {
              const mapperResponse = mapperGtm({
                path,
                title,
                payload: additionalPayload,
              });
              console.log(
                TRANSPORTS.GTM + ' Event props adapted',
                mapperResponse
              );
              TagManager.dataLayer(mapperResponse);
            } else {
              TagManager.dataLayer({
                dataLayer: {
                  ...additionalPayload,
                  path,
                  title,
                  event: 'Pageview',
                },
              });
            }
            break;
          case TRANSPORTS.TEALIUM:
            const mapperTealium = (t as TealiumDefinition).pageMapper;
            if (mapperTealium) {
              const mapperResponse = mapperTealium({
                path,
                title,
                payload: additionalPayload,
              });
              console.log(
                TRANSPORTS.TEALIUM + ' Event props adapted',
                mapperResponse
              );
              Tealium.emit('view', mapperResponse);
            } else {
              Tealium.emit('view', {
                ...additionalPayload,
                page_name: path,
                page_title: title,
              });
            }
            break;
          case TRANSPORTS.DELIO:
            console.log(`Method not implemented for ${TRANSPORTS.DELIO}`);
            break;
          default:
            throw new Error('Event tracker transport not supported');
        }
      }
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('embedData', ({ componentProps }) => payload => {
    const transports = componentProps.transports || [
      { engine: TRANSPORTS.GTM },
    ];
    for (let i = 0; i < transports.length; i++) {
      const t = transports[i];
      switch (t.engine) {
        case TRANSPORTS.GTM:
          TagManager.dataLayer({
            dataLayer: {
              ...payload,
            },
          });
          break;
        case TRANSPORTS.TEALIUM:
          const tealiumPayload = { ...payload };
          delete tealiumPayload?.lead_config;
          Tealium.utag_data({
            data: tealiumPayload,
          });
          break;
        case TRANSPORTS.DELIO:
          if (payload?.lead_config && t?.initProps?.scriptsConfig?.useScripts) {
            Delio.lead_config({
              config: payload.lead_config,
            });
          }
          break;
        default:
          throw new Error('Event tracker transport not supported');
      }
    }
  });

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('regLead', ({ componentProps }) => payload => {
    console.log('Event props', payload);
    const transports = componentProps.transports || [
      { engine: TRANSPORTS.DELIO },
    ];
    for (let i = 0; i < transports.length; i++) {
      const t = transports[i];
      switch (t.engine) {
        case TRANSPORTS.GTM:
          console.log(`Method not implemented for ${TRANSPORTS.GTM}`);
          break;
        case TRANSPORTS.TEALIUM:
          console.log(`Method not implemented for ${TRANSPORTS.TEALIUM}`);
          break;
        case TRANSPORTS.DELIO:
          const eConversionMapper = (t as DelioDefinition)?.eConversionMapper;
          const eVisitMapper = (t as DelioDefinition)?.eVisitMapper;

          if (t?.initProps?.enableDebug) {
            console.log('## 🧿 DELIO TRANSPORT → ', t);
          }

          if (
            (t?.initProps?.wsConfig?.includeConversionTags &&
              !eConversionMapper) ||
            (t?.initProps?.wsConfig?.includeVisitTags && !eVisitMapper)
          ) {
            throw new Error(
              'Delio mappers are required. Provide it from Delio wrapper transport definition props'
            );
          }

          if (t?.initProps?.scriptsConfig?.useScripts) {
            return Delio.leadRegister(
              t?.initProps?.scriptsConfig?.useScript,
              t?.initProps?.scriptsConfig?.useConversionOnlineScript,
              eConversionMapper,
              eVisitMapper,
              t?.initProps?.enableDebug,
              t?.initProps?.idTag,
              t?.initProps?.wsConfig,
              payload,
              undefined,
              undefined,
              undefined
            );
          } else {
            return Delio.leadRegister(
              t?.initProps?.scriptsConfig?.useScript,
              t?.initProps?.scriptsConfig?.useConversionOnlineScript,
              eConversionMapper,
              eVisitMapper,
              t?.initProps?.enableDebug,
              t?.initProps?.idTag,
              t?.initProps?.wsConfig,
              payload,
              {
                ...t?.initProps?.leadConfig?.declarativeData,
                solicita_desde: payload?.solicita_desde,
              },
              {
                ...t?.initProps?.leadConfig?.conversionData,
                solicita_desde: payload?.solicita_desde,
              },
              { ...t?.initProps?.leadConfig?.visitData }
            );
          }
        default:
          throw new Error('Event tracker transport not supported');
      }
    }
    return;
  });
