import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  outline: 0,
  textComponent: {
    color: _theme?.colors?.defaultFontColor,
  },
  loadingComponent: {
    color: _theme?.colors?.defaultFontColor,
  },
});

export default baseConfiguration;
