import React, { FunctionComponent, useState } from 'react';

import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { View } from '../View';

const e = React.createElement;

export const Sidebar: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  width,
  open,
  onClose,
  content,
  closeOnClick,
  children,
  ...props
}) => {
  checkStyleNames(enumKey, props);

  const [internalOpen, setInternalOpen] = useState(false);
  const computedOpen = open === undefined ? internalOpen : open;
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Inyectar estilos ad hoc -----------------------------------------
  let containerStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { open: computedOpen }
  );
  // Others

  if (width) {
    containerStyle.sidebarContainerComponent = {
      ...containerStyle.sidebarContainerComponent,
      alignSelf: 'flex-start', // neded to correct translation
      width,
    };
  }
  const xTranslation = width
    ? computedOpen
      ? 0
      : -1 * Number(width || 0) + 'px'
    : computedOpen
    ? 0
    : '-100%';

  // Return ---------------------------------------------------
  return e(
    React.Fragment,
    {},
    e(
      View,
      {
        ...generateProperties(
          parentReference,
          enumKey,
          'sidebarContainer',
          coreId,
          id
        ),
        cleared: true,
        onClick: () => {
          closeOnClick && setInternalOpen(!computedOpen);
          onClose && onClose();
        },
        style: {
          ...containerStyle.sidebarContainerComponent,
          transition: 'transform 0.8s ease',
          transform: `translatex(${xTranslation})`,
        },
      },
      content
    ),
    e(
      View,
      {
        ...generateProperties(
          parentReference,
          enumKey,
          'contentContainer',
          coreId,
          id
        ),
        cleared: true,
        style: {
          ...containerStyle.contentContainerComponent,
          transition: 'transform 0.8s ease',
          transform: `translatex(${xTranslation})`,
        },
      },
      children
    )
  );
};
