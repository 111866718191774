export class BaseNameController {
  private static _i: BaseNameController;
  static get instance() {
    if (!this._i) {
      this._i = new BaseNameController();
    }
    return this._i;
  }
  private _basename: string = '';
  public set basename(value: string) {
    const procesedValue = (value || '').trim();
    this._basename = procesedValue
      ? procesedValue === '/'
        ? ''
        : procesedValue
      : procesedValue;
  }

  public get basename() {
    return this._basename;
  }
}
