import {
  Width,
  Height,
  SliderStyle,
  SliderExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import {
  AxisType,
  OnChangeIndexCallback,
  OnSwitchingCallback,
  OnTransitionEndCallback,
  SpringConfig,
} from 'react-swipeable-views';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.slider;

export interface ClassProperties {}

export type ComponentStyle = SliderStyle;
export type ExpectedStyles = SliderExpectedStyles;
// Copied directly from the sourcecode
export interface SwipeableViewsProps {
  animateHeight?: boolean;
  animateTransitions?: boolean;
  axis?: AxisType;
  containerStyle?: React.CSSProperties;
  disabled?: boolean;
  /*
   * This is the config used to disable lazy loading, if true it will render all the views in first rendering.
   */
  disableLazyLoading?: boolean;
  enableMouseEvents?: boolean;
  hysteresis?: number;
  ignoreNativeScroll?: boolean;
  index?: number;
  onChangeIndex?: OnChangeIndexCallback;
  onSwitching?: OnSwitchingCallback;
  onTransitionEnd?: OnTransitionEndCallback;
  resistance?: boolean;
  style?: React.CSSProperties;
  slideStyle?: React.CSSProperties;
  springConfig?: SpringConfig;
  slideClassName?: string;
  threshold?: number;
}

export type Props = GenericComponentProps<SliderStyle> & {
  width?: Width;
  height?: Height;
  showDots?: boolean;
  onClickStep?: (index: number) => void;
} & Exclude<SwipeableViewsProps, 'style' | 'slideStyle' | 'containerStyle'>;
