export type ActionText = DISPATCH_TYPES | EXTERNAL_DISPATCH_TYPES;
export type ActionObject<T> = { type: T; payload?: any };

export type HookResponse<T> = {
  payload: T | undefined | null;
  loading: boolean;
};

export enum DISPATCH_TYPES {
  FIRST_RENDER = 'firstRender',
  CHANGE_IN_RULES = 'changeInRules',
  OPERATION_HANDLED = 'operationHandled',
  START_SESSION = 'startSession',
  END_SESSION = 'endSession',
  DEVICEREADY = 'deviceready',
  RESUME = 'resume',
  UNREADED_MESSAGES = 'unReadedMessages',
  CHAT_READY = 'chatReady',
  DEEPLINK_CLICKED = 'deepLinkClicked',
  PUSH_ROUTE = 'pushRoute',
  REPLACE_ROUTE = 'replaceRoute',
  TARGET_ACTIVATED = 'targetActivated',
  TARGET_REACTIVATED = 'targetReactivated',
  TARGET_FINISHED = 'targetFinished',
  TARGET_PAUSED = 'targetPaused',
  TARGET_CANCELED = 'targetCanceled',
  TARGET_PRODUCT_CONFIGURED = 'targetProductConfigured',
  TARGET_PROPERTY_CHANGED = 'targetPropertyChanged',
  GO_BACK = 'goBack',
  RESET_ROUTES = 'resetRoutes',
  REDIRECTION_PERFORMED = 'redirectionPerformed',
  TOKEN_RECEIVED = 'tokenReceived',
}

export enum EXTERNAL_DISPATCH_TYPES {
  PERSIST = 'persist',
}
