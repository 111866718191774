import { CoreStateHandler, CoreVariablesHandler, Register } from '../common';
import { ActionObject } from '../../generic/interfaces';

export class ReducersRegister<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes
> implements Register {
  private _reducers: {
    reducerName: string;
    persisted: boolean;
    reducerDefinition: (wrapperEnvironment: {
      stateHandler: CoreStateHandler<State, DispatchTypes>;
      variablesHandler: CoreVariablesHandler<
        InternalVariables,
        Variables,
        ExternalVariables
      >;
      functions: InternalFunctions & Functions & ExternalFunctions;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
    }) => (
      action: ActionObject<DispatchTypes>,
      persistedState: Partial<State>,
      memoryState: Partial<State>
    ) => Partial<State>;
  }[] = [];

  addReducer(
    reducerName: string,
    persisted: boolean,
    reducerDefinition: (wrapperEnvironment: {
      stateHandler: CoreStateHandler<State, DispatchTypes>;
      variablesHandler: CoreVariablesHandler<
        InternalVariables,
        Variables,
        ExternalVariables
      >;
      functions: InternalFunctions & Functions & ExternalFunctions;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
    }) => (
      action: ActionObject<DispatchTypes>,
      persistedState: Partial<State>,
      memoryState: Partial<State>
    ) => Partial<State>
  ) {
    this._reducers.push({ reducerName, persisted, reducerDefinition });
    return this;
  }

  getKeys() {
    return this._reducers.map(v => v.reducerName);
  }
  getReducerDefinition<
    T extends
      | Extract<keyof InternalFunctions, string>
      | Extract<keyof Functions, string>
      | Extract<keyof ExternalFunctions, string>
  >(varName: T) {
    const def = this._reducers.find(varDef => varDef.reducerName === varName);
    if (!def) {
      throw new Error('Function not found: ' + varName);
    }
    return def;
  }

  hasRegistrations() {
    return !!this._reducers.length;
  }
}
