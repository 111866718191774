import React, { FunctionComponent } from 'react';

import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  COMPONENTS,
  generateProperties,
} from '../common';

import { UiWrapper } from '../../../UiWrapper';
import { PredefinedLayouts, LayoutContext } from './others';
import { Alert } from '../LayoutAlert';
import { Loading } from '../LayoutLoading';
import { Modal } from '../Modal';
import { Container } from './others/Container';
import { wrapProvider } from '../../../../generic/utils';

const e = React.createElement;

export const Layout: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  children,
  predefinedLayout = 'HeaderFooter',
  style,
  layoutOverride,
  implementationProps: baseLayoutProps,
  alertGenerator: localAlertGenerator,
  modalGenerator: localModalGenerator,
  loadingGenerator: localLoadingGenerator,
  ...styleKeys
}) => {
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Modificadores de estilo --------------------------------------------------

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = mergeStylesWithInlineStyles<ComponentStyle>(
    mainStyle,
    style || {}
  );

  // Otros

  //  Comprobar el layout a utilizar
  if (
    !layoutOverride &&
    predefinedLayout &&
    !PredefinedLayouts[predefinedLayout]
  ) {
    throw new Error(
      'The key ' + predefinedLayout + ' is not a valid predefined layout'
    );
  }

  // Return ---------------------------------------------------
  const {
    alertGenerator: mainAlertGenerator,
    modalGenerator: mainModalGenerator,
    loadingGenerator: mainLoadingGenerator,
    layoutProps,
    header,
    footer,
    showLoading,
    showLoadingProps: customLoadingProps,
    alertMessage,
    alertOptions,
    closeAlert,
    openedModals,
    modalsStates,
    updateModalState,
    removeModal,
  } = UiWrapper.useInternal();
  const alertGenerator = localAlertGenerator || mainAlertGenerator;
  const modalGenerator = localModalGenerator || mainModalGenerator;
  const loadingGenerator = localLoadingGenerator || mainLoadingGenerator;

  let renderComponent;
  let renderComponentProps;

  if (layoutOverride) {
    // Configuring overrided layout
    renderComponent = layoutOverride;
    renderComponentProps = {
      ...styleKeys,
      ...baseLayoutProps,
      ...layoutProps,
    };
  } else {
    // Configuring Predefined selected layout
    renderComponent = PredefinedLayouts[predefinedLayout];
    renderComponentProps = {
      style: componentStyle,
    };
    // Setting props
    switch (predefinedLayout) {
      case 'HeaderFooter':
        renderComponentProps = {
          ...renderComponentProps,
          headerComponent: header,
          footerComponent: footer,
        };
        break;
      case 'Simple':
        break;
      default:
        throw new Error(
          'Predefined Layout ' + predefinedLayout + ' does no exists'
        );
    }

    renderComponentProps = {
      ...renderComponentProps,
      ...baseLayoutProps,
      ...layoutProps,
    };
  }

  return wrapProvider(LayoutContext, {
    predefinedLayout,
    overrided: !!layoutOverride,
  })(
    e(
      React.Fragment,
      {},
      e(
        renderComponent as any, // TODO: check this
        renderComponentProps,
        children
      ),
      showLoading && (loadingGenerator || Loading)
        ? (loadingGenerator && loadingGenerator(customLoadingProps || {})) ||
            e(
              Loading,
              {
                ...generateProperties(undefined, COMPONENTS.layout, 'loading'),
                style: componentStyle.loadingComponent,
              },
              null
            )
        : null,

      (openedModals || [])
        .filter(c => c)
        .map((c, i) => {
          let content: React.ReactNode;
          if (typeof c.contentGenerator === 'function') {
            content = c.contentGenerator(
              () => removeModal(i),
              (modalsStates || {})[i] || {},
              (newState: any) => updateModalState(i, newState)
            );
          } else {
            content = c.contentGenerator;
          }
          return !modalGenerator
            ? e(
                Modal,
                {
                  ...generateProperties(parentReference, enumKey, 'modal'),
                  nested: i >= 1,
                  style: componentStyle.modalComponent,
                  key: 'modal' + i,
                  fullScreen: c?.options?.fullScreen,
                  transition: c?.options?.transition,
                  disableClickAway: c?.options?.disableClickAway,
                  onClose: () => {
                    removeModal(i);
                  },
                },
                generateModalContent(content)
              )
            : modalGenerator(() => removeModal(i), content, c && c.options);
        }),
      alertMessage
        ? !alertGenerator
          ? e(Alert, {
              ...generateProperties(undefined, COMPONENTS.layout, 'alert'),
              style: componentStyle.alertComponent,
              alertOptions,
              closeAlert,
              alertMessage,
            })
          : alertGenerator(() => closeAlert(), alertMessage, alertOptions)
        : null
    )
  );
};

const generateModalContent = (generatorResponse: any) => {
  return generatorResponse;
};

Layout.Container = Container;
