import {
  OnChange,
  InputValue,
  InputRadioStyle,
  Disabled,
  InputRadioExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputRadio;

export interface ClassProperties {}

export type ComponentStyle = InputRadioStyle;
export type ExpectedStyles = InputRadioExpectedStyles;
export type Props = GenericComponentProps<InputRadioStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
};
