import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  containerComponent: {},
  swipeContainerComponent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: 'auto',
  },
  labelComponent: {
    color: _theme?.colors?.main,
  },
  prevIconComponent: {
    color: _theme?.colors?.main,
  },
  nextIconComponent: {
    color: _theme?.colors?.main,
  },
  verticalMode: {
    swipeContainerComponent: {
      flexDirection: 'column',
    },

    prevIconComponent: {
      transform: 'rotate(90deg)',
    },
    nextIconComponent: {
      transform: 'rotate(90deg)',
    },
  },
});

export default baseConfiguration;
