import { ExternalVar, Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { TARGET_MODES } from './others';
import { InAppBrowser } from './others/inAppBrowser';

declare var cordova: ExternalVar;
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder.getFunctionsRegisterInstance().addFunction('open', () => {
  return (url, target = TARGET_MODES.SELF, options = { location: true }) => {
    console.log('ºº REQUEST OPEN BROWSER', url, target, options);
    const optionsProcesed = {
      ...options,
      location: options.location === undefined ? true : options.location,
    };
    try {
      if (!cordova) {
        throw new Error('Is not a cordova environment');
      }
    } catch {
      throw new Error('Is not a cordova environment');
    }
    const opts = Object.keys(optionsProcesed).reduce((acc, act) => {
      const value = optionsProcesed[act as keyof typeof optionsProcesed];
      const type = typeof value;
      return (
        acc +
        act +
        '=' +
        (type === 'boolean' ? (value ? 'yes' : 'no') : value) +
        ','
      );
    }, '');
    console.log('ºº COMPUTED OPTIONS', opts);

    const ref: InAppBrowser = cordova.InAppBrowser.open(url, target, opts);
    return ref;
  };
});
