import { ReducersRegister } from './reducersRegister';
import { ReducerBuilder } from './reducerBuilder';
import { Builder, CoreStateHandler, CoreVariablesHandler } from '../common';

export class ReducersBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes
> implements Builder {
  public wrapperName!: string;

  public stateHandler!: CoreStateHandler<State, DispatchTypes>;
  public variablesHandler!: CoreVariablesHandler<
    InternalVariables,
    Variables,
    ExternalVariables
  >;
  public functions!: InternalFunctions & Functions & ExternalFunctions;
  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;
  public virtuals!: Virtuals;

  private _reducersRegister!: ReducersRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    InternalFunctions,
    Functions,
    ExternalFunctions,
    State,
    Virtuals,
    BindedContexts,
    DispatchTypes
  >;
  public set reducersRegister(
    value: ReducersRegister<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      InternalFunctions,
      Functions,
      ExternalFunctions,
      State,
      Virtuals,
      BindedContexts,
      DispatchTypes
    >
  ) {
    this._reducersRegister = value;
  }

  build() {
    const reducerBuilder = new ReducerBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      InternalFunctions,
      Functions,
      ExternalFunctions,
      State,
      Virtuals,
      BindedContexts,
      DispatchTypes
    >();
    reducerBuilder.variablesHandler = this.variablesHandler;
    reducerBuilder.stateHandler = this.stateHandler;
    reducerBuilder.functions = this.functions;
    reducerBuilder.componentProps = this.componentProps;
    reducerBuilder.bindedContexts = this.bindedContexts;
    reducerBuilder.virtuals = this.virtuals;

    return this._reducersRegister
      .getKeys()
      .map(varName => {
        const def = this._reducersRegister.getReducerDefinition(varName as any);
        reducerBuilder.setReducer(def.reducerDefinition);
        return {
          name: varName,
          persisted: def.persisted || false,
          reducerFunction: reducerBuilder.build(),
        };
      })
      .filter(x => x);
  }
  isConfigured() {
    return this._reducersRegister && this._reducersRegister.hasRegistrations();
  }
}
