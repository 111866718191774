import { ImagesResolver, ImageUrl } from '../interfaces';

export class PixabayResolver extends ImagesResolver {
  constructor(private key: string = '10465050-661faffb220a43b9f64d560a2') {
    super();
  }
  async resolveImages(search: string | undefined): Promise<ImageUrl[]> {
    let rawData: any;
    try {
      const { data } = await this.coreFetch(
        'get',
        'https://pixabay.com/api/',
        undefined,
        {
          queryParams: {
            key: this.key,
            q: search,
            orientation: 'horizontal',
          },
        }
      );
      rawData = data;
    } catch (e) {}
    return ((rawData && rawData.hits) || []).map((pixabayImage: any) => ({
      id: pixabayImage.id,
      url: pixabayImage.webformatURL,
      alt: pixabayImage.tags,
    }));
  }
}
