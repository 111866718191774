import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  imageComponent: {
    height: '200px',
    minHeight: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '4px 4px 8px 2px',
    borderRadius: 5,
  },
  modalComponent: {},
  modalContentComponent: {
    backgroundColor: 'white',
    flexShrink: 1,
    height: '100%',
  },
  headerContainerComponent: {
    padding: 5,
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    flexShrink: 1,
  },
  headerTextContainerComponent: {
    marginLeft: 24,
    flexShrink: 1,
  },
  headerTextComponent: {
    color: _theme?.colors?.main,
  },
  closeIconComponent: { padding: 0, color: _theme?.colors?.main },
  searchContainerComponent: { flexShrink: 1 },
  resultsContainerParentComponent: {
    width: '100%',
    overflow: 'auto',
    height: '100%',
    flexShrink: 1,
  },
  resultsContainerComponent: {
    width: '100%',
    overflow: 'auto',
    height: '100%',
    flexShrink: 1,
  },
  resultItemImageComponent: {
    width: '100%',
    minWidth: 310,
    flexShrink: 1,
    height: 200,
    objectFit: 'cover',
    borderRadius: 7,
    border: 'solid 1px ' + _theme?.colors?.main,
  },
});

export default baseConfiguration;
