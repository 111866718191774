import { CoreStateHandler, CoreVariablesHandler } from '../common';
import { ActionObject } from '../../generic/interfaces';

export class ReducerBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes
> {
  public stateHandler!: CoreStateHandler<State, DispatchTypes>;
  public variablesHandler!: CoreVariablesHandler<
    InternalVariables,
    Variables,
    ExternalVariables
  >;
  public functions!: InternalFunctions & Functions & ExternalFunctions;
  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;
  public virtuals!: Virtuals;
  private _reducer!: (context: {
    stateHandler: CoreStateHandler<State, DispatchTypes>;
    variablesHandler: CoreVariablesHandler<
      InternalVariables,
      Variables,
      ExternalVariables
    >;
    functions: InternalFunctions & Functions & ExternalFunctions;
    componentProps: ComponentPropsDefinition;
    bindedContexts: BindedContexts;
    virtuals: Virtuals;
  }) => (
    action: ActionObject<DispatchTypes>,
    persistedState: Partial<State>,
    memoryState: Partial<State>
  ) => Partial<State>;

  setReducer(
    value: (context: {
      stateHandler: CoreStateHandler<State, DispatchTypes>;
      variablesHandler: CoreVariablesHandler<
        InternalVariables,
        Variables,
        ExternalVariables
      >;
      functions: InternalFunctions & Functions & ExternalFunctions;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
    }) => (
      action: ActionObject<DispatchTypes>,
      persistedState: Partial<State>,
      memoryState: Partial<State>
    ) => Partial<State>
  ) {
    this._reducer = value;
  }

  build() {
    return this._reducer(this);
  }
}
