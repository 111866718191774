export const wrapperIdentifier = 'envelopes';

export interface Envelope {
  id: string;
  userId: string;
  thirdIdentifier: string;
  signStatus: string;
  combinedSignedUrl: string;
  title: string;
}
