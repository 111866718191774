/* eslint-disable react-hooks/rules-of-hooks */
import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { MODES } from './others/Chat';
import { BackendWrapper } from '../BackendWrapper';
import { SessionWrapper } from '../SessionWrapper';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomRenderRegisterInstance()
  .setRenderer(
    ({ componentProps: props, variables: context, state, functions }) => (
      e,
      children
    ) => {
      const backendContext = BackendWrapper.use();
      const sessionContext = SessionWrapper.use();

      const closeComponent = e(
        'div',
        {
          style: {
            position: 'absolute',
            top: 0,
            right: 0,
            marginTop: 'env(safe-area-inset-top)',
            padding: '5px 10px 5px 10px',
          },
        },
        e('span', { onClick: functions.closeChat }, e('b', undefined, 'X'))
      );
      const url = (props.serverUrl || backendContext?.baseUrl || '').trim();
      const generatedUrl = `${url}${
        url.substr(-1) === '/' ? '' : '/'
      }api/support/chat?${
        (sessionContext?.sessionData as any)?.token
          ? 'tokenUser=' + (sessionContext.sessionData as any).token
          : 'tokenDevice=' + context.deviceChatId
      }`;
      const iframeComponent = e('iframe', {
        style: {
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: 0,
          marginTop: 'env(safe-area-inset-top)',
        },
        src: generatedUrl,
      });
      console.log(
        'ºº values ',
        generatedUrl,
        props.serverUrl || (backendContext && backendContext.baseUrl),
        backendContext && backendContext.baseUrl,
        props.mode === MODES.SERVER,
        context.chatOpened,
        state?.isReady
      );
      const parentComponent =
        props.mode === MODES.SERVER && context.chatOpened && state?.isReady
          ? e(
              'div',
              {
                key: 'chatServerComponent',
                style: {
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 9999999,
                  backgroundColor: 'white',
                  maxHeight:
                    'calc( 100vh - ( env(safe-area-inset-top, "0px") + env(safe-area-inset-bottom, "0px") ) )',
                  marginTop: 'env(safe-area-inset-top)',
                  marginBottom: 'env(safe-area-inset-bottom)',
                },
              },
              [iframeComponent, closeComponent]
            )
          : undefined;
      console.log(
        ' Rendering chat in server mode',
        props.mode,
        iframeComponent,
        children
      );
      return [parentComponent, children];
    }
  );
