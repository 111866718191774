import {
  LoadingStyle,
  LoadingExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.loading;

export interface ClassProperties {}

export type ComponentStyle = LoadingStyle;
export type ExpectedStyles = LoadingExpectedStyles;
export type Props = GenericComponentProps<LoadingStyle> & {};
