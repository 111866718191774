import axios from 'axios';
import { Logger } from '../../../generic/utils';
import { wrapperIdentifier } from '../common';
import { TransportDefinition, TRANSPORTS } from './transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare var delioLeadConfig: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare var delioContactFormsConfig: any;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare var DFI: any;

export interface DELIOEventProps {
  [k: string]: any;
}
export interface DelioDefinition
  extends TransportDefinition<
    {
      account: string;
      profile: string;
      enableDebug: string;
      idTag: string;
      leadConfig: any;
      contactFormsConfig: any;
      scriptsConfig: any;
      wsConfig: any;
    },
    DELIOEventProps
  > {
  engine: TRANSPORTS.DELIO;
}

var inited = false;

const Snippets = {
  tags: function({
    account,
    profile,
    idTag,
    leadConfig,
    contactFormsConfig,
  }: {
    [k: string]: any;
  }) {
    if (!account || !profile || !idTag) {
      throw new Error(
        'Delio props ire required. Provide it from env var "CLIENT_ENV_DELIO_ACCOUNT", "CLIENT_ENV_DELIO_PROFILE",  "CLIENT_ENV_DELIO_TAG" or Delio initialize method'
      );
    }

    if (!leadConfig || !contactFormsConfig) {
      throw new Error(
        'Delio props are required. Provide it from Delio initialize method'
      );
    }

    const delioScript = `https://t.womtp.com/slider/c/${account}/${profile}/satellite.js`;
    const leadScript = this.leadConfigScript(leadConfig);
    const contactFormsScript = this.contactFormsScript(contactFormsConfig);

    return {
      idTag,
      delioScript,
      leadScript,
      contactFormsScript,
    };
  },
  leadConfigScript: function(leadConfig?: string) {
    return `var delioLeadConfig = ${JSON.stringify(leadConfig || {})}`;
  },
  contactFormsScript: function(contactFormsConfig?: string) {
    return `var delioContactFormsConfig = ${JSON.stringify(
      contactFormsConfig || {}
    )}`;
  },
};

export const Delio = {
  initialize: function({
    account,
    profile,
    idTag,
    leadConfig,
    contactFormsConfig,
    scriptsConfig,
  }: {
    account?: string;
    profile?: string;
    idTag?: string;
    leadConfig?: any;
    contactFormsConfig?: any;
    scriptsConfig?: any;
  }) {
    // console.log(`## ${TRANSPORTS.DELIO} inited`, inited);
    if (!inited) {
      inited = true;
      try {
        const delio = this.delio({
          account:
            account ||
            (window &&
              window.__CLIENT_ENV &&
              window.__CLIENT_ENV.CLIENT_ENV_TEALIUM_ACCOUNT) ||
            undefined,
          profile:
            profile ||
            (window &&
              window.__CLIENT_ENV &&
              window.__CLIENT_ENV.CLIENT_ENV_TEALIUM_PROFILE) ||
            undefined,
          idTag:
            idTag ||
            (window &&
              window.__CLIENT_ENV &&
              window.__CLIENT_ENV.CLIENT_ENV_DELIO_TAG) ||
            undefined,
          leadConfig,
          contactFormsConfig,
        });

        if (scriptsConfig.useScript) {
          document.body.prepend(delio.delioScript);
        }

        if (scriptsConfig.useScripts) {
          document.body.prepend(delio.contactFormsScript);
          document.body.prepend(delio.leadConfigScript);
        }
      } catch (e) {
        console.warn(
          e.message || `${TRANSPORTS.DELIO} manager initialization failed`
        );
      }
    }
  },
  delio: function(args: { [k: string]: any }) {
    const snippets = Snippets.tags(args);

    const delioScript = this.srcScript(snippets.delioScript);
    const leadConfigScript = this.dataScript(snippets.leadScript);
    const contactFormsScript = this.dataScript(snippets.contactFormsScript);

    return {
      delioScript,
      leadConfigScript,
      contactFormsScript,
    };
  },
  srcScript: function(src: string) {
    const script = document.createElement('script');
    script.src = src;
    return script;
  },
  dataScript: function(data: string) {
    const script = document.createElement('script');
    script.innerHTML = data;
    return script;
  },
  lead_config: function({ config }: DELIOEventProps) {
    delioLeadConfig.declarativeData.solicita_desde = config;
    delioLeadConfig.conversionData.solicita_desde = config;
    return delioLeadConfig;
  },
  leadRegister: async function(
    generateLeadId: boolean,
    useConversionOnlineScript: boolean,
    eConversionMapper: any,
    eVisitMapper: any,
    enableDebug: any,
    idTag: any,
    wsConfig: any,
    leadData: any,
    declarativeData: any,
    conversionData: any,
    visitData: any
  ): Promise<void> {
    const registerLead = async () => {
      if (!idTag) {
        throw new Error(
          'Delio props are required. Provide it from Delio initProps'
        );
      }

      if (!leadData?.phone || !leadData?.email || !leadData?.nombre) {
        throw new Error(
          'Delio tags are required. Provide it from Delio lead_register event'
        );
      }

      let idLead = 'No generado por configuración';
      if (generateLeadId) {
        idLead = DFI.getLeadId();
      }
      if (enableDebug) {
        console.log('## 📌 idLead', idLead);
      }

      let eDeclarative = undefined;
      if (wsConfig?.includeDeclarativeTags) {
        eDeclarative = this.eDeclarativeTags(
          declarativeData ? declarativeData : delioLeadConfig?.declarativeData
        );
        console.log('eDeclarative', eDeclarative);
      }

      let eConversion = undefined;
      if (wsConfig?.includeConversionTags) {
        eConversion = this.eConversionTags(
          conversionData ? conversionData : delioLeadConfig?.conversionData,
          eConversionMapper
        );
        console.log('eConversion', eConversion);
      }

      let eVisit = undefined;
      if (wsConfig?.includeVisitTags) {
        eVisit = this.eVisitTags(
          visitData ? visitData : delioLeadConfig?.visitData,
          eVisitMapper
        );
        console.log('eVisit', eVisit);
      }

      try {
        let eConversionEncoded = undefined;
        if (wsConfig?.includeConversionTags) {
          eConversionEncoded = encodeURIComponent(JSON.stringify(eConversion));
          console.log('eConversionEncoded', eConversionEncoded);
        }
        let eVisitEncoded = undefined;
        if (wsConfig?.includeVisitTags) {
          eVisitEncoded = encodeURIComponent(JSON.stringify(eVisit));
          console.log('eVisitEncoded', eVisitEncoded);
        }

        let url = `https://ws.walmeric.com/provision/wsclient/client_addlead.html?format=json&idTag=${idTag}&verifyLeadId=NO&phone=${leadData.phone.trim()}&email=${leadData.email.trim()}&nombre=${leadData.nombre.trim()}`;
        if (generateLeadId) {
          url = url + `&idlead=${idLead}`;
        }

        if (wsConfig?.includeDeclarativeTags) {
          url = url + `${eDeclarative}`;
        }

        if (wsConfig?.includeConversionTags) {
          url = url + `&EConversion=${eConversionEncoded}`;
        }

        if (wsConfig?.includeVisitTags) {
          url = url + `&EVisit=${eVisitEncoded}`;
        }

        if (enableDebug) {
          console.log('## 💥 url', url);
        }

        // Mock
        // return { code: 200, leadId: idLead };

        const response = await axios.get(url);
        if (enableDebug) {
          console.log('## ✅ response', response);
        }
        return response.data;
      } catch (error) {
        if (error?.response?.status === 485) {
          return error?.response?.data;
        }
        console.log('## ❌ error', error);
        throw new Error(`Error creating new lead - ${error?.message}`);
      }
    };

    const addConversionOnlineScript = async (leadId: string) => {
      const delioConversionOnlineScript = this.srcScript(
        `http://t.womtp.com/js/otg.min.js?idtag=${idTag}&orderid=${leadId}`
      );
      document.body.appendChild(delioConversionOnlineScript);
      return;
    };

    return await new Promise<void>((res, rej) => {
      (async () => {
        try {
          const data: any = await registerLead();
          if (data.code === 200) {
            if (useConversionOnlineScript) {
              await addConversionOnlineScript(data.leadId);
            }
            res(data.leadId);
          } else if (data.code === 485) {
            if (enableDebug) {
              console.log('## 💥 WARN Lead en proceso de registro', data);
            }
            res(data.message);
          } else {
            if (enableDebug) {
              console.log('## ❌ ERROR', data);
            }
            res(data.message);
          }
        } catch (e) {
          if (enableDebug) {
            console.log('## ❌ ERROR', e);
          }
          rej(String(e?.message));
        }
      })();
    });
  },
  eDeclarativeTags: function(declarativeData?: any) {
    let eDeclarative = '';
    Object.keys(declarativeData).map((tag: any) => {
      eDeclarative += `&${tag}=${declarativeData[tag] || ''}`;
    });
    return eDeclarative;
  },
  eConversionTags: function(conversionData?: any, eConversionMapper?: any) {
    let eConversion = [];
    eConversion = Object.keys(conversionData || {}).map((tag: any) => {
      return {
        id: eConversionMapper(tag) || 0,
        value: conversionData[tag] || '',
      };
    });
    return eConversion;
  },
  eVisitTags: function(visitData?: any, eVisitMapper?: any) {
    let eVisit = [];
    eVisit = Object.keys(visitData || {}).map((tag: any) => {
      return {
        id: eVisitMapper(tag) || 0,
        value: visitData[tag] || '',
      };
    });
    return eVisit;
  },
};
