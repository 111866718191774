import { useInitialize } from '../../generic/use';
import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { Coinscrap } from './others/Coinscrap';
import { FacebookPixel } from './others/FacebookPixel';
import { GoogleAnalytics } from './others/GoogleAnalytics';
import { GoogleTagManager } from './others/GoogleTagManager';
import { Hotjar } from './others/Hotjar';
import { Teallium } from './others/Teallium';
import { TRANSPORTS } from './others/transportDefinitions';
import { WalmericDelio } from './others/WalmericDelio';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(
    ({
      bindedContexts: { user, getInstanceApi, updateUser },
      componentProps,
    }) => {
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.COINSCRAP },
      ];

      useInitialize(() => {
        for (let i = 0; i < transports.length; i++) {
          const transport = transports[i];
          switch (transport.engine) {
            case TRANSPORTS.COINSCRAP:
              if (
                user &&
                (i === 0 ||
                  user?.metadata?.analytics?.[transports[i - 1]?.engine])
              ) {
                Coinscrap.initialize(getInstanceApi, user, updateUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.FACEBOOK_PIXEL:
              if (
                user &&
                (i === 0 ||
                  user?.metadata?.analytics?.[transports[i - 1]?.engine])
              ) {
                FacebookPixel.initialize(getInstanceApi, user, updateUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.GOOGLE_ANALYTICS:
              if (
                user &&
                (i === 0 ||
                  user?.metadata?.analytics?.[transports[i - 1]?.engine])
              ) {
                GoogleAnalytics.initialize(getInstanceApi, user, updateUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.GOOGLE_TAG_MANAGER:
              if (
                user &&
                (i === 0 ||
                  user?.metadata?.analytics?.[transports[i - 1]?.engine])
              ) {
                GoogleTagManager.initialize(getInstanceApi, user, updateUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.HOTJAR:
              if (
                user &&
                (i === 0 ||
                  user?.metadata?.analytics?.[transports[i - 1]?.engine])
              ) {
                Hotjar.initialize(getInstanceApi, user, updateUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.TEALLIUM:
              if (
                user &&
                (i === 0 ||
                  user?.metadata?.analytics?.[transports[i - 1]?.engine])
              ) {
                Teallium.initialize(getInstanceApi, user, updateUser, {
                  ...transport.initProps,
                  // debugEnabled
                });
              }
              break;
            case TRANSPORTS.WALMERIC_DELIO:
              if (
                user &&
                (i === 0 ||
                  user?.metadata?.analytics?.[transports[i - 1]?.engine])
              ) {
                WalmericDelio.initialize(getInstanceApi, user, updateUser, {
                  ...transport.initProps,
                  // debugEnabled, useMainScript
                });
              }
              break;
            default:
              throw new Error('Analytics transport not supported');
          }
        }
      }, true);
    }
  );
