import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder.getCustomLogicRegisterInstance().setLogic(
  ({
    functions: { loadRules },
    variablesHandler: {
      variables: { rules },
      variablesSetter,
    },
    bindedContexts: { user },
  }) => {
    const userId = user && (user._id || user.id);

    useEffect(() => {
      if (userId) {
        console.log('Loading rules');
        loadRules();
      } else if (!userId) {
        if (rules) {
          variablesSetter('rules')(undefined);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
  }
);
