import { LogicFunctionDefinited } from './interfaces';
import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';
import { useInterval } from '../../generic/use';
import { ApplicationWrapper } from '../../wrappers/ApplicationWrapper';
import { DISPATCH_TYPES } from '../../generic/interfaces';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const useLogic: LogicFunctionDefinited = (
  _1,
  { disableInitialLoading, config },
  [
    {
      operationsPendingToRun,
      currentPendingOperationId,
      operationsHandlers,
      operationsHandled,
      loadOperations,
      operations,
      updateOperations,
      updateOperation,
    },
    contextSetter,
  ],
  { user }
) => {
  /*console.log('ºº DATA', {
    operationsPendingToRun,
    currentPendingOperationId,
    operationsHandlers,
    operationsHandled,
    operations,
  });*/
  const applicationContext = ApplicationWrapper.use();
  const { dispatch: dp } = applicationContext || {};
  const dispatch = dp || (() => console.warn('Dispatch not present'));
  const userId = user && (user._id || user.id);
  useEffect(() => {
    if (userId) {
      console.log('Loading operations');
      if (!disableInitialLoading) {
        loadOperations({
          where: { status: 'PENDING' },
        });
      } else {
        contextSetter('operations')({});
        contextSetter('operationsHandled')([]);
      }
    } else if (!userId) {
      if (Object.keys(operations || {}).length) {
        console.log(`ºº Removing operations`);
        contextSetter('operations')(undefined);
        contextSetter('operationsHandled')(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const operationsToPool = operations
    ? (Object.keys(operations || {}) || [])
        .filter(
          opKey =>
            operations[opKey].status === 'PENDING' &&
            operations[opKey].currentAction?.status !== 'CONTINUE'
        )
        .filter(opKey => operationsHandlers[opKey])
    : [];
  console.log('ºº OPERATIONS TO POOL ', operationsToPool);
  useInterval(
    () => {
      console.log('ºº DOING POOLING', operationsToPool);
      operations &&
        updateOperations(operationsToPool.map(opKey => operations[opKey].id));
    },
    operationsToPool.length ? Number(config?.poolingMs || 2000) : null
  );

  /*useEffect(() => {
    console.log('ºº HANDLERS', operationsHandlers);
  }, [operationsHandlers]);*/

  useEffect(() => {
    if (operations && operationsPendingToRun && operationsPendingToRun.length) {
      console.log(
        'ºº <<<<<<<<<<< HANDLE OPERATIONS PENDING TO RUN: ' +
          operationsPendingToRun.length
      );
      operationsPendingToRun.forEach(p => p.run());
      contextSetter('operationsPendingToRun')([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!operations, operationsPendingToRun?.length]);

  useEffect(() => {
    console.log('ºº TRIGERED EFFECT', [
      Object.keys(operations || {})?.length || 0,
      operationsHandled?.length || 0,
      Object.keys(operationsHandlers || {}).length,
      currentPendingOperationId,
    ]);

    // Defines the function to exits this process (Normally called when handled)
    const exitLifecicle = (operationId: string, discardHandle = false) => {
      console.log(`ºº LIFECICLING: ${operationId} OPERATION MARKED AS HANLDED`);
      contextSetter('operationsHandled')(hanlded => [
        ...(hanlded || []),
        operationId,
      ]);
      if (discardHandle) {
        console.log(
          `ºº LIFECICLING: ${operationId} OPERATION REMOVED FROM USER AWAITING`
        );
        contextSetter('currentPendingOperationId')(undefined);
      }
    };
    if (!operations || !operationsHandled) {
      // This vars indicates when the normal state of the wrapper its inited. Its some one is undefined, then no action ocurs yet (Component not inited)
      console.log('ºº LIFECILING: STANDBY');
    } else {
      // NORMAL EXECUTION OF THE LIFECICLE OPERATIONS

      if (!currentPendingOperationId) {
        // There are not operation that need action continue, will check it if new ones need
        console.log(
          `ºº LIFECICLING: NO CURRENT OPERATION AWAITING FOR USER CONTINUE ACTION, WILLCHECK IF SOME OPERATION NEEDS USER ACTION`
        );
        const operationToHandle = (Object.keys(operations) || []).find(
          opKey =>
            operations[opKey].status === 'PENDING' &&
            operations[opKey].currentAction?.status === 'CONTINUE' &&
            operationsHandlers[opKey] &&
            operationsHandlers[opKey].onPending
        );
        if (operationToHandle) {
          console.log(
            `ºº LIFECICLING: FINDED A OPERATION REQUESTING CONTINUE ACTION WITH HANDLER SETTED`,
            operationToHandle
          );
          console.log(
            `ºº LIFECICLING: SELECTING THE OPERATION TO PROCESS`,
            operationToHandle
          );
          contextSetter('currentPendingOperationId')(
            operations[operationToHandle].id
          );
        } else {
          console.log(
            `ºº LIFECICLING: NO OPERATION IS AVAILABLE TO ACTION CONTINUE (DOES NOT EXISTS ONE OR HAVE NOT HANDLER DEFINED)`
          );
        }
      } else {
        console.log(
          `ºº LIFECICLING: ${currentPendingOperationId} GETING THE OPERATION THAT NEEDS USER ACTION CONTINUE`
        );
        const operation = operations && operations[currentPendingOperationId];
        if (operation) {
          if (
            operation.status === 'PENDING' &&
            operation.currentAction?.status === 'CONTINUE' &&
            operationsHandlers[operation.id] &&
            operationsHandlers[operation.id].onPending
          ) {
            const handler = operationsHandlers[operation.id];
            console.log(
              `ºº LIFECICLING: ${operation.type}|${operation.id} CURRENT OPERATION TAKING USER ATTENTION ` +
                operation.status
            );
            console.log(
              `ºº LIFECICLING: ${operation.type}|${operation.id} THE HANDLER `,
              handler
            );
            if (handler && handler.onPending) {
              console.log(
                `ºº LIFECICLING: ${operation.type}|${operation.id} WILL EXECUTE THE HANDLER`
              );
              try {
                handler.onPending(operation.currentAction?.output, () => {
                  // contextSetter('currentPendingOperationId')(undefined);
                  updateOperation(operation.id);
                });

                console.log(
                  `ºº LIFECICLING: ${operation.type}|${operation.id} HANLDED PENDING-CONTINUE STATE ` +
                    handler && handler.onPending
                );
              } catch (e) {
                console.log(
                  `ºº LIFECICLING: ${operation.type}|${operation.id} HANLDED PENDING-CONTINUE STATE ERROR: ` +
                    e
                );
              }
            } else {
              console.log(
                `ºº LIFECICLING: ${operation.type}|${operation.id} HANLDED PENDING-CONTINUE STATE ERROR! NO HANLDER DEFINED`
              );
            }
          } else {
            console.log(
              `ºº LIFECICLING: ${operation.type}|${operation.id} (TODO) THE OPERATION MARKED AS NEEDS USER ATTENTION HAS THE STATUSES ` +
                `${operation.status} ${
                  operation.currentAction?.status
                } AND HANDLER ${operationsHandlers[operation.id] &&
                  operationsHandlers[operation.id].onPending}`
            );
            contextSetter('currentPendingOperationId')(undefined);
          }
        } else {
          console.log(`ºº LIFECICLING: NO FOUNDED OPERATION`);
          exitLifecicle(currentPendingOperationId, true);
        }
      }

      // Now continues the normal execution of the operations
      console.log(`ºº LIFECICLING: PROCESSING OPERATIONS`);

      const operationsToResolve = operations
        ? Object.keys(operations).filter(
            opKey =>
              (operations[opKey].status === 'SUCCESS' ||
                operations[opKey].status === 'DISCARDED' ||
                operations[opKey].status === 'FAILURE') &&
              operationsHandled.indexOf(opKey) < 0
          )
        : [];
      console.log(`ºº LIFECICLING: PENDING OF RESOLUTION`, operationsToResolve);
      for (const opKey of operationsToResolve) {
        const operation = operations && operations[opKey];
        const handler = operation && operationsHandlers[operation.id];
        if (operation && operation.status === 'SUCCESS') {
          handler && handler.onSuccess && handler.onSuccess(operation);
          console.log(
            `ºº LIFECICLING: ${operation.type}|${operation.id} HANLDED SUCCESS STATE`
          );
          exitLifecicle(operation.id);
        } else if (operation && operation.status === 'DISCARDED') {
          handler && handler.onDiscard && handler.onDiscard();
          console.log(
            `ºº LIFECICLING: ${operation.type}|${operation.id} HANLDED DISCARDED STATE`
          );
          exitLifecicle(operation.id);
        } else if (operation && operation.status === 'FAILURE') {
          handler &&
            handler.onFailure &&
            handler.onFailure(
              operation.error || {
                code: '',
                message: 'Error desconocido',
                payload: {},
                type: 'UKNOWN',
              }
            );
          console.log(
            `ºº LIFECICLING: ${operation.type}|${operation.id} HANLDED FAILURE STATE`
          );
          exitLifecicle(operation.id);
        }

        console.log('ºº DISPATCHING OPERATION HANDLED', operation);
        dispatch({
          type: DISPATCH_TYPES.OPERATION_HANDLED,
          payload: operation,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operations,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    operationsHandled?.length || 0,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Object.keys(operationsHandlers || {}).length,
    currentPendingOperationId,
  ]);
};
export default useLogic;
