/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import { Text } from '../Text';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { makeStyles, MenuItem } from '@material-ui/core';
import { generateMaterialStyles } from '../../common';

const e = React.createElement;

const useContainerStyles = makeStyles({
  // style rule
  root: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
});

export const SelectItem: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  children,
  noT = false,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled }
  );

  // Otros ----------------------------------------------------------------
  const materialContainer = useContainerStyles(componentStyle);

  // Return -----------------------------------------------------
  return e(
    MenuItem,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        true
      ),
      classes: materialContainer,
      value,
      disabled,
      ...styleKeys, // TODO: handle style keys here or using ref to propagate props
    },
    e(
      Text,
      {
        ...generateProperties(parentReference, enumKey, 'text'),
        noT,
        style: componentStyle.textComponent,
      },
      children
    )
  );
};
