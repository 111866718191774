import { DISPATCH_TYPES } from '../../generic/interfaces';
import { Operation } from '../OperationsWrapper/common';
import { OPERATION_TYPES } from '../../generic/Apis';
import { wrapperBuilder } from './build';

wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer(
    'Institutions Accounts: Refresh products',
    false,
    ({ functions: { updateInstitutionAccount } }) => (action, _, state) => {
      if (action.type === DISPATCH_TYPES.OPERATION_HANDLED && action.payload) {
        // SHOULD UPDATE INSTITUTION IF UPDATE PRODUCTS OPERATION SUCCESS
        const op: Operation = action.payload;
        if (
          op.type === OPERATION_TYPES.FETCH_PRODUCTS &&
          op.status === 'SUCCESS'
        ) {
          updateInstitutionAccount(op.institutionAccountId);
        }
      }

      return {
        ...state,
      };
    }
  );
