import { BackendWrapper } from '../../wrappers/BackendWrapper';
import { UserWrapper } from '../../entityWrappers/UserWrapper';
import { ContributionsWrapper } from '../../entityWrappers/ContributionsWrapper';
import { WrapperBuilder } from '../../builders/wrapperBuilder';
import {
  ComponentProps,
  Functions,
  InternalFunctions,
  InternalVariables,
  State,
  Variables,
  Virtuals,
  ExternalFunctions,
  ExternalVariables,
  Statics,
} from './interfaces';
import { wrapperIdentifier } from './common';

export const wrapperBuilder = new WrapperBuilder<
  ComponentProps,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  Statics,
  typeof BackendWrapper.bindedContext &
    typeof UserWrapper.bindedContext &
    typeof ContributionsWrapper.bindedContext,
  {}
>(wrapperIdentifier, wrapperIdentifier, [
  BackendWrapper,
  UserWrapper,
  ContributionsWrapper,
]);
