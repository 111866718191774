import { isMobile } from 'react-device-detect';

import { StatusBarService } from '../../ApplicationWrapper/services/statusBar.service';

const transitionDuration = 2;
const start = 2000;
export const setSplashScreen = (image: string | undefined, logo?: boolean) => {
  if (image) {
    var iDiv = document.createElement('div');
    iDiv.id = 'core-splash';
    iDiv.style.height = '100vh';
    iDiv.style.backgroundRepeat = 'no-repeat';
    iDiv.style.backgroundColor = 'white';
    if (logo) {
      iDiv.style.backgroundPosition = 'center center';
    } else {
      iDiv.style.backgroundSize = 'cover';
    }
    iDiv.style.position = 'absolute';
    iDiv.style.top = '0';
    iDiv.style.left = '0';
    iDiv.style.transition = `opacity ${transitionDuration}s`;
    iDiv.style.zIndex = '10000';
    iDiv.style.height = '100%';
    iDiv.style.width = '100%';
    if (image[0] === '/' || /data:/.exec(image)) {
      iDiv.style.backgroundImage = 'url(' + image + ')';
    } else {
      iDiv.style.backgroundImage = 'url("data:image/png;base64,' + image + '")';
    }
    document.getElementsByTagName('body')[0].appendChild(iDiv);
  }
};

export const hideSplashScreen = () => {
  var iDiv: HTMLDivElement | null = document.getElementById(
    'core-splash'
  ) as any;
  setTimeout(() => {
    if (iDiv) {
      iDiv.style.opacity = '0';
    }
  }, start);
  setTimeout(() => {
    if (iDiv) {
      iDiv.parentNode?.removeChild(iDiv);
    }
    StatusBarService.instance.e('show');
  }, start + transitionDuration * 1000);
};

export const setup = (
  {
    appContainerId,
    showDesktopScrollbarFromPixels,
    disableHeigthControl,
  }: {
    appContainerId?: string;
    showDesktopScrollbarFromPixels?: number;
    disableHeigthControl?: boolean;
  } = {
    appContainerId: 'root',
  }
) => {
  // Create default styles for root div if React APP are rendered

  var style = document.createElement('style');
  style.type = 'text/css';
  style.setAttribute('data-meta', 'UiWrapperDefaultPAgeStyle');
  style.innerHTML = `

  html.async-hide {
    opacity: 1 !important;
  }
  
  html {
    background: black;
    ${disableHeigthControl ? '' : 'height: 100%;'}
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    padding-top: constant(safe-area-inset-top); /* iOS 11.0 */
    padding-top: env(safe-area-inset-top); /* iOS 11.2 */
    padding-bottom: constant(safe-area-inset-bottom); /* iOS 11.0 */
    padding-bottom: env(safe-area-inset-bottom); /* iOS 11.2 */
  }

  *, *::before, *::after {
      box-sizing: inherit;
  }

  body {
    height: 100%;
    width: 100vw;
    ${
      disableHeigthControl
        ? ''
        : 'max-height: calc( 100vh - ( env(safe-area-inset-top, "0px") + env(safe-area-inset-bottom, "0px") ) )!important;'
    }
    display: flex;
    justify-content: flex-start;
    margin: 0px;
    padding: 0px;
    align-items: stretch;
  }
  body #${appContainerId || 'root'}{ 
    width: 100%;
    display: flex;
    flex-flow: row;
    flex-basis: 100%;
    justify-content: flex-start;
    align-items: stretch;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  html,
  body {
    text-size-adjust: none;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
  }

  body #${appContainerId || 'root'}::-webkit-scrollbar {
    display: none;
  }

  ${
    showDesktopScrollbarFromPixels && !isMobile
      ? `
  @media (max-width: ${showDesktopScrollbarFromPixels}px) {
    *::-webkit-scrollbar {
      display: none;
    }
  }
    `
      : `
  *::-webkit-scrollbar {
    display: none;
  }
    `
  }


  * {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  div {
    flex-shrink: 0;
  }
  
  `;
  document.getElementsByTagName('head')[0].appendChild(style);
};
