import { LogicFunctionDefinited } from './interfaces';
import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const useLogic: LogicFunctionDefinited = (
  _1,
  _2,
  [
    { loadTestResponses, loadTestCompletions, testResponses, testCompletions },
    contextSetter,
  ],
  { user }
) => {
  const userId = user && (user._id || user.id);

  useEffect(() => {
    if (userId) {
      console.log('Loading testResponses');
      loadTestResponses();
      console.log('Loading testCompletions');
      loadTestCompletions();
    } else if (!userId) {
      if (testResponses) {
        contextSetter('testResponses')(undefined);
      }
      if (testCompletions) {
        contextSetter('testCompletions')(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
};
export default useLogic;
