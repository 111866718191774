import { ExternalVar } from '../../generic/utils';
import { BlinkId } from './others';
import { wrapperBuilder } from './build';
import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';

declare var cordova: ExternalVar;
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('createBlinkId', ({ componentProps }) => async licenses => {
    return new BlinkId({
      android: licenses?.android || componentProps.androidLicense,
      ios: licenses?.ios || componentProps.iosLicense,
    });
  });

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'scan',
    ({ coreDynamicFunctionGetter }) => async (defaultValue, licenses) => {
      console.log('## SCANN');
      let plugin;
      try {
        plugin = cordova && cordova.plugins && cordova.plugins.BlinkID;
      } catch (e) {
        console.log('## SCANN WITHOUT CORDOVA');
        return defaultValue;
      }
      console.log('## PLUGIN EXISTS');
      if (plugin) {
        console.log('## SCANN WITH CORDOVA');
        let createBlinkId = coreDynamicFunctionGetter('createBlinkId');
        console.log('## CREATED BLINKID');
        const blinkId = await createBlinkId(licenses);
        console.log('## CREATED CLASS', blinkId);
        return blinkId.scan();
      } else {
        console.log('## SCANN WITHOUT PLUGIN');
        return defaultValue;
      }
    }
  );
