import { Logger } from '../../generic/utils';
import { wrapperIdentifier, setMatchedQuerys } from './common';

import { querysConfigured } from './others/userMedias';
import useMediaQuery from './others/_mediaquery/useMediaQuery';
import { wrapperBuilder } from './build';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(_ => {
    const querysComputed: { [k: string]: boolean } = {};
    console.log('ºº querys', querysConfigured);
    Object.keys(querysConfigured).forEach(queryId => {
      querysComputed[queryId] = useMediaQuery(querysConfigured[queryId]);
    });
    setMatchedQuerys(querysComputed);
    console.log('ºº querys Computed', querysComputed);
    return {
      matched: querysComputed,
    };
  });
