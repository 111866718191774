export enum TRANSPORTS {
  COINSCRAP = 'COINSCRAP',
  FACEBOOK_PIXEL = 'FACEBOOK_PIXEL',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
  GOOGLE_TAG_MANAGER = 'GOOGLE_TAG_MANAGER',
  HOTJAR = 'HOTJAR',
  TEALLIUM = 'TEALLIUM',
  WALMERIC_DELIO = 'WALMERIC_DELIO',
}

export interface TransportDefinition<T, K> {
  engine: TRANSPORTS;
  initProps?: T;
  eventMapper?: (data: { name: string; payload: any }) => K;
  pageMapper?: (data: { path: string; title: string; payload: any }) => K;
  eConversionMapper?: (data: { name: string }) => String;
  eVisitMapper?: (data: { name: string }) => String;
}
