/* eslint-disable no-undef */
export const init = () => {
  cordova.define('cordova-plugin-ionic-webview.IonicWebView', function(
    require,
    exports,
    module
  ) {
    var exec = require('cordova/exec');

    var WebView = {
      convertFileSrc: function(url) {
        if (!url) {
          return url;
        }
        if (url.indexOf('/') === 0) {
          return window.WEBVIEW_SERVER_URL + '/_app_file_' + url;
        }
        if (url.indexOf('file://') === 0) {
          return (
            window.WEBVIEW_SERVER_URL + url.replace('file://', '/_app_file_')
          );
        }
        if (url.indexOf('content://') === 0) {
          return (
            window.WEBVIEW_SERVER_URL +
            url.replace('content:/', '/_app_content_')
          );
        }
        return url;
      },
      setServerBasePath: function(path) {
        exec(null, null, 'IonicWebView', 'setServerBasePath', [path]);
      },
      getServerBasePath: function(callback) {
        exec(callback, null, 'IonicWebView', 'getServerBasePath', []);
      },
      persistServerBasePath: function() {
        exec(null, null, 'IonicWebView', 'persistServerBasePath', []);
      },
    };

    module.exports = WebView;
  });
};
