export enum TRANSPORTS {
  GTM = 'GTM',
  TEALIUM = 'TEALIUM',
  DELIO = 'DELIO',
}

export interface TransportDefinition<T, K> {
  engine: TRANSPORTS;
  initProps?: T;
  eventMapper?: (data: { name: string; payload: any }) => K;
  pageMapper?: (data: { path: string; title: string; payload: any }) => K;
  eConversionMapper?: (data: { name: string }) => String;
  eVisitMapper?: (data: { name: string }) => String;
}
