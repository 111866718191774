import { Test } from './common';

export const testsMapper = (testsReceived: Test[]): Test[] => {
  return (testsReceived || []).map(t => {
    t.testQuestions = (t.testQuestions || []).sort(
      (a, b) => (a.order || 0) - (b.order || 0)
    );
    return t;
  });
};
