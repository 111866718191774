import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';
import { Logger } from '../../../../generic/utils';

const console = new Logger('textField');

if (console) {
  //Just for usage
}

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => {
  console.log('º DEFAULT', _theme, _styleKeys);
  const toret: ComponentStyle = {
    containerComponent: {
      //width: '100%',
    },
    inputComponent: {
      color: _theme?.colors?.defaultFontColor,
    },
    labelComponent: {
      color: _theme?.colors?.defaultFontColor,
    },
    iconComponent: {
      color: _theme?.colors?.main,
    },
    currencyMode: {
      inputComponent: {
        textAlign: 'right',
        paddingRight: 0,
      },
      iconComponent: {},
    },
  };
  return toret;
};

export default baseConfiguration;
