import { FunctionComponent, useEffect } from 'react';
import { NavigationWrapper } from '../../NavigationWrapper';
import { wrapperIdentifier } from '../common';
import { Logger } from '../../../generic/utils';
import { defaultValidation } from '.';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export interface ComponentRedirectWrapperProps {
  sessionData: any;
  entryPoint: { pageIfValid: string; pageIfInvalid: string };
}
export const Redirect: FunctionComponent<ComponentRedirectWrapperProps> = function Component({
  entryPoint,
  sessionData,
}) {
  const navigationContext = NavigationWrapper.use();
  useEffect(() => {
    if (entryPoint) {
      console.log('ºº USER HAS ENTRYPOINST CONFIGURATED');
      if (defaultValidation(sessionData)) {
        console.log('ºº VALIDATION SAIS ITS VALID');
        navigationContext.navigateTo(entryPoint.pageIfValid, {
          mode: 'replace',
        });
        console.log('VALIDO ');
      } else {
        console.log('ºº VALIDATION SAIS ITS INVALID');
        navigationContext.navigateTo(entryPoint.pageIfInvalid, {
          mode: 'replace',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
