/* eslint-disable no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import { Text } from '../Text';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  applyFunctionalityStyles,
  generateProperties,
} from '../common';

import CameraIcon from '@material-ui/icons/PhotoCamera';
import CircularProgress from '@material-ui/core/CircularProgress';
import { View } from '../View';

const e = React.createElement;

export const InputImage: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  name,
  text,
  onSelectImage,
  defaultImage,
  disabled,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled }
  );

  // Otros ----------------------------------------------------------------
  const [isLoading, setIsLoading] = useState(false);
  const [localImage, setLocalImage] = useState<any>();
  const imageUrl = localImage || defaultImage;

  // Return -----------------------------------------------------
  return e(
    'label',
    {
      ...generateProperties(
        parentReference,
        undefined,
        'container',
        coreId,
        id,
        true
      ),
      htmlFor: name,
      style: componentStyle.containerComponent,
    },
    e(
      View,
      {
        ...generateProperties(parentReference, enumKey, 'imageContainer'),
        style: {
          ...componentStyle.imageContainerComponent,
          backgroundImage: 'url(' + imageUrl + ')',
        },
      },
      !imageUrl &&
        e(
          React.Fragment,
          {},
          isLoading && e(CircularProgress, { size: 50, color: 'primary' }),
          !isLoading && [
            e(CameraIcon, {
              ...generateProperties(
                parentReference,
                enumKey,
                'camera',
                undefined,
                undefined,
                undefined,
                true
              ),
              style: componentStyle.cameraComponent,
            }),
            e(
              Text,
              {
                ...generateProperties(parentReference, enumKey, 'text'),
                style: componentStyle.textComponent,
              },
              text
            ),
          ]
        )
    ),
    e('input', {
      type: 'file',
      disabled,
      id: name,
      style: { display: 'none' },
      accept: 'image/*',
      onChange: async e => {
        if (e.target.files && e.target.files[0]) {
          setIsLoading(true);
          const reader = new FileReader();
          const file = e.target.files[0];
          reader.onload = async e => {
            e?.target && setLocalImage(e.target.result);
          };
          reader.readAsDataURL(file);

          setIsLoading(false);
          onSelectImage && onSelectImage(file);
        }
      },
    })
  );
};
