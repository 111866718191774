export class VirtualsGeneratorBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  State,
  Virtuals,
  BindedContexts
> {
  public variables!: InternalVariables & Variables & ExternalVariables;
  public state?: State;
  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;

  private _constructor!: (
    context: VirtualsGeneratorBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      State,
      Virtuals,
      BindedContexts
    >
  ) => Virtuals;

  setConstructor(
    value: (
      context: VirtualsGeneratorBuilder<
        ComponentPropsDefinition,
        InternalVariables,
        Variables,
        ExternalVariables,
        State,
        Virtuals,
        BindedContexts
      >
    ) => Virtuals
  ) {
    this._constructor = value;
  }

  build() {
    return this._constructor(this);
  }
}
