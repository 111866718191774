import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  thumbComponent: {
    backgroundColor: _theme?.colors?.secondary,
  },
  disabledMode: {
    thumbComponent: {
      backgroundColor: 'rgb(200,200,200)',
    },
  },
});

export default baseConfiguration;
