import { Logger } from '../../generic/utils';
import { useState, SetStateAction } from 'react';
import { Builder, CoreStateHandler } from '../common';
import { VariablesRegister } from './variablesRegister';

const console = new Logger('build');

if (console) {
  //Just for usage
}

export class VariablesBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  State,
  DispatchTypes
> implements Builder {
  public wrapperName!: string;

  public stateHandler!: CoreStateHandler<State, DispatchTypes>;
  public componentProps!: ComponentPropsDefinition;

  private _variablesRegister!: VariablesRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    State
  >;
  public set variablesRegister(
    value: VariablesRegister<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      State
    >
  ) {
    this._variablesRegister = value;
  }

  build() {
    const varKeys = (
      this._variablesRegister || { getKeys: () => [] }
    ).getKeys();

    let variables = varKeys
      .map(varName => {
        const def = this._variablesRegister.getVariableDefinitionBuilder(
          varName
        );
        return {
          [varName]: this.generateVariableMainPair(
            varName,
            typeof def === 'function'
              ? def({
                  state: this.stateHandler.state,
                  componentProps: this.componentProps,
                })
              : def
          ),
        };
      })
      .reduce((acc, act) => ({ ...acc, ...act }), {});
    /*for (const varName of this._variablesRegister.getKeys()) {
      variables[varName] = this.generateVariableMainPair(
        varName,
        this._variablesRegister.getVariableDefinition(varName)
      );
    }*/
    const vars = {
      // ...variables,
      keys: varKeys,
      getVariablePair: <
        T extends
          | Extract<keyof InternalVariables, string>
          | Extract<keyof Variables, string>
          | Extract<keyof ExternalVariables, string>
      >(
        varName: T
      ) => {
        if (variables && variables[varName]) {
          return variables[varName];
        } else {
          throw new Error('Variable ' + varName + ' not defined.');
        }
      },
      getVariableValue: <
        T extends
          | Extract<keyof InternalVariables, string>
          | Extract<keyof Variables, string>
          | Extract<keyof ExternalVariables, string>
      >(
        varName: T
      ) => {
        if (variables && variables[varName]) {
          return variables[varName][0];
        } else {
          throw new Error('Variable ' + varName + ' not defined.');
        }
      },
      getVariableSetter: <
        T extends
          | Extract<keyof InternalVariables, string>
          | Extract<keyof Variables, string>
          | Extract<keyof ExternalVariables, string>
      >(
        varName: T
      ) => {
        if (variables && variables[varName]) {
          return variables[varName][1];
        } else {
          throw new Error('Variable ' + varName + ' not defined.');
        }
      },
      resetVariables: () => {
        for (const varName of this._variablesRegister.getKeys()) {
          const varDefinition = this._variablesRegister.getVariableDefinitionBuilder(
            varName
          );
          //for (let i = 0; i < Object.keys(stateVariables || {}).length; i++) {
          //const varName = Object.keys(stateVariables || {})[i];
          variables[varName][1](
            typeof varDefinition === 'function'
              ? varDefinition({
                  state: this.stateHandler.state,
                  componentProps: this.componentProps,
                }).startValue
              : varDefinition.startValue
          );
        }
      },
    };
    return vars;
  }

  private generateVariableMainPair<
    T extends
      | Extract<keyof InternalVariables, string>
      | Extract<keyof Variables, string>
      | Extract<keyof ExternalVariables, string>
  >(
    varName: T,
    varDefinition: {
      persist?: boolean;
      startValue: T extends Extract<keyof InternalVariables, string>
        ? InternalVariables[T]
        : T extends Extract<keyof Variables, string>
        ? Variables[T]
        : T extends Extract<keyof ExternalVariables, string>
        ? ExternalVariables[T]
        : any;
    }
  ): [
    T extends Extract<keyof InternalVariables, string>
      ? InternalVariables[T]
      : T extends Extract<keyof Variables, string>
      ? Variables[T]
      : T extends Extract<keyof ExternalVariables, string>
      ? ExternalVariables[T]
      : any,
    (
      value: SetStateAction<
        T extends Extract<keyof InternalVariables, string>
          ? InternalVariables[T]
          : T extends Extract<keyof Variables, string>
          ? Variables[T]
          : T extends Extract<keyof ExternalVariables, string>
          ? ExternalVariables[T]
          : any
      >
    ) => void
  ] {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setter] = useState(
      (varDefinition.persist &&
        this.stateHandler.state &&
        (((this.stateHandler.state as unknown) as any)[
          varName
        ] as T extends Extract<keyof InternalVariables, string>
          ? InternalVariables[T]
          : T extends Extract<keyof Variables, string>
          ? Variables[T]
          : T extends Extract<keyof ExternalVariables, string>
          ? ExternalVariables[T]
          : any)) ||
        varDefinition.startValue
    );

    return [
      value,
      setStateAction => {
        if (typeof setStateAction === 'function') {
          setter(prevValue => {
            const valueToSet = (setStateAction as (
              prevState: T extends Extract<keyof InternalVariables, string>
                ? InternalVariables[T]
                : T extends Extract<keyof Variables, string>
                ? Variables[T]
                : T extends Extract<keyof ExternalVariables, string>
                ? ExternalVariables[T]
                : any
            ) => T extends Extract<keyof InternalVariables, string>
              ? InternalVariables[T]
              : T extends Extract<keyof Variables, string>
              ? Variables[T]
              : T extends Extract<keyof ExternalVariables, string>
              ? ExternalVariables[T]
              : any)(
              prevValue as T extends Extract<keyof InternalVariables, string>
                ? InternalVariables[T]
                : T extends Extract<keyof Variables, string>
                ? Variables[T]
                : T extends Extract<keyof ExternalVariables, string>
                ? ExternalVariables[T]
                : any
            );
            if (varDefinition.persist) {
              console.log('ºº PERSISTING', varName, valueToSet);
              this.stateHandler.dispatch({
                type: (this.wrapperName + '_propertyPersist') as any,
                payload: { name: varName, value: valueToSet },
              });
            }
            return valueToSet;
          });
        } else {
          if (varDefinition.persist) {
            console.log('ºº PERSISTING', varName, setStateAction);
            this.stateHandler.dispatch({
              type: (this.wrapperName + '_propertyPersist') as any,
              payload: { name: varName, value: setStateAction },
            });
          }
          setter(setStateAction);
        }
      },
    ];
  }

  isConfigured() {
    return (
      this._variablesRegister && this._variablesRegister.hasRegistrations()
    );
  }
}
