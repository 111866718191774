/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { WrapperBuilder } from '../../builders/wrapperBuilder';

export enum CONNECTION_MODES {
  'HTTP',
  'HTTPS',
}
// Main builder

// ################### YOU JUST CAN IGNORE THE TYPINGS
// const wrapperBuilder = new WrapperBuilder('allInOneWrapper', 'allInOneWrapper')
// ###################

export const wrapperBuilder = new WrapperBuilder<
  // props of the wrapper
  {
    enableCors: boolean;
  },
  // Internal variables used in the wrapper logic
  {
    corsEncriptionSystem: 'SHA' | 'MD5';
  },
  // Variables only visibled in the core library for interconexion between wrappers
  {
    corsActivatingStatus: 'activating' | 'activated';
  },
  // public variables
  {
    enabledCors: boolean;
  },
  //Internal
  {
    construcCorsNetworkPackage: (data: string) => any;
  },
  //Core
  {
    registerListener: (listener: () => any) => void;
  },
  //Public
  {
    getCorsStatus: () => boolean;
  },
  // State variables
  {
    lastActionDispatched: string;
  },
  // Virtuals
  {
    corsCustomstatus:
      | 'enabledAndActivated'
      | 'enabledButActivating'
      | 'notEnabled';
  },
  // Static properties of the wrapper
  {
    CONNECTION_MODES: typeof CONNECTION_MODES;
  },
  {},
  {}
>('allInOneWrapper', 'allInOneWrapper');

// ##### Functions Definitions
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('getCorsStatus', wrapperEnvironment => () => {
    return wrapperEnvironment.variablesHandler.variables.enabledCors;
  });
// ..other functions

// ##### Variables Definitions
wrapperBuilder
  .getVariablesRegisterInstance()
  .addVariable('enabledCors', { startValue: false })
  .addVariable('corsEncriptionSystem', _ => {
    return { startValue: 'MD5', persist: true };
  });
// ..other varaibles

// ##### Virtuals generator Definition
wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(wrapperEnvironment => {
    return {
      corsCustomstatus: wrapperEnvironment.variables.enabledCors
        ? wrapperEnvironment.variables.corsActivatingStatus === 'activated'
          ? 'enabledAndActivated'
          : 'enabledButActivating'
        : 'notEnabled',
    };
  });

// ##### Reducers Definition
wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer('exampleReducer', true, () => action => {
    return {
      lastActionDispatched: action.type + '-' + action.payload,
    };
  });

// ##### Logic Definition
wrapperBuilder.getCustomLogicRegisterInstance().setLogic(wrapperEnvironment => {
  useEffect(() => {
    if (wrapperEnvironment.componentProps.enableCors) {
      console.log('ENABLING CORS');
      wrapperEnvironment.variablesHandler.variablesSetter('enabledCors')(true);
    } else {
      console.log('NOT ENABLING CORS');
      wrapperEnvironment.variablesHandler.variablesSetter('enabledCors')(false);
    }
  }, [wrapperEnvironment.componentProps.enableCors]);
});

// ##### Register a custom render for the wrapper
wrapperBuilder
  .getCustomRenderRegisterInstance()
  .setRenderer(wrapperEnvironment => (e, children) => {
    console.log(
      ' CUSTOM RENDERER FOR THE WRAPPER. The context is',
      wrapperEnvironment
    );
    return e(
      React.Fragment,
      {},
      e('div', {}, 'Custom render aditional text'),
      children
    );
  });

export const AllInOneWrapper = wrapperBuilder.build();
