/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
// import { useInitialize } from '../../generic/use';
import { Logger, UserInput, ExternalVar } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { Chat, MODES, LOGIN_METHODS } from './others/Chat';
import { ApplicationWrapper } from '../ApplicationWrapper';
import { useEffect } from 'react';
import { SessionWrapper } from '../SessionWrapper';
import { BackendWrapper } from '../BackendWrapper';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { wrapperBuilder } from './build';
import { UserData } from './interfaces';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(
    ({
      componentProps: {
        mode,
        zopimId,
        applicationName,
        loginMethod,
        tokenProvider,
        serverUrl,
      },
      variablesHandler: { variables, variablesSetter },
    }) => {
      const { dispatch } = ApplicationWrapper.use();
      const backendContext = BackendWrapper.use();
      const url = serverUrl || (backendContext && backendContext.baseUrl);

      useEffect(() => {
        console.log(' INITING ' + wrapperIdentifier + ' Wrapper');
        if (!mode || mode === MODES.CLIENT) {
          Chat.initialize({
            // Zopim chat id
            id: zopimId,
            applicationName: applicationName,
            onInit: function(liveChatInstance: ExternalVar) {
              console.log(' INNITED', liveChatInstance);
              variablesSetter('liveChatInstance')(liveChatInstance);
              console.log(' INNITED2 ', liveChatInstance);
            },
            onUnreadMessage: function(countUnread) {
              console.log('UREAD', countUnread);
              dispatch({
                type: DISPATCH_TYPES.UNREADED_MESSAGES,
                payload: countUnread,
              });
            },
          });

          if (!variables.applicationToken && tokenProvider) {
            console.log(' Calling token obtainer');
            new Promise<string | undefined>(async resolve => {
              try {
                const token = await tokenProvider();
                console.log(' OK token obtainer', token);
                resolve(token);
              } catch (e) {
                console.log(' ERROR token obtainer');
                resolve(undefined);
              }
            }).then(token => variablesSetter('applicationToken')(token));
          }
        }
      }, []);

      const loginChat = (data: UserInput, token: UserInput) => {
        Chat.login(data, token, () => dispatch(DISPATCH_TYPES.CHAT_READY));
      };

      //Handler Server mode isReady
      useEffect(() => {
        console.log('ºº logic CHAT is ready', url);
        if (url && mode === MODES.SERVER) {
          dispatch(DISPATCH_TYPES.CHAT_READY);
        }
      }, [url]);

      //Handle login/logout GUEST
      useEffect(() => {
        if (
          variables.applicationToken &&
          variables.liveChatInstance &&
          (!loginMethod || loginMethod === LOGIN_METHODS.GUEST)
        ) {
          loginChat(
            {
              email: 'invitado@mail.com',
              name: 'Invitado',
              id: variables.deviceChatId,
            },
            variables.applicationToken
          );
        }
      }, [variables.applicationToken, variables.liveChatInstance]);

      //Handle login/logout FROM_SESSION
      const sessionContext = SessionWrapper.use();
      const sessionData = sessionContext && sessionContext.sessionData;
      useEffect(() => {
        if (
          variables.applicationToken &&
          variables.liveChatInstance &&
          loginMethod === LOGIN_METHODS.FROM_SESSION
        ) {
          if (sessionData) {
            loginChat(
              {
                email: (sessionData as UserData).email,
                name: (sessionData as UserData).name,
                id: (sessionData as UserData).id || variables.deviceChatId,
              },
              variables.applicationToken
            );
          } /* else {
            // Reducer do this
            Chat.logout();
          } */
        }
      }, [variables.applicationToken, sessionData, variables.liveChatInstance]);

      //Handle login/logout FROM_USER
      useEffect(() => {
        if (
          variables.applicationToken &&
          variables.liveChatInstance &&
          loginMethod === LOGIN_METHODS.FROM_USER
        ) {
          // TODO: login/logout using user
        }
      }, [variables.applicationToken, variables.liveChatInstance]);

      //Handle login/logout PROVIDED
      useEffect(() => {
        if (
          variables.liveChatInstance &&
          loginMethod === LOGIN_METHODS.PROVIDED &&
          variables.userData &&
          variables.applicationToken
        ) {
          loginChat(
            {
              email: variables.userData.email,
              name: variables.userData.name,
              id: variables.userData.id || variables.deviceChatId,
            },
            variables.applicationToken
          );
        }
      }, [
        variables.userData,
        variables.applicationToken,
        variables.liveChatInstance,
      ]);
    }
  );
