import React, { FunctionComponent, useState } from 'react';

import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import SwipeableViews from 'react-swipeable-views';
//import MobileStepper from '@material-ui/core/MobileStepper';

import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  applyFunctionalityStyles,
} from '../common';
import { MobileStepperCustom } from './others/MobileStepperCustom';

const e = React.createElement;

export const Slider: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  width,
  height,
  children,
  showDots,
  ...props
}) => {
  // checkStyleNames(enumKey, props);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  if (width) {
    mainStyle.rootComponent = {
      ...mainStyle.rootComponent,
      width: width,
    };
  }

  if (height) {
    mainStyle.rootComponent = {
      ...mainStyle.rootComponent,
      height: height,
    };
  }

  // Inyectar estilos ad hoc -----------------------------------------
  let containerStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {}
  );

  // Otros

  const [pos, setPos] = useState(0);
  // Return ---------------------------------------------------
  return e(
    React.Fragment,
    undefined,
    e(
      SwipeableViews,
      {
        ...props,
        index: typeof props.index !== 'undefined' ? props.index : pos,
        ignoreNativeScroll:
          typeof props.ignoreNativeScroll !== 'undefined'
            ? props.ignoreNativeScroll
            : true,
        onChangeIndex: (i: number, latest: number) => {
          setPos(i);
          props.onChangeIndex && props.onChangeIndex(i, latest);
        },
        style: containerStyle.rootComponent,
        containerStyle: containerStyle.containerComponent,
        slideStyle: containerStyle.slideComponent,
      } as any,
      children
    ),
    showDots
      ? e(
          MobileStepperCustom,
          {
            steps: React.Children.count(children),
            activeStep: typeof props.index !== 'undefined' ? props.index : pos,
            onClickStep: (stepIndex: number) => {
              setPos(stepIndex);
              props.onClickStep && props.onClickStep(stepIndex);
            },
            style: containerStyle,
          } as any,
          null
        )
      : null
  );
};
