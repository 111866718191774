import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { hideSplashScreen } from './native';
import { wrapperBuilder } from './build';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer(
    'UiWrapper: First render handler',
    false,
    _ => (action, _, state) => {
      if (action.type === DISPATCH_TYPES.FIRST_RENDER) {
        hideSplashScreen();
      }
      return {
        ...state,
      };
    }
  );
