import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { Contribution, wrapperIdentifier } from './common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const functionsRegister = wrapperBuilder.getFunctionsRegisterInstance();

functionsRegister.addFunction(
  'loadContributions',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async userId => {
      const contributions: Contribution[] = await api.getUserContributions(
        userId
      );
      variablesSetter('contributions')(contributions);
    };
  }
);
export type CreateContribution = (
  idTarget: string,
  amount: number,
  concept?: string
) => void;
functionsRegister.addFunction(
  'createContribution',
  ({
    bindedContexts: { getInstanceApi },
    variablesHandler: { variablesSetter },
  }) => {
    const api = getInstanceApi();
    return async (idTarget, amount, concept) => {
      const data: any = { amount, concept };
      console.log('IMPLEMENT THIS', api, idTarget, data, variablesSetter);
      /*const newContribution: any = await api.createContribution(
        idProductContribution,
        data
      );
      variablesSetter('contributions')(contributions => [
        ...(contributions || []),
        newContribution,
      ]);*/
    };
  }
);
