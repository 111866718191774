import { Tag } from '../interface';

export const policyTag = new Tag(
  `default-src * data: blob: ws: wss: gap://ready file://*;
style-src * 'unsafe-inline'; 
script-src * 'unsafe-inline' 'unsafe-eval' blob:;
connect-src * ws: wss:;`,
  undefined,
  'Content-Security-Policy'
);
