import { CoreFetch } from '../../wrappers/BackendWrapper/common';
import { CoinscrapApi } from '../../generic/Apis';
import { Logger } from '../../generic/utils';
import { userMapper } from './mappers';

export const wrapperIdentifier = 'user';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type UserGetter<T> = (
  sessionData: any,
  coinscrapApi: CoinscrapApi,
  coreFetch: CoreFetch,
  options?: {
    enableRoles?: boolean;
  }
) => Promise<T | null>;

export const defaultUserGetter: UserGetter<User> = async (
  sessionData,
  coinscrapApi,
  _,
  options
) => {
  let user;
  const token = sessionData?.token;
  try {
    user = await coinscrapApi.getUser(
      'me',
      token,
      [options?.enableRoles && 'roles'].filter(x => x) as string[]
    );
    console.log('Loaded user ', user);

    user = userMapper(user, coinscrapApi.getCurrentOptions().baseUrl);
  } catch (e) {
    user = null;
  }
  return user;
};

export class UserService {
  private static _i: UserService;

  public static get instance() {
    if (!this._i) {
      this._i = new UserService();
    }
    return this._i;
  }

  constructor(public getter: UserGetter<User> = defaultUserGetter) {}
}
export interface ExternalToken {
  sessionData?: any;
}
export interface IdentityProvider {
  id: string;
  identifier: string;
  userIdentifier: string;
  metadata?: any;
  userId: string;
  externalTokens?: ExternalToken[];
}

export interface User {
  id: string;
  _id: string;
  name: string;
  surname: string;
  phoneNumber: string;
  email: string;
  metadata: any;
  roles: { identifier: string }[];
  identityProviders?: IdentityProvider[];
}
