export class Tag {
  constructor(
    private content: string,
    private name?: string,
    private httpEquiv?: string
  ) {}
  insert() {
    const meta = document.createElement('meta');
    this.httpEquiv && (meta.httpEquiv = this.httpEquiv);
    this.name && (meta.name = this.name);
    meta.content = this.content;
    document.getElementsByTagName('head')[0].appendChild(meta);
  }
}

export class Script {
  constructor(private content?: string, private src?: string) {}
  insert() {
    const script = document.createElement('script');
    script.async = false;
    script.type = 'text/javascript';
    this.content && (script.innerText = this.content);
    this.src && (script.src = this.src);
    document.getElementsByTagName('head')[0].appendChild(script);
  }
}

export class Link {
  constructor(private rel?: string, private href?: string) {}
  insert(href?: string) {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');
    this.rel && (link.rel = this.rel);
    const hrefComputed: string = (this.href || href) as any;
    hrefComputed &&
      (link.href =
        hrefComputed[0] === '/' || /data:/.exec(hrefComputed)
          ? hrefComputed
          : 'data:image/png;base64,' + hrefComputed);
    document.getElementsByTagName('head')[0].appendChild(link);
  }
}
