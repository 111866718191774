import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { Coinscrap } from './others/Coinscrap';
import { Teallium } from './others/Teallium';
import { TRANSPORTS } from './others/transportDefinitions';
import { WalmericDelio } from './others/WalmericDelio';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

// PAGE VIEW
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'pageView',
    ({
      bindedContexts: { user, getInstanceApi },
      componentProps,
    }) => async payload => {
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.COINSCRAP },
      ];
      for (let i = 0; i < transports.length; i++) {
        const transport = transports[i];
        switch (transport?.engine) {
          case TRANSPORTS.COINSCRAP:
            await Coinscrap.pageView(getInstanceApi, user, payload, {
              ...transport.initProps,
              // debugEnabled
            });
            break;
          case TRANSPORTS.TEALLIUM:
            await Teallium.pageView(getInstanceApi, user, payload, {
              ...transport.initProps,
              // debugEnabled
            });
            break;
          default:
            console.log('pageView not supported', transport?.engine);
            break;
        }
      }
      return;
    }
  );

// EMIT EVENT
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'emitEvent',
    ({
      bindedContexts: { user, getInstanceApi },
      componentProps,
    }) => async payload => {
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.COINSCRAP },
      ];
      for (let i = 0; i < transports.length; i++) {
        const transport = transports[i];
        switch (transport?.engine) {
          case TRANSPORTS.TEALLIUM:
            await Teallium.emitEvent(getInstanceApi, user, payload, {
              ...transport.initProps,
              // debugEnabled
            });
            break;
          default:
            console.log('emitEvent not supported', transport?.engine);
            break;
        }
      }
      return;
    }
  );

// UPDATE DATA
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'updateData',
    ({
      bindedContexts: { user, getInstanceApi },
      componentProps,
    }) => async payload => {
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.COINSCRAP },
      ];
      for (let i = 0; i < transports.length; i++) {
        const transport = transports[i] || undefined;
        switch (transport?.engine) {
          case TRANSPORTS.TEALLIUM:
            await Teallium.updateData(getInstanceApi, user, payload, {
              ...transport.initProps,
              // debugEnabled
            });
            break;
          default:
            console.log('updateData not supported', transport?.engine);
            break;
        }
      }
      return;
    }
  );

// REGISTER LEAD
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'regLead',
    ({
      bindedContexts: { user, getInstanceApi },
      componentProps,
    }) => async payload => {
      const apiInstance = getInstanceApi();
      const transports = componentProps.transports || [
        { engine: TRANSPORTS.WALMERIC_DELIO },
      ];
      for (let i = 0; i < transports.length; i++) {
        const transport = transports[i];
        switch (transport?.engine) {
          case TRANSPORTS.WALMERIC_DELIO:
            return await WalmericDelio.leadRegister(
              transport?.initProps?.useMainScript,
              transport?.initProps?.debugEnabled,
              user,
              apiInstance,
              payload
            );
            break;
          default:
            console.log('regLead not supported', transport?.engine);
            break;
        }
      }
      return;
    }
  );
