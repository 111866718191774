import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';
import { DISPATCH_TYPES } from '../../generic/interfaces';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

// ##### Reducers Definition
wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer(
    'RuleChangesReducer',
    false,
    ({ functions: { loadTargets } }) => (action, _, state) => {
      if (
        action.type === DISPATCH_TYPES.CHANGE_IN_RULES ||
        action.type === DISPATCH_TYPES.TARGET_PROPERTY_CHANGED ||
        action.type === DISPATCH_TYPES.TARGET_ACTIVATED ||
        action.type === DISPATCH_TYPES.TARGET_REACTIVATED
      ) {
        loadTargets(
          (Array.isArray(action.payload) && action.payload) || undefined
        );
      }
      return state;
    }
  );
