import { Logger } from '../../../generic/utils';
import {
  wrapperIdentifier,
  CoreFetch,
  CoreRequestError,
  CoreConnectionError,
} from '../common';
import qs from 'qs';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
export const navigatorFetchBuilder: () => CoreFetch = () => {
  return async (method, url, options, rD) => {
    const headers: any = {};

    const { queryParams, bodyParams } = rD || {};

    let procesedUrl = url.split('?')[0];
    let params: any = (url.split('?')[1] || '').trim()
      ? { ...queryParams, ...qs.parse(url.split('?')[1]) }
      : queryParams;
    let body: any = bodyParams;

    if (
      (['get', 'head', 'delete', 'upload', 'download'].indexOf(
        method.toLowerCase().trim()
      ) >= 0 &&
        body) ||
      (['post', 'patch', 'put'].indexOf(method.toLowerCase().trim()) >= 0 &&
        params)
    ) {
      throw new CoreConnectionError(
        `Cannot specify ${
          params ? 'queryParams' : 'requestBody'
        } in a ${method.toLowerCase().trim()}`
      );
    }

    try {
      if (!options || !options.formData) {
        headers['Content-Type'] = 'application/json';
      }
      if (options && options.urlencoded) {
        headers['Content-Type'] =
          'application/x-www-form-urlencoded;charset=UTF-8';
      }

      const data: RequestInit = {
        method: method.toUpperCase().trim(),
        headers: {
          ...headers,
          ...options?.headers,
        },
      };
      if (
        ['get', 'head', 'delete', 'upload', 'download'].indexOf(
          method.toLowerCase().trim()
        ) >= 0
      ) {
        data.headers = options?.headers as any;
        if (params) {
          params = Object.keys(params).reduce(
            (acc, act) => ({
              ...acc,
              [act]:
                (typeof (params as any)[act] as any) === 'object'
                  ? JSON.stringify((params as any)[act] as any)
                  : ((params as any)[act] as any),
            }),
            {}
          );
          procesedUrl = procesedUrl + '?' + qs.stringify(params);
        }
      } else {
        if (options?.formData && body) {
          const formData = new global.FormData();
          Object.keys(body).forEach(k => {
            formData.append(k, (body as any)[k]);
          });
          body = formData;
        } else if (options?.urlencoded && body) {
          const formBody: string[] = [];
          Object.keys(body).forEach(property => {
            const encodedKey = encodeURIComponent(property);
            const encodedValue = encodeURIComponent(body[property]);
            formBody.push(encodedKey + '=' + encodedValue);
          });
          body = formBody.join('&');
        } else {
          body = body && typeof body === 'object' ? JSON.stringify(body) : body;
        }
        if (body) {
          data.body = body;
        }
      }

      let response;
      try {
        console.log(
          'ºº CoreFetchCall',
          {
            method,
            url,
            options,
            requesData: rD,
          },
          {
            method,
            url: procesedUrl,
            headers: data.headers,
            requesData: { queryParams: params, bodyParams: body },
          }
        );
        response = await fetch(procesedUrl, data);
        console.log('ºº RESPONSE', url, procesedUrl, response);
      } catch (e) {
        console.log('ºº RESPONSE', url, procesedUrl, e);
        throw new CoreConnectionError(e || 'Unknow reason');
      }
      const responseHeaders: any = {};
      response.headers.forEach((val, key) => {
        responseHeaders[key] = val;
      });
      let rawData;
      if (response.status < 200 || response.status > 299) {
        try {
          rawData = await response.json();
        } catch (e) {}
        throw new CoreRequestError(response.status, rawData, responseHeaders);
      } else {
        if (response.status === 204 || !response.body) {
          return {
            status: response.status,
            data: {},
            headers: responseHeaders,
          };
        }

        console.log('ºº ' + method + '  ' + url + ' PREV JSON');
        try {
          return {
            status: response.status,
            data: await response.json(),
            headers: responseHeaders,
          };
        } catch (e) {
          console.log('ºº ' + method + '  ' + url + ' PARSING ERROR', e);
          return {
            status: response.status,
            data: {},
            headers: responseHeaders,
          };
        }
      }
    } catch (e) {
      console.log('ºº ' + method + '  ' + url + ' API ERROR', e);
      throw e;
    }
  };
};
