import { Logger } from '../../generic/utils';
import { wrapperIdentifier, InstitutionAccount } from './common';
import { OPERATION_TYPES } from '../../generic/Apis';
import { institutionAccountsMapper } from './mappers';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadInstitutionAccounts = () => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadInstitutionAccounts',
    ({
      bindedContexts: { user, getInstanceApi },
      variablesHandler: { variablesSetter },
    }) => async () => {
      if (!user) {
        throw new Error('No existent user');
      }
      let institutionAccounts: InstitutionAccount[] | null;
      try {
        institutionAccounts = await getInstanceApi().getUserInstitutionAccounts(
          user && (user._id || user.id)
        );
        institutionAccounts = institutionAccountsMapper(institutionAccounts);
        console.log('Loaded institutionAccounts ', institutionAccounts);
      } catch (e) {
        institutionAccounts = null;
      }
      variablesSetter('institutionAccounts')(institutionAccounts);
    }
  );

export type DisableInstitutionAccount = (institutionAccountId: string) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'disableInstitutionAccount',
    ({
      bindedContexts: { getInstanceApi },
      variablesHandler: { variablesSetter },
    }) => async iAcId => {
      await getInstanceApi().disableInstitutionAccount(iAcId);
      variablesSetter('institutionAccounts')(institutionAccounts =>
        institutionAccounts?.map(i => {
          if (i.id === iAcId) {
            i.isDisabled = true;
          }
          return i;
        })
      );
    }
  );

export type EnableInstitutionAccount = (institutionAccountId: string) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'enableInstitutionAccount',
    ({
      bindedContexts: { getInstanceApi },
      variablesHandler: { variablesSetter },
    }) => async iAcId => {
      await getInstanceApi().enableInstitutionAccount(iAcId);
      variablesSetter('institutionAccounts')(institutionAccounts =>
        institutionAccounts?.map(i => {
          if (i.id === iAcId) {
            i.isDisabled = false;
          }
          return i;
        })
      );
    }
  );

export type UdateInstitutionAccount = (institutionAccountId: string) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'updateInstitutionAccount',
    ({
      bindedContexts: { getInstanceApi },
      variablesHandler: { variablesSetter },
    }) => async institutionAccountId => {
      let institutionAccount: InstitutionAccount;
      try {
        institutionAccount = await getInstanceApi().getInstitutionAccount(
          institutionAccountId
        );
        institutionAccount = institutionAccountsMapper([institutionAccount])[0];
        console.log('Updated institutionAccount ', institutionAccount);
        variablesSetter('institutionAccounts')(prevInstitutionAccounts =>
          (prevInstitutionAccounts || []).map(i =>
            i.id === institutionAccount.id ? institutionAccount : i
          )
        );
        return institutionAccount;
      } catch (e) {}
      return undefined;
    }
  );

export type CreateInstitutionAccount = (
  identifier: string
) => Promise<InstitutionAccount>;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'createInstitutionAccount',
    ({
      bindedContexts: { institutions, createOperation, user },
      variablesHandler: { variablesSetter },
    }) => async institutionIdentifier => {
      if (!user) {
        throw new Error('No existent user');
      }
      let newAcc: InstitutionAccount;
      const opNewAcc = await createOperation(
        OPERATION_TYPES.CREATE_INSTITUTION_ACCOUNT,
        {
          institutionIdentifier,
        }
      );
      // TODO: SYNCED with institution account model, USED RETURNED
      newAcc = {
        id: opNewAcc.institutionAccountId,
        createDate: new Date().toISOString(),
        institutionIdentifier,
        name: (institutions || []).find(
          i => i.identifier === institutionIdentifier
        )?.name as string,
        isDeleted: false,
        isDisabled: false,
        userId: user && (user._id || user.id),
        products: [],
      };
      console.log('Created institutionAccounts ', newAcc);
      variablesSetter('institutionAccounts')(iAcs => [...(iAcs || []), newAcc]);
      return newAcc;
    }
  );

export type EnableDisableProduct = (
  productId: string,
  isDisabled: boolean
) => Promise<void>;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'enableDisableProduct',
    ({
      bindedContexts: { getInstanceApi },
      variablesHandler: { variablesSetter },
    }) => async (productId, isDisabled) => {
      await getInstanceApi().enableDisableProduct(productId, isDisabled);
      variablesSetter('institutionAccounts')(prevInstitutionAccounts =>
        (prevInstitutionAccounts || []).map(iAc => {
          const productoActualizado = iAc.products?.find(
            p => p.id === productId
          );
          if (productoActualizado) {
            iAc.products = iAc.products.map(p => {
              if (p.id === productoActualizado.id) {
                p.isDisabled = isDisabled;
              }
              return p;
            });
            return iAc;
          } else {
            return iAc;
          }
        })
      );
    }
  );
