import React from 'react';
import { Slide } from '@material-ui/core';

const e = React.createElement;
export const Transition = React.forwardRef(function Transition(props, ref) {
  return e(Slide, {
    ref: ref,
    direction: 'up',
    ...props,
  } as any);
});
