import React, { PropsWithChildren, ReactElement } from 'react';
import { PageComponentProps, PageComponent } from '../interfaces';
import { Module } from './module';
import { isEqual } from 'lodash';
import { useLocation, matchPath } from 'react-router-dom';
import { NavigationWrapper } from '..';
import { Logger, wrapProvider } from '../../../generic/utils';
import { useProvider } from '../../../generic';
import { Controller } from './controller';
import { ApplicationWrapper } from '../../ApplicationWrapper';
import { DISPATCH_TYPES } from '../../../generic/interfaces';
import { UiWrapper } from '../../UiWrapper';
import { lastRouteChange } from '../functions';
import { BaseNameController } from './baseNameController';

const console = new Logger('page');

if (console) {
  //Just for usage
}

let animationKeyCount = 0;
//let lastSettedAction = '';
let currentAnimationData: any = ['', { animationKey: animationKeyCount }];
export const getCurrentAnimationData = () => currentAnimationData;
let lastSettedAnimation = '';
let firstRenderNotified = false;
export interface PageContext {
  params: { [k: string]: any };
  query: { [k: string]: any };
  location?: any;
}

const pagesControllers: any = {};

const e = React.createElement;
let params: any = {};
const PageWrapper = React.createContext<PageContext>({
  params: {},
  query: {},
  location: undefined,
});
export const Page: (<T>(
  props: PropsWithChildren<PageComponentProps<T>>,
  context?: any
) => ReactElement<any, any> | null) & {
  use: (
    requester?: string
  ) => {
    params: { [k: string]: any };
    query: { [k: string]: any };
  };
} = ({
  path,
  component,
  controller,
  name,
  disableRenderNotification,
  pushAnimation,
  backAnimation,
  //...pageProps
}) => {
  const uiWrapperContext = UiWrapper.useInternal();
  const applicationWrapperContext = ApplicationWrapper.use();
  const moduleContext = Module.use();
  const prevPath = moduleContext && moduleContext.path;
  const pathGenerated =
    BaseNameController.instance.basename +
    (prevPath || '') +
    (path ? path : '/' + (name || ''));

  const location = useLocation();
  const match = matchPath(location.pathname, {
    exact: true,
    path: pathGenerated,
  });
  const { setCurrentParams, query } = NavigationWrapper.use() as any;
  // eslint-disable-next-line react-hooks/rules-of-hooks

  if (match) {
    if (!isEqual(match.params, params)) {
      params = match.params;
      console.log('## SETTING PARAMS', params);
      setCurrentParams(match.params);
    }
  }
  if (!pagesControllers[name]) {
    pagesControllers[name] /*e(*/ =
      controller || component.defaultsController
        ? (componentControllerHOC(component, controller) as any)
        : component;
    /*{},
        null
      );*/
  }
  const render = match ? e(pagesControllers[name], {}, null) : null;
  if (
    !disableRenderNotification &&
    !firstRenderNotified &&
    applicationWrapperContext
  ) {
    firstRenderNotified = true;
    applicationWrapperContext.dispatch(DISPATCH_TYPES.FIRST_RENDER);
  }
  if (
    uiWrapperContext &&
    render &&
    lastSettedAnimation !== name //&&
    //lastSettedAction !== lastRouteChange
  ) {
    lastSettedAnimation = name;
    //lastSettedAction = lastRouteChange;
    let animationData = undefined;
    if (lastRouteChange) {
      if (lastRouteChange === 'back' && backAnimation) {
        animationKeyCount++;
        animationData = { ...backAnimation, animationKey: animationKeyCount };
      } else if (lastRouteChange === 'push' && pushAnimation) {
        animationKeyCount++;
        animationData = { ...pushAnimation, animationKey: animationKeyCount };
      }
    }
    currentAnimationData = [
      name,
      { ...animationData, animationKey: animationKeyCount },
    ];
    uiWrapperContext.setCurrentPageAnimation([
      name + (!animationData ? '___done___' : ''),
      { ...animationData, animationKey: animationKeyCount },
    ]);
  }
  return wrapProvider(PageWrapper, { params, query, location })(
    uiWrapperContext &&
      ((lastRouteChange === 'back' && backAnimation) ||
        (lastRouteChange === 'push' && pushAnimation))
      ? uiWrapperContext.currentPageAnimation?.[0] === name + '___done___'
        ? render
        : null
      : render
  );
};

const componentControllerHOC: <T>(
  view: PageComponent<T>,
  controller?: Controller<T>
) => (
  props: PropsWithChildren<PageComponentProps<T>>,
  context?: any
) => ReactElement<any, any> | null = (view, controller) => {
  console.log('ººº BUILDING CONTROLLER');
  const componentToRender = componentControllerBuilder(view, controller);
  return controller && (controller.getHOCS() || []).length
    ? controller.getHOCS().reduce((component: any, hocToApply) => {
        const hocInstance = hocToApply.instance;
        return hocInstance
          ? hocInstance(...(hocToApply.args || []))(component)
          : component;
      }, componentToRender)
    : componentToRender;
};

const componentControllerBuilder: <T>(
  view: PageComponent<T>,
  controller?: Controller<T>
) => (
  props: PropsWithChildren<PageComponentProps<T>>,
  context?: any
) => ReactElement<any, any> | null = (view, controller) => ({
  children,
  ...props
}) => {
  const controllerGeneratedProps = (controller && controller.logic()) || {};
  const componentToRender = e(
    view,
    {
      ...(view.defaultsController || {}),
      ...controllerGeneratedProps,
      ...props,
    },
    children
  );
  return componentToRender;
};

Page.use = (requester?: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useProvider(PageWrapper, requester);
};

// Annotation for recursive search navigation tree
(Page as any).page = true;
