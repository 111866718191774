/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import { Text } from '../Text';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  applyFunctionalityStyles,
  generateProperties,
} from '../common';
import { View } from '../View';

const e = React.createElement;

export const InputCheck: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  label,
  disabled,
  checkedIcon,
  children,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled, checked: !!value || false }
  );

  // Otros ----------------------------------------------------------------

  // Return -----------------------------------------------------
  return e(
    View,
    {
      ...generateProperties(parentReference, enumKey, 'container', coreId),
      cleared: true,
      style: componentStyle.containerComponent,
    },
    label &&
      e(
        Text,
        {
          ...generateProperties(parentReference, enumKey, 'label'),
          style: componentStyle.labelComponent,
        },
        label
      ),
    e(Checkbox, {
      checked: value || false,
      color: 'primary',
      disabled: disabled || false,
      checkedIcon,
      inputProps: {
        ...generateProperties(
          parentReference,
          enumKey,
          'check',
          undefined,
          id,
          undefined,
          true
        ),
        'aria-label': 'primary checkbox',
      },
      onChange: v => {
        onChange && onChange(v);
      },
      style: componentStyle.checkComponent,
    }),
    children
  );
};
