import moment from 'moment';

import { Logger } from '../../../generic/utils';
import { wrapperIdentifier } from '../common';
import { TRANSPORTS } from './transportDefinitions';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

var inited = false;

export const Coinscrap = {
  initialize: async function(
    getInstanceApi: any,
    user: any,
    updateUser: any,
    { debugEnabled }: { debugEnabled?: boolean }
  ) {
    if (!inited) {
      debugEnabled && console.log(`## 📣 ${TRANSPORTS.COINSCRAP} INITING`);
      inited = true;

      let analyticId = user?.metadata?.analytics?.COINSCRAP?.id;

      // SI NO TIENE LA ANALYTIC CREADA SE CREA
      if (!analyticId) {
        try {
          analyticId = await this.startAnalytic({
            getInstanceApi,
            user,
            debugEnabled,
          });
          if (analyticId) {
            await updateUser({
              ...user,
              metadata: {
                ...user?.metadata,
                analytics: {
                  ...user?.metadata?.analytics,
                  COINSCRAP: { id: analyticId },
                },
              },
            });
          }
        } catch (error) {
          throw error;
        }
      }
      console.log(`## ✅ ${TRANSPORTS.COINSCRAP} analyticId`, analyticId);
    }
  },
  startAnalytic: async function(args: { [k: string]: any }) {
    const { getInstanceApi, user, debugEnabled } = args;

    debugEnabled &&
      console.log(`## 📣 CREATE NEW ${TRANSPORTS.COINSCRAP} ANALYTICS`);

    try {
      const apiInstance = getInstanceApi();

      const transports = await apiInstance.microserviceCall('Analytics')(
        '/transports',
        'GET',
        {
          filter: { where: { identifier: TRANSPORTS.COINSCRAP } },
        }
      );

      if (transports.length === 0) {
        throw new Error('COINSCRAP is not actived');
      }

      const analyticsCreated = await apiInstance.microserviceCall('Analytics')(
        `/users/${user.id}/anltcs`,
        'POST',
        {
          transportId: transports[0].id,
          input: {},
        }
      );

      debugEnabled &&
        console.log(
          ` ${TRANSPORTS.COINSCRAP} analyticsCreated`,
          analyticsCreated
        );

      let analyticPooled: any = {};
      let status = analyticsCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'CREATED_SUCCESS' && status !== 'CREATED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        analyticPooled = await apiInstance.microserviceCall('Analytics')(
          `/anltcs/${analyticsCreated.id}`,
          'GET'
        );
        status = analyticPooled.status;
      }

      if (status === 'CREATED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.COINSCRAP} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Error creating analytic');
      } else if (status !== 'CREATED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.COINSCRAP} startAnalytic analyticPooled`,
            analyticPooled
          );
        throw new Error('Timeout reached creating analytic');
      }
      // debugEnabled &&
      //   console.log(
      //     `## ✅ ${TRANSPORTS.COINSCRAP} startAnalytic analyticPooled`,
      //     analyticPooled
      //   );

      return analyticsCreated.id;
    } catch (error) {
      debugEnabled && console.log('## 📌 error', error);
      throw error;
    }
  },
  pageView: async function(
    getInstanceApi: any,
    user: any,
    payload: any,
    { debugEnabled }: { debugEnabled?: boolean }
  ) {
    debugEnabled &&
      console.log(`## 📝 ${TRANSPORTS.COINSCRAP} pageView`, payload);

    try {
      const apiInstance = getInstanceApi();

      const eventDefinitions = await apiInstance.microserviceCall('Analytics')(
        '/event-definitions',
        'GET',
        {
          filter: {
            where: {
              identifier: `${TRANSPORTS.COINSCRAP}_PAGE_VIEW`,
              transportIdentifier: TRANSPORTS.COINSCRAP,
            },
          },
        }
      );

      if (eventDefinitions.length === 0) {
        throw new Error('PAGE_VIEW for COINSCRAP is not actived');
      }

      if (!user?.metadata?.analytics?.[TRANSPORTS.COINSCRAP]?.id) {
        let analyticInited: boolean = false;
        let id: string | undefined = undefined;
        let finishDate = moment()
          .add(10, 'seconds')
          .toDate();

        while (id === undefined || +new Date() > +finishDate) {
          await new Promise(r => setTimeout(r, 1000));
          analyticInited = user?.metadata?.analytics?.[TRANSPORTS.COINSCRAP]?.id
            ? true
            : false;
          id =
            user?.metadata?.analytics?.[TRANSPORTS.COINSCRAP]?.id ?? undefined;
        }

        if (!analyticInited || id === undefined) {
          debugEnabled &&
            console.log(`## ❌ ${TRANSPORTS.COINSCRAP} not inited`);
          throw new Error(`${TRANSPORTS.COINSCRAP} not inited`);
        }
      }

      const eventCreated = await apiInstance.microserviceCall('Analytics')(
        `/anltcs/${user.metadata.analytics[TRANSPORTS.COINSCRAP].id}/events`,
        'POST',
        {
          eventDefinitionId: eventDefinitions[0].id,
          input: { ...payload },
        }
      );
      debugEnabled &&
        console.log(` ${TRANSPORTS.COINSCRAP} eventCreated`, eventCreated);

      let eventPooled: any = {};
      let status = eventCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        eventPooled = await apiInstance.microserviceCall('Analytics')(
          `/events/${eventCreated.id}`,
          'GET'
        );
        status = eventPooled.status;
      }

      if (status === 'FINISHED_ERROR') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.COINSCRAP} pageView eventPooled`,
            eventPooled
          );
        throw new Error('Error creating event');
      } else if (status !== 'FINISHED_SUCCESS') {
        debugEnabled &&
          console.log(
            `## ❌ ${TRANSPORTS.COINSCRAP} pageView eventPooled`,
            eventPooled
          );
        throw new Error('Timeout reached creating event');
      }
      debugEnabled &&
        console.log(
          `## ✅ ${TRANSPORTS.COINSCRAP} pageView eventPooled`,
          eventPooled
        );
      return { ...eventPooled.output };
    } catch (error) {
      debugEnabled && console.log('## 📌 error', error);
      throw error;
    }
  },
};
