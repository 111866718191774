import React from 'react';
import { LayoutKey as oneKey, HeaderFooterLayout } from './HeaderFooterLayout';
import { LayoutKey as twoKey, SimpleLayout } from './SimpleLayout';
export const PredefinedLayouts = {
  [oneKey]: HeaderFooterLayout,
  [twoKey]: SimpleLayout,
};

export const LayoutContext = React.createContext<{
  predefinedLayout: string | undefined;
  overrided: boolean;
}>({ predefinedLayout: undefined, overrided: false });
