import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';
import { Operation } from 'entityWrappers/OperationsWrapper/common';
import moment from 'moment';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(
    ({
      bindedContexts: { operations },
      variables: { institutionAccounts },
    }) => {
      return {
        institutionAccounts:
          institutionAccounts &&
          institutionAccounts.map(iAc => {
            iAc.pendingOperations =
              Object.keys(operations || {})
                .filter(
                  k => (operations || {})[k].institutionAccountId === iAc.id
                )
                .filter(k => (operations || {})[k].status === 'PENDING')
                .length || undefined;
            iAc.currentPendingOperation = Object.keys(operations || {})
              .filter(
                k => (operations || {})[k].institutionAccountId === iAc.id
              )
              .filter(k => (operations || {})[k].status === 'PENDING')
              .reduce(
                (acc, act) =>
                  acc &&
                  moment(acc.createDate).isAfter(
                    (operations || {})[act].createDate
                  )
                    ? acc
                    : (operations || {})[act],
                (undefined as unknown) as Operation | undefined
              );
            return iAc;
          }),
      };
    }
  );
