import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { SESSION_STATUSES } from './others';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getVariablesRegisterInstance()
  .addVariable('sessionData', {
    startValue: undefined,
    persist: true,
  })
  .addVariable('sessionStatus', {
    startValue: SESSION_STATUSES.NOT_CREATED,
  });
