import { CoinscrapApi, ErrorHandler } from '../../generic/Apis';

import { Logger, HandlersStack } from '../../generic/utils';
import { useEffect } from 'react';
import { wrapperIdentifier, CoreFetch } from './common';
import { cordovaFetchBuilder } from './native/cordovaFetchBuilder';
import { navigatorFetchBuilder } from './native/navigatorFetchBuilder';
import { isNative } from '../ApplicationWrapper';
import { nativeBridge } from './logic';
import { wrapperBuilder } from './build';
import { environmentBaseUrl } from './others';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export const getCoreFetch: (options?: {
  nativeBridge: boolean;
}) => CoreFetch = options =>
  (options || { nativeBridge: nativeBridge }).nativeBridge && isNative
    ? cordovaFetchBuilder()
    : navigatorFetchBuilder();

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'getInstanceApi',
    ({
      variablesHandler: { variables },
      componentProps: { enableNativeBridge, defaultBaseUrl, prefixOrVersion },
    }) => () => {
      const baseUrl = (
        (variables.baseUrl || environmentBaseUrl || defaultBaseUrl || '/') + '/'
      ).replace(/([^:]\/)\/+/g, '$1');
      const token = variables.token;
      return new CoinscrapApi({
        // TODO use dinamically fetch or native http plugin to connect to API
        client: getCoreFetch({ nativeBridge: enableNativeBridge || false }),
        prefix: prefixOrVersion ?? 'api/',
        baseUrl: baseUrl.replace('api/', ''),
        token,
      });
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'getInstanceApiForVinculation',
    ({
      variablesHandler: {
        variables: {
          vinculationsTokens,
          baseUrl: compBaseurl,
          token: requesterToken,
        },
        variablesSetter,
      },
      coreDynamicFunctionGetter,
      componentProps: { enableNativeBridge, defaultBaseUrl, prefixOrVersion },
    }) => async vinculationId => {
      if (!vinculationId) {
        throw new Error('Must specify a vinculationId and user requester');
      }
      const tokenVinculationKey = `${vinculationId}-${requesterToken}`;

      let token: string | undefined = vinculationsTokens[tokenVinculationKey];
      if (!token) {
        const resp = await coreDynamicFunctionGetter(
          'getInstanceApi'
        )().getVinculationToken(vinculationId);
        token = resp.accessToken as string;
        variablesSetter('vinculationsTokens')(vincs => {
          vincs[tokenVinculationKey] = token as string;
          return vincs;
        });
      }

      const baseUrl = (
        (compBaseurl || environmentBaseUrl || defaultBaseUrl || '/') + '/'
      ).replace(/([^:]\/)\/+/g, '$1');
      return new CoinscrapApi({
        // TODO use dinamically fetch or native http plugin to connect to API
        client: getCoreFetch({ nativeBridge: enableNativeBridge || false }),
        prefix: prefixOrVersion ?? 'api/',
        baseUrl: baseUrl.replace('api/', ''),
        token,
      });
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'changeBaseUrl',
    ({ variablesHandler: { variablesSetter } }) => newBaseUrl => {
      console.log('Change url');
      variablesSetter('baseUrl')(newBaseUrl);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'registerErrorHandler',
    ({ variablesHandler: { variablesSetter, variables } }) => (
      errorHandler,
      key
    ) => {
      const keyGenerated =
        key || 'HanlderRegistered' + Math.floor(Math.random() * 9999);
      console.log(' depp func', key);
      console.log(
        'prev handlers state failured',
        (variables.errorHandlers || new HandlersStack()).printStack()
      );

      variablesSetter('errorHandlers')(
        (errorHandlers: HandlersStack<ErrorHandler> | undefined) => {
          const newHandlers = errorHandlers || new HandlersStack();
          console.log('prev handlers state success', newHandlers?.printStack());
          newHandlers.push(errorHandler, key);
          console.log(' new handlers state', newHandlers?.printStack());
          return newHandlers;
        }
      );
      return keyGenerated;
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'removeErrorHandler',
    ({ variablesHandler: { variablesSetter, variables } }) => key => {
      console.log(' depp rem func', key);
      console.log(
        'prev handlers state failured',
        (variables.errorHandlers || new HandlersStack()).printStack()
      );
      variablesSetter('errorHandlers')(
        (errorHandlers: HandlersStack<ErrorHandler> | undefined) => {
          const newHandlers = errorHandlers || new HandlersStack();
          console.log('prev handlers state success', newHandlers?.printStack());
          newHandlers && newHandlers.pop(key);
          console.log(' new handlers state', newHandlers?.printStack());
          return newHandlers;
        }
      );
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'useErrorHandler',
    ({ coreDynamicFunctionGetter }) => (errorHandler, key) => {
      const registerErrorHandler = coreDynamicFunctionGetter(
        'registerErrorHandler'
      );
      const removeErrorHandler = coreDynamicFunctionGetter(
        'removeErrorHandler'
      );
      console.log(' calling ', key);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        console.log(' register ', key);
        registerErrorHandler(errorHandler, key);
        return function cleanUp() {
          console.log(' removing ', key);
          removeErrorHandler(key);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [key]);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'setAutenticationToken',
    ({ variablesHandler: { variablesSetter } }) => token => {
      variablesSetter('token')(token);
    }
  );
