import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  backgroundComponent: {
    /*maxHeight:
      'calc( 100vh - ( env(safe-area-inset-top, "0px") + env(safe-area-inset-bottom, "0px") ) )',
    top: 'env(safe-area-inset-top, "0px")!important',*/
  },
  baseComponent: {
    maxHeight:
      'calc( 100vh - ( env(safe-area-inset-top, "0px") + env(safe-area-inset-bottom, "0px") ) )',
    top: 'env(safe-area-inset-top, "0px")!important',
  },
  containerComponent: {},
  contentComponent: {
    overflow: 'hidden',
  },
});

export default baseConfiguration;
