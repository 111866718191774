import { Logger, UserInput } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import {
  OPERATION_TYPES,
  ACTION_TYPES,
  ACTION_PAYLOAD_TYPES,
} from '../../generic/Apis';
import { wrapperBuilder } from './build';
import { InstitutionAccount } from 'entityWrappers/InstitutionAccountsWrapper/common';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'createOrUpdateCredentialsInstitution',
    ({
      coreDynamicFunctionGetter,
      bindedContexts: {
        createInstitutionAccount,
        getInstanceApi,
        institutionAccounts: institutionAccountsFromContext,
        institutions,
        createOperation,
        user,
      },
    }) => async (
      institutionIdentifier,
      credentials,
      { alwaysCreate, mode } = {
        alwaysCreate: false,
        mode: 'AWAIT_CREATION',
      },
      onOtpRequest = undefined,
      { onDiscard, onSuccess, onFailure } = {}
    ) => {
      if (
        !institutions ||
        !institutions.find(i => i.identifier === institutionIdentifier)
      ) {
        throw new Error('Institution identifier invalid');
      }
      let institutionAccounts = institutionAccountsFromContext;
      if (!institutionAccounts) {
        const institutionAccountsFromBack: InstitutionAccount[] = await getInstanceApi().getUserInstitutionAccountsLean(
          user?.id || user?._id || 'undefined'
        );
        institutionAccounts = institutionAccountsFromBack;
      }
      let institutionAccountToUpdate = institutionAccounts.find(
        ins => ins.institutionIdentifier === institutionIdentifier
      );
      if (!institutionAccountToUpdate || alwaysCreate) {
        institutionAccountToUpdate = await createInstitutionAccount(
          institutionIdentifier
        );
      }
      if (!institutionAccountToUpdate) {
        throw new Error('No institution account to update');
      }

      const data: UserInput = {
        authenticationMethodIdentifier: credentials.method,
        authenticationMethodFieldsValue: {},
      };
      Object.keys(credentials.values).forEach(
        k => (data.authenticationMethodFieldsValue[k] = credentials.values[k])
      );

      console.log(credentials, data, user);
      return createOperation(
        OPERATION_TYPES.VALIDATE_CREDENTIALS,
        {
          institutionAccountId: institutionAccountToUpdate.id,
          payload: { value: data },
        },
        mode || 'AWAIT_CREATION',
        {
          onPending: onOtpRequest
            ? coreDynamicFunctionGetter('buildOtpCallback')(onOtpRequest)
            : undefined,
          onSuccess,
          onDiscard,
          onFailure,
        }
      );
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'updateInstitutionProducts',
    ({
      coreDynamicFunctionGetter,
      bindedContexts: { institutionAccounts, institutions },
    }) => async (institutionIdentifier, options, onOtpRequest, events) => {
      let updateInstitutionAccountProducts = coreDynamicFunctionGetter(
        'updateInstitutionAccountProducts'
      );
      if (
        !institutions ||
        !institutions.find(i => i.identifier === institutionIdentifier)
      ) {
        throw new Error('Institution identifier invalid');
      }
      let institutionAccountsToUpdate = (institutionAccounts || []).filter(
        ins => ins.institutionIdentifier === institutionIdentifier
      );
      if (!institutionAccountsToUpdate || !institutionAccountsToUpdate.length) {
        throw new Error('No institution account to update');
      }
      if (options?.multiUpdate) {
        throw new Error('NOT IMPLEMENTED');
        /*return Promise.all(
          institutionAccountsToUpdate.map(iA =>
            updateInstitutionAccountProduct(
              iA.id,
              onOtpRequest,
              onSuccess,
              onFailure
            )
          )
        );*/
      } else {
        return updateInstitutionAccountProducts(
          institutionAccountsToUpdate[0].id,
          { mode: options?.mode || 'AWAIT_CREATION' },
          onOtpRequest,
          events
        );
      }
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'updateInstitutionAccountProducts',
    ({
      coreDynamicFunctionGetter,
      bindedContexts: { institutionAccounts, createOperation },
    }) => async (
      institutionAccountId,
      { mode, filters } = { mode: 'AWAIT_CREATION' },
      onOtpRequest = undefined,
      { onDiscard, onSuccess, onFailure } = {}
    ) => {
      let institutionAccountToUpdate = (institutionAccounts || []).find(
        ins => ins.id === institutionAccountId
      );
      if (!institutionAccountToUpdate) {
        throw new Error('No institution account to update');
      }
      return createOperation(
        OPERATION_TYPES.FETCH_PRODUCTS,
        {
          institutionAccountId: institutionAccountToUpdate.id,
          payload: {
            value: {
              productsFilter: {
                ...filters,
              },
            },
          },
        },
        mode || 'AWAIT_CREATION',
        {
          onPending: onOtpRequest
            ? coreDynamicFunctionGetter('buildOtpCallback')(onOtpRequest)
            : undefined,
          onSuccess,
          onDiscard,
          onFailure,
        }
      );
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'buildOtpCallback',
    ({ bindedContexts: { sendAction } }) => onOtpRequest => {
      return (actionContinue, onUserHandled, operationId) => {
        console.log('ºº CRENTIALS ACTION CONTINUE', actionContinue);
        if (actionContinue && actionContinue.type === 'OTP') {
          onOtpRequest(
            actionContinue.payload.message,
            async otpValue => {
              console.log('ºº CRENTIALS OTP RECEIVED SENDING ACTION', otpValue);
              try {
                await sendAction(
                  operationId,
                  { payloadType: ACTION_PAYLOAD_TYPES.OTP },
                  {
                    OTP: otpValue,
                  }
                );
              } catch (e) {}
              onUserHandled();
            },
            async () => {
              console.log('ºº CRENTIALS OTP DISCARD ACTION');
              try {
                await sendAction(operationId, {
                  actionType: ACTION_TYPES.DISCARD_OPERATION,
                });
              } catch (e) {}
              onUserHandled();
            }
          );
        } else {
          console.log(
            'ºº CRENTIALS UNEXPECTED ACTION CONTINUE',
            actionContinue
          );
        }
      };
    }
  );
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'runOperation',
    ({ coreDynamicFunctionGetter, bindedContexts: { runOperation } }) => async (
      operationId,
      data,
      mode,
      onOtpRequest = undefined,
      { onDiscard, onSuccess, onFailure } = {}
    ) => {
      return runOperation(operationId, data, mode, {
        onPending: onOtpRequest
          ? coreDynamicFunctionGetter('buildOtpCallback')(onOtpRequest)
          : undefined,
        onSuccess,
        onDiscard,
        onFailure,
      });
    }
  );
