/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect } from 'react';

import { useInitialize } from '../../generic/use';
import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { setup } from './native';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

var inited = false;
wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { online },
      componentProps: {
        mobilePixels,
        showDesktopScrollbarFromPixels,
        disableSetup,
        disableHeigthControl,
      },
    }) => {
      useEffect(() => {
        if (!online) {
        }
      }, [online]);

      useInitialize(() => {
        if (inited) {
          return;
        }
        inited = true;
        console.log('INITING Layout Wrapper');
        if (!disableSetup) {
          setup({
            appContainerId: undefined,
            showDesktopScrollbarFromPixels:
              showDesktopScrollbarFromPixels ?? mobilePixels,
            disableHeigthControl,
          });
        }

        function handleResize() {
          const { innerWidth: width, innerHeight: height } = window;
          variablesSetter('windowDimensions')({ width, height });
        }
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, true);
    }
  );
