import React, { FunctionComponent, useEffect } from 'react';
import { VariablesBuilder } from './variables/variablesBuilder';
import { useProvider } from '../generic';
import { extractValue, Logger, wrapProvider } from '../generic/utils';
import { ApplicationWrapper } from '../wrappers/ApplicationWrapper';
import { ContextBuilder } from './context/contextBuilder';
import { FunctionsBuilder } from './functions/functionsBuilder';
import { FunctionsRegister } from './functions/functionsRegister';
import { VariablesRegister } from './variables/variablesRegister';
import { VirtualsGeneratorsRegister } from './virtuals/virtualsGeneratorsRegister';
import { VirtualsGeneratorsBuilder } from './virtuals/virtualsGeneratorsBuilder';
import { StaticsBuilder } from './statics/staticsBuilder';
import { StaticsRegister } from './statics/staticsRegister';
import { RenderBuilder } from './render/renderBuilder';
import { ReducersBuilder } from './reducers/reducersBuilder';
import { ReducersRegister } from './reducers/reducersRegister';
import { LogicBuilder } from './logic/logicBuilder';

const console = new Logger('wrapperBuilder');

if (console) {
  //Just for usage
}

export class WrapperBuilder<
  ComponentPropsDefinition = any,
  InternalVariables = any,
  Variables = any,
  ExternalVariables = any,
  InternalFunctions = any,
  Functions = any,
  ExternalFunctions = any,
  State = any,
  Virtuals = any,
  Statics = any,
  BindedContexts = any,
  DispatchTypes = any,
  Hooks = any
> {
  public generatedComponent!: FunctionComponent<
    ComponentPropsDefinition & {
      children: (
        context: ComponentPropsDefinition &
          ExternalVariables &
          ExternalFunctions &
          State &
          Virtuals
      ) => React.ReactNode;
      //| React.ReactNode;
    }
  > & {
    use: (
      requester?: string
    ) => ExternalVariables &
      ExternalFunctions &
      State &
      Virtuals &
      ComponentPropsDefinition;
    useInternal: (
      requester?: string
    ) => InternalVariables &
      Variables &
      ExternalVariables &
      InternalFunctions &
      Functions &
      ExternalFunctions &
      State &
      Virtuals &
      ComponentPropsDefinition;
  } & {
    bindedContext: Variables &
      ExternalVariables &
      Functions &
      ExternalFunctions &
      Virtuals &
      ComponentPropsDefinition;
  } & Statics;
  private _context:
    | React.Context<
        ComponentPropsDefinition &
          InternalVariables &
          Variables &
          ExternalVariables &
          InternalFunctions &
          Functions &
          ExternalFunctions &
          State &
          Virtuals
      >
    | undefined;
  private _pathToLocalState: string[] = [];
  private _wrapperDependencies: Array<{ use: () => any }>;
  constructor(
    private wrapperName: string,
    pathToLocalState: string = wrapperName,
    dependencies: Array<{ use: () => any }> = [],
    externalContext:
      | React.Context<
          ComponentPropsDefinition &
            InternalVariables &
            Variables &
            ExternalVariables &
            InternalFunctions &
            Functions &
            ExternalFunctions &
            State &
            Virtuals
        >
      | undefined = undefined
  ) {
    this._context = externalContext;
    this._pathToLocalState = pathToLocalState.split('.');
    this._wrapperDependencies = dependencies;
  }

  private _defaultContext!: ComponentPropsDefinition &
    InternalVariables &
    Variables &
    ExternalVariables &
    InternalFunctions &
    Functions &
    ExternalFunctions &
    State &
    Virtuals;
  public set defaultContext(
    value: ComponentPropsDefinition &
      InternalVariables &
      Variables &
      ExternalVariables &
      InternalFunctions &
      Functions &
      ExternalFunctions &
      State &
      Virtuals
  ) {
    this._defaultContext = value;
  }

  build() {
    // Object assignations and base builders contructions here
    const variablesBuilder = new VariablesBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      State,
      DispatchTypes
    >();
    variablesBuilder.variablesRegister = this._userVariablesRegisterUtility;

    const functionsBuilder = new FunctionsBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      InternalFunctions,
      Functions,
      ExternalFunctions,
      State,
      Virtuals,
      BindedContexts,
      DispatchTypes
    >();
    functionsBuilder.functionsRegister = this._userFunctionsRegisterUtility;

    const virtualsGeneratorsBuilder = new VirtualsGeneratorsBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      State,
      Virtuals,
      BindedContexts
    >();
    virtualsGeneratorsBuilder.virtualsGeneratorsRegister = this._userVirtualsRegisterUtility;

    const contextBuilder = new ContextBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      InternalFunctions,
      Functions,
      ExternalFunctions,
      State,
      Virtuals,
      BindedContexts,
      DispatchTypes
    >();
    contextBuilder.variablesBuilder = variablesBuilder;
    contextBuilder.functionsBuilder = functionsBuilder;
    contextBuilder.virtualsGeneratorsBuilder = virtualsGeneratorsBuilder;
    contextBuilder.wrapperName = this.wrapperName;

    // Builders not related directly with the context

    const reducersBuilder = new ReducersBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      InternalFunctions,
      Functions,
      ExternalFunctions,
      State,
      Virtuals,
      BindedContexts,
      DispatchTypes
    >();
    reducersBuilder.wrapperName = this.wrapperName;
    reducersBuilder.reducersRegister = this._userReducersRegisterUtility;

    const staticsBuilder = new StaticsBuilder<Statics, Hooks>();
    staticsBuilder.wrapperName = this.wrapperName;
    staticsBuilder.staticsRegister = this._userStaticsRegisterUtility;

    // end

    const contextInstance =
      this._context ||
      React.createContext<
        ComponentPropsDefinition &
          InternalVariables &
          Variables &
          ExternalVariables &
          InternalFunctions &
          Functions &
          ExternalFunctions &
          State &
          Virtuals
      >(this._defaultContext);
    const component: FunctionComponent<
      ComponentPropsDefinition & {
        children: (
          context: ComponentPropsDefinition &
            ExternalVariables &
            ExternalFunctions &
            State &
            Virtuals
        ) => React.ReactNode;
        //| React.ReactNode;
      }
    > & {
      use: (
        requester?: string
      ) => ExternalVariables &
        ExternalFunctions &
        State &
        Virtuals &
        ComponentPropsDefinition;
      useInternal: (
        requester?: string
      ) => InternalVariables &
        Variables &
        ExternalVariables &
        InternalFunctions &
        Functions &
        ExternalFunctions &
        State &
        Virtuals &
        ComponentPropsDefinition;
    } & {
      bindedContext: Variables &
        ExternalVariables &
        Functions &
        ExternalFunctions &
        Virtuals &
        ComponentPropsDefinition;
    } = ({ children, ...componentProps }) => {
      const applicationContext = ApplicationWrapper.use();
      const { registerReducer, state, dispatch: dp } = applicationContext || {};
      const dispatch = dp || (() => console.warn('Dispatch not present'));

      const appState: State = extractValue(
        state || {},
        ...this._pathToLocalState
      );
      const bindedContext = this._wrapperDependencies
        .map(t => t.use())
        .reduce((acc, act) => Object.assign(acc, act), {});
      const stateHandler = { state: appState, dispatch };
      contextBuilder.componentProps = componentProps as any;
      contextBuilder.stateHandler = stateHandler;
      contextBuilder.bindedContexts = bindedContext;
      const {
        context: contextValue,
        variablesHandler,
        functions,
        virtuals,
      } = contextBuilder.build();

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        registerReducer({
          name: this.wrapperName.toUpperCase() + ' context propert persistor',
          persistState: true,
          pathToLocalState: this._pathToLocalState,
          reduceFunction: (pe, _, a) => {
            if (a.type === this.wrapperName + '_propertyPersist') {
              return {
                ...pe,
                [a.payload.name]: a.payload.value,
              };
            }
            return pe;
          },
        });
        if (reducersBuilder.isConfigured()) {
          reducersBuilder.componentProps = componentProps as any;
          reducersBuilder.variablesHandler = variablesHandler;
          reducersBuilder.virtuals = virtuals;
          reducersBuilder.bindedContexts = bindedContext;
          reducersBuilder.functions = functions;

          const reducersBuilded = reducersBuilder.build();
          if (registerReducer) {
            reducersBuilded.forEach(({ name, reducerFunction, persisted }) => {
              registerReducer({
                name,
                pathToLocalState: this._pathToLocalState,
                reduceFunction: (pe, me, a) => reducerFunction(a, pe, me),
                persistState: persisted,
              });
            });
          }
        }
      }, [
        bindedContext,
        componentProps,
        functions,
        registerReducer,
        variablesHandler,
        virtuals,
      ]);

      if (this._userCustomLogic && this._userCustomLogic.isConfigured()) {
        console.log('ºº Wrapper builder using logic');
        this._userCustomLogic.componentProps = componentProps as any;
        this._userCustomLogic.stateHandler = stateHandler;
        this._userCustomLogic.variablesHandler = variablesHandler;
        this._userCustomLogic.virtuals = virtuals;
        this._userCustomLogic.bindedContexts = bindedContext;
        this._userCustomLogic.functions = functions;
        this._userCustomLogic.apply();
      }

      let render;
      if (this._userCustomRender && this._userCustomRender.isConfigured()) {
        this._userCustomRender.componentProps = componentProps as any;
        this._userCustomRender.state = stateHandler.state;
        this._userCustomRender.variables = variablesHandler.variables;
        this._userCustomRender.virtuals = virtuals;
        this._userCustomRender.functions = functions;
        this._userCustomRender.bindedContexts = bindedContext;
        const childrenProcesing =
          typeof children === 'function'
            ? (children as any)(contextValue)
            : children;
        render = wrapProvider(
          contextInstance as any,
          contextValue
        )(
          this._userCustomRender.build()(React.createElement, childrenProcesing)
        );
      } else {
        render = wrapProvider(
          contextInstance as any,
          contextValue
        )(
          typeof children === 'function'
            ? (children as any)(contextValue)
            : children
        );
      }

      return render;
    };

    component.use = (requester?: string) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const contextOfWrapper = useProvider(contextInstance, requester);
      return contextOfWrapper;
    };
    component.useInternal = (requester?: string) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const contextOfWrapper = useProvider(contextInstance, requester);
      return contextOfWrapper;
    };

    component.bindedContext = {} as any;

    return (staticsBuilder.isConfigured()
      ? staticsBuilder.assign(component)
      : component) as typeof component & Statics;
  }

  private _userFunctionsRegisterUtility!: FunctionsRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    InternalFunctions,
    Functions,
    ExternalFunctions,
    State,
    Virtuals,
    BindedContexts,
    DispatchTypes
  >;
  getFunctionsRegisterInstance() {
    if (!this._userFunctionsRegisterUtility) {
      this._userFunctionsRegisterUtility = new FunctionsRegister();
    }
    return this._userFunctionsRegisterUtility;
  }
  private _userVariablesRegisterUtility!: VariablesRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    State
  >;
  getVariablesRegisterInstance() {
    if (!this._userVariablesRegisterUtility) {
      this._userVariablesRegisterUtility = new VariablesRegister();
    }
    return this._userVariablesRegisterUtility;
  }
  private _userVirtualsRegisterUtility!: VirtualsGeneratorsRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    State,
    Virtuals,
    BindedContexts
  >;
  getVirtualsGeneratorsRegisterInstance() {
    if (!this._userVirtualsRegisterUtility) {
      this._userVirtualsRegisterUtility = new VirtualsGeneratorsRegister();
    }
    return this._userVirtualsRegisterUtility;
  }

  private _userReducersRegisterUtility!: ReducersRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    InternalFunctions,
    Functions,
    ExternalFunctions,
    State,
    Virtuals,
    BindedContexts,
    DispatchTypes
  >;
  getReducersRegisterInstance() {
    if (!this._userReducersRegisterUtility) {
      this._userReducersRegisterUtility = new ReducersRegister();
    }
    return this._userReducersRegisterUtility;
  }

  private _userStaticsRegisterUtility!: StaticsRegister<Statics, Hooks>;
  getStaticsGeneratorsRegisterInstance() {
    if (!this._userStaticsRegisterUtility) {
      this._userStaticsRegisterUtility = new StaticsRegister();
    }
    return this._userStaticsRegisterUtility;
  }

  private _userCustomRender!: RenderBuilder<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    InternalFunctions,
    Functions,
    ExternalFunctions,
    State,
    Virtuals,
    BindedContexts
  >;
  getCustomRenderRegisterInstance() {
    if (!this._userCustomRender) {
      this._userCustomRender = new RenderBuilder();
    }
    return this._userCustomRender;
  }

  private _userCustomLogic!: LogicBuilder<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    InternalFunctions,
    Functions,
    ExternalFunctions,
    State,
    Virtuals,
    BindedContexts,
    DispatchTypes
  >;
  getCustomLogicRegisterInstance() {
    if (!this._userCustomLogic) {
      this._userCustomLogic = new LogicBuilder();
    }
    return this._userCustomLogic;
  }
}
