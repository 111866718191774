import { useEffect } from 'react';
import { Logger } from '../../generic/utils';
import { ModalHandler, wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

new Logger(wrapperIdentifier);

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'setCurrentPageAnimation',
    ({ variablesHandler: { variablesSetter } }) => animationInfo => {
      variablesSetter('currentPageAnimation')(animationInfo);
    }
  );
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'openModal',
    ({ variablesHandler: { variablesSetter } }) => (
      contentGenerator,
      options
    ) => {
      variablesSetter('openedModals')(currentModals => [
        ...currentModals,
        { contentGenerator, options },
      ]);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'updateModalState',
    ({ variablesHandler: { variablesSetter, variables } }) => (
      key,
      newState
    ) => {
      variablesSetter('modalsStates')({
        ...variables.modalsStates,
        [key]: newState,
      });
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'removeModal',
    ({ variablesHandler: { variablesSetter, variables } }) => key => {
      const modal = variables.openedModals[Number(key)];
      modal &&
        modal.options &&
        modal.options.executeOnClose &&
        modal.options.executeOnClose();
      const aux = variables.openedModals.reduce(
        (acc, act, index) => (index === key ? acc : [...acc, act]),
        [] as ModalHandler[]
      );
      variablesSetter('openedModals')([...aux]);
      if ((variables.modalsStates || {})[key]) {
        variablesSetter('modalsStates')({
          ...variables.modalsStates,
          [key]: undefined,
        });
      }
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'showAlert',
    ({ variablesHandler: { variablesSetter } }) => (message, options) => {
      variablesSetter('alertMessage')(message);
      variablesSetter('alertOptions')(options);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'closeAlert',
    ({ variablesHandler: { variablesSetter } }) => () => {
      variablesSetter('alertMessage')(undefined);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'useLoading',
    ({ variablesHandler: { variablesSetter } }) => (loading, props) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (loading === null) {
        } else {
          variablesSetter('showLoading')(loading);
          variablesSetter('showLoadingProps')(props);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [loading]);
      return null;
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'useBackground',
    ({ variablesHandler: { variablesSetter } }) => (key, position) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        //if (c.backgroundKey === key) return;
        variablesSetter('backgroundKey')(key);
        variablesSetter('backgroundColor')(key);
        variablesSetter('backgroundPosition')(position);
        // Comento esto porque a veces se producen parpadeos al quitar y poner fondo
        /*return function cleanUp() {
        variableHandler('backgroundKey')(undefined);
        variableHandler('backgroundPosition')(undefined);
      };*/
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'useFooter',
    ({ variablesHandler: { variablesSetter } }) => (component, check) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        variablesSetter('footer')(component);
        return () => {
          variablesSetter('footer')(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, check || []);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'useHeader',
    ({ variablesHandler: { variablesSetter } }) => (component, check) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        variablesSetter('header')(component);
        return () => {
          variablesSetter('header')(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, check || []);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'useLayoutProps',
    ({ variablesHandler: { variablesSetter } }) => (props, check) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        variablesSetter('layoutProps')(props);
        return () => {
          variablesSetter('layoutProps')(undefined);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, check || []);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'openMenu',
    ({ variablesHandler: { variablesSetter } }) => () => {
      variablesSetter('menuOpen')(true);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'closeMenu',
    ({ variablesHandler: { variablesSetter } }) => () => {
      variablesSetter('menuOpen')(false);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'toggleMenu',
    ({ variablesHandler: { variablesSetter } }) => () => {
      variablesSetter('menuOpen')(value => !value);
    }
  );
