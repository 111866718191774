import { PickData, PickerHOC } from '../../hocs/PickerHOC';
import React, { FunctionComponent } from 'react';
import { NavigationWrapper, UiWrapper } from '../../wrappers';
import { RouteNavigationComplex } from '../../wrappers/NavigationWrapper/interfaces';

const SimpleComponentTriggeringLoader = () =>
  (UiWrapper.use() || { useLoading: () => null }).useLoading(true);

const InternalRedirectHOC = (
  hasPicker: boolean,
  redirectLogic: (
    pickedData: any
  ) => RouteNavigationComplex | undefined | null = () => undefined,
  DefaultView: FunctionComponent = SimpleComponentTriggeringLoader
) => (WrappedComponent: FunctionComponent) => ({ children, ...props }: any) => {
  const { navigateTo } = NavigationWrapper.use();

  let data;
  const pickerData = PickerHOC.use();
  if (hasPicker) {
    data = pickerData;
  }

  const redirectComputation = redirectLogic(data);

  if (redirectComputation) {
    navigateTo(redirectComputation.key, redirectComputation.navigationProps);
  }

  return redirectComputation || redirectComputation === null
    ? React.createElement(DefaultView, {})
    : React.createElement(WrappedComponent, { ...props }, children);
};

export const RedirectHOC = (
  pickData: PickData | undefined = undefined,
  redirectLogic: (
    pickedData: any
  ) => RouteNavigationComplex | undefined | null = () => undefined,
  DefaultView: FunctionComponent = SimpleComponentTriggeringLoader
) => (WrappedComponent: FunctionComponent) =>
  pickData
    ? PickerHOC(
        pickData,
        DefaultView
      )(InternalRedirectHOC(true, redirectLogic, DefaultView)(WrappedComponent))
    : InternalRedirectHOC(false, redirectLogic, DefaultView)(WrappedComponent);
