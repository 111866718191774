import './variables';
import './functions';
import './logic';
import { wrapperBuilder } from './build';
import { ScanManagerBuilder } from './others/ScanManager';

export const IdentityDocumentsWrapper = wrapperBuilder.build();
IdentityDocumentsWrapper.ScanManager = ScanManagerBuilder(
  IdentityDocumentsWrapper
);
