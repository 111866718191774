import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { wrapperBuilder } from './build';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer('Navigation: History Logger', false, () => (action, _, state) => {
    let aux = [...(state?.routes || [])];
    if (action.type === DISPATCH_TYPES.PUSH_ROUTE) {
      aux.push(action.payload);
    }
    if (action.type === DISPATCH_TYPES.REPLACE_ROUTE) {
      aux.pop();
      aux.push(action.payload);
    }
    if (action.type === DISPATCH_TYPES.GO_BACK) {
      aux.pop();
    }

    if (action.type === DISPATCH_TYPES.RESET_ROUTES) {
      aux = [];
    }
    return {
      ...state,
      routes: aux,
    };
  });
