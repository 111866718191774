import { CoreFetch } from '../../../../../wrappers/BackendWrapper/common';
import { BackendWrapper } from '../../../../../wrappers/BackendWrapper';

export interface ImageUrl {
  url: string;
  id?: string;
  alt?: string;
}

export abstract class ImagesResolver {
  private _coreFetch!: CoreFetch;
  public get coreFetch() {
    return this._coreFetch;
  }

  public async returnImages(search: string | undefined) {
    this._coreFetch = BackendWrapper.getCoreFetch();
    let response: ImageUrl[] = [];
    try {
      response = await this.resolveImages(search);
    } catch (e) {}
    return response;
  }

  abstract resolveImages(search: string | undefined): Promise<ImageUrl[]>;
}
