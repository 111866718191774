import {
  OnClick,
  OnChange,
  OnBlur,
  OnFocus,
  TextFieldType,
  TextFieldDefaultValue,
  TextFieldUnit,
  TextFieldLabel,
  GenericComponentProps,
  TextFieldStyle,
  TextFieldValue,
  TextFieldExpectedStyles,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.textField;

export interface ClassProperties {
  variant: 'outlined' | 'filled';
}

export type ComponentStyle = TextFieldStyle;
export type ExpectedStyles = TextFieldExpectedStyles;
export type Props = GenericComponentProps<TextFieldStyle> & {
  onClick?: OnClick;
  onChange?: OnChange;
  onBlur?: OnBlur;
  onFocus?: OnFocus;
  disabled?: boolean;
  disableUnderline?: boolean;
  error?: string;
  value?: TextFieldValue;
  speech?: boolean;
  label?: TextFieldLabel;
  type?: TextFieldType;
  unit?: TextFieldUnit;
  defaultValue?: TextFieldDefaultValue;
  placeholder?: string;
  speechIcon?: any;
  inputRef?: any;
  endAdornment?: any;
  startAdornment?: any;
  autoFocus?: boolean;
  disableDecimals?: boolean;
  readOnly?: boolean;
  // true or directly the number of rows to show
  multiline?: boolean | number;
};
