import { CoreVariablesHandler, CoreStateHandler } from '../common';
import { VariablesBuilder } from '../variables/variablesBuilder';
import { FunctionsBuilder } from '../functions/functionsBuilder';
import { VirtualsGeneratorsBuilder } from '../virtuals/virtualsGeneratorsBuilder';

export class ContextBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes
> {
  public set wrapperName(value: string) {
    this.functionsBuilder.wrapperName = value;
    this.variablesBuilder.wrapperName = value;
    this.virtualsGeneratorsBuilder.wrapperName = value;
  }
  private _stateHandler!: CoreStateHandler<State, DispatchTypes>;
  public set stateHandler(value: CoreStateHandler<State, DispatchTypes>) {
    this._stateHandler = value;
    this.functionsBuilder.stateHandler = value;
    this.variablesBuilder.stateHandler = value;
    this.virtualsGeneratorsBuilder.state = value.state;
  }

  private _componentProps!: ComponentPropsDefinition;
  public set componentProps(value: ComponentPropsDefinition) {
    this._componentProps = value;
    this.functionsBuilder.componentProps = value;
    this.variablesBuilder.componentProps = value;
    this.virtualsGeneratorsBuilder.componentProps = value;
  }

  public set bindedContexts(value: BindedContexts) {
    this.functionsBuilder.bindedContexts = value;
    this.virtualsGeneratorsBuilder.bindedContexts = value;
  }

  public variablesBuilder!: VariablesBuilder<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    State,
    DispatchTypes
  >;
  public functionsBuilder!: FunctionsBuilder<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    InternalFunctions,
    Functions,
    ExternalFunctions,
    State,
    Virtuals,
    BindedContexts,
    DispatchTypes
  >;
  public virtualsGeneratorsBuilder!: VirtualsGeneratorsBuilder<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    State,
    Virtuals,
    BindedContexts
  >;

  build() {
    let variables = this.variablesBuilder.build();
    let variablesGenerated = variables.keys.reduce(
      (acc, act) =>
        Object.assign({}, acc, {
          [act]: variables.getVariableValue(act),
        }),
      {} as InternalVariables & Variables & ExternalVariables
    );
    let variablesHandler: CoreVariablesHandler<
      InternalVariables,
      Variables,
      ExternalVariables
    > = {
      variables: variablesGenerated,
      variablesSetter: varName =>
        variables.getVariableSetter(
          // DATA
          varName
        ) as any,
    };

    let virtuals = {} as Virtuals;
    if (this.virtualsGeneratorsBuilder.isConfigured()) {
      this.virtualsGeneratorsBuilder.variables = variablesHandler.variables;
      virtuals = this.virtualsGeneratorsBuilder.build();
    }
    let functions = {} as InternalFunctions & Functions & ExternalFunctions;
    if (this.functionsBuilder.isConfigured()) {
      this.functionsBuilder.variablesHandler = variablesHandler;
      this.functionsBuilder.virtuals = virtuals;
      functions = this.functionsBuilder.build();
    }
    return {
      context: {
        ...this._componentProps,
        ...this._stateHandler.state,
        ...variablesGenerated,
        ...this.componentProps,
        ...virtuals,
        ...functions,
      },
      variablesHandler,
      functions,
      virtuals,
    };
  }
}
