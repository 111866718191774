/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
// import { useInitialize } from '../../generic/use';
import { useEffect } from 'react';
import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';
import { i18nInit } from './others/i18n';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(
    ({
      componentProps: { languageResources, defaultLang, options },
      variablesHandler: { variablesSetter },
    }) => {
      useEffect(() => {
        console.log(' INITING ' + wrapperIdentifier + ' Wrapper');

        const hasObjectStructure =
          (options &&
            options.hasOwnProperty('keySeparator') &&
            options?.keySeparator === undefined) ||
          false;

        i18nInit(languageResources, defaultLang, options)
          .then(() => {
            variablesSetter('inited')(true);
            variablesSetter('hasObjectStructure')(hasObjectStructure);
          })
          .catch(() => {
            variablesSetter('inited')(null);
            variablesSetter('hasObjectStructure')(null);
          });
      }, [languageResources]);
    }
  );
