import { Logger } from '../../generic/utils';

import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer(
    'Chat: Handel Chat Changes',
    false,
    ({ variablesHandler: { variables } }) => (action, _, state) => {
      const instance = variables.liveChatInstance;
      console.log('ºº CHAT REDUCER', action);
      if (action.type === 'endSession') {
        instance && instance.clearAll();
        return {
          ...state,
          isReady: false,
        };
      }
      if (action.type === 'chatReady') {
        return {
          ...state,
          isReady: true,
        };
      }
      console.log('ºº REDUCED STATE', state);
      return state;
    }
  );
