import {
  CoreDynamicFunctionGetter,
  CoreStateHandler,
  CoreVariablesHandler,
  Register,
} from '../common';

export class FunctionsRegister<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes
> implements Register {
  private _functions: {
    name: keyof (InternalFunctions & Functions & ExternalFunctions);
    builder: (wrapperEnvironment: {
      variablesHandler: CoreVariablesHandler<
        InternalVariables,
        Variables,
        ExternalVariables
      >;
      stateHandler: CoreStateHandler<State, DispatchTypes>;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
      coreDynamicFunctionGetter: CoreDynamicFunctionGetter<
        InternalFunctions,
        Functions,
        ExternalFunctions
      >;
    }) => any;
  }[] = [];

  addFunction<
    T extends keyof (InternalFunctions & Functions & ExternalFunctions)
  >(
    name: T,
    builder: (wrapperEnvironment: {
      variablesHandler: CoreVariablesHandler<
        InternalVariables,
        Variables,
        ExternalVariables
      >;
      stateHandler: CoreStateHandler<State, DispatchTypes>;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
      coreDynamicFunctionGetter: CoreDynamicFunctionGetter<
        InternalFunctions,
        Functions,
        ExternalFunctions
      >;
    }) => (InternalFunctions & Functions & ExternalFunctions)[T]
  ) {
    this._functions.push({ name, builder });
    return this;
  }

  getKeys() {
    return this._functions.map(v => v.name);
  }
  getFunctionBuilder<
    T extends
      | Extract<keyof InternalFunctions, string>
      | Extract<keyof Functions, string>
      | Extract<keyof ExternalFunctions, string>
  >(varName: T) {
    const def = this._functions.find(varDef => varDef.name === varName);
    if (!def) {
      throw new Error('Function not found: ' + varName);
    }
    return def.builder;
  }

  hasRegistrations() {
    return !!this._functions.length;
  }
}
