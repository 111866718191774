import {
  CoreStateHandler,
  CoreVariablesHandler,
  CoreDynamicFunctionGetter,
} from '../common';

export class FunctionBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes,
  GenericFunction
> {
  public variablesHandler!: CoreVariablesHandler<
    InternalVariables,
    Variables,
    ExternalVariables
  >;
  public stateHandler!: CoreStateHandler<State, DispatchTypes>;
  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;
  public virtuals!: Virtuals;

  public coreDynamicFunctionGetter!: CoreDynamicFunctionGetter<
    InternalFunctions,
    Functions,
    ExternalFunctions
  >;

  private _constructor!: (context: {
    variablesHandler: CoreVariablesHandler<
      InternalVariables,
      Variables,
      ExternalVariables
    >;
    stateHandler: CoreStateHandler<State, DispatchTypes>;
    componentProps: ComponentPropsDefinition;
    bindedContexts: BindedContexts;
    virtuals: Virtuals;
    coreDynamicFunctionGetter: CoreDynamicFunctionGetter<
      InternalFunctions,
      Functions,
      ExternalFunctions
    >;
  }) => GenericFunction;

  setConstructor(
    value: (context: {
      variablesHandler: CoreVariablesHandler<
        InternalVariables,
        Variables,
        ExternalVariables
      >;
      stateHandler: CoreStateHandler<State, DispatchTypes>;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
      coreDynamicFunctionGetter: CoreDynamicFunctionGetter<
        InternalFunctions,
        Functions,
        ExternalFunctions
      >;
    }) => GenericFunction
  ) {
    this._constructor = value;
  }

  build() {
    return this._constructor(this);
  }
}
