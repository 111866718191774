import { Logger, UserInput } from '../../generic/utils';
import { SessionService, wrapperIdentifier } from './common';
import { SESSION_STATUSES } from './others';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'instanceSession',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, coreFetch },
    }) => async (sessionData, enabledRoles) => {
      const checker = SessionService.instance.checker;
      console.log('º Handling Session');
      let validInstance: boolean | null = true;

      variablesSetter('sessionStatus')(SESSION_STATUSES.CHEKING);
      if (checker && sessionData) {
        validInstance = await new Promise(async resolve => {
          try {
            const result = await (checker &&
              checker(sessionData, getInstanceApi(), coreFetch, enabledRoles));
            resolve(result);
          } catch (e) {
            resolve(false);
          }
        });
        console.log(
          'ºº SESSION ',
          checker,
          enabledRoles,
          validInstance,
          validInstance ? 'OK' : 'FGAIL'
        );
      } else {
        if (!sessionData) {
          validInstance = false;
        }
      }
      variablesSetter('sessionStatus')(
        validInstance ? SESSION_STATUSES.CREATED : SESSION_STATUSES.NOT_CREATED
      );
      return validInstance;
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'startSession',
    ({
      coreDynamicFunctionGetter,
      stateHandler: { dispatch },
      variablesHandler: { variablesSetter },
      bindedContexts: { setAutenticationToken },
    }) => async (sessionData, enabledRoles = []) => {
      const instanceSession = coreDynamicFunctionGetter('instanceSession');
      console.log('Regstering session data');

      const validInstance = await instanceSession(sessionData, enabledRoles);

      if (validInstance) {
        dispatch({ type: DISPATCH_TYPES.START_SESSION, payload: sessionData });
        if (sessionData && (sessionData as UserInput).token) {
          setAutenticationToken((sessionData as UserInput).token);
        }
        variablesSetter('sessionData')({
          ...sessionData,
        });
      } else {
        setAutenticationToken(undefined);
        variablesSetter('sessionData')(
          validInstance === null ? null : undefined
        );
      }
      return validInstance;
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'endSession',
    ({
      coreDynamicFunctionGetter,
      bindedContexts: { setAutenticationToken },
    }) => (route, props) => {
      console.log('ºº Removing session data');
      SessionService.instance.ended = true;
      setAutenticationToken(undefined);
      if (route) {
        SessionService.instance.routeEnded = [route, props];
      }
      coreDynamicFunctionGetter('clearSession')();
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'clearSession',
    ({
      stateHandler: { dispatch },
      variablesHandler: { variablesSetter },
      bindedContexts: { setAutenticationToken },
    }) => () => {
      console.log('ºº Clearing session data');
      dispatch(DISPATCH_TYPES.END_SESSION);
      setAutenticationToken(undefined);
      variablesSetter('sessionStatus')(SESSION_STATUSES.NOT_CREATED);
      variablesSetter('sessionData')(undefined);
    }
  );
