import { Logger } from '../../generic/utils';
import { wrapperIdentifier, TargetType } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadTargetTypes',
    ({
      variablesHandler: { variablesSetter },
      componentProps: { filter },
      bindedContexts: { getInstanceApi },
    }) => async () => {
      let targetTypes: TargetType[] | null;
      try {
        targetTypes = await getInstanceApi().getTargetTypes(filter);
        console.log('Loaded targetTypes ', targetTypes);
      } catch (e) {
        targetTypes = null;
      }
      variablesSetter('targetTypes')(targetTypes);
    }
  );
