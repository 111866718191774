declare var StatusBar: any;

export let contextTheme = 'dark';

export const setContextTheme = (value: any) => {
  contextTheme = value;
};
export class StatusBarService {
  private static _i: StatusBarService | undefined;
  private static _native: any;
  public static get instance() {
    if (!this._i) {
      this._i = new StatusBarService();
    }
    return this._i;
  }

  e(method: string) {
    if (!StatusBarService._native) {
      try {
        StatusBarService._native = StatusBar;
      } catch (error) {}
    }
    if (StatusBarService._native) {
      return StatusBarService._native[method]();
    }
  }
}
