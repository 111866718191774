export enum MODES {
  'A',
  'B',
}

export interface ComponentProps {
  enablePersonNaming: boolean;
}
export interface IV {
  isShowingName: boolean;
}
export interface V {
  aditionalMessage: string;
}
export interface EV {
  name: string;
}
export interface IF {
  constructMessage: (value: string) => string;
}
export interface F {
  getAdditionalMessage: (value: object) => string;
}
export interface EF {
  showPersonName: (personName: string, aditionalMessage?: string) => void;
}
export interface S {
  propState: number;
}
export interface VR {
  messageWithAditional: string;
}
export interface ST {
  MODES: typeof MODES;
}
