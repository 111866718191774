import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';

/* const console = */ new Logger(wrapperIdentifier);
wrapperBuilder
  .getVariablesRegisterInstance()
  .addVariable('alertMessage', { startValue: undefined })
  .addVariable('alertOptions', { startValue: undefined })
  .addVariable('backgroundColor', { startValue: undefined })
  .addVariable('backgroundKey', { startValue: undefined })
  .addVariable('backgroundPosition', { startValue: undefined })
  .addVariable('currentPageAnimation', { startValue: undefined })
  .addVariable('footer', { startValue: undefined })
  .addVariable('header', { startValue: undefined })
  .addVariable('layoutProps', { startValue: undefined })
  .addVariable('menuOpen', { startValue: undefined })
  .addVariable('modalsStates', { startValue: undefined })
  .addVariable('openedModals', { startValue: [] })
  .addVariable('showLoading', { startValue: false })
  .addVariable('showLoadingProps', { startValue: undefined })
  .addVariable('windowDimensions', {
    startValue: {
      width: window.window.innerWidth,
      height: window.window.innerHeight,
    },
  });
