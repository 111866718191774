import { UiConfiguration } from '../interfaces';
import _ from 'lodash';
import { componentsConfiguration } from './userDefinitions';
//TODO: Por ahora simplemente retorna el valor recibido
export default (styles?: UiConfiguration, rawConfiguration?: any) => {
  //return styles;
  const mainColor = styles?.colors?.main || 'rgb(0,0,0)';
  const secondaryColor = styles?.colors?.secondary || 'rgb(120,120,120)';
  const createdLibraryConfigFromUiConfiguration = {
    palette: {
      primary: {
        main: mainColor,
      },
      secondary: {
        main: secondaryColor,
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      useNextVariants: true,
      fontFamily: styles?.fontFamily && [styles?.fontFamily].join(','),
    },
    overrides: {
      MuiPickersCalendarHeader: {
        transitionContainer: {
          width: '200px',
        },
      },
      MuiPickersToolbar: {
        toolbar: {
          ...componentsConfiguration?.inputDate?.styles?.default
            ?.toolbarComponent,
        },
      },
      MuiPickersModal: {
        withAdditionalAction: {
          ...componentsConfiguration?.inputDate?.styles?.default
            ?.actionsComponent,
        },
      },
      MuiPickersDay: {
        day: {
          ...componentsConfiguration?.inputDate?.styles?.default?.dayComponent,
        },
        daySelected: {
          ...componentsConfiguration?.inputDate?.styles?.default
            ?.daySelectedComponent,
          '&:hover': {
            ...componentsConfiguration?.inputDate?.styles?.default
              ?.daySelectedComponent,
          },
        },
        dayDisabled: {
          ...componentsConfiguration?.inputDate?.styles?.default
            ?.dayDisabledComponent,
        },
        current: {
          ...componentsConfiguration?.inputDate?.styles?.default
            ?.currentDayComponent,
        },
      },
    },
  };
  return _.merge(
    {},
    createdLibraryConfigFromUiConfiguration,
    rawConfiguration || {}
  );
};
