/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Logger, ExternalVar } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';
import { ApplicationWrapper } from '../ApplicationWrapper';
import { NavigationWrapper } from '../NavigationWrapper';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(({ componentProps, stateHandler: { state } }) => {
    const navigationContext = NavigationWrapper.use();
    const applicationContext = ApplicationWrapper.use();
    const dispatch = applicationContext
      ? applicationContext.dispatch
      : () => undefined;
    const deviceReady =
      (applicationContext && applicationContext.deviceReady) || false;
    useEffect(() => {
      if (deviceReady) {
        console.log(' HANDLING BRANCH FROM DEVICE READY ');
        handleBranch('deviceReady');
      }
    }, [deviceReady]);

    useEffect(() => {
      if (state?.resume) {
        console.log(' HANDLING BRANCH  FROM RESUME ');
        handleBranch('resume');
      }
    }, [state?.resume]);

    const handleBranch = async (from: string) => {
      console.log(' HANDLE BRANCH ' + from);
      // Branch initialization
      if (
        (window as ExternalVar).Branch &&
        'initSession' in (window as ExternalVar).Branch
      ) {
        const data = await (window as ExternalVar).Branch.initSession();
        console.log(' HANDLE BRANCH :', data);
        if (data['+clicked_branch_link']) {
          console.log(' Data :', data);
          dispatch({ type: DISPATCH_TYPES.DEEPLINK_CLICKED, payload: data });
          if (data && data.__internalPurposesRedirection) {
            if (navigationContext) {
              const { navigateTo } = navigationContext;
              const redirection = JSON.parse(
                data.__internalPurposesRedirection
              );
              if (redirection.key) {
                navigateTo(redirection.key, {
                  handleRedirection: false,
                  mode: 'replace',
                  ...redirection.redirectOptions,
                });
              }
            } else {
              throw new Error(
                'Deeplink data contains a redirection, cannot redirect because NavigationWrapper context not found'
              );
            }
          }
        }
        if (data.__internalPurposesMetadata) {
          const metadata = JSON.parse(data.__internalPurposesMetadata);
          if (
            metadata &&
            Object.keys(metadata).length &&
            componentProps.onMetadataReceived
          ) {
            componentProps.onMetadataReceived(metadata);
          }
        }
      } else {
        console.log('No Branch or initSession', (window as ExternalVar).Branch);
      }
    };
  });
