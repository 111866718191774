import { NavigateToProps } from '../../wrappers/NavigationWrapper/interfaces';
import { CoinscrapApi } from '../../generic/Apis';
import { CoreFetch } from '../../wrappers/BackendWrapper/common';
import { SessionData } from './interfaces';
import { defaultBackendCheck } from './others';

export const wrapperIdentifier = 'session';

export type SessionChecker<T> = (
  data: SessionData,
  api: CoinscrapApi,
  coreFetch: CoreFetch,
  enabledRoles: string[]
) => Promise<T>;

export class SessionService {
  private static _i: SessionService;
  private _ended: boolean = false;
  private _routeEnded:
    | [string, NavigateToProps | undefined]
    | undefined = undefined;

  public static get instance() {
    if (!this._i) {
      this._i = new SessionService();
    }
    return this._i;
  }

  constructor(
    public checker: SessionChecker<boolean | null> = defaultBackendCheck
  ) {}

  public get ended() {
    return this._ended;
  }
  public set ended(value) {
    this._ended = value;
  }
  public get routeEnded() {
    return this._routeEnded;
  }
  public set routeEnded(value) {
    this._routeEnded = value;
  }
}
