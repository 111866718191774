export enum TARGET_MODES {
  SELF = '_self',
  BLANK = '_blank',
  SYSTEM = '_system',
}

// Aditional properties in
//   https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-inappbrowser/#cordovainappbrowseropen
export interface OpenOptions {
  location?: boolean;
  beforeload?: boolean | string;
  hidden?: boolean;
}
