import {
  OnChange,
  InputValue,
  RadioOptionStyle,
  Disabled,
  RadioOptionExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.radioOption;

export interface ClassProperties {}

export type ComponentStyle = RadioOptionStyle;
export type ExpectedStyles = RadioOptionExpectedStyles;
export type Props = GenericComponentProps<RadioOptionStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  noT?: boolean;
};
