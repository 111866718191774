import './functions';
import './logic';
import './variables';
import './reducers';
import './virtuals';
import './hooks';
import { wrapperBuilder } from './build';
import { Bind } from '../../generic/contextInjection';

export const RulesWrapper = wrapperBuilder.build();

Bind('rulesWrapper', RulesWrapper);
