import React, { FunctionComponent, useEffect } from 'react';
import { COMPONENTS, generateProperties } from '../../common';
import { HeaderFooterLayoutStyle } from '../../propsDefinition';
import { View } from '../../View';
import { ApplicationWrapper } from '../../../../ApplicationWrapper';
import { Sidebar } from '../../Sidebar';
import { UiWrapper } from '../../../../UiWrapper';
import { getCurrentAnimationData } from '../../../../NavigationWrapper/others/page';
import { Body } from './Body';

const e = React.createElement;

export const LayoutKey = 'HeaderFooter';
export type HeaderFooterLayoutPredefinedKey = typeof LayoutKey;

export interface HeaderFooterLayoutProps {
  style: HeaderFooterLayoutStyle;
  headerComponent: any;
  footerComponent: any;
  menuContent: any;
  menuWidth: number; // number important, not React.Css allowed
  menuCloseOnClick: boolean; // default false
}

export const HeaderFooterLayout: FunctionComponent<HeaderFooterLayoutProps> = ({
  children,
  style,
  headerComponent: header,
  footerComponent: footer,
  menuContent,
  menuWidth,
  menuCloseOnClick = false,
}) => {
  const {
    menuOpen,
    closeMenu,
    currentPageAnimation: cpa,
    setCurrentPageAnimation,
  } = UiWrapper.useInternal();
  const appState = ApplicationWrapper.use();
  const currentPageAnimation = getCurrentAnimationData();
  const name = cpa?.[0];

  useEffect(() => {
    if (name && name.indexOf('___done___') < 0) {
      setCurrentPageAnimation([name + '___done___', cpa?.[1] || {}]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);
  const isIos = (appState && appState.isIos) || false;
  return e(
    Sidebar,
    {
      ...generateProperties(undefined, COMPONENTS.layout, 'sidebar'),
      key: '1',
      style: style.sidebarComponent,
      open: menuOpen || false,
      closeOnClick: menuCloseOnClick,
      onClose: menuCloseOnClick ? () => closeMenu() : undefined,
      content: menuContent,
      width: menuWidth,
    },
    e(
      View,
      {
        cleared: true,
        style: { flexBasis: '100%', width: '100%' },
        animation: currentPageAnimation?.[1],
      },
      header &&
        e(
          View,
          {
            ...generateProperties(
              undefined,
              COMPONENTS.layout,
              'header',
              'Layout_HF-Header'
            ),
            style: style.headerComponent,
          },
          header
        ),
      e(
        View,
        {
          ...generateProperties(
            undefined,
            COMPONENTS.layout,
            'bodyContent',
            'Layout_HF-Body-Parent',
            undefined
          ),
          style: {
            ...style.bodyContentComponent,
            ...(isIos ? { display: 'block' } : {}),
          },
        },
        e(
          View,
          {
            ...generateProperties(
              undefined,
              COMPONENTS.layout,
              'bodyScroller',
              'Layout_HF-Body-Scroller',
              undefined
            ),
            style: style.bodyScrollerComponent,
          },
          e(
            Body,
            {
              predefinedLayout: LayoutKey,
              style: style.bodyComponent,
            },
            children
          )
        )
      ),
      footer &&
        e(
          View,
          {
            ...generateProperties(
              undefined,
              COMPONENTS.layout,
              'footer',
              'Layout_HF-Footer'
            ),
            style: style.footerComponent,
          },
          footer
        )
    )
  );
};
