import './functions';
import './logic';
import './reducers';
import './render';
import './statics';
import './variables';
import './virtuals';
import { wrapperBuilder } from './build';

export const UiWrapper = wrapperBuilder.build();
