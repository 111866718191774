import React, { FunctionComponent, useState, useEffect } from 'react';
import { NavigationWrapper } from '../../NavigationWrapper';
import { RouteNavigation } from '../../NavigationWrapper/interfaces';
import { SessionWrapper } from '../';
import { SessionService, wrapperIdentifier } from '../common';
import { Logger, UserInput, wrapProvider } from '../../../generic/utils';
import { defaultValidation, SESSION_STATUSES } from '.';
import { SessionData } from '../interfaces';
import { UiWrapper } from '../../UiWrapper';
import { BackendWrapper } from '../../BackendWrapper';
import { useProvider } from '../../../generic';
import { CoreFetch } from 'wrappers/BackendWrapper/common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
let ended = false;
export interface ComponentSessionWrapperProps {
  availableRoles?: string[];
  onAccess?: (isValidSession: boolean) => void;
  sessionValidator?: (
    sessionData: SessionData,
    sessionStatus?: SESSION_STATUSES,
    coreFetch?: CoreFetch,
    enabledRoles?: string[]
  ) => boolean | null;
  pageNoSession: string | RouteNavigation;
}

const WithSessionWrapper = React.createContext<{ inContext: boolean }>({
  inContext: false,
});

export const WithSession: FunctionComponent<ComponentSessionWrapperProps> = ({
  ...props
}): any => {
  const uiContext = UiWrapper.use();
  const { sessionValidator, availableRoles } = props;
  const validateSession = sessionValidator || defaultValidation;
  const { navigateTo, getCurrentRoute } = NavigationWrapper.use(
    wrapperIdentifier
  );
  const { inContext } = useProvider(WithSessionWrapper);
  const { token, setAutenticationToken } = BackendWrapper.use(
    wrapperIdentifier
  );
  const {
    sessionData,
    instanceSession,
    clearSession,
    sessionStatus,
  } = SessionWrapper.use(wrapperIdentifier);
  const [valid, setValid] = useState<boolean | undefined | null>(undefined);

  const checkFunction = instanceSession;
  if (!inContext) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (
        sessionData &&
        (sessionData as UserInput).token &&
        (sessionData as UserInput).token !== token
      ) {
        setAutenticationToken((sessionData as UserInput).token);
      }
      if (sessionStatus === SESSION_STATUSES.NOT_CREATED) {
        console.log('ºº Handling Session');
        checkFunction(sessionData, availableRoles || []).then(valid => {
          console.log('ºº REJECTING Session', valid);
          setValid(valid);
          if (!valid) {
            if (!ended) {
              ended = true;
              console.log('ºº GO TOREJECT NOW');
              clearSession();
            }
          } else {
            ended = false;
          }
        });
      } else if (sessionStatus === SESSION_STATUSES.CREATED) {
        setValid(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [(sessionData as UserInput)?.token, sessionStatus]);
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setValid(
      validateSession(
        sessionData,
        sessionStatus,
        BackendWrapper.getCoreFetch(),
        availableRoles
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionData, sessionStatus]);

  useEffect(() => {
    const sessionEnded = SessionService.instance.ended;
    const routeEnded = SessionService.instance.routeEnded;
    console.log('ºº CHEKING TO REDIRECT', valid, sessionEnded, routeEnded);
    SessionService.instance.ended = false;
    SessionService.instance.routeEnded = undefined;
    if (valid === false || valid === null) {
      const routeToGo =
        typeof props.pageNoSession === 'string' ||
        typeof props.pageNoSession === 'undefined'
          ? {
              name: props.pageNoSession || '/',
              params: {},
            }
          : props.pageNoSession;

      const currentRoute = getCurrentRoute();
      if (sessionEnded) {
        navigateTo(
          routeEnded?.[0] || routeToGo.name,
          routeEnded?.[1] || {
            routeParams: routeToGo.params,
            mode: 'replace',
          }
        );
      } else {
        navigateTo(routeToGo.name, {
          routeParams: routeToGo.params,
          queryParams: {
            from: (currentRoute && currentRoute.url) || '',
          },
          mode: 'replace',
        });
      }
    }
    if (!sessionEnded) {
      if (typeof valid === 'boolean' || valid === null) {
        if (props.onAccess) {
          props.onAccess(valid === true);
        }
        if (!props.onAccess && !valid && uiContext) {
          uiContext.showAlert(
            typeof valid === 'boolean'
              ? 'Debes iniciar sesión primero. 🔒'
              : 'No tienes permisos suficientes. 🔒'
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valid]);

  console.log('WRAPPING Session.WithSession Wrapper');
  return wrapProvider(WithSessionWrapper, { inContext: true })(
    valid ? props.children : null
  );
};
