import {
  OnChange,
  InputValue,
  InputSwitchStyle,
  Disabled,
  InputSwitchExpectedStyles,
  GenericComponentProps,
  InputLabel,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.inputSwitch;

export interface ClassProperties {
  variant: 'filled' | 'outlined';
}

export type ComponentStyle = InputSwitchStyle;
export type ExpectedStyles = InputSwitchExpectedStyles;
export type Props = GenericComponentProps<InputSwitchStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  label?: InputLabel;

  error?: string;
};
