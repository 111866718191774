/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { View } from '../View';
import { Text } from '../Text';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Logger } from '../../../../generic/utils';
import { InternationalizationWrapper } from '../../../InternationalizationWrapper';

const console = new Logger('inputSwipe');

if (console) {
  //Just for usage
}

const e = React.createElement;

export const InputSwipe: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  label,
  vertical = false,
  children = [],
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled, vertical }
  );

  // Otros ----------------------------------------------------------------

  const t =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use().t) ||
    ((k: any) => k);

  console.log(' CHILDRENS ', children);

  const childProps =
    ((children[
      React.Children.toArray(children).reduce(
        (currentIndex, option: any, i) =>
          (option.props && option.props.value) === value ? i : currentIndex,
        0
      ) as number
    ] as any).props as any) || {};
  const toShow =
    !childProps.noT && typeof childProps.children === 'string'
      ? t(childProps.children)
      : (childProps.children as any);
  console.log(' CHILDRENS 2 ', toShow);

  // Return -----------------------------------------------------
  return e(
    View,
    {
      ...generateProperties(parentReference, enumKey, 'container', coreId, id),
      cleared: true,
      style: componentStyle.containerComponent,
    },
    label &&
      e(
        Text,
        {
          ...generateProperties(parentReference, enumKey, 'label'),
          style: componentStyle.labelComponent,
        },
        label
      ),
    e(
      View,
      {
        ...generateProperties(parentReference, enumKey, 'swipeContainer'),
        cleared: true,
        style: componentStyle.swipeContainerComponent,
      },
      e(KeyboardArrowLeft, {
        ...generateProperties(
          parentReference,
          enumKey,
          'prevIcon',
          undefined,
          undefined,
          true
        ),
        style: componentStyle.prevIconComponent,
        onClick: () => {
          const currentIndex = (children || []).reduce(
            (currentIndex: any, option: any, i: any) =>
              (option.props && option.props.value) === value ? i : currentIndex,
            0
          );
          onChange &&
            children &&
            onChange(
              (
                (children[
                  currentIndex - 1 < 0 ? children.length - 1 : currentIndex - 1
                ] as any).props || {}
              ).value
            );
        },
      }),
      e(
        Text,
        {
          ...generateProperties(parentReference, enumKey, 'text'),
          noT: true,
          style: componentStyle.textComponent,
        },
        toShow
      ),
      e(KeyboardArrowRight, {
        ...generateProperties(
          parentReference,
          enumKey,
          'nextIcon',
          undefined,
          undefined,
          true
        ),
        style: componentStyle.nextIconComponent,
        onClick: () => {
          const currentIndex = (children || []).reduce(
            (currentIndex, option: any, i) =>
              (option.props && option.props.value) === value ? i : currentIndex,
            0
          );
          onChange &&
            children &&
            onChange(
              (
                (children[(currentIndex + 1) % children.length] as any).props ||
                {}
              ).value
            );
        },
      })
    )
  );
};
