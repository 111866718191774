import { Logger } from '../../generic/utils';
import { wrapperIdentifier, RuleDefinition } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadRuleDefinitions = () => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadRuleDefinitions',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi },
    }) => async () => {
      let ruleDefinitions: RuleDefinition[] | null;
      try {
        ruleDefinitions = await getInstanceApi().getRuleDefinitions();
        console.log('Loaded ruleDefinitions ', ruleDefinitions);
      } catch (e) {
        ruleDefinitions = null;
      }
      variablesSetter('ruleDefinitions')(ruleDefinitions);
    }
  );

export type AutocompleteRuleField = (
  fieldIdentifier: string,
  search: string
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'autocompleteRuleField',
    ({ bindedContexts: { getInstanceApi } }) => async (
      fieldIdentifier,
      searchString
    ) => {
      return await getInstanceApi().autocompleteRuleField(
        fieldIdentifier,
        searchString
      );
    }
  );
