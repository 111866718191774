import {
  SoftSwitcherStyle,
  SoftSwitcherExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.softSwitcher;

export interface ClassProperties {}

export type ComponentStyle = SoftSwitcherStyle;
export type ExpectedStyles = SoftSwitcherExpectedStyles;
export type Props = GenericComponentProps<SoftSwitcherStyle> & {
  evaluate: number | boolean | any;
  duration?: number;
};
