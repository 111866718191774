/* eslint-disable no-unused-vars */
import React, { useState, FunctionComponent, useRef } from 'react';
import MuiTextField from '@material-ui/core/TextField';

import EuroIcon from '@material-ui/icons/EuroSymbol';

import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';

import MicNoneIcon from '@material-ui/icons/MicNone';

import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import { makeStyles } from '@material-ui/core';
import { Text } from '../Text';
import { generateMaterialStyles } from '../../common';
import { InternationalizationWrapper } from '../../../InternationalizationWrapper';
import { componentsOverrides } from '../userOverrides';

const useContainerStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
});

const useIconStyles = makeStyles({
  /*root: (props: ComponentStyle) =>
    generateMaterialStyles('iconComponent', props),*/
  adornment: (props: ComponentStyle) =>
    generateMaterialStyles('iconComponent', props, false, [
      '&.MuiSvgIcon-root',
    ]),
});

const useInputStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('inputContainerComponent', props),
  input: (props: ComponentStyle) =>
    generateMaterialStyles('inputComponent', props),
});

const useLabelStyles = makeStyles({
  // style rule
  root: (props: ComponentStyle) =>
    generateMaterialStyles('labelComponent', props, false, [
      '&.MuiInputLabel-marginDense',
    ]),
});

const e = React.createElement;

window.SpeechRecognition =
  (window as any).webkitSpeechRecognition || window.SpeechRecognition;

export const TextField: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  label,
  style,
  onChange,
  onBlur,
  onFocus,
  value,
  disableUnderline = true,
  error,
  unit,
  defaultValue,
  type,
  disabled,
  placeholder,
  speech,
  disableDecimals = false,
  speechIcon,
  inputRef,
  endAdornment,
  startAdornment,
  multiline = false,
  autoFocus,
  readOnly,
  internalProps,
  ...styleKeys
}) => {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      label,
      style,
      onChange,
      onBlur,
      onFocus,
      value,
      disableUnderline,
      error,
      unit,
      defaultValue,
      type,
      disabled,
      placeholder,
      speech,
      disableDecimals,
      speechIcon,
      inputRef,
      endAdornment,
      startAdornment,
      multiline,
      autoFocus,
      readOnly,
      internalProps,
      ...styleKeys,
    });
  }
  checkStyleNames(enumKey, styleKeys);

  const componentRef = useRef();
  const [listening, setListening] = useState(false);
  const [focused, setFocused] = useState(false);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {
      noLabel: !label,
      filled: !(value === undefined || value === null),
      currency: type === 'currency',
      speech,
      focused,
      listening,
      error: !!error,
      disabled,
    }
  );

  const t =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use().t) ||
    ((k: any) => k);

  // Return -----------------------------------------------------
  if (overrides?.component) {
    return overrides.component({
      id,
      label: t(label),
      style,
      noLabel: !label,
      filled: !(value === undefined || value === null),
      currency: type === 'currency',
      speech,
      focused,
      listening,
      error: !!error,
      disabled,
      type: false
        ? 'text'
        : type === 'number' || type === 'currency'
        ? 'number'
        : type,
      onChange: (e: any) => {
        if (type === 'number' && disableDecimals) {
          e.target.value =
            e.target.value && e.target.value.replace('.', '').replace(',', '');
        }
        if (type === 'number' || type === 'tel') {
          e.target.value = (isNaN(Number(e.target.value))
            ? ''
            : Number(e.target.value)) as any;
        }
        onChange && onChange(e);
      },
      onBlur: (e: any) => {
        setFocused(false);
        try {
          onBlur && onBlur(e);
        } catch {}
      },
      onFocus: (e: any) => {
        setFocused(true);
        try {
          onFocus && onFocus(e);
        } catch {}
        // El tipo email no permite seleccionarlo
        if (!readOnly && type !== 'email' && type !== 'number') {
          //TODO: En escritorio, abre el menu contextual?!?
          e.target.select(); //(0, e.target.value.length);
        }
      },
      value,
      disableUnderline,
      helperText: t(error),
      placeholder: t(placeholder),
      multiline: Boolean(multiline),
      rows: multiline ? Number(multiline) : undefined,
      unit,
      defaultValue,
      disableDecimals,
      speechIcon,
      inputRef,
      endAdornment,
      startAdornment,
      autoFocus,
      readOnly,
      internalProps,
      ...styleKeys,
    });
  }

  // Otros ----------------------------------------------------------------
  let innerInputProps = { ...internalProps };
  const [showPassword, setShowPassword] = useState(false);
  // Generación de estilos material basados en estilos UiWrapper
  const materialContainer = useContainerStyles(componentStyle);
  const materialInput = useInputStyles(componentStyle);
  const materialIcon = useIconStyles(componentStyle);
  const materialLabel = useLabelStyles(componentStyle);

  // eslint-disable-next-line no-lone-blocks
  {
    // TODO: Esto esta sin probar
    if (speech) {
      if (window.SpeechRecognition) {
        endAdornment = e(
          InputAdornment,
          {
            position: 'end',
            className: materialIcon.adornment,
          },
          e(speechIcon || MicNoneIcon, {
            onClick: () => {
              if (!(window as any).coinscrapSpeechRecognition) {
                (componentRef as any).current.querySelector('input').focus();
                setListening(true);
                microfonoStart(text => {
                  if (text.trim() !== '') {
                    //setValue(interimTranscript);
                    (componentRef as any).current.querySelector(
                      'input'
                    ).value = text;
                    onChange &&
                      onChange({
                        target: (componentRef as any).current.querySelector(
                          'input'
                        ),
                      });

                    //setValue(interimTranscript);
                  }
                });
              } else {
                setListening(false);
                microfonoStop();
                onChange &&
                  onChange({
                    target: (componentRef as any).current.querySelector(
                      'input'
                    ),
                  });
              }
            },
          } as any)
        );
      }
    } else if (unit) {
      endAdornment = e(
        IconButton,
        {
          'aria-label': 'action button',
          className: materialIcon.adornment,
        },
        e(
          Text,
          {
            style: { fontSize: 11, fontWeight: 800 },
          },
          unit
        )
      );
    }
    if (!endAdornment) {
      if (type === 'currency') {
        endAdornment = e(
          InputAdornment,
          { position: 'end', className: materialIcon.adornment },
          e(EuroIcon, {})
        );
      }
      if (type === 'password') {
        endAdornment = e(
          IconButton,
          {
            'aria-label': 'toggle password visibility',
            color: 'primary',
            style: { width: 56, minWidth: 56, marginRight: -15 },
            onClick: () => setShowPassword(!showPassword),
          },
          e(
            !showPassword ? Visibility : VisibilityOff,
            {
              style: { fontSize: 23 },
            },
            unit
          )
        );
      }
    }
  }

  return e(MuiTextField, {
    ...generateProperties(
      parentReference,
      enumKey,
      'container',
      coreId,
      undefined,
      true
    ),
    ref: componentRef as any,
    type: showPassword
      ? 'text'
      : type === 'number' || type === 'currency'
      ? 'number'
      : type,
    margin: 'dense',
    variant: (TextField.variant || 'filled') as any,
    label: t(label),
    disabled,
    autoFocus,
    error: !!error,
    helperText: t(error),
    placeholder: t(placeholder),
    value,
    defaultValue,
    inputRef,
    multiline: Boolean(multiline),
    rows: multiline ? Number(multiline) : undefined,
    onFocus: e => {
      setFocused(true);
      try {
        onFocus && onFocus(e);
      } catch {}
      // El tipo email no permite seleccionarlo
      if (!readOnly && type !== 'email' && type !== 'number') {
        //TODO: En escritorio, abre el menu contextual?!?
        e.target.select(); //(0, e.target.value.length);
      }
    },
    onBlur: e => {
      setFocused(false);
      try {
        onBlur && onBlur(e);
      } catch {}
    },
    onChange: e => {
      if (type === 'number' && disableDecimals) {
        e.target.value =
          e.target.value && e.target.value.replace('.', '').replace(',', '');
      }
      if (type === 'number' || type === 'tel') {
        e.target.value = (isNaN(Number(e.target.value))
          ? ''
          : Number(e.target.value)) as any;
      }
      onChange && onChange(e);
    },
    classes: materialContainer,
    InputProps: {
      classes: materialInput,
      disableUnderline,
      ...generateProperties(
        parentReference,
        enumKey,
        'inputContainer',
        undefined,
        undefined,
        true
      ),
      ...innerInputProps,
      inputProps: {
        ...innerInputProps.inputProps,
        ...generateProperties(
          parentReference,
          enumKey,
          'input',
          undefined,
          id,
          true
        ),
      },
      endAdornment,
      startAdornment,
      readOnly,
    },
    InputLabelProps: {
      ...generateProperties(
        parentReference,
        enumKey,
        'label',
        undefined,
        undefined,
        true
      ),
      shrink: !(value === undefined || value === null) || focused,
      classes: materialLabel,
    },
  });
};

function microfonoStart(onResult: (texto: string) => void) {
  if (!(window as any).coinscrapSpeechRecognition) {
    (window as any).coinscrapSpeechRecognition = new window.SpeechRecognition();
    let sr = (window as any).coinscrapSpeechRecognition;

    sr.interimResults = true;
    sr.maxAlternatives = 10;
    sr.continuous = true;
    (window as any).coinscrapSpeechRecognitionFinal = '';
    sr.onresult = (event: any) => {
      let interimTranscript = '';
      for (
        let i = event.resultIndex, len = event.results.length;
        i < len;
        i++
      ) {
        let transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          (window as any).coinscrapSpeechRecognitionFinal += transcript;
        } else {
          interimTranscript += transcript;
        }
      }
      onResult && onResult(interimTranscript.trim());
    };
    sr.start();
  }
}

function microfonoStop() {
  if ((window as any).coinscrapSpeechRecognition) {
    //setValue(window.coinscrapSpeechRecognitionFinal);
    (window as any).coinscrapSpeechRecognition.stop();
    (window as any).coinscrapSpeechRecognition = null;
    // onChange && onChange({ target: inputRef.current.querySelector('input') });
  }
}

TextField.variant = 'filled';
