import './variables';
//import './virtuals';
import './reducers';
import './functions';
import './logic';

import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import React, { FunctionComponent } from 'react';
import { Logger } from '../../generic/utils';
import {
  Context,
  ExternalComponentWrapperProps,
  NavigationScreens,
} from './interfaces';
import { extractScreens } from './others';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';
import { BaseNameController } from './others/baseNameController';

export const history = createBrowserHistory();
const e = React.createElement;

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const Inner = wrapperBuilder.build();

// External component for the builded Wrapper

let treeInited = false;
let screens: NavigationScreens = {};
export const NavigationWrapper: FunctionComponent<
  ExternalComponentWrapperProps
> & {
  use: (requester?: string) => Context;
  setBaseName: (baseName: string) => void;
} = function Component({ children, ...props }) {
  if (!treeInited) {
    treeInited = true;
    if (Array.isArray(children)) {
      children.forEach((c: any) => {
        extractScreens(BaseNameController.instance.basename || '', c, screens);
      });
    } else {
      extractScreens(
        BaseNameController.instance.basename || '',
        children,
        screens
      );
    }
    console.log('SCREENS', screens);
  }
  return e(
    Router,
    {
      basename: BaseNameController.instance.basename,
      history: history,
    } as any,
    e(
      Inner,
      {
        history: history,
        screens,
        navigationEvents: props.navigationEvents,
        children: children as any,
      },
      children
    )
  );
};

//Setting defaul basename url
BaseNameController.instance.basename = process.env.PUBLIC_URL || '';

NavigationWrapper.setBaseName = (basename: string) => {
  BaseNameController.instance.basename = basename;
};

NavigationWrapper.use = (req?: string) => {
  const navigationContext = Inner.use(req);
  return navigationContext;
};
