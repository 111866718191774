import { ScannResult } from './scanResult';
import { Logger } from '../../../generic/utils';
import { wrapperIdentifier } from '../common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

declare var cordova: any;
export class BlinkId {
  private _recognizer: any;
  private _overlaySettings: any;
  private _recognizerCollection: any;

  private _androidLicense?: string =
    (window &&
      window.__CLIENT_ENV &&
      window.__CLIENT_ENV.CLIENT_ENV_SCANN_LICENSE_AND) ||
    undefined;
  private _iosLicense?: string =
    (window &&
      window.__CLIENT_ENV &&
      window.__CLIENT_ENV.CLIENT_ENV_SCANN_LICENSE_IOS) ||
    undefined;

  constructor(licenses: { android?: string; ios?: string }) {
    licenses.android && (this._androidLicense = licenses.android);
    licenses.ios && (this._iosLicense = licenses.ios);
    if (cordova && cordova.plugins && cordova.plugins.BlinkID) {
      this._recognizer = new cordova.plugins.BlinkID.BlinkIdCombinedRecognizer();
      this._recognizer.returnFullDocumentImage = true;
      this._recognizer.returnFaceImage = true;

      // there are lots of Recognizer objects in BlinkID - check blinkIdScanner.js for full reference

      this._overlaySettings = new cordova.plugins.BlinkID.BlinkIdOverlaySettings();

      // Traducciones
      this._overlaySettings.firstSideInstructionsText =
        'Escaneo de la parte frontal del documento';
      this._overlaySettings.flipInstructions = 'Voltea el documento';
      this._overlaySettings.errorMoveCloser = 'Aleja el documento';
      this._overlaySettings.errorMoveFarther = 'Acerca el documento';
      this._overlaySettings.sidesNotMatchingTitle = 'Documento inválido';
      this._overlaySettings.sidesNotMatchingMessage =
        'Las caras del documento no coinciden';
      this._overlaySettings.unsupportedDocumentTitle = 'Documento inválido';
      this._overlaySettings.unsupportedDocumentMessage =
        'Este tipo de documento no esta soportado';
      this._overlaySettings.recognitionTimeoutTitle = 'Alerta';
      this._overlaySettings.recognitionTimeoutMessage =
        'El reconocimiento esta tardando demasiado';
      this._overlaySettings.retryButtonText = 'Reintentar';
      this._overlaySettings.requireDocumentSidesDataMatch =
        'Las dos caras del documento deben coincidir';
      this._overlaySettings.showNotSupportedDialog = 'No soportado';
      this._overlaySettings.scanBarcodeText = 'Escanear codigo de barras';
      this._overlaySettings.errorDocumentTooCloseToEdge =
        'El documento debe estar alejado de los bordes';

      // create RecognizerCollection from any number of recognizers that should perform recognition
      this._recognizerCollection = new cordova.plugins.BlinkID.RecognizerCollection(
        [this._recognizer /*, mrtdSuccessFrameGrabber */]
      );
    }
  }

  async scan(): Promise<ScannResult> {
    console.log('##Punto de entrada funcion scan');
    if (!this._androidLicense || !this._iosLicense) {
      console.log('##Sin alguna de las licencias', {
        a: this._androidLicense,
        i: this._iosLicense,
      });
      throw new Error('Some license is not provided');
    }
    console.log('##Licencias Detectadas', {
      a: this._androidLicense,
      i: this._iosLicense,
    });

    const result: ScannResult = await new Promise((res, rej) => {
      console.log('##Iniciando promesa');
      try {
        console.log('##Llamando a nativo');
        cordova.plugins.BlinkID.scanWithCamera(
          // Register the callback handler
          (cancelled: boolean) => {
            console.log('##Nativo ha devuelto callback', cancelled);
            // handle cancelled scanning
            if (cancelled) {
              console.log('##Ejecutando cancel');
              rej({ type: 'USER_CANCEL' });
              return;
            }
            let frontImageData: any;
            let backImageData: any;
            let faceImage: any;

            let blinkIdResult: any;

            try {
              console.log('##Procesando respuesta');
              if (
                this._recognizer.result.resultState ===
                cordova.plugins.BlinkID.RecognizerResultState.valid
              ) {
                console.debug(
                  'Valid scan',
                  this._recognizer.result.resultState
                );
                // EJEMPLO
                // imageComponent.src = "data:image/jpg;base64, " + resultImageData;

                var resultDocumentFrontImage = this._recognizer.result
                  .fullDocumentFrontImage;
                if (resultDocumentFrontImage) {
                  frontImageData = resultDocumentFrontImage;
                }
                var resultDocumentBackImage = this._recognizer.result
                  .fullDocumentBackImage;
                if (resultDocumentBackImage) {
                  backImageData = resultDocumentBackImage;
                }
                var resultFaceImage = this._recognizer.result.faceImage;
                if (resultFaceImage) {
                  faceImage = resultFaceImage;
                }

                console.log('##Readed images');
                blinkIdResult = this._recognizer.result;
                if (
                  (
                    String(blinkIdResult.mrzResult?.documentType) || ''
                  ).trim() !== '2'
                ) {
                  console.log('##Documento tipo incorrecto');
                  rej({
                    type: 'INVALID_DOCUMENT_TYPE',
                    error: {
                      message: 'The processed document is not correct',
                      data: {
                        documentTypeProcessed:
                          blinkIdResult.mrzResult?.documentType,
                        documentTypeExpected: '2',
                      },
                    },
                  });
                  return;
                }
                console.log('##El documento es de tipo correcto');
                if (
                  !(String(blinkIdResult.personalIdNumber) || '').trim() ||
                  !(
                    String(blinkIdResult.mrzResult?.sanitizedOpt1) || ''
                  ).trim() ||
                  (String(blinkIdResult.personalIdNumber) || '').trim() !==
                    (
                      String(blinkIdResult.mrzResult?.sanitizedOpt1) || ''
                    ).trim()
                ) {
                  console.log('##Lectura incongruente');
                  rej({
                    type: 'UNMATCHED_DATA',
                    error: {
                      message: 'Unmatched data with MRZ',
                      data: {
                        baseId: blinkIdResult.personalIdNumber,
                        mrzId: blinkIdResult.mrzResult?.sanitizedOpt1,
                      },
                    },
                  });
                  return;
                }
                console.debug(
                  'Valores de lectura coincidentes, generando respuesta'
                );
                const toret: ScannResult = {
                  firstName: blinkIdResult.mrzResult?.secondaryId,
                  lastName: blinkIdResult.mrzResult?.primaryId,
                  dni: blinkIdResult.personalIdNumber,
                  sex: blinkIdResult.sex,
                  address: blinkIdResult.address,
                  dateOfBirth: blinkIdResult.dateOfBirth
                    ? new Date(
                        Number(blinkIdResult.dateOfBirth.year),
                        Number(blinkIdResult.dateOfBirth.month) + 1,
                        Number(blinkIdResult.dateOfBirth.day)
                      )
                    : undefined,
                  dateOfExpiry: blinkIdResult.dateOfExpiry
                    ? new Date(
                        Number(blinkIdResult.dateOfExpiry.year),
                        Number(blinkIdResult.dateOfExpiry.month) + 1,
                        Number(blinkIdResult.dateOfExpiry.day)
                      )
                    : undefined,

                  faceImage: faceImage,
                  backImage: backImageData,
                  frontImage: frontImageData,
                  rawNativeData: this._recognizer.result,
                };
                res(toret);
              } else {
                console.log('##Invalid scan', this._recognizer.result);
                rej({
                  type: 'INVALID_SCAN',
                  error: {
                    message: 'Scan invalid',
                    data: this._recognizer.result,
                  },
                });
              }
            } catch (e) {
              console.log('##Error en procesamiento de respuesta');
              rej({
                type: 'NATIVE_ERROR_CALLBACK',
                error: {
                  message: 'Native call returned error',
                  data: e,
                },
              });
            }
          },

          // Register the error callback
          (err: any) => {
            //console.log('Error', err);
            rej({
              type: 'NATIVE_ERROR_CALLBACK',
              error: {
                message: 'Native call returned error',
                data: err,
              },
            });
          },

          this._overlaySettings,
          this._recognizerCollection,
          {
            android: this._androidLicense,
            ios: this._iosLicense,
          }
        );
        console.log('##Llamada nativa realizada');
      } catch (e) {
        console.log('##Error no controlado en la inicialización de la promesa');
        rej({
          type: 'INITIALIZATION_ERROR',
          error: {
            message: 'Native return error before call',
            data: e,
          },
        });
      }
    });
    console.log('##Resultado promesa');
    return result;
  }
}
