import { Logger } from '../../generic/utils';
import { FunctionBuilded, WrapperFunctionsDefinited } from './interfaces';
import { wrapperIdentifier, TestResponse, TestCompletion } from './common';
import { QuestionTypes } from '../TestsWrapper/common';
import moment from 'moment';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadTestResponses = () => void;
const loadTestResponsesBuilder: FunctionBuilded<LoadTestResponses> = (
  [_, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi, user }
) => {
  return async () => {
    if (!user) {
      throw new Error('No existent user');
    }
    let testResponses: TestResponse[] | null;
    try {
      testResponses = await getInstanceApi().getUserLatestResponses(
        user && (user._id || user.id)
      );
      console.log('Loaded testResponses ', testResponses);
    } catch (e) {
      testResponses = null;
    }
    contextSetter('testResponses')(
      testResponses?.map(t => {
        t.createDate = moment(t.createDate).toDate();
        return t;
      })
    );
  };
};

export type LoadTestCompletions = () => void;
const loadTestCompletionsBuilder: FunctionBuilded<LoadTestCompletions> = (
  [_, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi, user }
) => {
  return async () => {
    if (!user) {
      throw new Error('No existent user');
    }
    let testCompletions: TestCompletion[] | null;
    try {
      testCompletions = await getInstanceApi().getUserTestCompletions(
        user && (user._id || user.id)
      );
      console.log('Loaded testCompletions ', testCompletions);
    } catch (e) {
      testCompletions = null;
    }
    contextSetter('testCompletions')(
      testCompletions?.map(t => {
        t.completionDate = moment(t.completionDate).toDate();
        return t;
      })
    );
  };
};

export type SaveResponse = (
  questionId: string,
  value: string | number | boolean | Date
) => void;
const saveResponseBuilder: FunctionBuilded<SaveResponse> = (
  [{ testResponses }, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi, user, tests, testQuestions }
) => {
  return async (questionId, responseValue) => {
    console.log(
      'ºº saving response',
      tests,
      testQuestions,
      questionId,
      responseValue
    );
    const question = (testQuestions || []).find(q => q.id === questionId);
    if (!question) {
      throw new Error('Question not found');
    }
    if (!user) {
      throw new Error('No user present');
    }
    console.log('ºº question founded');
    let procesedResponseValue: string | number | boolean | Date | undefined;
    if (question.type === QuestionTypes.NUMBER) {
      procesedResponseValue = Number(responseValue);
    } else if (question.type === QuestionTypes.BOOLEAN) {
      procesedResponseValue =
        responseValue === 'false' || responseValue === false
          ? false
          : responseValue === 'true' || responseValue === true
          ? true
          : undefined;
    } else if (question.type === QuestionTypes.DATE) {
      procesedResponseValue = moment(
        responseValue instanceof Date
          ? (responseValue as Date)
          : String(responseValue)
      ).toDate();
    } else if (
      question.type === QuestionTypes.STRING ||
      question.type === QuestionTypes.OPTION
    ) {
      procesedResponseValue = String(responseValue);
    }
    const questionType = question.type.replace(
      QuestionTypes.OPTION,
      QuestionTypes.STRING
    ) as 'string' | 'boolean' | 'number' | 'date';

    let actualResponse: TestResponse | undefined;
    actualResponse = (testResponses || [])
      .sort((a, b) => +b.createDate - +a.createDate)
      .find(r => r.testQuestionId === questionId);

    console.log(
      'ºº PROCESED RESPONSE',
      actualResponse,
      questionType,
      procesedResponseValue
    );
    if (actualResponse) {
      if (questionType === 'date') {
        if (
          moment((actualResponse as any)[questionType + 'Value'])
            .toDate()
            .toISOString() ===
          moment(procesedResponseValue as Date)
            .toDate()
            .toISOString()
        ) {
          console.log('ºº SAME DATE SKIP');
          return;
        }
      } else {
        if (
          (actualResponse as any)[questionType + 'Value'] ===
          procesedResponseValue
        ) {
          console.log('ºº SAME VALUE SKIP');
          return;
        }
      }
    }

    let newResponse: TestResponse;
    try {
      const data = {
        [questionType + 'Value']: procesedResponseValue,
      };
      console.log('ºº NEW RESPONSE DATA', data);
      newResponse = await getInstanceApi().responseQuestion(
        user._id || user.id,
        questionId,
        data
      );
    } catch (e) {
      console.log('ºº ERROR SAVING RESPONSE');
      return;
    }

    console.log('ºº RESPONSE CREATED ', newResponse);
    let newResponses = testResponses || [];
    if (actualResponse) {
      newResponses = newResponses.map(r => {
        if (r.testQuestionId !== questionId) return r;
        return newResponse;
      });
    } else {
      newResponses.push(newResponse);
    }
    contextSetter('testResponses')(
      newResponses?.map(t => {
        t.createDate = moment(t.createDate).toDate();
        return t;
      })
    );
  };
};

export type GetResponse = (
  questionIdOrIdentifier: string,
  options?: {
    consolided?: boolean;
  }
) => number | boolean | string | Date | undefined;
const getResponseBuilder: FunctionBuilded<GetResponse> = (
  [{ testResponses }],
  _1,
  _2,
  _3,
  { testQuestions }
) => {
  return (questionIdOrIdentifier, _) => {
    const question = (testQuestions || []).find(
      r =>
        r.id === questionIdOrIdentifier ||
        r.identifier === questionIdOrIdentifier
    );
    if (!question) {
      return undefined;
    }
    const response = (testResponses || [])
      .sort((a, b) => +b.createDate - +a.createDate)
      .find(r => r.testQuestionId === question.id);
    let value;
    if (question.type === QuestionTypes.NUMBER) {
      value = response?.numberValue;
    } else if (question.type === QuestionTypes.BOOLEAN) {
      value = response?.booleanValue;
    } else if (question.type === QuestionTypes.DATE) {
      value = response?.dateValue;
    } else if (
      question.type === QuestionTypes.STRING ||
      question.type === QuestionTypes.OPTION
    ) {
      value = response?.stringValue;
    }
    return value ?? question.default;
  };
};

export type IsTestSettled = (testId: string) => boolean;
const isTestSettledBuilder: FunctionBuilded<IsTestSettled> = (
  _,
  _1,
  _2,
  builder,
  { tests }
) => {
  return (testIdOrIdentifier: string) => {
    const getResponse = builder('getResponse');
    const testQuestions = tests?.find(
      t => t.id === testIdOrIdentifier || t.identifier === testIdOrIdentifier
    )?.testQuestions;
    const someRequired = !!testQuestions?.find(tq => tq.required);

    const questionsMustBeFilled = (testQuestions || []).filter(tq =>
      someRequired ? tq.required : true
    );
    const someQuestionNotAwswered = questionsMustBeFilled.find(
      tq => getResponse(tq.id) === undefined || getResponse(tq.id) === null
    );

    return !someQuestionNotAwswered;
  };
};

export type RegisterTestCompletion = (
  testIdentifier: string
) => Promise<boolean>;
const registerTestCompletionBuilder: FunctionBuilded<RegisterTestCompletion> = (
  [{ testCompletions }, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi, user, tests }
) => {
  return async identifier => {
    const aux = testCompletions || [];
    const test = (tests || []).find(t => t.identifier === identifier);
    if (!user || !test) {
      return false;
    }
    aux.push(
      await getInstanceApi().addTestCompletion(test.id, user._id || user.id)
    );

    contextSetter('testCompletions')(aux);
    return true;
  };
};

export type ValidateTest = (testIdentifier: string) => void;
const validateTestBuilder: FunctionBuilded<ValidateTest> = (
  _,
  _1,
  _2,
  _3,
  { getInstanceApi, user, tests }
) => {
  return async testIdentifier => {
    const test = (tests || []).find(t => t.identifier === testIdentifier);
    if (!user || !test) {
      return;
    }
    await getInstanceApi().validateTest(test.id, user._id || user.id);
  };
};

export const wrapperFunctions: WrapperFunctionsDefinited = [
  {
    name: 'loadTestResponses',
    builder: loadTestResponsesBuilder,
  },
  {
    name: 'loadTestCompletions',
    builder: loadTestCompletionsBuilder,
  },
  {
    name: 'saveResponse',
    builder: saveResponseBuilder,
  },
  {
    name: 'registerTestCompletion',
    builder: registerTestCompletionBuilder,
  },
  {
    name: 'validateTest',
    builder: validateTestBuilder,
  },
  {
    name: 'getResponse',
    builder: getResponseBuilder,
  },
  {
    name: 'isTestSettled',
    builder: isTestSettledBuilder,
  },
];
