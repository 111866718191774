import {
  OnClick,
  TextCurrencyStyle,
  TextCurrencyExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.textCurrency;

export interface ClassProperties {}

export type ComponentStyle = TextCurrencyStyle;
export type ExpectedStyles = TextCurrencyExpectedStyles;
export type Props = GenericComponentProps<TextCurrencyStyle> & {
  onClick?: OnClick;
  decimals?: number;
};
