import { BackendWrapper } from '../../wrappers/BackendWrapper';
import { UserWrapper } from '../UserWrapper';
import { WrapperBuilder } from '../../builders/wrapperBuilder';
import {
  ComponentProps,
  Functions,
  InternalFunctions,
  InternalVariables,
  State,
  Variables,
  Virtuals,
  ExternalFunctions,
  ExternalVariables,
  Statics,
} from './interfaces';

export const wrapperBuilder = new WrapperBuilder<
  ComponentProps,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  Statics,
  typeof BackendWrapper.bindedContext & typeof UserWrapper.bindedContext,
  {}
>('envelopesWrapper', 'envelopesWrapper', [BackendWrapper, UserWrapper]);
