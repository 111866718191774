import React, { FunctionComponent, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { InternationalizationWrapper } from '../../../InternationalizationWrapper';
import { Trans } from '../../../InternationalizationWrapper/export';
import { Text } from '../Text';
import { ClassProperties, ComponentStyle, enumKey, Props } from './interface';

const console = new Logger('button');

if (console) {
  //Just for usage
}

import { Logger } from '../../../../generic/utils';
import {
  applyFunctionalityStyles,
  checkStyleNames,
  generateProperties,
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
} from '../common';
import { componentsOverrides } from '../userOverrides';

const e = React.createElement;

export const Button: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  label,
  children,
  onClick,
  style,
  disabled,
  icon,
  iconPosition = 'left',
  loading,
  ...styleKeys
}) => {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      label,
      children,
      onClick,
      style,
      disabled,
      icon,
      iconPosition,
      loading,
      ...styleKeys,
    });
  }
  checkStyleNames(enumKey, styleKeys);

  // Control functional modes variables
  const [localLoading, setLocalLoading] = useState(false);
  const computedLoading = loading === undefined ? localLoading : loading;

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled, loading: computedLoading }
  );
  // Modificadores de estilo --------------------------------------------

  //Others --------------------------------------------------
  const i18nContext = InternationalizationWrapper.use();
  if (i18nContext) {
    console.log(
      'ºº Button i18n CHILDRENS ' + id,
      /*children,*/ React.Children.toArray(children)
    );
  }

  // Return --------------------------------------------
  if (overrides?.component) {
    return overrides.component({
      _componentStyle: componentStyle,
      styleNames,
      id,
      children: label
        ? i18nContext
          ? e(Trans, { id }, label)
          : label
        : children
        ? i18nContext
          ? e(Trans, { id }, children)
          : children
        : null,
      onClick: async () => {
        if (!computedLoading && !disabled && onClick) {
          setLocalLoading(true);
          await onClick();
          setLocalLoading(false);
        }
      },
      style,
      disabled,
      icon,
      loading: computedLoading,
      ...styleKeys,
    });
  }
  return e(
    'button',
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        true
      ),
      style: {
        ...componentStyle,
      },
      onClick: async () => {
        if (!computedLoading && !disabled && onClick) {
          setLocalLoading(true);
          await onClick();
          setLocalLoading(false);
        }
      },
      className: styleNames,
      disabled,
      id,
    },
    computedLoading
      ? e(CircularProgress, {
          size: 15,
          style: componentStyle.loadingComponent,
        })
      : e(
          React.Fragment,
          undefined,
          iconPosition === 'left' && icon,
          label
            ? e(
                Text,
                {
                  ...generateProperties(parentReference, enumKey, 'text'),
                  style: componentStyle.textComponent,
                },
                label
              )
            : children
            ? e(
                Text,
                {
                  ...generateProperties(parentReference, enumKey, 'text'),
                  style: componentStyle.textComponent,
                },
                children
              )
            : null,
          iconPosition === 'right' && icon
        )
  );
};
