import { ComponentsConfiguration } from '../interfaces';

import React from 'react';

type Components = keyof ComponentsConfiguration;

export const componentsOverrides: {
  [k in Components]?: {
    component: React.FunctionComponent<any>;
    full?: boolean;
  };
} = {};

export const overrideComponent = <T>(
  name: Components,
  full: boolean,
  component: React.FunctionComponent<T>
) => {
  componentsOverrides[name] = { component, full };
};
