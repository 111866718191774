import { Logger, ExternalVar } from '../../generic/utils';
import { FunctionBuilded, WrapperFunctionsDefinited } from './interfaces';
import { wrapperIdentifier } from './common';
import { getMobileOperatingSystem } from './others';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { contextTheme, StatusBarService } from './services/statusBar.service';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

declare var IRoot: ExternalVar;
declare var cordova: ExternalVar;
declare var BuildInfo: ExternalVar;
declare var device: ExternalVar;
declare var networkinterface: ExternalVar;
declare var networkinformation: ExternalVar;
declare var AppVersion: ExternalVar;
export type BindEvents = () => void;
const bindEventsBuilder: FunctionBuilded<BindEvents> = (
  [, contextSetter],
  [, dispatch],
  _
) => {
  return () => {
    try {
      console.log('ºº CORDOVA', cordova);
    } catch (e) {
      contextSetter('isVirtual')(false);
      contextSetter('isRooted')(false);
      contextSetter('deviceReady')(true);
    }
    document.addEventListener(
      'deviceready',
      () => {
        const windowProps: ExternalVar = window;
        try {
          // Cordova Keyboard
          /*windowProps?.Keyboard?.shrinkView(
            props && props.keyboardShrink ? true : false
          );*/
          //windowProps?.Keyboard?.disableScrollingInShrinkView(false);

          // Ionic Keyboard
          windowProps?.Keyboard?.disableScroll(false);
        } catch (e) {
          console.log(' Failed configurating keyboar', e);
        }

        StatusBarService.instance.e(
          contextTheme === 'light' ? 'styleDefault' : 'styleLightContent'
        );
        var os = getMobileOperatingSystem();
        if (os === 'ios' || os === 'android') {
          document.addEventListener('DOMContentLoaded', function() {
            document.body.classList.add('is-' + os);
          });
        }
        IRoot.isRooted(contextSetter('isRooted'), (error: string) => {
          console.log(' ERROR ', error);
        });
        console.log('DEVICE READY', device, AppVersion);
        dispatch(DISPATCH_TYPES.DEVICEREADY);
        const isIos =
          ((device && device.platform && device.platform.toLowerCase()) ||
            os) === 'ios';

        console.log('OS DETECTED', isIos ? 'IOS' : 'ANDROID', device?.platform);
        contextSetter('deviceReady')(true);
        contextSetter('isVirtual')(device?.isVirtual);
        contextSetter('buildInfo')(BuildInfo);
        contextSetter('isIos')(isIos);
        contextSetter('deviceInfo')(device);
        contextSetter('version')(AppVersion);
        console.log('ºº 1-->', networkinterface);

        const checkIpAdresses = () => {
          networkinterface &&
            networkinterface.getWiFiIPAddress(
              (res: ExternalVar) => {
                contextSetter('netInterface')({
                  ip: res.ip,
                  subnet: res.subnet,
                });
                console.log('ºº RES 11', res);
              },
              () => {
                // contextSetter('netInterface')({ ip: res.ip, subnet: res.subnet });
                networkinterface &&
                  networkinterface.getCarrierIPAddress(
                    (res: ExternalVar) => {
                      contextSetter('netInterface')({
                        ip: res.ip,
                        subnet: res.subnet,
                      });
                      console.log('ºº RES 12', res);
                    },
                    () => {
                      // contextSetter('netInterface')({ ip: res.ip, subnet: res.subnet });
                      contextSetter('netInterface')({
                        ip: undefined,
                        subnet: undefined,
                      });
                    }
                  );
              }
            );
        };
        checkIpAdresses();

        console.log(
          'ºº 2-->',
          navigator,
          navigator && (navigator as ExternalVar).connection,
          navigator &&
            (navigator as ExternalVar).connection &&
            (navigator as ExternalVar).connection.type,
          networkinformation
        );
        contextSetter('connection')(networkinformation?.type);
        document.addEventListener(
          'online',
          () => {
            console.log('ºº ONLINE 12');
            contextSetter('online')(() => true);
            checkIpAdresses();
          },
          false
        );
        document.addEventListener(
          'offline',
          () => {
            console.log('ºº OFFLINE 12');
            contextSetter('online')(() => false);
            checkIpAdresses();
          },
          false
        );
      },
      false
    );

    document.addEventListener(
      'resume',
      () => {
        dispatch(DISPATCH_TYPES.RESUME);
      },
      false
    );
  };
};

export type CopyToClipboard = (text: string) => void;
const copyToClipboardBuilder: FunctionBuilded<CopyToClipboard> = () => {
  return text => {
    try {
      if (
        cordova &&
        cordova.plugins &&
        cordova.plugins.clipboard &&
        cordova.plugins.clipboard.copy
      ) {
        cordova.plugins.clipboard.copy(text);
      } else {
        if (navigator.clipboard) {
          navigator.clipboard.writeText(text);
        } else {
          alert('Disabled clipboard must be a https or localhost webpage');
        }
      }
    } catch (e) {
      console.warn('Failed clipboard function', e);
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text);
      } else {
        alert('Disabled clipboard must be a https or localhost webpage');
      }
    }
  };
};
export const wrapperFunctions: WrapperFunctionsDefinited = [
  { name: 'bindEvents', builder: bindEventsBuilder },
  { name: 'copyToClipboard', builder: copyToClipboardBuilder },
];
