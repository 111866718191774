import React, { FunctionComponent } from 'react';

import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { componentsOverrides } from '../userOverrides';

const e = React.createElement;

export const Enfatize: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  children,
  ...props
}) => {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      style,
      children,
      ...props,
    });
  }
  checkStyleNames(enumKey, props);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Inyectar estilos ad hoc -----------------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {}
  );
  if (overrides?.component) {
    return overrides.component({
      _componentStyle: componentStyle,
      styleNames,
      id,
      style,
      children,
      ...props,
    });
  }
  // Return --------------------------------------------------
  return e(
    'span',
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        true
      ),
      style: componentStyle,
      className: `${(styleNames || '').trim()}`,
    },
    children
  );
};
