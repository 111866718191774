import React, { useState, FunctionComponent } from 'react';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloseIcon from '@material-ui/icons/Close';

import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';

import { View } from '../View';
import { Button } from '../Button';
import {
  checkStyleNames,
  getStylesOfUserMergedWithBase,
  applyFunctionalityStyles,
  mergeStylesWithInlineStyles,
  generateProperties,
} from '../common';
import { TextField } from '../TextField';
import { Modal } from '../Modal';
import { Text } from '../Text';
import { Image } from '../Image';
import { ImagesResolver, ImageUrl } from './others/interfaces';
import { PixabayResolver } from './others/providers/PixabayProvider';

const e = React.createElement;

let resolver: ImagesResolver = new PixabayResolver();

export const ImageSelector: FunctionComponent<Props> &
  ClassProperties & { setResolver: (resolver: ImagesResolver) => void } = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  disabled,
  onError,
  captions = {},
  value,
  style,
  defaultImages,
  lengthSearch = 3,
  openerComponent,
  headerComponent,
  SearchFieldProps,
  EmptyImagesTextProps,
  DefaultImageSuggestionTextProps,
  NoResultsTextProps,
  SearchButtonProps,
  ...props
}) => {
  checkStyleNames(enumKey, props);

  const {
    buttonLabel,
    defaultSearch,
    emptyImagesText,
    noResultsText,
    headerText,
    defaultImageSuggestionText,
  } = captions;
  const computedHeaderText = headerText || 'Buscador';
  const computedDefaultSearch = defaultSearch || '';
  const computedLabel = buttonLabel || 'Buscar';
  const computedEmptyImagesText = emptyImagesText || 'Escribe para buscar';
  const computedNoResultsText = noResultsText || 'Sin resultados';
  const computedDefaultImageSuggestionText =
    defaultImageSuggestionText || 'Prueba alguna de estas';

  const [openBuscarFotos, setOpenBuscarFotos] = useState(false);
  const [queryImages, setQueryImages] = useState(computedDefaultSearch || '');
  const [, setShowImages] = useState(false);
  const [images, setImages] = useState<ImageUrl[]>([]);
  const [loading, setLoading] = useState(false);

  // Obtención de estilos principal --------------------------------------------
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {
      disabled,
      empty:
        !images ||
        !images.length || //(!images.length && !defaultImages?.length) ||
        !queryImages ||
        queryImages.length <= lengthSearch,
    }
  );

  async function getListImages(query = '') {
    if (!images || !images.length) {
      setLoading(true);
    }
    if (!query || query.length <= lengthSearch) {
      setShowImages(false);
      setQueryImages(query || '');
      setImages([]);
    } else {
      setImages(await resolver.returnImages(query));
      setShowImages(true);
    }
    setLoading(false);
  }

  return e(
    React.Fragment,
    undefined,
    openerComponent
      ? e(openerComponent, {
          open: () => {
            setQueryImages(computedDefaultSearch);
            setOpenBuscarFotos(true);
            getListImages(computedDefaultSearch);
          },
        })
      : e(
          View,
          {
            ...generateProperties(
              parentReference,
              enumKey,
              'image',
              coreId,
              id
            ),
            onClick: () => {
              setQueryImages(computedDefaultSearch);
              setOpenBuscarFotos(true);
              getListImages(computedDefaultSearch);
            },
            style: {
              backgroundImage: 'url(' + value + ')',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              ...componentStyle.imageComponent,
            },
          },
          e(PhotoCameraIcon, {
            ...generateProperties(
              parentReference,
              enumKey,
              'camera',
              undefined,
              undefined,
              undefined,
              true
            ),
            style: componentStyle.cameraComponent,
          }),
          e(
            Button,
            {
              ...generateProperties(parentReference, enumKey, 'button'),
              style: componentStyle.buttonComponent,
              ...SearchButtonProps,
            },
            computedLabel
          )
        ),
    e(
      Modal,
      {
        ...generateProperties(parentReference, enumKey, 'modal'),
        open: openBuscarFotos,
        onClose: () => setOpenBuscarFotos(false),
        style: componentStyle.modalComponent,
        fullScreen: true,
      },
      e(
        View,
        {
          ...generateProperties(parentReference, enumKey, 'modalContent'),
          style: componentStyle.modalContentComponent,
        },
        headerComponent
          ? e(headerComponent, {
              close: () => {
                setShowImages(false);
                setOpenBuscarFotos(false);
              },
            })
          : e(
              View,
              {
                ...generateProperties(
                  parentReference,
                  enumKey,
                  'headerContainer'
                ),
                style: componentStyle.headerContainerComponent,
              },
              e(
                View,
                {
                  ...generateProperties(
                    parentReference,
                    enumKey,
                    'headerTextContainer'
                  ),
                  style: componentStyle.headerTextContainerComponent,
                },
                e(
                  Text,
                  {
                    ...generateProperties(
                      parentReference,
                      enumKey,
                      'headerText'
                    ),
                    style: componentStyle.headerTextComponent,
                  },
                  computedHeaderText
                )
              ),
              e(CloseIcon, {
                ...generateProperties(
                  parentReference,
                  enumKey,
                  'closeIcon',
                  undefined,
                  undefined,
                  undefined,
                  true
                ),
                style: componentStyle.closeIconComponent,
                color: 'primary',
                onClick: () => {
                  setShowImages(false);
                  setOpenBuscarFotos(false);
                },
              })
            ),
        e(
          View,
          {
            ...generateProperties(parentReference, enumKey, 'searchContainer'),
            style: componentStyle.searchContainerComponent,
          },
          e(TextField, {
            ...generateProperties(parentReference, enumKey, 'searchField'),
            style: componentStyle.searchFieldComponent,
            label: 'Buscar',
            value: queryImages,
            onChange: (event: any) => {
              setQueryImages(event.target.value);
              getListImages(event.target.value);
            },
            ...SearchFieldProps,
          })
        ),
        e(
          View,
          {
            ...generateProperties(
              parentReference,
              enumKey,
              'resultsContainerParent'
            ),
            style: {
              ...componentStyle.resultsContainerParentComponent,
            },
          },

          e(
            View,
            {
              ...generateProperties(
                parentReference,
                enumKey,
                'resultsContainer'
              ),
              key: String((images || []).length),
              style: {
                ...componentStyle.resultsContainerComponent,
                justifyContent:
                  !images ||
                  !images.length ||
                  !queryImages ||
                  queryImages.length <= lengthSearch
                    ? undefined
                    : 'flex-start',
              },
            },
            !queryImages || queryImages.length <= lengthSearch
              ? e(
                  Text,
                  {
                    ...generateProperties(
                      parentReference,
                      enumKey,
                      'emptyImagesText'
                    ),
                    style: componentStyle.emptyImagesTextComponent,
                    ...EmptyImagesTextProps,
                  },
                  computedEmptyImagesText
                )
              : loading
              ? e(
                  View,
                  {
                    cleared: true,
                    style: {
                      position: 'absolute',
                      top: '50%',
                      left: 0,
                      flexShrink: 1,
                      width: '100%',
                      display: 'flex',
                      margin: 0,
                      justifyContent: 'center',
                    },
                  },
                  e(CircularProgress, {})
                )
              : images && images.length
              ? e(
                  React.Fragment,
                  undefined,
                  images.map((image: any, i) => {
                    return e(
                      View,
                      {
                        ...generateProperties(
                          parentReference,
                          enumKey,
                          'resultItem'
                        ),
                        key: image.id || image.url || i,
                        style: componentStyle.resultItemComponent,
                        animation: {
                          exit: {
                            opacity: 0,
                          },
                          initial: {
                            opacity: 0,
                          },
                          animate: {
                            opacity: 1,
                            x: 0,
                          },
                          transition: {
                            duration: 0.4 + i * 0.1,
                          },
                        },
                        onClick: () => {
                          //console.log('changeImage', image.webformatURL);
                          onChange && onChange(image.url);
                          setShowImages(false);
                          setOpenBuscarFotos(false);
                        },
                      },
                      e(Image, {
                        ...generateProperties(
                          parentReference,
                          enumKey,
                          'resultItemImage'
                        ),
                        style: componentStyle.resultItemImageComponent,
                        src: image.url,
                        alt: image.alt || image.url,
                      })
                    );
                  })
                )
              : e(
                  React.Fragment,
                  undefined,
                  e(
                    Text,
                    {
                      ...generateProperties(
                        parentReference,
                        enumKey,
                        'noResultsText'
                      ),
                      style: componentStyle.emptyImagesTextComponent,
                      ...NoResultsTextProps,
                    },
                    computedNoResultsText
                  ),

                  defaultImages &&
                    defaultImages?.length !== 0 &&
                    e(
                      Text,
                      {
                        ...generateProperties(
                          parentReference,
                          enumKey,
                          'defaultImageSuggestionText'
                        ),
                        style:
                          componentStyle.defaultImageSuggestionTextComponent,
                        ...DefaultImageSuggestionTextProps,
                      },
                      computedDefaultImageSuggestionText
                    ),
                  defaultImages &&
                    defaultImages?.length !== 0 &&
                    e(
                      View,
                      {
                        ...generateProperties(
                          parentReference,
                          enumKey,
                          'suggestionsContainer'
                        ),
                        key: String((images || []).length),
                        style: {
                          ...componentStyle.suggestionsContainerComponent,
                          justifyContent:
                            !images ||
                            !images.length ||
                            !queryImages ||
                            queryImages.length <= lengthSearch
                              ? undefined
                              : 'flex-start',
                        },
                      },
                      defaultImages.map((image: any, i) => {
                        const imageUrl = `${process.env.PUBLIC_URL}/${image}`.replace(
                          '//',
                          '/'
                        );
                        return e(
                          View,
                          {
                            ...generateProperties(
                              parentReference,
                              enumKey,
                              'resultItem'
                            ),
                            key: i + '',
                            style: componentStyle.resultItemComponent,
                            animation: {
                              exit: {
                                opacity: 0,
                              },
                              initial: {
                                opacity: 0,
                              },
                              animate: {
                                opacity: 1,
                                x: 0,
                              },
                              transition: {
                                duration: 0.4 + i * 0.1,
                              },
                            },
                            onClick: () => {
                              //console.log('changeImage', image.webformatURL);
                              onChange && onChange(imageUrl);
                              setShowImages(false);
                              setOpenBuscarFotos(false);
                            },
                          },
                          e(Image, {
                            ...generateProperties(
                              parentReference,
                              enumKey,
                              'resultItemImage'
                            ),
                            style: componentStyle.resultItemImageComponent,
                            src: imageUrl,
                            alt: imageUrl,
                          })
                        );
                      })
                    )
                )
          )
        )
      )
    )
  );
};
ImageSelector.setResolver = r => {
  resolver = r;
};
