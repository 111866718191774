import { wrapperBuilder } from './build';
import { defineComponent } from './others/userDefinitions';
import { overrideComponent } from './others/userOverrides';
import { defineMedia } from './others/userMedias';

wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addStaticProp('overrideComponent', overrideComponent);
wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addStaticProp('defineComponent', defineComponent);

wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addStaticProp('defineMedia', defineMedia);
