import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  cameraComponent: {
    fontSize: 50,
    color: _theme?.colors?.main,
  },
  containerComponent: {
    width: '80vw',
    height: '22vh',
    margin: 5,
    overflow: 'hidden',
    borderRadius: 15,
    backgroundColor: 'rgba(0,0,0,.4)',
    border: 'solid 2px ' + _theme?.colors?.main,
  },
  imageContainerComponent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',

    width: '100%',
    height: '100%',
  },
  textComponent: {},
});

export default baseConfiguration;
