import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => {
  const toret: ComponentStyle = {
    containerComponent: {
      //width: '100%',
    },
    inputComponent: {
      color: _theme?.colors?.defaultFontColor,
    },
    iconComponent: {
      color: _theme?.colors?.defaultFontColor,
    },
    labelComponent: {
      color: _theme?.colors?.defaultFontColor,
    },
    currentDayComponent: {
      fontWeight: 800,
    },
  };
  return toret;
};

export default baseConfiguration;
