import qs from 'qs';
import React, { FunctionComponent, useEffect, useReducer } from 'react';

import { useProvider } from '../../generic/use';
import { extractValue, generateContext, generateVariables, Logger, wrapProvider } from '../../generic/utils';
import { setSplashScreen } from '../UiWrapper/native';
import { wrapperIdentifier } from './common';
import { wrapperFunctions } from './functions';
import {
  BindedWrapperProperties,
  ComponentWrapperProps,
  Context,
  ContextFunctions,
  ContextState,
  ContextVariables,
  InternalContextFunctions,
  Statics,
} from './interfaces';
import { getPersistedState, reducer, registerReducer } from './mainReducer';
import { initialize } from './native';
import { tagCreation } from './native/tags';
import { getMobileOperatingSystem } from './others';
import { setContextTheme } from './services/statusBar.service';
import { stateVariables } from './variables';

//import { useInitialize } from "../../generic/use";
declare var window: any;
declare var _cordovaNative: any;

export interface InitOptions {
  projectName: string; // obligatorio
  projectEnv?: string; // optional
  awsRegion?: string;
  splashBackground?: string; //splashScreen
  disableNative: boolean;
  logo?: string; //logo
  contextTheme?: 'dark' | 'light'; // aspecto de aplicacion
  onStartupParams?: (params: { [k: string]: any }) => void;
  disableEnvs?: boolean;
}

export let isNative = false;

export let projectName: string =
  window.__CLIENT_ENV?.CLIENT_ENV_PROJECT_NAME || '';
export let projectEnv: string =
  window.__CLIENT_ENV?.CLIENT_ENV_PROJECT_ENV || '';
export let awsRegion: string = window.__CLIENT_ENV?.CLIENT_ENV_AWS_REGION || '';

// overrides de default "console.log" with a custom logger
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const ApplicationWrapperContext = React.createContext<Context>(
  undefined as any
);

let initialValidationsDoned = false;
let applicationInited = false;
let inited = false;
export const ApplicationWrapper: FunctionComponent<ComponentWrapperProps> & {
  use: () => Context;
  init: (options: InitOptions) => void;
} & Statics = function Component({ children, ...props }) {
  if (!initialValidationsDoned) {
    initialValidationsDoned = true;
    if (!applicationInited) {
      throw new Error(
        'Application must be inited. Please call ApplicationWrapper.init() method properly previous any render of the app. (Recommended in index.js or App.js of src)'
      );
    } else if (!projectName) {
      throw new Error(
        'No project name specified. Please provide one in ApplicationWrapper.init() method.'
      );
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    ...getPersistedState(),
    ...getPersistedState()._persisted,
  });

  const appState = extractValue(state, ...[wrapperIdentifier]);
  const variables = generateVariables(wrapperIdentifier, stateVariables, [
    appState,
    dispatch,
  ]);
  const context = generateContext<
    InternalContextFunctions,
    ContextFunctions,
    {},
    ContextVariables,
    ContextState,
    ComponentWrapperProps,
    BindedWrapperProperties
  >(
    wrapperIdentifier,
    variables,
    wrapperFunctions,
    [state, dispatch],
    props,
    {}
  );

  useEffect(() => {
    if (!inited) {
      inited = true;
      console.log('INITING Application Wrapper', state);
      context.bindEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  context.isNative = isNative;
  context.registerReducer = registerReducer;
  context.dispatch = dispatch;
  context.state = state || {};
  console.log(' WRAPPING Application Wrapper', /*JSON.stringify*/ context);
  return context.deviceReady
    ? wrapProvider(ApplicationWrapperContext, context)(children)
    : (console.logValue('', 'ºº APPLICATION NOT READY YET') as any);
};

ApplicationWrapper.use = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useProvider(ApplicationWrapperContext);
};

//Initialization projectName
ApplicationWrapper.projectName = projectName;
ApplicationWrapper.projectEnv = projectEnv;
ApplicationWrapper.awsRegion = awsRegion;

ApplicationWrapper.init = ({
  projectName: PN,
  projectEnv: PENV,
  awsRegion: AWSR,
  logo,
  splashBackground,
  disableNative,
  onStartupParams,
}: InitOptions) => {
  ApplicationWrapper.projectName = PN;
  ApplicationWrapper.projectEnv = PENV;
  ApplicationWrapper.awsRegion = AWSR;
  applicationInited = true;
  if (PN && typeof PN === 'string' && /^[a-z0-9]*$/) {
    projectName = PN;
  } else {
    throw new Error(
      'Project Name specification error. Must be a string, reg. exp: /^[a-z0-9]*$/'
    );
  }
  if (PENV && typeof PENV === 'string') {
    projectEnv = PENV;
  }
  if (AWSR && typeof AWSR === 'string') {
    awsRegion = AWSR;
  }

  setContextTheme(/*CT || */ 'dark'); // Debido a un cambio en el html siempre va a ser fondo negro
  setSplashScreen(splashBackground || logo, !splashBackground);
  var os = getMobileOperatingSystem();
  try {
    if (
      !disableNative &&
      localStorage.getItem('disableCordova') !== 'true' &&
      ((os === 'android' && _cordovaNative) ||
        (os === 'ios' && (window as any).Ionic))
    ) {
      console.log('## CORDOVA ANDROID ON');

      // Tags Injection
      tagCreation();
      // CORDOVA INJECTION
      console.log('PREV');
      console.log('POST', os);
      isNative = true;
      console.log('IN');
      initialize(os);
    } else {
      throw new Error('No cordova');
    }
  } catch (e) {
    tagCreation(logo);
    console.log('## CORDOVA ANDROID OFF');
  } finally {
    // Scripts Injection
  }

  try {
    if (onStartupParams) {
      onStartupParams(
        qs.parse(window.location.search, {
          ignoreQueryPrefix: true,
        })
      );
    }
  } catch (e) {}
};
