export const wrapperIdentifier = 'targets';

export interface TargetProperty {
  name: string;
  value: any;
}

export interface Target {
  id: string;
  targetTypeId: string;
  targetProperties?: TargetProperty[];
  image: string;
  name: string;
  progress?: any;
}

export interface RecommendedSaving {
  daily: number;
  weekly: number;
  monthly: number;
  yearly: number;
}
