import $mediaQueries from './mediaQuery';
import hyphenate from 'hyphenate-style-name';

const negate = (cond: string) => `not ${cond}`;

const keyVal = (k: string | number, v: boolean | string | number) => {
  const realKey = hyphenate(k);

  // px shorthand
  if (typeof v === 'number') {
    v = `${v}px`;
  }
  if (v === true) {
    return realKey;
  }
  if (v === false) {
    return negate(realKey);
  }
  return `(${realKey}: ${v})`;
};

const join = (conds: any) => conds.join(' and ');

const toQuery = (obj: any) => {
  const rules: any = [];
  Object.keys($mediaQueries.all).forEach(k => {
    const v = obj[k];
    if (v != null) {
      rules.push(keyVal(k, v));
    }
  });
  return join(rules);
};

export default toQuery;
