import { VirtualsGenerated } from './interfaces';
import { Logger } from '../../generic/utils';
import { wrapperIdentifier, TestQuestion } from './common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export const virtualsGenerator: VirtualsGenerated = (
  { tests, ...context },
  _
) => {
  return {
    ...context,
    tests,
    testQuestions:
      tests &&
      tests.reduce(
        (acc, act) => [...acc, ...act.testQuestions],
        [] as TestQuestion[]
      ),
  };
};
