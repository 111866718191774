import { WrapperVariablesDefinition } from '../../generic/utils';

import { VariableTypes } from './interfaces';
import { AutomaticOperationsProcessMode } from './common';

const variables: WrapperVariablesDefinition<VariableTypes> = {
  operationsPendingToRun: {
    startValue: undefined,
  },
  currentPendingOperationId: {
    startValue: undefined,
  },
  operations: {
    startValue: undefined,
  },
  operationsHandlers: {
    startValue: {},
  },
  operationsHandled: {
    startValue: undefined,
  },
  operationsProcessMode: {
    startValue: AutomaticOperationsProcessMode.USER,
  },
};

export const stateVariables = variables;
