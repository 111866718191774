import React from 'react';
import { SliderStyle } from '../../propsDefinition';

const e = React.createElement;

export const MobileStepperCustom = React.forwardRef(
  (comProps: { style: SliderStyle; [k: string]: any }, ref: any) => {
    const { activeStep = 0, steps, style, onClickStep, ...props } = comProps;

    const dots = Array(steps)
      .fill(undefined)
      .map((_, index) => {
        return e(
          'div',
          {
            key: index,
            onClick: () => onClickStep && onClickStep(index),
            style: {
              ...style?.dotComponent,
              ...(index === activeStep ? style?.dotActiveComponent : {}),
            },
          },
          null
        );
      });

    return e(
      'div',
      { ...props, ref, style: style?.dotsContainerComponent },
      e('div', { style: { display: 'flex', flexDirection: 'row' } }, ...dots)
    );
  }
);
