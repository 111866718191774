import { NavigationScreens } from '../interfaces';
import { wrapperIdentifier } from '../common';
import { Logger } from '../../../generic/utils';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export const extractScreens = (
  parentPath: string,
  currentElement: any,
  screens: NavigationScreens
) => {
  const isPage =
    currentElement.type &&
    (currentElement.type.name === 'Page' || currentElement.type.page);
  const isModule =
    currentElement.type &&
    (currentElement.type.name === 'Module' || currentElement.type.module);
  const name = currentElement.props && currentElement.props.name;
  const path = currentElement.props && currentElement.props.path;
  const currentPath =
    parentPath +
    `${isModule && path ? path : ''}` +
    `${isPage && path ? path : name ? '/' + name : ''}`;

  if (isPage && name) {
    screens[name] = {
      path: (currentPath || '').replace('//', '/'),
    };
  }
  //currentElement.props.exact = true
  if (currentElement.props && currentElement.props.children) {
    if (Array.isArray(currentElement.props.children)) {
      currentElement.props.children.forEach((c: any) => {
        extractScreens(currentPath, c, screens);
      });
    } else {
      extractScreens(currentPath, currentElement.props.children, screens);
    }
  } else {
    return {};
  }

  return;
};

export const isPageOrHaveChildrenPage = (currentElement: any): boolean => {
  if (currentElement.type && currentElement.type.name === 'Page') {
    return true;
  }
  if (!currentElement.props || !currentElement.props.children) {
    return false;
  }
  if (Array.isArray(currentElement.props.children)) {
    let hasPage = false;
    currentElement.props.children.forEach((c: any) => {
      !hasPage && (hasPage = isPageOrHaveChildrenPage(c));
    });
    return hasPage;
  } else {
    return isPageOrHaveChildrenPage(currentElement.props.children);
  }
};
