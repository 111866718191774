export const wrapperIdentifier = 'testsWrapper';

export enum QuestionTypes {
  NUMBER = 'number',
  STRING = 'string',
  OPTION = 'option',
  BOOLEAN = 'boolean',
  DATE = 'date',
}

export interface TestQuestion {
  identifier: string;
  id: string;
  required?: boolean;
  order?: number;
  type: QuestionTypes;
  default?: string;
}
export interface TestRestriction {
  primaryQuestionIdentifier: string;
  secondaryQuestionIdentifier: string;
  primaryQuestionValue: string;
  secondaryQuestionValue: string;
}

export interface Test {
  identifier: string;
  id: string;
  testQuestions: TestQuestion[];
  testRestrictions?: TestRestriction[];
}
