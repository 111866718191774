import { wrapperBuilder } from './build';

const virtualsRegister = wrapperBuilder.getVirtualsGeneratorsRegisterInstance();

virtualsRegister.addVirtualsGenerator(wrapperEnvironment => {
  return {
    stylesStriginfy:
      JSON.stringify(wrapperEnvironment.variables.styles) +
      ' ' +
      wrapperEnvironment.bindedContexts.aditionalMessage,
  };
});
