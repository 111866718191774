import { LogicFunctionDefinited } from './interfaces';
import { Logger, UserInput } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';
import { SESSION_STATUSES } from '../../wrappers/SessionWrapper/others';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

let lastToken: string | undefined = undefined;
const useLogic: LogicFunctionDefinited = (
  _1,
  _2,
  [{ loadTests, tests }, contextSetter],
  { sessionData, sessionStatus }
) => {
  const token = sessionData && (sessionData as UserInput).token;

  useEffect(() => {
    if (sessionStatus === SESSION_STATUSES.CREATED) {
      if (token && token !== lastToken) {
        lastToken = token;
        console.log('Loading tests');
        loadTests();
      }
    } else if (sessionStatus === SESSION_STATUSES.NOT_CREATED) {
      lastToken = undefined;
      if (tests) {
        contextSetter('tests')(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, sessionStatus]);
};
export default useLogic;
