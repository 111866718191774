/* eslint-disable no-unused-vars */
import { makeStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { Logger } from '../../../../generic/utils';
import { InternationalizationWrapper } from '../../../InternationalizationWrapper';
import { generateMaterialStyles } from '../../common';
import {
  applyFunctionalityStyles,
  checkStyleNames,
  generateProperties,
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
} from '../common';
import { Text } from '../Text';
import { ClassProperties, ComponentStyle, enumKey, Props } from './interface';

const console = new Logger('inputDate');

if (console) {
  //Just for usage
}

const e = React.createElement;

const useContainerStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
});

const useIconStyles = makeStyles({
  adornmentContainer: (props: ComponentStyle) =>
    generateMaterialStyles('iconContainerComponent', props, false, [
      '& .MuiIconButton-root',
    ]),
  adornment: (props: ComponentStyle) =>
    generateMaterialStyles('iconComponent', props, false, [
      '& .MuiSvgIcon-root',
    ]),
});

const useInputStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('inputContainerComponent', props),
  input: (props: ComponentStyle) =>
    generateMaterialStyles('inputComponent', props),
});

const useLabelStyles = makeStyles({
  // style rule
  root: (props: ComponentStyle) =>
    generateMaterialStyles('labelComponent', props, false, [
      '&.MuiInputLabel-marginDense',
    ]),
});

export const InputDate: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  label,
  style,
  onChange,
  value,
  maxDate,
  minDate,
  disableUnderline = true,
  error,
  format = 'DD/MM/YYYY',
  defaultValue,
  disabled,
  placeholder,
  pickerRef,
  dateIcon,
  readOnly,
  toolbarComponent,
  pickOnSelect = true,
  open,
  onOpen,
  fullClickable,
  onClick,
  onClose,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  const [internalValue, setInternalValue] = useState<any>(
    !value ? undefined : moment(value, format)
  );
  const [focused, setFocused] = useState(false);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {
      noLabel: !label,
      filled: internalValue !== null,
      focused,
      error: !!error,
      disabled,
    }
  );

  // Generación de estilos material basados en estilos UiWrapper
  const materialContainer = useContainerStyles(componentStyle);
  const materialInput = useInputStyles(componentStyle);
  const materialIcon = useIconStyles(componentStyle);
  const materialLabel = useLabelStyles(componentStyle);

  // Otros ----------------------------------------------------------------
  const values = {
    value,
    inputValue:
      typeof internalValue?._i === 'string' && !internalValue?._isValid
        ? internalValue?._i
        : undefined,
  };

  const [opened, setOpened] = useState(false);

  const t =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use().t) ||
    ((k: any) => k);

  const tHasObjectStructure =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use()?.hasObjectStructure) ||
    false;

  console.log(
    'InternationalizationWrapper.use()',
    InternationalizationWrapper.use()
  );
  console.log('tHasObjectStructure', tHasObjectStructure);

  // Return -----------------------------------------------------
  return e(KeyboardDatePicker, {
    // Pendiente añadir generacion de propiedades
    margin: 'dense',
    inputVariant: InputDate.variant || 'filled',
    variant: InputDate.mode || 'dialog',
    label: t(label),
    disabled,
    maxDate,
    minDate,
    error: !!error,
    invalidLabel: t(error),
    helperText: t(error),
    placeholder: t(placeholder),
    pickerRef,
    format,
    ...values,
    cancelLabel: e(
      Text,
      { style: componentStyle.actionTextComponent },
      tHasObjectStructure ? 'core.inputDate.cancelar' : 'Cancelar'
    ),
    clearLabel: e(
      Text,
      { style: componentStyle.actionTextComponent },
      tHasObjectStructure ? 'core.inputDate.borrar' : 'Borrar'
    ),
    okLabel: e(
      Text,
      { style: componentStyle.actionTextComponent },
      tHasObjectStructure ? 'core.inputDate.aceptar' : 'Aceptar'
    ),
    clearable: true,
    autoOk: pickOnSelect,
    invalidDateMessage: tHasObjectStructure
      ? t(
          'core.inputDate.formatoDeFechaIncorrecto',
          'Formato de fecha incorrecto.'
        )
      : t('Formato de fecha incorrecto.'),
    maxDateMessage:
      maxDate instanceof Date
        ? tHasObjectStructure
          ? t('core.inputDate.fechaMaxima', 'La fecha máxima es ')
          : t('La fecha máxima es ') +
            maxDate.getDate() +
            '/' +
            (maxDate.getMonth() + 1) +
            '/' +
            maxDate.getFullYear()
        : null,
    minDateMessage:
      minDate instanceof Date
        ? tHasObjectStructure
          ? t('core.inputDate.fechaMinima', 'La fecha mínima es ')
          : t('La fecha mínima es ') +
            minDate.getDate() +
            '/' +
            (minDate.getMonth() + 1) +
            '/' +
            minDate.getFullYear()
        : null,
    defaultValue,
    open: !readOnly ? (open !== undefined ? open : opened) : false,
    onClick: () => {
      onClick && onClick();
      if (fullClickable) {
        onOpen && onOpen();
        setOpened(true);
      }
    },
    onOpen: () => {
      onOpen && onOpen();
      setOpened(true);
    },
    onClose: () => {
      onClose && onClose();
      setOpened(false);
    },
    onFocus: () => {
      setFocused(true);
    },
    onBlur: () => {
      setFocused(false);
    },
    onChange: (e: any) => {
      const date = e && e.toDate();
      if (date instanceof Date && !isNaN(date.getTime())) {
        setInternalValue(undefined);
        onChange && onChange(date);
      } else {
        setInternalValue(e);
        onChange && onChange(undefined);
      }
    },
    className: materialContainer.root,
    InputAdornmentProps: {
      className: materialIcon.adornmentContainer + ' ' + materialIcon.adornment,
    } as any,
    keyboardIcon: dateIcon,
    ToolbarComponent: toolbarComponent
      ? (props: any) =>
          e(toolbarComponent, {
            ...props,
            close: () => {
              onClose && onClose();
              setOpened(false);
            },
          })
      : undefined,
    InputProps: {
      classes: materialInput,
      readOnly,
      disableUnderline,
      ...generateProperties(
        parentReference,
        enumKey,
        'inputContainer',
        undefined,
        undefined,
        undefined,
        true
      ),
      inputProps: {
        ...generateProperties(
          parentReference,
          enumKey,
          'input',
          undefined,
          id,
          undefined,
          true
        ),
      },
    },
    InputLabelProps: {
      ...generateProperties(
        parentReference,
        enumKey,
        'label',
        undefined,
        undefined,
        undefined,
        true
      ),
      shrink: internalValue !== null || focused,
      classes: materialLabel,
    },
  } as any);
};

InputDate.variant = 'filled';
InputDate.mode = 'dialog';
