import moment from 'moment';

export const institutionAccountsMapper = <T>(
  institutionAccountsReceived: T
): T => {
  return (institutionAccountsReceived as any).map((i: any) => {
    const orderedCredentials = i?.credentials?.sort(
      (a: any, b: any) =>
        +moment(a.createDate).toDate() - +moment(b.createDate).toDate()
    );

    const validCredentials = orderedCredentials?.filter(
      (credential: any) => credential.areInvalidCredentials === false
    );

    const lastValidCredential = validCredentials?.[validCredentials.length - 1];

    const lastCredential =
      lastValidCredential ||
      orderedCredentials?.[orderedCredentials.length - 1];

    return {
      ...i,
      invalidCredentials: lastCredential
        ? lastCredential.areInvalidCredentials
        : true,
      image:
        'https://s3.eu-central-1.amazonaws.com/com.coinscrap.others/public/banks/icons/' +
        i.institutionIdentifier +
        '.png',
    };
  });
};
