/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { RadioGroup } from '@material-ui/core';

const e = React.createElement;

export const InputRadio: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  children,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled }
  );

  // Otros ----------------------------------------------------------------

  // Return -----------------------------------------------------
  return e(
    RadioGroup,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        undefined,
        true
      ),
      value: value,
      color: 'primary',
      className: styleNames,
      onChange: onChange,
      style: componentStyle,
    },
    children
  );
};
