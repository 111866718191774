import React, { FunctionComponent } from 'react';

import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { View } from '../View';
import { Text } from '../Text';
import { componentsOverrides } from '../userOverrides';

const e = React.createElement;

export const TextCurrency: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  children,
  onClick,
  decimals = 2,
  ...props
}) => {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      style,
      children,
      onClick,
      decimals,
      ...props,
    });
  }
  checkStyleNames(enumKey, props);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Inyectar estilos ad hoc -----------------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { clickable: !!onClick }
  );

  // Others --------------------------------------------------
  let decimalPart = '00';
  let procesedChildren = '0';
  if (typeof children === 'number') {
    let currency = Intl.NumberFormat('es-ES', {
      useGrouping: true,
      maximumFractionDigits: decimals ? decimals : 2,
      minimumFractionDigits: decimals ? decimals : 2,
    }).format(children);

    let splitNum = (currency + '').split(',');
    procesedChildren = splitNum[0];
    decimalPart = splitNum[1];
  } else {
    console.error('El contenido recibido en textCurrency no es un número');
  }
  // Return --------------------------------------------------
  if (overrides?.component) {
    return overrides.component({
      _componentStyle: componentStyle,
      styleNames,
      id,
      style,
      children,
      onClick,
      decimals,
      entire: procesedChildren,
      decimal: decimals === 0 ? ' €' : ',' + decimalPart + ' €',
      ...props,
    });
  }
  return e(
    View,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id
      ),
      style: componentStyle.containerComponent,
      onClick,
    },
    e(
      Text,
      {
        ...generateProperties(parentReference, enumKey, 'entire'),
        noT: true,
        style: componentStyle.entireComponent,
      },
      procesedChildren
    ),
    e(
      Text,
      {
        ...generateProperties(parentReference, enumKey, 'decimal'),
        noT: true,
        style: componentStyle.decimalComponent,
      },
      decimals === 0 ? ' €' : ',' + decimalPart + ' €'
    )
  );
};
