import React, { FunctionComponent } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  applyFunctionalityStyles,
  generateProperties,
} from '../common';
import { componentsOverrides } from '../userOverrides';

const e = React.createElement;

export const Image: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  filename,
  onClick,
  src,
  ext,
  alt,
  width,
  height,
  className,
  style,
  ...props
}) => {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides?.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      filename,
      onClick,
      src,
      ext,
      alt,
      width,
      height,
      className,
      style,
      ...props,
    });
  }
  checkStyleNames(enumKey, props);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Inyectar estilos ad hoc -----------------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {}
  );

  if (width) {
    componentStyle.width = width;
  }

  if (height) {
    componentStyle.height = height;
  }

  // Otros ----------------------------------------------------------------
  if (String(filename).indexOf('/') === -1) {
    filename = '/images/' + filename;
    if (String(filename).indexOf('.') === -1) {
      filename = filename + '.' + (ext || 'png');
    }
  }

  // Return --------------------------------------------------
  if (overrides?.component) {
    return overrides.component({
      _componentStyle: componentStyle,
      styleNames,
      id,
      onClick,
      src: src || filename,
      alt: alt || filename,
      width,
      height,
      className,
      style,
      ...props,
    });
  }
  return e('img', {
    ...generateProperties(
      parentReference,
      enumKey,
      componentReference,
      coreId,
      id,
      true
    ),
    onClick,
    alt: alt || filename,
    src: src || filename,
    className: `${className ? className + ' ' : ''}${(
      styleNames || ''
    ).trim()}`,
    style: componentStyle,
  });
};
