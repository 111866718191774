import React, { FunctionComponent } from 'react';

import { Props, enumKey, ComponentStyle, ClassProperties } from './interface';
import { View } from '../View';
import { Dialog, CircularProgress, makeStyles } from '@material-ui/core';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  generateProperties,
} from '../common';

const useStyles = makeStyles({
  // style rule
  colorPrimary: (props: ComponentStyle) => ({
    color: props.loaderColor,
  }),
});

const e = React.createElement;

export const Loading: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  ...styleKeys
}) => {
  //checkStyleNames(enumKey, props);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = mergeStylesWithInlineStyles<ComponentStyle>(
    mainStyle,
    style || {}
  );

  // Generación de estilos material basados en estilos UiWrapper
  const material = useStyles(componentStyle);

  // Return ---------------------------------------------------
  return e(
    Dialog,
    {
      key: 'loading',
      open: true,
      fullScreen: true,
      fullWidth: true,
      PaperProps: {
        style: {
          background: 'transparent',
        },
      },
      keepMounted: true,
      'aria-labelledby': 'alert-dialog-slide-title',
      'aria-describedby': 'alert-dialog-slide-description',
    },
    e(
      View,
      {
        ...generateProperties(parentReference, enumKey, 'container'),
        style: {
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          background: componentStyle.background,
          backgroundColor: componentStyle.backgroundColor,
        },
      },
      e(CircularProgress, {
        size: componentStyle.loaderSize || 70,
        className: material.colorPrimary,
      })
    )
  );
};
