/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import {
  Switch,
  FormControl,
  FormHelperText,
  makeStyles,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { generateMaterialStyles } from '../../common';
import { Logger } from '../../../../generic/utils';
import { InternationalizationWrapper } from '../../../InternationalizationWrapper';

const console = new Logger('switch');

if (console) {
  //Just for usage
}
const e = React.createElement;

const useContainerStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
});

const useLabelStyles = makeStyles({
  label: (props: ComponentStyle) =>
    generateMaterialStyles('labelComponent', props),
});

const useSwitchtStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('switchContainerComponent', props),
  switchBase: (props: ComponentStyle) =>
    generateMaterialStyles('switchComponent', props, false, [
      ['trackComponent', '&.Mui-checked + .MuiSwitch-track'],
    ]),
  thumb: (props: ComponentStyle) =>
    generateMaterialStyles('thumbComponent', props),
  track: (props: ComponentStyle) =>
    console.logValue(
      generateMaterialStyles(
        'trackComponent',
        console.logValue(props, 'ºº TRACK')
      ),
      'ºº RETURNED TRACK'
    ),
});

export const InputSwitch: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  label,
  children,
  error,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled, error: !!error, checked: !!value || false }
  );

  // Otros ----------------------------------------------------------------

  // Generación de estilos material basados en estilos UiWrapper
  const materialContainer = useContainerStyles(componentStyle);
  const materialSwitch = useSwitchtStyles(componentStyle);
  const materialLabel = useLabelStyles(componentStyle);

  const t =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use().t) ||
    ((k: any) => k);

  // Return -----------------------------------------------------
  return e(
    FormControl,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        true
      ),
      classes: materialContainer,
      error: !!error,
      margin: 'dense',
      variant: InputSwitch.variant || 'filled',
    },
    e(
      FormGroup,
      {},
      label
        ? e(FormControlLabel, {
            control: e(
              Switch,
              {
                onChange: v => {
                  console.log('switch onchange', v, v.target.checked);
                  onChange && onChange(v.target.checked);
                },
                disabled,
                checked: value || false,
                color: 'primary',
                disableRipple: true,
                classes: materialSwitch,
              },
              children
            ),
            label: t(label),
            disabled,
            classes: materialLabel,
          })
        : e(
            Switch,
            {
              onChange: v => {
                console.log('switch onchange', v, v.target.checked);
                onChange && onChange(v.target.checked);
              },
              disabled,
              color: 'primary',
              checked: value || false,
              disableRipple: true,
              classes: materialSwitch,
            },
            children
          )
    ),
    error && e(FormHelperText, {}, t(error))
  );
};

InputSwitch.variant = 'filled';
