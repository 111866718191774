import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  selectComponent: {
    flexShrink: 1,
    color: _theme?.colors?.defaultFontColor,
    width: '100%',
  },
  labelComponent: {
    color: _theme?.colors?.defaultFontColor,
  },
  iconComponent: {
    color: _theme?.colors?.main,
  },
});

export default baseConfiguration;
