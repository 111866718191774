import {
  Width,
  GenericComponentProps,
  SidebarStyle,
  SidebarExpectedStyles,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.sidebar;

export interface ClassProperties {}

export type ComponentStyle = SidebarStyle;
export type ExpectedStyles = SidebarExpectedStyles;
export type Props = GenericComponentProps<SidebarStyle> & {
  width?: Width;
  open?: boolean;
  content?: any;
  onClose?: () => void;
  closeOnClick?: boolean;
};
