// eslint-disable-next-line no-unused-vars
import React, { useState, SetStateAction } from 'react';
import { ActionObject, ActionText } from './interfaces';

export const realLog = window.console.log;
export const realInfo = window.console.info;
export const realWarn = window.console.warn;
export const realError = window.console.error;

export class Logger {
  private static disabledFilenameLogs: string[] = ['config'];
  private static enabledFilenameLogs: string[] = [];

  static disableKey(string: string) {
    Logger.enabledFilenameLogs = Logger.enabledFilenameLogs.filter(
      k => string !== k
    );
    Logger.disabledFilenameLogs.push(string);
  }
  static enableKey(string: string) {
    Logger.disabledFilenameLogs = Logger.disabledFilenameLogs.filter(
      k => string !== k
    );
    Logger.enabledFilenameLogs.push(string);
  }

  private static _levelsDisabled: string[] = [];
  static toggle(
    targetLevel: 'log' | 'warn' | 'info' | 'error' | 'all',
    status: 'on' | 'off'
  ) {
    if (status === 'off') {
      Logger._levelsDisabled.push(targetLevel);
    }
    if (status === 'on') {
      Logger._levelsDisabled = Logger._levelsDisabled.filter(
        level => level !== targetLevel
      );
      if (targetLevel === 'all') {
        Logger._levelsDisabled = [];
      }
    }
  }

  static checkEnvVars() {
    const windowVars: any = window;
    return (
      windowVars &&
      windowVars.__CLIENT_ENV &&
      windowVars.__CLIENT_ENV.CLIENT_ENV_LOGS_ENABLED === 'true'
    );
  }

  static checkLevelEnabled(level: string) {
    return !Logger._levelsDisabled.find(l => l === 'all' || l === level);
  }

  private static checkGlobal(level: string) {
    const enabled = Logger.checkEnvVars() && Logger.checkLevelEnabled(level);
    return enabled;
  }

  static debug(...args: any[]) {
    return !Logger.checkGlobal('debug') ? null : realLog(...args);
  }
  static log(...args: any[]) {
    return !Logger.checkGlobal('log') ? null : realLog(...args);
  }
  static info(...args: any[]) {
    return !Logger.checkGlobal('info') ? null : realInfo(...args);
  }
  static warn(...args: any[]) {
    return !Logger.checkGlobal('warn') ? null : realWarn(...args);
  }
  static error(...args: any[]) {
    return !Logger.checkGlobal('error') ? null : realError(...args);
  }

  constructor(private filename: string) {}

  private checkHighPriority(...args: any[]) {
    return args.find(l => typeof l === 'string' && l.indexOf('##') >= 0);
  }

  private checkFilename() {
    if (Logger.enabledFilenameLogs) {
      return Logger.enabledFilenameLogs.indexOf(this.filename) >= 0;
    }
    return Logger.disabledFilenameLogs.indexOf(this.filename) < 0;
  }

  logValue<T>(value: T, ...args: any[]) {
    this.log(...args, value);
    return value;
  }

  log(...args: any[]) {
    if (this.checkHighPriority(...args)) {
      return Logger.log(...args);
    } else {
      if (this.checkFilename()) {
        return this._log(...args);
      }
    }
  }
  private _log(...args: any[]) {
    Logger.log(...args);
  }

  info(...args: any[]) {
    if (this.checkHighPriority(...args)) {
      return Logger.info(...args);
    } else {
      if (this.checkFilename()) {
        return this._info(...args);
      }
    }
  }
  private _info(...args: any[]) {
    Logger.info(...args);
  }

  warn(...args: any[]) {
    if (this.checkHighPriority(...args)) {
      return Logger.warn(...args);
    } else {
      if (this.checkFilename()) {
        return this._warn(...args);
      }
    }
  }
  private _warn(...args: any[]) {
    Logger.warn(...args);
  }
  error(...args: any[]) {
    if (this.checkHighPriority(...args)) {
      return Logger.error(...args);
    } else {
      if (this.checkFilename()) {
        return this._error(...args);
      }
    }
  }
  private _error(...args: any[]) {
    Logger.error(...args);
  }

  debug(...args: any[]) {
    if (this.checkHighPriority(...args)) {
      return Logger.debug(...args);
    } else {
      if (this.checkFilename()) {
        return this._debug(...args);
      }
    }
  }
  private _debug(...args: any[]) {
    Logger.debug(...args);
  }
}

realLog('LOGS ENABLED', Logger.checkEnvVars(), Logger.checkLevelEnabled('log'));

if (!Logger.checkEnvVars()) {
  window.console = {
    ...window.console,
    log: Logger.log,
    info: Logger.info,
    warn: Logger.warn,
    error: Logger.error,
  };

  (window as any).realConsole = {
    log: realLog,
    info: realInfo,
    warn: realWarn,
    error: realError,
  };
}

const logger = new Logger('config');

export const getKeys = <T extends {}>(o: T): Array<keyof T> =>
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  <Array<keyof T>>Object.keys(o);

/**
 * This represents a _useState()_ functionality
 */
export type VariableHandler<Variable> = [
  Variable,
  React.Dispatch<React.SetStateAction<Variable>>
];

export type ContextVariableHandler<VarKeys> = <T extends keyof VarKeys>(
  varName: T
) => VariableHandler<VarKeys[T]>;

export type ContextHandlerFunction<VarKeys> = <T extends keyof VarKeys>(
  varName: T
) => React.Dispatch<React.SetStateAction<VarKeys[T]>>;

/**
 * Array of 2 elements to handle the variables in wrapper
 *
 * [0] - _All wrapper variables getter_
 * [1] - _Wrapper variable setter by param_
 */
export type ContextHandler<VarKeys> = [
  VarKeys,
  ContextHandlerFunction<VarKeys>
];

export type FullContextHandler<FullContext, VarKeys> = [
  FullContext,
  ContextHandlerFunction<VarKeys>
];

/**
 * Array of 2 elements to handle the state in wrapper
 *
 * [0] - _Wrapper state_
 * [1] - _Wrapper dispatch actions function_
 */
export type StateHandler<State> = [
  State,
  React.Dispatch<ActionText | ActionObject<any>>
];
/**
 * Component props especification
 */
//export type ComponentProps<ComponentPropsDefinition> = ComponentPropsDefinition

/**
 * Wrapper function
 */
export type WrapperFunction = () => Function;

/**
 * Wrapper function builder
 * Returns the function binded to current variables, state and functions of the wrapper.
 */
export type FunctionBuilder<
  Variables,
  ExternalVariables,
  State,
  Functions,
  ExternalFunctions,
  ComponentPropsDefinition,
  BindedWrapperProperties,
  FunctionDefinition
> = (
  contextHandler: ContextHandler<Variables & ExternalVariables>,
  stateHandler: StateHandler<State>,
  componentProps: ComponentPropsDefinition,
  functionBuilder: <T extends keyof (Functions & ExternalFunctions)>(
    funcName: T
  ) => (Functions & ExternalFunctions)[T],
  bindedWrapperProperties: BindedWrapperProperties,
  functions?: Functions & ExternalFunctions
) => FunctionDefinition;

/**
 * Function builder and name.
 */
export type WrapperFunctionDefinition<
  Variables,
  ExternalVariables,
  State,
  Functions,
  ExternalFunctions,
  ComponentPropsDefinition,
  BindedWrapperProperties
> = {
  name: keyof (Functions & ExternalFunctions);
  builder: FunctionBuilder<
    Variables,
    ExternalVariables,
    State,
    Functions,
    ExternalFunctions,
    ComponentPropsDefinition,
    BindedWrapperProperties,
    (Functions & ExternalFunctions)[keyof (Functions & ExternalFunctions)]
  >;
};

/**
 * Bundle of functions registered for the wrapper.
 */
export type WrapperFunctionsDefinition<
  Variables,
  ExternalVariables,
  State,
  Functions,
  ExternalFunctions,
  ComponentPropsDefinition,
  BindedWrapperProperties
> = WrapperFunctionDefinition<
  Variables,
  ExternalVariables,
  State,
  Functions,
  ExternalFunctions,
  ComponentPropsDefinition,
  BindedWrapperProperties
>[];

/**
 * Generates and populates the functions defined in ```wrapperFunctions```, bingind the values of the current context of the wrapper.
 */
export const generateWrapperFunctions = <
  Functions,
  ExternalFunctions,
  Variables,
  ExternalVariables,
  State,
  ComponentPropsDefinition,
  BindedWrapperProperties
>(
  wrapperFunctions: WrapperFunctionsDefinition<
    Variables,
    ExternalVariables,
    State,
    Functions,
    ExternalFunctions,
    ComponentPropsDefinition,
    BindedWrapperProperties
  >,
  [componentContext, componentContextSetter]: ContextHandler<
    Variables & ExternalVariables
  >,
  [localState, dispatch]: StateHandler<State>,
  componentProps: ComponentPropsDefinition,
  bindedWrapperContexts: BindedWrapperProperties
) => {
  const funcs = wrapperFunctions.reduce((functions, actualFunction) => {
    const createdFunctionsGetter: <T extends keyof (Functions &
      ExternalFunctions)>(
      funcName: T
    ) => (Functions & ExternalFunctions)[T] = funcName => funcs[funcName];
    const acc: Functions & ExternalFunctions = {
      ...functions,
      [actualFunction.name]: actualFunction.builder(
        [componentContext, componentContextSetter],
        [localState, dispatch],
        componentProps,
        <T extends keyof (Functions & ExternalFunctions)>(funcName: T) =>
          functions[funcName] ||
          (wrapperFunctions
            .find(f => f.name === funcName)
            ?.builder(
              [componentContext, componentContextSetter],
              [localState, dispatch],
              componentProps,
              createdFunctionsGetter,
              bindedWrapperContexts
            ) as (Functions & ExternalFunctions)[T]),
        bindedWrapperContexts
      ),
    };
    return acc;
  }, {} as Functions & ExternalFunctions);

  return funcs;
};

/**
 * Defines the varible name and the start value in the wrapper
 */
export interface WrapperVariableDefinition<T> {
  startValue: T[keyof T];
  persist?: boolean;
}

/**
 * Bundle of variables for the wrapper.
 */
export type WrapperVariablesDefinition<VarKeys> = Record<
  keyof VarKeys,
  WrapperVariableDefinition<VarKeys>
>;

/**
 * Object that store ```useState()``` value for each variable
 */
export type WrapperVariablesHandlers<K> = Record<
  keyof K,
  [K[keyof K], React.Dispatch<React.SetStateAction<K[keyof K]>>]
>;

/**
 * Return a ```useState``` value for the ```varName``` specifies.
 * @throws Error If the ```varName``` its not defined
 */
export type VariablePairExtractor<VarKeys> = <T extends keyof VarKeys>(
  varName: T
) => [VarKeys[T], React.Dispatch<React.SetStateAction<VarKeys[T]>>];

/**
 * Return the value for the ```varName``` specifies.
 * @throws Error If the ```varName``` its not defined
 */
export type VariableValueExtractor<VarKeys> = <T extends keyof VarKeys>(
  varName: T
) => VarKeys[T];

/**
 * Return the ```useState``` ```setter``` (```[1]``` position of ```useState```) for the ```varName``` specifies.
 * @throws Error If the ```varName``` its not defined
 */
export type VariableSetterExtractor<VarKeys> = <T extends keyof VarKeys>(
  varName: T
) => React.Dispatch<SetStateAction<VarKeys[T]>>;

/**
 * Set the all variables of the wrapper to their default values
 */
export type VariableResetFunction = () => void;

export type VirtualsGenerator<
  Functions,
  ExternalFunctions,
  Variables,
  ExternalVariables,
  State,
  ComponentPropsDefinition,
  BindedWrapperProperties,
  Virtuals
> = (
  context: State &
    Variables &
    ExternalVariables &
    ComponentPropsDefinition &
    Functions &
    ExternalFunctions,
  bindedCOntext: BindedWrapperProperties
) => State &
  Variables &
  ExternalVariables &
  ComponentPropsDefinition &
  Functions &
  ExternalFunctions &
  Virtuals;

/**
 * Handles the values of the variables in the wrapper
 */
export type WrapperVariablesSet<VarKeys> = {
  keys: (keyof VarKeys)[];
  getVariablePair: VariablePairExtractor<VarKeys>;
  getVariableValue: VariableValueExtractor<VarKeys>;
  getVariableSetter: VariableSetterExtractor<VarKeys>;
  resetVariables: VariableResetFunction;
};

/**
 * Build the estructure to handle and manage the variables and value's getters
 */
export const generateVariables = <State, VarKeys>(
  wrapperName: string,
  stateVariables: WrapperVariablesDefinition<VarKeys>,
  [state, dispatch]: StateHandler<State>
): WrapperVariablesSet<VarKeys> => {
  const variables: WrapperVariablesHandlers<VarKeys> = {} as any;
  for (const varName in stateVariables) {
    //for (let i = 0; i < Object.keys(stateVariables || {}).length; i++) {
    //const varName = Object.keys(stateVariables || {})[i];
    const varDefinition = stateVariables[varName];
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [value, setter] = useState(
      (varDefinition.persist && state && (state as any)[varName]) ||
        varDefinition.startValue
    );

    variables[varName] = [
      value,
      varDefinition.persist
        ? val => {
            dispatch({
              type: (wrapperName + '_propertyPersist') as any,
              payload: { name: varName, value: val },
            });
            setter(val);
          }
        : setter,
    ];
  }
  const vars = {
    // ...variables,
    keys: getKeys(stateVariables),
    getVariablePair: <T extends keyof VarKeys>(varName: T) => {
      if (variables && variables[varName]) {
        return variables[varName];
      } else {
        throw new Error('Variable ' + varName + ' not defined.');
      }
    },
    getVariableValue: <T extends keyof VarKeys>(varName: T) => {
      if (variables && variables[varName]) {
        return variables[varName][0];
      } else {
        throw new Error('Variable ' + varName + ' not defined.');
      }
    },
    getVariableSetter: <T extends keyof VarKeys>(varName: T) => {
      if (variables && variables[varName]) {
        return variables[varName][1];
      } else {
        throw new Error('Variable ' + varName + ' not defined.');
      }
    },
    resetVariables: () => {
      for (const varName in stateVariables) {
        //for (let i = 0; i < Object.keys(stateVariables || {}).length; i++) {
        //const varName = Object.keys(stateVariables || {})[i];
        const varDefinition = stateVariables[varName];
        variables[varName][1](varDefinition.startValue);
      }
    },
  };
  logger.log('Generated variables ' + wrapperName, vars);
  return vars as WrapperVariablesSet<VarKeys>;
};

/**
 * Build the estructure of the global context of the wrapper. Mix the variables, logic and functions together and its the main strucutre provided down for the children components.
 * Returns and object that mixes variables, state and functions.
 */
export const generateContext = <
  Functions,
  ExternalFunctions,
  Variables,
  ExternalVariables,
  State,
  ComponentPropsDefinition,
  BindedWrapperProperties
>(
  wrapperName: string,
  variables: WrapperVariablesSet<Variables & ExternalVariables>,
  wrapperFunctions: WrapperFunctionDefinition<
    Variables,
    ExternalVariables,
    State,
    Functions,
    ExternalFunctions,
    ComponentPropsDefinition,
    BindedWrapperProperties
  >[],
  [appState, dispatch]: StateHandler<State>,
  componentProps: ComponentPropsDefinition,
  bindedContext: BindedWrapperProperties
) => {
  const variablesGenerated = variables.keys.reduce(
    (acc: Variables & ExternalVariables, act) => ({
      ...acc,
      [act]: variables.getVariableValue(act),
    }),
    {} as Variables & ExternalVariables
  );

  logger.log(
    'Generating context ' + wrapperName,
    appState,
    variablesGenerated,
    componentProps
  );
  const context = {
    ...appState,
    ...variablesGenerated,
    ...componentProps,
  };
  logger.log('Generated context ' + wrapperName, context);
  const contextWithFunctions = {
    ...context,
    ...generateWrapperFunctions<
      Functions,
      ExternalFunctions,
      Variables,
      ExternalVariables,
      State,
      ComponentPropsDefinition,
      BindedWrapperProperties
    >(
      wrapperFunctions,
      [
        variablesGenerated,
        varName =>
          variables.getVariableSetter(
            // DATA
            varName
          ),
      ],
      [appState, dispatch],
      componentProps,
      bindedContext
    ),
  };
  return contextWithFunctions;
};

/**
 + Reducer function that handles the state in actions dispatching.
 * @param persistedState Partial state that is persisted
 * @param memoryState Partial state that is volatile
 * @param action The action dispatched that must be processed
 * @param variableHandler Accesor to the ```useState()``` of a especified ```varName```
 */
export type WrapperReducerFunction<State, VarKeys, Functions> = (
  persistedState: Partial<State>,
  memoryState: Partial<State>,
  action: ActionObject<any>,
  variableHandler?: ContextVariableHandler<VarKeys>,
  callFunction?: <F extends keyof Functions>(functionName: F) => Functions[F]
) => Partial<State>;

export type WrapperReducerDefinition<State, VarKeys, Functions> = {
  name: string;
  reduceFunction: WrapperReducerFunction<State, VarKeys, Functions>;
  persistState?: boolean;
};

/**
 * Defines a function reducer of the wrapper.
 */
export type ReducerDefinition<State, VarKeys, Functions> = {
  name: string;
  pathToLocalState: string[];
  reduceFunction: WrapperReducerFunction<State, VarKeys, Functions>;
  persistState?: boolean;
  contextHandler?: ContextVariableHandler<VarKeys> | undefined;
  callableFunctions?: Functions;
};

/**
 * Bundle of reducers defines for the wrapper
 */
export type WrapperReducersDefinition<
  State,
  VarKeys,
  Functions
> = WrapperReducerDefinition<State, VarKeys, Functions>[];

/**
 * This function register the specified reducer in the Application context to observe actions
 */
export type ReducerRegister = <State, VarKeys, Functions>(
  reducer: ReducerDefinition<State, VarKeys, Functions>
) => void;

/**
 * This function register the specified reducers in the app.
 * Binds the current state and variables to be used by the reducer
 * @param variables Current variables of the wrapper
 * @param wrapperReducers Reducer definitions of the wrapper
 * @param pathToLocalState Path where the persisted portion of state will be storage. Specified as an array and represents the path from the root object of the application state.
 * @param registerReducer Function from application state that register the reducer
 */
export const bindReducers = <State, VarKeys, Functions>(
  variables: WrapperVariablesSet<VarKeys>,
  wrapperReducers: WrapperReducersDefinition<State, VarKeys, Functions>,
  pathToLocalState: string[],
  registerReducer: ReducerRegister | undefined,
  callableFunctions: Functions
) => {
  wrapperReducers.forEach(({ name, reduceFunction, persistState }) => {
    registerReducer &&
      registerReducer({
        name,
        pathToLocalState,
        reduceFunction,
        persistState,
        contextHandler: varName => variables.getVariablePair(varName),
        callableFunctions,
      });
  });
};

/**
 * This function register the specified reducer in the Application context to observe actions
 */
export type RenderFunction<
  ComponentPropsDefintion,
  FullContext,
  BindedWrapperProperties
> = (
  componentProps: ComponentPropsDefintion,
  wrapperContext: React.Context<FullContext & ComponentPropsDefintion>,
  contextValue: FullContext & ComponentPropsDefintion,
  children: React.ReactNode,
  elementCreator: Function,
  bindedProperties: BindedWrapperProperties
) => any;

/**
 * This function register the specified reducer in the Application context to observe actions
 */
export type LogicFunction<
  ComponentPropsDefintion,
  FullContext,
  BindedWrapperProperties,
  VarKeys
> = (
  wrapperName: string,
  componentProps: ComponentPropsDefintion,
  context: FullContextHandler<FullContext, VarKeys>,
  bindedProperties: BindedWrapperProperties
) => void;

export const camelToPascal = (text: string) => {
  var result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const extractValue: (
  object: { [name: string]: any } | undefined,
  ...args: string[]
) => any = (config = {}, ...args) => {
  const procesedArgs = args.reduce(
    (acc, act) => (Array.isArray(act) ? [...acc, ...act] : [...acc, act]),
    [] as string[]
  );
  if (!config) {
    logger.log('EXTRACTION', {
      object: config,
      path: /*JSON.stringify*/ procesedArgs,
      valueExtracted: undefined,
    });
    return undefined;
  }
  const value = procesedArgs.reduce((acc, act) => {
    if (!acc) return undefined;
    return acc[act];
  }, config);
  logger.log('EXTRACTION', {
    object: config,
    path: /*JSON.stringify*/ procesedArgs,
    valueExtracted: value,
  });
  return value;
};

const add: <C>(
  object: { [name: string]: any },
  value: C,
  ...args: string[]
) => any = (config, value, ...args) => {
  const procesedArgs = args.reduce(
    (acc, act) => (Array.isArray(act) ? [...acc, ...act] : [...acc, act]),
    [] as string[]
  );
  let auxConfig = config;
  let auxArgs = [...procesedArgs];
  if (auxArgs.length === 0) {
    logger.log('LAST LEVEL', auxConfig, value, auxArgs);
    auxConfig = {
      ...auxConfig,
      ...value,
    };
  } else if (auxArgs.length === 1) {
    logger.log('LAST LEVEL', auxConfig, value, auxArgs);
    auxConfig = {
      ...auxConfig,
      [procesedArgs[0]]: value,
    };
  } else {
    auxArgs.shift();
    auxConfig[procesedArgs[0]] = {
      ...auxConfig[procesedArgs[0]],
      ...add(auxConfig[procesedArgs[1]] || {}, value, ...auxArgs),
    };
  }
  return auxConfig;
};

export const addValue: <C>(
  object: { [name: string]: any },
  value: C,
  ...args: string[]
) => any = (config, value, ...args) => {
  const procesedArgs = args.reduce(
    (acc, act) => (Array.isArray(act) ? [...acc, ...act] : [...acc, act]),
    [] as string[]
  );
  if (!config) {
    logger.log('ADDITION', {
      object: config,
      path: /*JSON.stringify*/ procesedArgs,
      objectResult: config,
    });
    return undefined;
  }
  logger.log('ARGS', procesedArgs);
  let auxConfig = add({ ...config }, value, procesedArgs as any);
  logger.log('ADDITION', {
    value,
    object: config,
    path: /*JSON.stringify*/ procesedArgs,
    objectResult: auxConfig,
  });
  return auxConfig;
};
export const wrapProvider = <Context>(
  provider: React.Context<Context>,
  value: Context
) => {
  return (content: any) => {
    return React.createElement(provider.Provider, { value }, content);
  };
};

export const replaceOrAddInArray = (
  matcherFunction: (a: any, b: any) => boolean,
  memoryData: any[],
  id: any,
  newItem: any
) => {
  let finded = false;
  let aux = memoryData.reduce(
    (acc, act) =>
      !matcherFunction(act, id)
        ? [...acc, act]
        : (finded = true) && [...acc, newItem],
    [] as any[]
  );
  if (!finded) {
    aux = [...aux, newItem];
  }
  return aux;
};
export type ExternalVar = any;
export type UserInput = any;

export const isValidEmail = (email: string) => {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isValidDni = (dni: string) => {
  var numero;
  var letr;
  var letra;
  var expresion_regular_dni;

  expresion_regular_dni = /^\d{8}[a-zA-Z]$/;

  if (expresion_regular_dni.test(dni) === true) {
    numero = Number(dni.substr(0, dni.length - 1));
    letr = dni.substr(dni.length - 1, 1);
    numero = numero % 23;
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
    letra = letra.substring(numero, numero + 1);
    if (letra !== letr.toUpperCase()) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

export class HandlersStack<T> {
  // Array is used to implement stack
  constructor(
    private items: T[] = [],
    private keys: { [k: string]: boolean } = {}
  ) {}

  // Functions to be implemented
  push(item: T, key: string) {
    if (!this.keys[key]) {
      this.items.push(item);
      this.keys[key] = true;
    }
    //this.items = [item, ...this.items];
  }
  pop(key: string): T | undefined {
    if (this.items.length === 0) return undefined;
    //const [item, ...items] = this.items;
    //this.items = items;
    //return item;
    const item = this.items.pop();
    delete this.keys[key];
    return item;
  }

  peek(): T | undefined {
    return this.items[this.items.length - 1];
  }
  isEmpty() {
    return this.items.length === 0;
  }
  printStack() {
    var str = '';
    for (var i = 0; i < this.items.length; i++) str = str + ' ' + this.items[i];
    return str;
  }
  raw(): T[] {
    return this.items;
  }
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0;
    var v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export class DeferredPromise<T, E> {
  private res!: (value: T | undefined) => void;
  private rej!: (error: E | undefined) => void;

  public set resolver(value) {
    this.res = value;
  }

  public get resolver() {
    return this.res;
  }

  public set rejecter(value) {
    this.rej = value;
  }

  public get rejecter() {
    return this.rej;
  }

  constructor(
    private createFunction: (
      resolve: (value: T | undefined) => void,
      reject: (error: E | undefined) => void
    ) => void
  ) {}

  public async run() {
    if (!this.rej || !this.res) {
      throw new Error('Resolver and Rejected must be setted');
    }
    return this.createFunction(this.res, this.rej);
  }
}

export interface TranslateOptions {}

export class InternacionalizationManager {
  private static _i: InternacionalizationManager;
  public static get instance() {
    if (!this._i) {
      this._i = new InternacionalizationManager();
    }
    return this._i;
  }

  private lang!: string;
  public set language(value) {
    this.lang = value;
  }
  public get language() {
    return this.lang;
  }

  public t(message: string, _options?: TranslateOptions) {
    if (!this.lang) {
      return message;
    } else {
      return message;
    }
  }
}

/**
 * Convert BASE64 to BLOB
 * @param base64Image Pass Base64 image data to convert into the BLOB
 */
export const convertBase64ToBlob = (base64Image: string) => {
  // Split into two parts
  const parts = base64Image.split(';base64,');

  // Hold the content type
  const imageType = parts[0].split(':')[1];

  // Decode Base64 string
  const decodedData = window.atob(parts[1]);

  // Create UNIT8ARRAY of size same as row data length
  const uInt8Array = new Uint8Array(decodedData.length);

  // Insert all character code into uInt8Array
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }

  // Return BLOB image after conversion
  return new Blob([uInt8Array], { type: imageType });
};
