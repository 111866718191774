import { ComponentsConfiguration } from '../interfaces';

import merge from 'lodash/merge';
export let componentsConfiguration: ComponentsConfiguration = {};

export const defineComponent = <T extends keyof ComponentsConfiguration>(
  component: T,
  definition: ComponentsConfiguration[T]
) => {
  componentsConfiguration[component] = merge(
    componentsConfiguration[component] || {},
    definition
  );
  return definition;
};
