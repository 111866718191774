import { WrapperVariablesDefinition } from '../../generic/utils';

import { VariableTypes } from './interfaces';

const variables: WrapperVariablesDefinition<VariableTypes> = {
  testResponses: { startValue: undefined },
  testCompletions: { startValue: undefined },
};

export const stateVariables = variables;
