import {
  OnChange,
  InputValue,
  SelectItemStyle,
  Disabled,
  SelectItemExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.selectItem;

export interface ClassProperties {}

export type ComponentStyle = SelectItemStyle;
export type ExpectedStyles = SelectItemExpectedStyles;
export type Props = GenericComponentProps<SelectItemStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  noT?: boolean;
};
