import React, { FunctionComponent, useEffect, useState } from 'react';

import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { Logger } from '../../../../generic/utils';
import { View } from '../View';

const console = new Logger('text');

if (console) {
  //Just for usage
}

const e = React.createElement;

const parseValuation = (value: boolean | number | any) => {
  return typeof value === 'number' ? value : !value ? 0 : 1;
};

export const SoftSwitcher: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  children,
  evaluate,
  duration = 200,
  ...props
}) => {
  checkStyleNames(enumKey, props);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Inyectar estilos ad hoc -----------------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {}
  );
  const [opacity, setOpacity] = useState(1);
  const [childrenToRender, setChildrenToRender] = useState(
    parseValuation(evaluate)
  );
  useEffect(() => {
    if (evaluate !== childrenToRender) {
      setOpacity(0);
      setTimeout(() => {
        setOpacity(1);
        setChildrenToRender(parseValuation(evaluate));
      }, duration);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluate]);

  // Return --------------------------------------------------
  return e(
    View,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id
      ),
      style: {
        ...componentStyle.softSwitchComponent,
        transition: 'opacity ' + duration / 1000 + 's ease-in-out',
        opacity,
      },
    },
    children && React.Children.toArray(children)[childrenToRender]
  );
};
