/* eslint-disable no-unused-vars */
import React, { FunctionComponent, useState } from 'react';

import MuiTooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';

import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import { View } from '../View';
import { makeStyles } from '@material-ui/core';
import { generateMaterialStyles } from '../../common';

const useTooltipStyles = makeStyles({
  // style rule
  popper: (props: ComponentStyle) =>
    generateMaterialStyles('popperContainerComponent', props),
  tooltip: (props: ComponentStyle) =>
    generateMaterialStyles('popperContentComponent', props),
  arrow: (props: ComponentStyle) =>
    generateMaterialStyles('arrowComponent', props),
});

const e = React.createElement;

export const Tooltip: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  content,
  onClose,
  onOpen,
  open,
  withArrow,
  transition = 'grow',
  placement = 'bottom',
  disabled,
  children,
  enableClick = true,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  const [localOpened, setLocalOpened] = useState(false);

  const handleTooltipClose = () => {
    setLocalOpened(false);
  };

  const handleTooltipOpen = () => {
    if (disabled) {
      return;
    }
    setLocalOpened(true);
  };

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {
      arrow: withArrow,
      disabled,
      opened: open === undefined ? localOpened : open,
    }
  );

  // Generación de estilos material basados en estilos UiWrapper
  const materialTooltip = useTooltipStyles(componentStyle);

  // Return -----------------------------------------------------
  const component = e(MuiTooltip, {
    title: content,
    onOpen,
    onClose: handleTooltipClose,
    classes: materialTooltip,
    open: open === undefined ? localOpened : open,
    placement,
    arrow: withArrow,
    PopperProps: {
      ...generateProperties(
        parentReference,
        enumKey,
        'popper',
        undefined,
        undefined,
        true
      ),
    },
    TransitionComponent:
      transition === 'grow'
        ? undefined
        : transition === 'fade'
        ? Fade
        : transition === 'zoom'
        ? Zoom
        : undefined,
    children: e(
      View,
      {
        ...generateProperties(
          parentReference,
          enumKey,
          'container',
          coreId,
          id
        ),
        style: componentStyle.containerComponent,
        onClick: enableClick ? handleTooltipOpen : undefined,
        cleared: true,
      },
      children
    ) as any,
  });
  return enableClick
    ? e(ClickAwayListener, {
        onClickAway: handleTooltipClose,
        children: component,
      })
    : component;
};
