import { Target } from './common';
export const targetsMapper = (targetsReceived: Target[]): Target[] => {
  return targetsReceived.map(t => {
    return {
      ...t,
      ...(t.targetProperties || []).reduce(
        (acc, act) => ({
          ...acc,
          [act.name]: act.value,
        }),
        {}
      ),
    };
  });
};
