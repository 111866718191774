/* eslint-disable no-undef */
export const init = () => {
  cordova.define('cordova-plugin-ionic-keyboard.keyboard', function(
    require,
    exports,
    module
  ) {
    var argscheck = require('cordova/argscheck'),
      utils = require('cordova/utils'),
      exec = require('cordova/exec'),
      channel = require('cordova/channel');

    var Keyboard = function() {};

    Keyboard.fireOnShow = function(height) {
      Keyboard.isVisible = true;
      cordova.fireWindowEvent('keyboardDidShow', {
        keyboardHeight: height,
      });
    };

    Keyboard.fireOnHide = function() {
      Keyboard.isVisible = false;
      cordova.fireWindowEvent('keyboardDidHide');
    };

    Keyboard.fireOnHiding = function() {
      cordova.fireWindowEvent('keyboardWillHide');
    };

    Keyboard.fireOnShowing = function(height) {
      cordova.fireWindowEvent('keyboardWillShow', {
        keyboardHeight: height,
      });
    };

    Keyboard.hideKeyboardAccessoryBar = function(hide) {
      exec(null, null, 'Keyboard', 'hideKeyboardAccessoryBar', [hide]);
    };

    Keyboard.close = function() {
      exec(null, null, 'Keyboard', 'close', []);
    };

    Keyboard.show = function() {
      exec(null, null, 'Keyboard', 'show', []);
    };

    Keyboard.disableScroll = function(disable) {
      console.warn('Keyboard.disableScroll() was removed');
    };

    channel.onCordovaReady.subscribe(function() {
      exec(success, null, 'Keyboard', 'init', []);

      function success(msg) {
        var action = msg.charAt(0);
        if (action === 'S') {
          var keyboardHeight = parseInt(msg.substr(1));
          Keyboard.fireOnShowing(keyboardHeight);
          Keyboard.fireOnShow(keyboardHeight);
        } else if (action === 'H') {
          Keyboard.fireOnHiding();
          Keyboard.fireOnHide();
        }
      }
    });

    Keyboard.isVisible = false;

    module.exports = Keyboard;
  });
};
