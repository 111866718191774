import React, { FunctionComponent } from 'react';

import { Transition } from './others/Transition';
import { Props, enumKey, ComponentStyle, ClassProperties } from './interface';
import { Dialog, makeStyles } from '@material-ui/core';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  applyFunctionalityStyles,
  generateProperties,
} from '../common';
import { generateMaterialStyles } from '../../common';
import { componentsOverrides } from '../userOverrides';

const e = React.createElement;
const useBackDropStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('backgroundComponent', props),
});
const useDialogStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('baseComponent', props),
  container: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
  paper: (props: ComponentStyle) =>
    generateMaterialStyles('contentComponent', props),
});

export const Modal: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  key,
  fullScreen,
  transition = false,
  open = true,
  disableClickAway,
  nested,
  children,
  onClose,
  ...styleKeys
}) => {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      style,
      key,
      fullScreen,
      transition,
      open,
      disableClickAway,
      nested,
      children,
      onClose,
      ...styleKeys,
    });
  }
  //checkStyleNames(enumKey, props);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { fullScreen, nested }
  );
  // Otros
  let fullScreenProps = {};
  if (fullScreen) {
    fullScreenProps = {
      fullScreen: true,
      TransitionComponent: transition ? Transition : undefined,
    };
  }
  if (overrides?.component) {
    return overrides.component({
      _componentStyle: componentStyle,
      styleNames,
      id,
      parentReference,
      componentReference,
      coreId,
      style,
      key,
      fullScreen,
      transition,
      open,
      nested,
      children,
      onClose: () => {
        if (!disableClickAway) {
          // removeModal && removeModal();
          onClose && onClose();
        }
      },
      ...styleKeys,
    });
  }
  const materialDialog = useDialogStyles(componentStyle);
  const materialBackdrop = useBackDropStyles(componentStyle);

  // Return ---------------------------------------------------
  return e(
    Dialog,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        true
      ),
      scroll: 'paper',
      PaperProps: {
        ...generateProperties(
          parentReference,
          enumKey,
          'content',
          undefined,
          undefined,
          undefined,
          true
        ),
      },
      hideBackdrop: nested,
      BackdropProps: {
        ...generateProperties(
          parentReference,
          enumKey,
          'background',
          undefined,
          undefined,
          undefined,
          true
        ),
        classes: materialBackdrop,
      },
      key,
      open,
      ...fullScreenProps,
      fullWidth: true,
      keepMounted: true,
      onClose: () => {
        if (!disableClickAway) {
          // removeModal && removeModal();
          onClose && onClose();
        }
      },
      classes: materialDialog,
      style: { position: 'absolute' },
      'aria-labelledby': 'alert-dialog-slide-title',
      'aria-describedby': 'alert-dialog-slide-description',
    },
    children
  );
};
