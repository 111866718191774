import { stateVariables } from './variables';
import { wrapperReducers } from './reducers';
import { wrapperFunctions } from './functions';
import { wrapperCreator } from '../../wrappers/wrapperCreator';
import logic from './logic';
import { wrapperIdentifier } from './common';
import { BackendWrapper } from '../../wrappers/BackendWrapper';
import { UserWrapper } from '../UserWrapper';
import { TestsWrapper } from '../TestsWrapper';

export const TestResponsesWrapper = wrapperCreator(
  wrapperIdentifier,
  stateVariables,
  wrapperFunctions,
  wrapperReducers,
  logic,
  undefined,
  [UserWrapper, BackendWrapper, TestsWrapper]
);
