import { WrapperVariablesDefinition } from '../../generic/utils';

import { VariableTypes } from './interfaces';

const variables: WrapperVariablesDefinition<VariableTypes> = {
  isRooted: {
    startValue: undefined,
  },
  isVirtual: {
    startValue: undefined,
  },
  isNative: { startValue: false },
  deviceReady: { startValue: undefined },
  isIos: { startValue: undefined },
  buildInfo: { startValue: undefined },
  deviceInfo: { startValue: undefined },
  version: { startValue: undefined },
  online: { startValue: undefined },
  connection: { startValue: undefined },
  netInterface: {
    startValue: { ip: undefined, subnet: undefined },
  },
};

export const stateVariables = variables;
