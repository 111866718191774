import { Logger } from '../../generic/utils';
import { FunctionBuilded, WrapperFunctionsDefinited } from './interfaces';
import { wrapperIdentifier, Test } from './common';
import { testsMapper } from './mappers';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadTests = () => void;
const loadTestsBuilder: FunctionBuilded<LoadTests> = (
  [_, contextSetter],
  _1,
  _2,
  _3,
  { getInstanceApi }
) => {
  return async () => {
    let tests: Test[] | null;
    try {
      tests = await getInstanceApi().getTests();
      tests = testsMapper(tests as Test[]);
      console.log('Loaded tests ', tests);
    } catch (e) {
      tests = null;
    }
    contextSetter('tests')(tests);
  };
};

export const wrapperFunctions: WrapperFunctionsDefinited = [
  { name: 'loadTests', builder: loadTestsBuilder },
];
