import { RuleDefinition } from 'entityWrappers/RuleDefinitionsWrapper/common';

export const wrapperIdentifier = 'rules';
export interface FieldValue {
  identifier: string;
  data: {
    value: any;
  };
}
export interface RuleValue {
  fieldValues: FieldValue[];
}
export interface Rule {
  id: string;
  ruleDefinitionId: string;
  targetId: string;
  isActive: boolean;
  ruleDefinition?: RuleDefinition;
  ruleValues?: RuleValue[];
  value?: RuleValue;
}
