import moment from 'moment';

export const wrapperIdentifier = 'analyticsTracker';

export const commonFunctions = {
  getScripts: async function(TRANSPORT: string, args: { [k: string]: any }) {
    const { getInstanceApi, debugEnabled, analyticId } = args;
    debugEnabled && console.log(`## 🧩 ${TRANSPORT} GET SCRIPTS`);

    try {
      const apiInstance = getInstanceApi();

      const eventDefinitions = await apiInstance.microserviceCall('Analytics')(
        '/event-definitions',
        'GET',
        {
          filter: {
            where: {
              identifier: `${TRANSPORT}_GENERATE_SCRIPTS`,
              transportIdentifier: TRANSPORT,
            },
          },
        }
      );

      if (eventDefinitions.length === 0) {
        throw new Error(`GENERATE_SCRIPTS for ${TRANSPORT} is not actived`);
      }

      const eventCreated = await apiInstance.microserviceCall('Analytics')(
        `/anltcs/${analyticId}/events`,
        'POST',
        {
          eventDefinitionId: eventDefinitions[0].id,
          input: {},
        }
      );
      debugEnabled && console.log(`${TRANSPORT} eventCreated`, eventCreated);

      let eventPooled: any = {};
      let status = eventCreated.status;
      let finishDate = moment()
        .add(20, 'seconds')
        .toDate();
      while (
        (status !== 'FINISHED_SUCCESS' && status !== 'FINISHED_ERROR') ||
        +new Date() > +finishDate
      ) {
        await new Promise(r => setTimeout(r, 1000));
        eventPooled = await apiInstance.microserviceCall('Analytics')(
          `/events/${eventCreated.id}`,
          'GET'
        );
        status = eventPooled.status;
      }

      if (status === 'FINISHED_ERROR') {
        debugEnabled &&
          console.log(`## ❌ ${TRANSPORT} getScripts eventPooled`, eventPooled);
        throw new Error('Error creating event');
      } else if (status !== 'FINISHED_SUCCESS') {
        debugEnabled &&
          console.log(`## ❌ ${TRANSPORT} getScripts eventPooled`, eventPooled);
        throw new Error('Timeout reached creating event');
      }
      // debugEnabled &&
      //   console.log(
      //     `## ✅ ${TRANSPORT} getScripts eventPooled`,
      //     eventPooled
      //   );

      return { ...eventPooled.output };
    } catch (error) {
      debugEnabled && console.log('## ❌ error', error);
      throw error;
    }
  },
  srcScript: function(src: string) {
    const script = document.createElement('script');
    script.src = src;
    return script;
  },
  dataScript: function(data: string) {
    const script = document.createElement('script');
    script.innerHTML = data;
    return script;
  },
};
