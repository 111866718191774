import {
  OnClick,
  OnChange,
  GenericComponentProps,
  InputStyle,
  InputExpectedStyles,
  InputValue,
  InputType,
  InputDefaultValue,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.input;

export interface ClassProperties {}

export type ComponentStyle = InputStyle;
export type ExpectedStyles = InputExpectedStyles;
export type Props = GenericComponentProps<InputStyle> & {
  onClick?: OnClick;
  onChange?: OnChange;
  disabled?: boolean;
  value?: InputValue;
  type?: InputType;
  defaultValue?: InputDefaultValue;
  placeholder?: string;
};
