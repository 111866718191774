import { Logger } from '../../generic/utils';
import {
  wrapperIdentifier,
  Vinculation,
  UserVinculationPayload,
} from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadVinculations = () => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadVinculations',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, token },
    }) => async () => {
      let masterVinculations: Vinculation[] | null;
      let slaveVinculations: Vinculation[] | null;
      try {
        let user = await getInstanceApi().getUser(
          'me',
          token as string,
          ['masterVinculations', 'slaveVinculations'].filter(x => x) as string[]
        );
        masterVinculations = user.masterVinculations || [];
        slaveVinculations = user.slaveVinculations || [];
      } catch (e) {
        console.log('ººERROR vinculations ', e);
        masterVinculations = null;
        slaveVinculations = null;
      }
      variablesSetter('masterVinculations')(masterVinculations);
      variablesSetter('slaveVinculations')(slaveVinculations);
    }
  );

export type CreateMasterVinculation = (
  type: string,
  userData: Partial<UserVinculationPayload>
) => Promise<Vinculation>;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'createMasterVinculation',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { user, getInstanceApi },
    }) => async (type, userData) => {
      if (!user) {
        throw new Error('No user defined');
      }
      const vinculation = await getInstanceApi().createVinculation(
        (user.id || user._id) as string,
        type,
        userData
      );
      variablesSetter('masterVinculations')(oldVinculations => {
        return [...(oldVinculations || []), vinculation];
      });
      return vinculation;
    }
  );
