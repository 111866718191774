import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  textComponent: {
    width: '100%',
  },
  containerComponent: {},
  radioComponent: {},
});

export default baseConfiguration;
