import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(({ bindedContexts: { targetTypes, targets } }) => {
    return {
      userTargets:
        targets &&
        targets.map(t => ({
          ...t,
          targetType: (targetTypes || []).find(tt => t.targetTypeId === tt.id),
        })),
    };
  });
