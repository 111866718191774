import {
  AlertStyle,
  AlertExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
import { ShowAlertOptions } from '../../common';
import { CloseAlert } from '../../interfaces';
export const enumKey = COMPONENTS.alert;

export interface ClassProperties {}

export type ComponentStyle = AlertStyle;
export type ExpectedStyles = AlertExpectedStyles;
export type Props = GenericComponentProps<AlertStyle> & {
  alertMessage: string;
  alertOptions?: ShowAlertOptions;
  closeAlert?: CloseAlert;
};
