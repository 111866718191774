import React, { FunctionComponent } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { ModuleComponentProps } from '../interfaces';
import { wrapProvider } from '../../../generic/utils';
import { useProvider } from '../../../generic';
import { BaseNameController } from './baseNameController';
import { NavigationWrapper } from '../../NavigationWrapper';

//const e = React.createElement;

export interface ModuleContext {
  params: { [k: string]: any };
  path: string;
}

const ModuleWrapper = React.createContext({} as ModuleContext);
export const Module: FunctionComponent<ModuleComponentProps> & {
  use: (requester?: string) => { path: string };
} = ({ children, ...props }) => {
  const { navigateTo } = NavigationWrapper.use();
  const location = useLocation();
  const moduleContext = Module.use();
  const prevPath = moduleContext && moduleContext.path;
  const pathGenerated = (prevPath || '') + props.path;

  const procesedModuleRoute = pathGenerated.split('/').filter(x => x.trim());
  const procesedCurrentLocation = location.pathname
    .split('/')
    .filter((x: any) => x.trim());

  let equals = true;
  for (let i = procesedModuleRoute.length; i >= 0 && equals; i--) {
    equals =
      procesedModuleRoute[i]?.startsWith(':') ||
      procesedModuleRoute[i] ===
        procesedCurrentLocation[
          procesedCurrentLocation.length - (procesedModuleRoute.length - i)
        ];
  }
  if (
    equals &&
    (typeof props.defaultRoute === 'string' || props.defaultRoute?.key)
  ) {
    console.log(' REDIRECTING TO DEFAULT ROUTE', props.defaultRoute);
    navigateTo(
      typeof props.defaultRoute === 'string'
        ? props.defaultRoute
        : props.defaultRoute.key,
      typeof props.defaultRoute === 'string'
        ? undefined
        : props.defaultRoute.props
    );
    return null;
  } else {
    const match = matchPath(location.pathname, {
      exact: false,
      path: BaseNameController.instance.basename + pathGenerated,
    });
    return match
      ? wrapProvider(ModuleWrapper, {
          path: pathGenerated,
          params: match.params || {},
        })(children)
      : null;
  }
};

Module.use = (requester?: string) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useProvider(ModuleWrapper, requester);
};

// Annotation for recursive search navigation tree
(Module as any).module = true;
