/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import { Text } from '../Text';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { FormControlLabel, Radio, makeStyles } from '@material-ui/core';
import { generateMaterialStyles } from '../../common';

const e = React.createElement;

const useFormControlStyles = makeStyles({
  // style rule
  root: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
});

const useRadioStyles = makeStyles({
  // style rule
  root: (props: ComponentStyle) =>
    generateMaterialStyles('radioComponent', props),
});

export const RadioOption: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  children,
  noT = false,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled }
  );

  // Otros ----------------------------------------------------------------
  const materialContainer = useFormControlStyles(componentStyle);
  const materialRadio = useRadioStyles(componentStyle);

  // Return -----------------------------------------------------
  return e(FormControlLabel, {
    ...generateProperties(parentReference, enumKey, id),
    classes: materialContainer,
    value: value,
    disabled,
    control: e(Radio, {
      classes: materialRadio,
      color: 'primary',
      disabled,
    }),
    label: e(
      Text,
      {
        ...generateProperties(parentReference, enumKey, 'text'),
        noT,
        style: componentStyle.textComponent,
      },
      children
    ),
  });
};
