import { COMPONENTS } from '../common';
import {
  ButtonExpectedStyles,
  ButtonStyle,
  Disabled,
  GenericComponentProps,
  Loading,
  OnClick,
  TextLabel,
  Width,
} from '../propsDefinition';
export const enumKey = COMPONENTS.button;

export interface ClassProperties {}

export const SupportedFunctionalities = ['disabled'];

export type ComponentStyle = ButtonStyle;
export type ExpectedStyles = ButtonExpectedStyles;
export type Props = GenericComponentProps<ButtonStyle> & {
  onClick?: OnClick;
  width?: Width;
  disabled?: Disabled;
  loading?: Loading;
  label?: TextLabel;
  icon?: any;
  iconPosition?: any;
};
