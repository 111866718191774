import {
  OnChange,
  InputValue,
  SwipeItemStyle,
  Disabled,
  SwipeItemExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.swipeItem;

export interface ClassProperties {}

export type ComponentStyle = SwipeItemStyle;
export type ExpectedStyles = SwipeItemExpectedStyles;
export type Props = Exclude<GenericComponentProps<undefined>, 'style'> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  noT?: boolean;
};
