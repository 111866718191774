import { ActionText, ActionObject } from '../../generic/interfaces';
import {
  ReducerDefinition,
  ReducerRegister,
  replaceOrAddInArray,
  extractValue,
  addValue,
  Logger,
} from '../../generic/utils';
import { ContextState } from './interfaces';
import { wrapperIdentifier } from './common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

// Default key name for application state in localStorage
const LOCAL_STORAGE_STATE_KEY = 'SATELITEXABARIN';

export const getPersistedState: () => any = () => {
  const local = localStorage.getItem(LOCAL_STORAGE_STATE_KEY);
  let toret;
  if (local) {
    toret = JSON.parse(localStorage.getItem(LOCAL_STORAGE_STATE_KEY) || '{}');
    console.log('Local founded', JSON.stringify(toret));
  } else {
    toret = {};
    console.log('No Local founded');
  }
  return toret;
};

const persistState = (state: any) => {
  console.log('Saving state', JSON.stringify(state));
  localStorage.setItem(LOCAL_STORAGE_STATE_KEY, JSON.stringify(state));
};

// Function to add custom reducers to the APP
let reducers: ReducerDefinition<any, any, any>[] = [];
export const registerReducer: ReducerRegister = r => {
  if (r) {
    console.log('Registering reducer ' + r.name, reducers);
    const l = reducers.length;
    // Searchs a reducer by and and upsert it in array
    reducers = replaceOrAddInArray((a, b) => a.name === b, reducers, r.name, r);
    if (l !== reducers.length) {
      console.log('Reducer ADDED');
    } else {
      console.log('Reducer REPLACED');
    }
  }
};

// Main reducer of the APP
//  Basically execute the registered reducers throght "registerReducer" function
export const reducer: (
  state: any,
  actionReceived: ActionText | ActionObject<any>
) => ContextState = (state, actionReceived) => {
  // Actions can be "string" or "objects"
  //  If string is received, here creates a "object" with the action name string
  const action =
    typeof actionReceived === 'string'
      ? { type: actionReceived, payload: undefined }
      : actionReceived;
  console.log(
    'Received action -' +
      JSON.stringify(action) +
      '-. Executting registered reducers: ' +
      reducers.map(r => r.name)
  );

  // Execute the registered reducers one by one handling his states
  let reducedState = state;
  for (const r of reducers) {
    //const r = reducers[index]
    console.log('Processing' + r.name + ' reducer', r);
    let rPersistState;
    let rMemoryState;
    try {
      rPersistState = extractValue(
        reducedState,
        ...['_persisted', ...r.pathToLocalState]
      );
      rMemoryState = extractValue(reducedState, ...r.pathToLocalState);

      console.log(
        'Processing' + r.name + ' State: ',
        rPersistState,
        rMemoryState
      );

      const result = r.reduceFunction
        ? r.reduceFunction(
            rPersistState,
            rMemoryState,
            action,
            r.contextHandler,
            name => async (...args: any) => {
              const f = r.callableFunctions[name];
              try {
                await f(...args);
              } catch (e) {}
            }
          )
        : undefined;
      console.log('Processing' + r.name + ' Result State: ', result);

      if (result) {
        console.log(
          'Processing' + r.name + ' ADDING Result State: ',
          reducedState
        );
        if (r.persistState === undefined || r.persistState !== false) {
          reducedState = {
            ...reducedState,
            ...addValue(
              {
                _persisted: reducedState._persisted,
              },
              result,
              ...['_persisted', ...r.pathToLocalState]
            ),
          };
          console.log(
            'Processing' + r.name + ' Persist Result State: ',
            reducedState
          );
        } else {
          reducedState = {
            ...reducedState,
            ...addValue(reducedState, result, ...r.pathToLocalState),
          };
          console.log(
            'Processing' + r.name + ' Memory Result State: ',
            reducedState
          );
        }
      }
    } catch (e) {
      console.log('Processing' + r.name + ' ERRROR: ', e);
      continue;
    }
  }
  persistState({
    _persisted: reducedState._persisted,
  });
  console.log('State Result', reducedState);
  // After reducers execution, the result state is storaged
  reducedState = {
    ...reducedState,
    ...reducedState._persisted,
  };
  //delete reducedState._persisted
  return reducedState;
};
