import { WrapperBuilder } from '../../builders/wrapperBuilder';
import { wrapperIdentifier } from './common';
import {
  ComponentProps,
  Functions,
  InternalFunctions,
  InternalVariables,
  State,
  Variables,
  Virtuals,
  ExternalFunctions,
  ExternalVariables,
  Statics,
} from './interfaces';

export const wrapperBuilder = new WrapperBuilder<
  ComponentProps,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  Statics,
  {},
  {}
>(wrapperIdentifier, wrapperIdentifier);
