/* eslint-disable no-undef */
export const definitionIos = () =>
  cordova.define('cordova/plugin_list', function(require, exports, module) {
    module.exports = [
      {
        id: 'branch-cordova-sdk.Branch',
        file: 'plugins/branch-cordova-sdk/src/index.js',
        pluginId: 'branch-cordova-sdk',
        clobbers: ['Branch'],
      },
      {
        id: 'cordova-plugin-appversion.RareloopAppVersion',
        file: 'plugins/cordova-plugin-appversion/www/app-version.js',
        pluginId: 'cordova-plugin-appversion',
        clobbers: ['AppVersion'],
      },
      {
        id: 'cordova-plugin-device.device',
        file: 'plugins/cordova-plugin-device/www/device.js',
        pluginId: 'cordova-plugin-device',
        clobbers: ['device'],
      },
      {
        id: 'phonegap-plugin-push.PushNotification',
        file: 'plugins/phonegap-plugin-push/www/push.js',
        pluginId: 'phonegap-plugin-push',
        clobbers: ['PushNotification'],
      },
      {
        id: 'cordova-plugin-ionic-webview.IonicWebView',
        file: 'plugins/cordova-plugin-ionic-webview/src/www/util.js',
        pluginId: 'cordova-plugin-ionic-webview',
        clobbers: ['Ionic.WebView'],
      },
      {
        id: 'cordova-plugin-ionic-webview.ios-wkwebview-exec',
        file:
          'plugins/cordova-plugin-ionic-webview/src/www/ios/ios-wkwebview-exec.js',
        pluginId: 'cordova-plugin-ionic-webview',
        clobbers: ['cordova.exec'],
      },
      {
        id: 'cordova-plugin-ionic-keyboard.keyboard',
        file: 'plugins/cordova-plugin-ionic-keyboard/www/ios/keyboard.js',
        pluginId: 'cordova-plugin-ionic-keyboard',
        clobbers: ['window.Keyboard', 'Keyboard'],
      },
      {
        id: 'cordova-clipboard.Clipboard',
        file: 'plugins/cordova-clipboard/www/clipboard.js',
        pluginId: 'cordova-clipboard',
        clobbers: ['cordova.plugins.clipboard'],
      },
      {
        id: 'blinkid-cordova.BlinkIDScanner',
        file: 'plugins/blinkid-cordova/www/blinkIdScanner.js',
        pluginId: 'blinkid-cordova',
        clobbers: ['cordova.plugins.BlinkID'],
      },
      {
        id: 'cordova-plugin-fingerprint-aio.Fingerprint',
        file: 'plugins/cordova-plugin-fingerprint-aio/www/Fingerprint.js',
        pluginId: 'cordova-plugin-fingerprint-aio',
        clobbers: ['Fingerprint'],
      },
      {
        id: 'cordova-plugin-network-information.network',
        file: 'plugins/cordova-plugin-network-information/www/network.js',
        pluginId: 'cordova-plugin-network-information',
        clobbers: [
          'navigator.connection',
          'navigator.network.connection',
          'window.networkinformation',
          'networkinformation',
        ],
      },
      {
        id: 'cordova-plugin-network-information.Connection',
        file: 'plugins/cordova-plugin-network-information/www/Connection.js',
        pluginId: 'cordova-plugin-network-information',
        clobbers: ['Connection'],
      },
      {
        id: 'cordova-plugin-networkinterface.networkinterface',
        file: 'plugins/cordova-plugin-networkinterface/www/networkinterface.js',
        pluginId: 'cordova-plugin-networkinterface',
        clobbers: ['window.networkinterface', 'networkinterface'],
      },
      {
        id: 'cordova-plugin-iroot.IRoot',
        file: 'plugins/cordova-plugin-iroot/www/iroot.js',
        pluginId: 'cordova-plugin-iroot',
        clobbers: ['IRoot'],
      },
      {
        id: 'cordova-plugin-file.DirectoryEntry',
        file: 'plugins/cordova-plugin-file/www/DirectoryEntry.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.DirectoryEntry'],
      },
      {
        id: 'cordova-plugin-file.DirectoryReader',
        file: 'plugins/cordova-plugin-file/www/DirectoryReader.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.DirectoryReader'],
      },
      {
        id: 'cordova-plugin-file.Entry',
        file: 'plugins/cordova-plugin-file/www/Entry.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.Entry'],
      },
      {
        id: 'cordova-plugin-file.File',
        file: 'plugins/cordova-plugin-file/www/File.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.File'],
      },
      {
        id: 'cordova-plugin-file.FileEntry',
        file: 'plugins/cordova-plugin-file/www/FileEntry.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.FileEntry'],
      },
      {
        id: 'cordova-plugin-file.FileError',
        file: 'plugins/cordova-plugin-file/www/FileError.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.FileError'],
      },
      {
        id: 'cordova-plugin-file.FileReader',
        file: 'plugins/cordova-plugin-file/www/FileReader.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.FileReader'],
      },
      {
        id: 'cordova-plugin-file.FileSystem',
        file: 'plugins/cordova-plugin-file/www/FileSystem.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.FileSystem'],
      },
      {
        id: 'cordova-plugin-file.FileUploadOptions',
        file: 'plugins/cordova-plugin-file/www/FileUploadOptions.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.FileUploadOptions'],
      },
      {
        id: 'cordova-plugin-file.FileUploadResult',
        file: 'plugins/cordova-plugin-file/www/FileUploadResult.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.FileUploadResult'],
      },
      {
        id: 'cordova-plugin-file.FileWriter',
        file: 'plugins/cordova-plugin-file/www/FileWriter.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.FileWriter'],
      },
      {
        id: 'cordova-plugin-file.Flags',
        file: 'plugins/cordova-plugin-file/www/Flags.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.Flags'],
      },
      {
        id: 'cordova-plugin-file.LocalFileSystem',
        file: 'plugins/cordova-plugin-file/www/LocalFileSystem.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.LocalFileSystem'],
        merges: ['window'],
      },
      {
        id: 'cordova-plugin-file.Metadata',
        file: 'plugins/cordova-plugin-file/www/Metadata.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.Metadata'],
      },
      {
        id: 'cordova-plugin-file.ProgressEvent',
        file: 'plugins/cordova-plugin-file/www/ProgressEvent.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.ProgressEvent'],
      },
      {
        id: 'cordova-plugin-file.fileSystems',
        file: 'plugins/cordova-plugin-file/www/fileSystems.js',
        pluginId: 'cordova-plugin-file',
      },
      {
        id: 'cordova-plugin-file.requestFileSystem',
        file: 'plugins/cordova-plugin-file/www/requestFileSystem.js',
        pluginId: 'cordova-plugin-file',
        clobbers: ['window.requestFileSystem'],
      },
      {
        id: 'cordova-plugin-file.resolveLocalFileSystemURI',
        file: 'plugins/cordova-plugin-file/www/resolveLocalFileSystemURI.js',
        pluginId: 'cordova-plugin-file',
        merges: ['window'],
      },
      {
        id: 'cordova-plugin-file.isChrome',
        file: 'plugins/cordova-plugin-file/www/browser/isChrome.js',
        pluginId: 'cordova-plugin-file',
        runs: true,
      },
      {
        id: 'cordova-plugin-file.iosFileSystem',
        file: 'plugins/cordova-plugin-file/www/ios/FileSystem.js',
        pluginId: 'cordova-plugin-file',
        merges: ['FileSystem'],
      },
      {
        id: 'cordova-plugin-file.fileSystems-roots',
        file: 'plugins/cordova-plugin-file/www/fileSystems-roots.js',
        pluginId: 'cordova-plugin-file',
        runs: true,
      },
      {
        id: 'cordova-plugin-file.fileSystemPaths',
        file: 'plugins/cordova-plugin-file/www/fileSystemPaths.js',
        pluginId: 'cordova-plugin-file',
        merges: ['cordova'],
        runs: true,
      },
      {
        id: 'cordova-plugin-advanced-http.cookie-handler',
        file: 'plugins/cordova-plugin-advanced-http/www/cookie-handler.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.dependency-validator',
        file:
          'plugins/cordova-plugin-advanced-http/www/dependency-validator.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.error-codes',
        file: 'plugins/cordova-plugin-advanced-http/www/error-codes.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.global-configs',
        file: 'plugins/cordova-plugin-advanced-http/www/global-configs.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.helpers',
        file: 'plugins/cordova-plugin-advanced-http/www/helpers.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.js-util',
        file: 'plugins/cordova-plugin-advanced-http/www/js-util.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.local-storage-store',
        file: 'plugins/cordova-plugin-advanced-http/www/local-storage-store.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.lodash',
        file: 'plugins/cordova-plugin-advanced-http/www/lodash.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.messages',
        file: 'plugins/cordova-plugin-advanced-http/www/messages.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.ponyfills',
        file: 'plugins/cordova-plugin-advanced-http/www/ponyfills.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.public-interface',
        file: 'plugins/cordova-plugin-advanced-http/www/public-interface.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.tough-cookie',
        file: 'plugins/cordova-plugin-advanced-http/www/umd-tough-cookie.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.url-util',
        file: 'plugins/cordova-plugin-advanced-http/www/url-util.js',
        pluginId: 'cordova-plugin-advanced-http',
      },
      {
        id: 'cordova-plugin-advanced-http.http',
        file: 'plugins/cordova-plugin-advanced-http/www/advanced-http.js',
        pluginId: 'cordova-plugin-advanced-http',
        clobbers: ['cordova.plugin.http'],
      },
      {
        id: 'cordova-plugin-buildinfo.BuildInfo',
        file: 'plugins/cordova-plugin-buildinfo/www/buildinfo.js',
        pluginId: 'cordova-plugin-buildinfo',
        clobbers: ['BuildInfo'],
      },
      {
        id: 'cordova-plugin-inappbrowser.inappbrowser',
        file: 'plugins/cordova-plugin-inappbrowser/www/inappbrowser.js',
        pluginId: 'cordova-plugin-inappbrowser',
        clobbers: ['cordova.InAppBrowser.open'],
      },
      {
        id: 'cordova-plugin-statusbar.statusbar',
        file: 'plugins/cordova-plugin-statusbar/www/statusbar.js',
        pluginId: 'cordova-plugin-statusbar',
        clobbers: ['window.StatusBar'],
      },
      {
        id: 'cordova-plugin-cache-clear.CacheClear',
        file: 'plugins/cordova-plugin-cache-clear/www/CacheClear.js',
        pluginId: 'cordova-plugin-cache-clear',
        clobbers: ['CacheClear'],
      },
      {
        id: 'cordova-plugin-clear-data.ClearData',
        file: 'plugins/cordova-plugin-clear-data/www/ClearData.js',
        pluginId: 'cordova-plugin-clear-data',
        clobbers: ['ClearData'],
      },
      {
        id: 'cordova-plugin-camera.Camera',
        file: 'plugins/cordova-plugin-camera/www/CameraConstants.js',
        pluginId: 'cordova-plugin-camera',
        clobbers: ['Camera'],
      },
      {
        id: 'cordova-plugin-camera.CameraPopoverOptions',
        file: 'plugins/cordova-plugin-camera/www/CameraPopoverOptions.js',
        pluginId: 'cordova-plugin-camera',
        clobbers: ['CameraPopoverOptions'],
      },
      {
        id: 'cordova-plugin-camera.camera',
        file: 'plugins/cordova-plugin-camera/www/Camera.js',
        pluginId: 'cordova-plugin-camera',
        clobbers: ['navigator.camera'],
      },
      {
        id: 'cordova-plugin-camera.CameraPopoverHandle',
        file: 'plugins/cordova-plugin-camera/www/ios/CameraPopoverHandle.js',
        pluginId: 'cordova-plugin-camera',
        clobbers: ['CameraPopoverHandle'],
      },
      {
        id: 'cordova-plugin-camera-preview.CameraPreview',
        file: 'plugins/cordova-plugin-camera-preview/www/CameraPreview.js',
        pluginId: 'cordova-plugin-camera-preview',
        clobbers: ['CameraPreview'],
      },
    ];
    module.exports.metadata = {
      'cordova-support-google-services': '1.2.0',
      'branch-cordova-sdk': '3.4.0',
      'cordova-plugin-appversion': '1.0.0',
      'cordova-plugin-device': '2.0.3',
      'cordova-plugin-whitelist': '1.3.4',
      'phonegap-plugin-push': '2.2.3',
      'cordova-plugin-ionic-webview': '4.2.2',
      'cordova-plugin-ionic-keyboard': '2.0.5',
      'cordova-clipboard': '1.3.0',
      'blinkid-cordova': '5.4.0',
      'cordova-plugin-add-swift-support': '2.0.2',
      'cordova-plugin-fingerprint-aio': '3.0.1',
      'cordova-plugin-iroot': '2.0.2',
      'cordova-plugin-file': '6.0.2',
      'cordova-plugin-advanced-http': '3.0.1',
      'cordova-plugin-proguard': '2.2.0',
      'cordova-plugin-buildinfo': '4.0.0',
      'cordova-plugin-inappbrowser': '4.0.0',
      'cordova-plugin-statusbar': '2.4.3',
      'cordova-plugin-cache-clear': '1.3.7',
      'cordova-plugin-clear-data': '1.0.0',
      'cordova-plugin-camera': '6.0.0',
      'cordova-plugin-camera-preview': '0.12.3',
    };
  });
