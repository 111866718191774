export const wrapperIdentifier = 'testResponses';

export interface TestResponse {
  id: string;
  testQuestionId: string;
  createDate: Date;
  booleanValue: boolean;
  dateValue: Date;
  stringValue: string;
  numberValue: number;
}

export interface TestCompletion {
  id: string;
  completionDate: Date;
}
