import { wrapperBuilder } from './build';

const virtualsRegister = wrapperBuilder.getVirtualsGeneratorsRegisterInstance();

virtualsRegister.addVirtualsGenerator(wrapperEnvironment => {
  return {
    messageWithAditional:
      wrapperEnvironment.variables.name +
      ' ' +
      wrapperEnvironment.variables.aditionalMessage,
  };
});
