import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';
import { SESSION_STATUSES } from '../../wrappers/SessionWrapper/others';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

let lastToken: string | undefined = undefined;

wrapperBuilder.getCustomLogicRegisterInstance().setLogic(
  ({
    functions: { loadTargetTypes },
    variablesHandler: {
      variables: { targetTypes },
      variablesSetter,
    },
    bindedContexts: { sessionStatus, token },
  }) => {
    useEffect(() => {
      if (sessionStatus === SESSION_STATUSES.CREATED) {
        if (token && token !== lastToken) {
          lastToken = token;
          console.log('Loading targetTypes');
          loadTargetTypes();
        }
      } else if (sessionStatus === SESSION_STATUSES.NOT_CREATED) {
        lastToken = undefined;
        if (targetTypes) {
          variablesSetter('targetTypes')(undefined);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, sessionStatus]);
  }
);
