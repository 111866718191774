import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => {
  const toret: ComponentStyle = {
    containerComponent: {
      //width: '100%',
    },
    popperContainerComponent: {},
    popperContentComponent: {},
    arrowComponent: {},
  };
  return toret;
};

export default baseConfiguration;
