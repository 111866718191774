import { Logger, ExternalVar } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { Branch } from './others';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('createBranch', _ => async (properties, defaultFunc) => {
    const b = new Branch(properties, defaultFunc);
    await b.init();
    return b;
  });

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'share',
    ({ componentProps, coreDynamicFunctionGetter }) => async (
      shareText,
      contentEspecification,
      contentMetadata,
      contentRedirection = { key: '/', redirectOptions: {} },
      defaultFunc
    ) => {
      let createBranch = coreDynamicFunctionGetter('createBranch');
      !contentRedirection.key && (contentRedirection.key = '/');
      const data = {
        canonicalIdentifier: componentProps.canonicalIdentifier || `LINK`,
        canonicalUrl: componentProps.canonicalUrl || 'https://google.es',
        title: contentEspecification.title,
        contentDescription: contentEspecification.description,
        contentImageUrl:
          contentEspecification.imageUrl || componentProps.defaultImage,
        contentMetadata: {
          __internalPurposesMetadata: {
            ...contentMetadata,
          },
          __internalPurposesRedirection: contentRedirection
            ? {
                ...contentRedirection,
                redirectOptions: {
                  handleRedirection: false,
                  mode: 'replace',
                  ...contentRedirection.redirectOptions,
                },
              }
            : {},
        },
      };
      const branch = await createBranch(data, defaultFunc);
      console.log('BRANCH GENERATED', branch);
      await new Promise((res, rej) => {
        // Config dismiss user action
        branch.onShareSheetDismissed((response: ExternalVar) => {
          console.log(' SHARED 1', response);
          res();
        });
        // Config shared user action
        branch.onLinkShareResponse((response: ExternalVar) => {
          console.log(' SHARED 2', response);
          if (response && response.error) {
            rej(new Error(response.error));
          } else {
            res();
          }
        });

        //Open share screen
        branch.showShareSheet({}, {}, shareText);
      });
    }
  );
