import { Logger } from '../../generic/utils';
import { wrapperIdentifier, Institution } from './common';
import { institutionsMapper } from './mappers';
import { OPERATION_TYPES } from '../../generic/Apis';
import { Operation } from '../OperationsWrapper/common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadInstitutions = () => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadInstitutions',
    ({
      componentProps: { mock },
      variablesHandler: { variablesSetter },
      bindedContexts: { createOperation },
    }) => async () => {
      if (mock) {
        variablesSetter('institutions')(mock);
        return;
      }
      let institutions: Institution[] | null;
      try {
        // TODO: Handle this with a pooling generic system
        const operationResp: Operation = await createOperation(
          OPERATION_TYPES.GET_INSTITUTIONS,
          undefined,
          'AWAIT_FULL_PROCESS'
        );
        console.log('ºº INSTITUTIONS OPERATION', operationResp);
        try {
          institutions = institutionsMapper(
            operationResp.currentAction?.output.payload
          );
        } catch (e) {
          throw new Error('Bad response');
        }

        console.log('ººLoaded institutions ', institutions);
      } catch (e) {
        console.log('ººERROR institutions ', e);
        institutions = null;
      }
      variablesSetter('institutions')(institutions);
    }
  );
