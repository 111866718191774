import { wrapperBuilder } from './build';
// Definition of Wrapper reducer / reducers

wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer('Branch: Device resume', false, _ => (action, _1, state) => {
    if (action.type === 'resume') {
      return {
        ...state,
        resume: (state?.resume || 0) + 1,
      };
    }
    if (action.type === 'deepLinkClicked') {
      return {
        ...state,
        lastDeepLink: action.payload,
      };
    }
    return {
      ...state,
    };
  });
