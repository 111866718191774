/* eslint-disable react-hooks/exhaustive-deps */
import { WrapperBuilder } from '../../builders/wrapperBuilder';
import { useEffect } from 'react';
import { UserWrapper } from '../../entityWrappers/UserWrapper';
import { BackendWrapper } from '../../wrappers/BackendWrapper';
import { SessionWrapper } from '../../wrappers/SessionWrapper';
import { Context as UserWrapperContext } from '../../entityWrappers/UserWrapper/interfaces';
import { Context as BackendWrapperContext } from '../../wrappers/BackendWrapper/interfaces';
import { Context as SessionWrapperContext } from '../../wrappers/SessionWrapper/interfaces';
import { User } from '../../entityWrappers/UserWrapper/common';
import { CoreFetch } from '../../wrappers/BackendWrapper/common';
import { Logger } from '../../generic/utils';

const console = new Logger('customEntityWrapperCreator');

if (console) {
  //Just for usage
}

type Getter<T> = (
  sessiondata: any,
  user: User,
  coreFetch: CoreFetch
) => Promise<T[] | null>;

type Saver<T> = (
  item: T,
  sessiondata: any,
  user: User,
  coreFetch: CoreFetch
) => Promise<T | null>;

type ReloadItems = () => void;
type SaveItem<T> = (item: T) => void;

export class EntityWrapperBuilder<T extends { id: string }> {
  public wrapperBuilder: WrapperBuilder<
    {},
    {},
    {},
    {
      [x: string]: T[] | null | undefined;
    },
    {},
    {},
    { reloadItems: ReloadItems; saveItem: SaveItem<T> },
    any,
    any,
    any,
    BackendWrapperContext & UserWrapperContext & SessionWrapperContext,
    {}
  >;
  constructor(private name: string, private item: T) {
    console.log('item', this.item);
    this.wrapperBuilder = new WrapperBuilder(this.name, this.name, [
      UserWrapper,
      BackendWrapper,
      SessionWrapper,
    ]);
  }

  private _getter!: Getter<T>;
  public defineGetter(value: Getter<T>) {
    this._getter = value;
  }
  private _saver!: Saver<T>;
  public defineSaver(value: Saver<T>) {
    this._saver = value;
  }

  public build() {
    this.wrapperBuilder
      .getVariablesRegisterInstance()
      .addVariable(this.name, { startValue: undefined });
    this.wrapperBuilder
      .getFunctionsRegisterInstance()
      .addFunction(
        'reloadItems',
        ({
          variablesHandler: { variablesSetter },
          bindedContexts: { coreFetch, user, sessionData },
        }) => async () => {
          let items: T[] | null;
          if (user) {
            try {
              items = await this._getter(sessionData, user, coreFetch);
              console.log('Loaded ' + this.name, items);
            } catch (e) {
              items = null;
            }
            variablesSetter(this.name)(items);
          }
        }
      );
    this.wrapperBuilder
      .getFunctionsRegisterInstance()
      .addFunction(
        'saveItem',
        ({
          variablesHandler: { variablesSetter },
          bindedContexts: { coreFetch, user, sessionData },
        }) => async newItem => {
          if (user) {
            const itemSaved = await this._saver(
              newItem,
              sessionData,
              user,
              coreFetch
            );

            variablesSetter(this.name)(currentItems =>
              itemSaved
                ? [
                    ...(currentItems || []).filter(r => r.id !== itemSaved.id),
                    itemSaved,
                  ]
                : currentItems
            );
          }
        }
      );

    this.wrapperBuilder.getCustomLogicRegisterInstance().setLogic(
      ({
        functions: { reloadItems },
        variablesHandler: {
          variables: { targets },
          variablesSetter,
        },
        bindedContexts: { user },
      }) => {
        const userId = user && (user.id || user._id);

        useEffect(() => {
          if (userId) {
            console.log('Loading ' + this.name);
            reloadItems();
          } else if (!userId) {
            if (targets) {
              variablesSetter(this.name)(undefined);
            }
          }
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [userId]);
      }
    );

    return this.wrapperBuilder.build();
  }
}
