import { Builder } from '../common';
import { StaticsRegister } from './staticsRegister';

export class StaticsBuilder<Statics, Hooks> implements Builder {
  public wrapperName!: string;

  private _staticsRegister!: StaticsRegister<Statics, Hooks>;
  public set staticsRegister(value: StaticsRegister<Statics, Hooks>) {
    this._staticsRegister = value;
  }

  assign<T>(wrapperInstance: T): T & Statics & { hooks: Hooks | undefined } {
    const extendedInstance = wrapperInstance as T &
      Statics & { hooks: Hooks | undefined };
    this._staticsRegister.getKeys().forEach(varName => {
      const staticValue = this._staticsRegister.getStatic(varName);
      if ((varName as string).indexOf('_hook$') >= 0) {
        staticValue &&
          (extendedInstance['hooks'] = {
            ...extendedInstance['hooks'],
            [(varName as string).replace('_hook$', '')]: staticValue.prop,
          } as any);
      } else {
        staticValue &&
          (extendedInstance[varName as keyof Statics] = staticValue.prop);
      }
    });
    return extendedInstance;
  }
  isConfigured() {
    return this._staticsRegister && this._staticsRegister.hasRegistrations();
  }
}
