import { Register } from '../common';

export class VirtualsGeneratorsRegister<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  State,
  Virtuals,
  BindedContexts
> implements Register {
  private _virtualsGenerators: {
    virtualsGenerator: (wrapperEnvironment: {
      state?: State;
      variables: InternalVariables & Variables & ExternalVariables;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
    }) => Virtuals;
  }[] = [];

  addVirtualsGenerator(
    virtualsGenerator: (wrapperEnvironment: {
      state?: State;
      variables: InternalVariables & Variables & ExternalVariables;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
    }) => Virtuals
  ) {
    this._virtualsGenerators.push({ virtualsGenerator });
    return this;
  }

  getVirtualsGenerators() {
    return this._virtualsGenerators;
  }

  hasRegistrations() {
    return !!this._virtualsGenerators.length;
  }
}
