import { Logger, UserInput } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import _ from 'lodash';
import { wrapperBuilder } from './build';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type InstanciateNewtarget = (targetEspecification?: {
  targetType?: string;
  parentTarget?: string;
}) => Promise<void>;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'instanciateNewTarget',
    ({
      bindedContexts: { user, getInstanceApi, loadTargets },
    }) => async targetEspecification => {
      if (!user) {
        throw new Error('No user defined');
      }
      const createdTarget = await getInstanceApi().createTargetForUser(
        user.id,
        {
          parentTargetId: targetEspecification?.parentTarget,
          targetTypeId: targetEspecification?.targetType,
        }
      );
      await loadTargets();
      return createdTarget;
    }
  );

export type SaveTargetProperty = (
  idTarget: string,
  propName: string,
  data: UserInput
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetProperty',
    ({ bindedContexts: { targets, saveTargetProperty } }) => async (
      idTarget,
      propName,
      data
    ) => {
      const target = (targets || []).find(tp => tp.id === idTarget);
      const propData = (target?.targetProperties || []).find(
        tp => tp.name === propName
      );
      console.log('NEW TARGET DATA', propData, data);
      const newPropData = {
        ...propData?.value,
        ...data,
      };
      idTarget &&
        !_.isEqual(newPropData, propData) &&
        (await saveTargetProperty(idTarget, propName, newPropData));
    }
  );

export type SaveTargetData = (
  idTarget: string,
  data: { name?: string; image?: string }
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetData',
    ({ bindedContexts: { saveTargetData } }) => async (idTarget, data) => {
      return saveTargetData(idTarget, { name: data.name, img: data.image });
    }
  );

export type SaveTargetProductData = (
  idTarget: string,
  data: { definition?: string; productId?: string }
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetProductData',
    ({ bindedContexts: { saveTargetProductData } }) => async (
      idTarget,
      data
    ) => {
      return saveTargetProductData(idTarget, data);
    }
  );

export type Activate = (idTarget: string, miliSegs?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'activate',
    ({ bindedContexts: { activateTarget, loadTargets } }) => async (
      idTarget,
      milisegs
    ) => {
      await activateTarget(idTarget, milisegs);
      await loadTargets();
    }
  );

export type Finalize = (idTarget: string, miliSegs?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'finalize',
    ({ bindedContexts: { finalizeTarget, loadTargets } }) => async (
      idTarget,
      milisegs
    ) => {
      await finalizeTarget(idTarget, milisegs);
      await loadTargets();
    }
  );

export type Pause = (idTarget: string, miliSegs?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'pause',
    ({ bindedContexts: { pauseTarget, loadTargets } }) => async (
      idTarget,
      milisegs
    ) => {
      await pauseTarget(idTarget, milisegs);
      await loadTargets();
    }
  );
export type Reactivate = (
  idTarget: string,
  payload?: any,
  miliSegs?: number
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'reactivate',
    ({ bindedContexts: { reactivateTarget, loadTargets } }) => async (
      idTarget,
      payload,
      milisegs
    ) => {
      await reactivateTarget(idTarget, payload, milisegs);
      await loadTargets();
    }
  );

export type Cancel = (idTarget: string, miliSegs?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'cancel',
    ({ bindedContexts: { cancelTarget, loadTargets } }) => async (
      idTarget,
      milisegs
    ) => {
      await cancelTarget(idTarget, milisegs);
      await loadTargets();
    }
  );

export type GetTargetCustom = (
  idTarget: string,
  methodName: string,
  payload?: any
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'getTargetCustom',
    ({ bindedContexts: { getInstanceApi, loadTargets } }) => async (
      idTarget,
      methodName,
      payload
    ) => {
      await getInstanceApi().getTargetCustom(idTarget, methodName, payload);
      await loadTargets();
    }
  );

export type PostTargetCustom = (
  idTarget: string,
  methodName: string,
  payload?: any
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'postTargetCustom',
    ({ bindedContexts: { getInstanceApi, loadTargets } }) => async (
      idTarget,
      methodName,
      payload
    ) => {
      await getInstanceApi().postTargetCustom(idTarget, methodName, payload);
      await loadTargets();
    }
  );
