/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { Slider, makeStyles } from '@material-ui/core';
import { generateMaterialStyles } from '../../common';

const e = React.createElement;

const useSliderStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
  rail: (props: ComponentStyle) =>
    generateMaterialStyles('railComponent', props),
  mark: (props: ComponentStyle) =>
    generateMaterialStyles('markComponent', props),
  thumb: (props: ComponentStyle) =>
    generateMaterialStyles('thumbComponent', props),
  track: (props: ComponentStyle) =>
    generateMaterialStyles('trackComponent', props),
});

export const InputSlider: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  children,
  max,
  min,
  step,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled }
  );

  // Otros ----------------------------------------------------------------

  // Generación de estilos material basados en estilos UiWrapper
  const materialSlider = useSliderStyles(componentStyle);
  // Return -----------------------------------------------------
  return e(
    Slider,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        undefined,
        true
      ),
      classes: materialSlider,
      value: Number(value || min || 0),
      max,
      min,
      step,
      onChange: onChange
        ? (_, ev) => {
            let v = Number(ev);
            //console.log('Slider onchange', e, v);
            if (max && v > max) {
              v = max;
            }
            if (min && v < min) {
              v = min;
            }
            onChange(v);
          }
        : undefined,
    },
    children
  );
};
