import { uuidv4 } from '../../generic/utils';
import { wrapperBuilder } from './build';

export const chatApplicationToken =
  window &&
  window.__CLIENT_ENV &&
  window.__CLIENT_ENV.CLIENT_ENV_CHAT_APPLICATION_TOKEN;

const localStorageKey =
  'deviceChatId' +
  (window &&
    window.__CLIENT_ENV &&
    window.__CLIENT_ENV.CLIENT_ENV_API_BASE_URL);

let deviceId = localStorage.getItem(localStorageKey);
if (!deviceId) {
  const newDID = uuidv4();
  localStorage.setItem(localStorageKey, newDID);
  deviceId = newDID;
}

wrapperBuilder
  .getVariablesRegisterInstance()
  .addVariable('applicationToken', { startValue: undefined })
  .addVariable('chatOpened', { startValue: false })
  .addVariable('deviceChatId', { startValue: deviceId })
  .addVariable('liveChatInstance', { startValue: undefined })
  .addVariable('pendingMessages', {
    startValue: 0,
    persist: true,
  })
  .addVariable('userData', { startValue: undefined });
