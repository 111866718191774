import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(
    ({ bindedContexts: { institutionAccounts, institutions } }) => {
      return {
        institutionAccounts,
        institutions,
      };
    }
  );
