import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  containerComponent: {
    flexDirection: 'row',
    width: 'auto',
    height: 'auto',
  },
  checkComponent: {},
  labelComponent: {},
});

export default baseConfiguration;
