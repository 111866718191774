import { Logger } from '../../generic/utils';
import { wrapperIdentifier, Target } from './common';
import { wrapperBuilder } from './build';
import { targetsMapper } from './mappers';
import { DISPATCH_TYPES } from '../../generic/interfaces';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadTargets = (targetIds?: string[]) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadTargets',
    ({
      componentProps: {
        enableNextSaving,
        enableAvailableProducts,
        enableRecommendedSaving,
        enableTargetRulesConfiguration,
      },
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, targetTypes, user },
    }) => async targetIds => {
      let targets: Target[] | null;
      if (targetTypes && user) {
        try {
          const unmappedTargets: Target[] = await getInstanceApi().getUserTargets(
            user.id,
            targetIds
          );

          targets = targetsMapper(
            await Promise.all(
              unmappedTargets
                .filter(t =>
                  t.targetTypeId
                    ? targetTypes.find(tt => tt.id === t.targetTypeId)
                    : true
                )
                .map(async t => {
                  const [
                    nextSaving,
                    availableProducts,
                    recommended,
                    targetRulesConfiguration,
                  ] = await Promise.all([
                    new Promise(async res => {
                      try {
                        if (enableNextSaving) {
                          res(await getInstanceApi().getNextSaving(t.id));
                        }
                      } catch (e) {}
                      res(undefined);
                    }),
                    new Promise(async res => {
                      try {
                        if (enableAvailableProducts) {
                          res(
                            await getInstanceApi().getAvailableProductDefinitions(
                              t.id
                            )
                          );
                        }
                      } catch (e) {}
                      res(undefined);
                    }),
                    new Promise(async res => {
                      try {
                        if (enableRecommendedSaving) {
                          res(await getInstanceApi().getRecommended(t.id));
                        }
                      } catch (e) {}
                      res(undefined);
                    }),
                    new Promise(async res => {
                      try {
                        if (enableTargetRulesConfiguration) {
                          res(
                            await getInstanceApi().getLastTargetRulesConfiguration(
                              user.id,
                              t.id
                            )
                          );
                        }
                      } catch (e) {}
                      res(undefined);
                    }),
                  ]);
                  return {
                    ...t,
                    nextSaving,
                    availableProducts,
                    recommended,
                    targetRulesConfiguration,
                  };
                })
            )
          );
          console.log('Refreshed targets ', targets);
        } catch (e) {
          targets = null;
        }
        variablesSetter('targets')(oldTargets => {
          const aux = oldTargets?.map(
            old => targets?.find(t => t.id === old.id) || old
          );

          targets?.forEach(
            upsertTarget =>
              aux &&
              aux.findIndex(
                existentTarget => existentTarget.id === upsertTarget.id
              ) < 0 &&
              aux.push(upsertTarget)
          );
          return aux || targets;
        });
      }
    }
  );

export type SaveTargetData = (
  idTarget: string,
  data: { name?: string; img?: string }
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetData',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi },
    }) => async (idTarget, data) => {
      try {
        await getInstanceApi().saveTargetData(idTarget, data?.name, data?.img);
        variablesSetter('targets')(
          targets =>
            targets && [
              ...targets.map(t => {
                if (t.id !== idTarget) {
                  return t;
                } else {
                  return {
                    ...t,
                    image: data?.img || t.image,
                    name: data?.name || t.name,
                  };
                }
              }),
            ]
        );
      } catch (e) {}
    }
  );

export type SaveTargetProductData = (
  idTarget: string,
  data: { definition?: string; productId?: string }
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetProductData',
    ({
      stateHandler: { dispatch },
      bindedContexts: { getInstanceApi },
    }) => async (idTarget, data) => {
      if (data && data.definition) {
        await getInstanceApi().setTargetProductDefinition(
          idTarget,
          data.definition
        );
      }
      if (data && data.productId) {
        await getInstanceApi().setTargetProduct(idTarget, data.productId);
      }
      if (data && (data.productId || data.definition)) {
        dispatch({
          type: DISPATCH_TYPES.TARGET_PRODUCT_CONFIGURED,
          payload: idTarget,
        });
      }
    }
  );

export type SaveTargetProperty = (
  idTarget: string,
  propName: string,
  data: any
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetProperty',
    ({
      stateHandler: { dispatch },
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, user },
    }) => async (idTarget, propName, data) => {
      if (user) {
        try {
          if (propName === 'creationData') {
            await getInstanceApi().saveTargetCreationData(idTarget, data);
          } else if (propName === 'finalizationData') {
            await getInstanceApi().saveTargetFinalizationData(idTarget, data);
          } else if (propName === 'contributionData') {
            await getInstanceApi().saveTargetContributionData(idTarget, data);
          } else {
            console.log('## GOTO SAVING', propName, data);
            await getInstanceApi().saveTargetCustomPropertyData(
              idTarget,
              propName,
              data
            );
            console.log('## GOTO SAVED', propName, data);
          }
          console.log('## Saving data', propName, data);
          variablesSetter('targets')(
            targets =>
              targets && [
                ...targets.map(t => {
                  if (t.id !== idTarget) {
                    return t;
                  } else {
                    return {
                      ...t,
                      targetProperties: [
                        ...(t.targetProperties || []).filter(
                          tp => tp.name !== propName
                        ),
                        { name: propName, value: data },
                      ],
                      [propName]: data,
                    };
                  }
                }),
              ]
          );
          dispatch({
            type: DISPATCH_TYPES.TARGET_PROPERTY_CHANGED,
            payload: idTarget,
          });
        } catch (e) {
          console.log(
            '## ERROR?',
            e,
            Object.keys(e),
            JSON.stringify(e),
            e.stackTrace
          );
        }
      }
    }
  );

export type SaveTargetRulesConfiguration = (
  idTarget: string,
  data: any
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'saveTargetRulesConfiguration',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, user },
    }) => async (idTarget, data) => {
      if (user) {
        try {
          await getInstanceApi().createTargetRulesConfiguration(
            user.id,
            idTarget,
            data
          );
          variablesSetter('targets')(
            targets =>
              targets && [
                ...targets.map(t => {
                  if (t.id !== idTarget) {
                    return t;
                  } else {
                    return {
                      ...t,
                      targetRulesConfiguration: {
                        metadata: { ...data },
                        targetId: idTarget,
                        userId: user.id,
                        createDate: new Date(),
                      },
                    };
                  }
                }),
              ]
          );
        } catch (e) {
          console.log(
            '## ERROR?',
            e,
            Object.keys(e),
            JSON.stringify(e),
            e.stackTrace
          );
        }
      }
    }
  );

export type CreateTransfer = (
  idTarget: string,
  transfer: { sourceTargetId: string; amount: number }
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'createTransfer',
    ({
      coreDynamicFunctionGetter,
      bindedContexts: { getInstanceApi },
    }) => async (idTarget, transfer) => {
      const t = await getInstanceApi().createTargetTransfer(idTarget, transfer);
      await coreDynamicFunctionGetter('loadTargets')();
      return t;
    }
  );
export type BindProductMovement = (
  idTarget: string,
  productMovementId: string
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'bindProductMovement',
    ({
      coreDynamicFunctionGetter,
      bindedContexts: { getInstanceApi },
    }) => async (idTarget, productMovementId) => {
      const t = await getInstanceApi().bindProductMovementToTarget(
        idTarget,
        productMovementId
      );
      await coreDynamicFunctionGetter('loadTargets')();
      return t;
    }
  );

export type ActivateTarget = (idTarget: string, timeout?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('activateTarget', ({ //coreDynamicFunctionGetter,
    bindedContexts: { getInstanceApi }, stateHandler: { dispatch } }) => async idTarget => {
    await getInstanceApi().activateTarget(idTarget);
    dispatch({
      type: DISPATCH_TYPES.TARGET_ACTIVATED,
      payload: idTarget,
    });
    //await coreDynamicFunctionGetter('loadTargets')();
  });

export type PauseTarget = (idTarget: string, timeout?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('pauseTarget', ({ //coreDynamicFunctionGetter,
    bindedContexts: { getInstanceApi }, stateHandler: { dispatch } }) => async idTarget => {
    await getInstanceApi().pauseTarget(idTarget);
    dispatch({
      type: DISPATCH_TYPES.TARGET_PAUSED,
      payload: idTarget,
    });
    //await coreDynamicFunctionGetter('loadTargets')();
  });

export type ReactivateTarget = (
  idTarget: string,
  payload?: any,
  timeout?: number
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('reactivateTarget', ({ //coreDynamicFunctionGetter,
    bindedContexts: { getInstanceApi }, stateHandler: { dispatch } }) => async (idTarget, payload) => {
    await getInstanceApi().reactivateTarget(idTarget, payload);
    dispatch({
      type: DISPATCH_TYPES.TARGET_REACTIVATED,
      payload: idTarget,
    });
    //await coreDynamicFunctionGetter('loadTargets')();
  });
export type FinalizeTarget = (idTarget: string, timeout?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('finalizeTarget', ({ //coreDynamicFunctionGetter,
    bindedContexts: { getInstanceApi }, stateHandler: { dispatch } }) => async idTarget => {
    await getInstanceApi().finalizeTarget(idTarget);
    dispatch({
      type: DISPATCH_TYPES.TARGET_FINISHED,
      payload: idTarget,
    });
    //await coreDynamicFunctionGetter('loadTargets')();
  });
export type CancelTarget = (idTarget: string, timeout?: number) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('cancelTarget', ({ //coreDynamicFunctionGetter,
    bindedContexts: { getInstanceApi }, stateHandler: { dispatch } }) => async idTarget => {
    await getInstanceApi().cancelTarget(idTarget);
    dispatch({
      type: DISPATCH_TYPES.TARGET_CANCELED,
      payload: idTarget,
    });
    //await coreDynamicFunctionGetter('loadTargets')();
  });
