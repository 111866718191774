import React, { FunctionComponent } from 'react';
/*import { View } from '../../View';
import { useProvider } from '../../../../../generic';
import { LayoutContext } from '.';
import { generateProperties, COMPONENTS } from '../../common';*/

const e = React.createElement;

export const Container: FunctionComponent<{
  style?: React.CSSProperties;
}> = ({ children }) => {
  return e(React.Fragment, {}, children);
  /*const context = useProvider(LayoutContext);
  const { overrided, predefinedLayout } = context || {};

  return e(RawContainer, { style, overrided, predefinedLayout }, children);*/
};
/*
export const RawContainer: FunctionComponent<{
  style?: React.CSSProperties;
  overrided?: boolean;
  predefinedLayout?: string;
}> = ({ children, style, overrided, predefinedLayout }) => {
  let props = {};
  let component: any = React.Fragment;
  switch ((overrided || false).toString() + ':' + predefinedLayout) {
    case 'false:HeaderFooter':
      component = View;
      props = {
        ...generateProperties(
          undefined,
          COMPONENTS.view,
          'container',
          'Layout_Container'
        ),
        cleared: true,
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          flexBasis: '100%',
          overflow: 'hidden visible',
          ...style,
        },
      };
      break;
    case 'false:Simple':
      component = View;
      props = {
        ...generateProperties(
          undefined,
          COMPONENTS.view,
          'container',
          'Layout_Container'
        ),
        cleared: true,
        style: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
          flexBasis: '100%',
          overflow: 'hidden visible',
          ...style,
        },
      };
      break;
    default:
  }

  return e(component, props, children);
};
*/
