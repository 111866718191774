import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder.getCustomLogicRegisterInstance().setLogic(
  ({
    functions: { loadTargets },
    variablesHandler: {
      variables: { targets },
      variablesSetter,
    },
    bindedContexts: { targetTypes, user },
  }) => {
    const userId = user && user.id;

    useEffect(() => {
      if (userId && targetTypes) {
        console.log('Loading targets');
        loadTargets();
      } else if (!userId) {
        if (targets) {
          variablesSetter('targets')(undefined);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, targetTypes?.length || 0]);
  }
);
