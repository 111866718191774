import { WrapperBuilder } from '../../builders/wrapperBuilder';
import { ComponentProps, F, IF, IV, S, V, VR, EF, EV, ST } from './interfaces';

export const wrapperBuilder = new WrapperBuilder<
  ComponentProps,
  IV,
  V,
  EV,
  IF,
  F,
  EF,
  S,
  VR,
  ST,
  {},
  {}
>('testingWrapper', 'testingWrapper');
