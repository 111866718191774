import React from 'react';
import hyphenate from 'hyphenate-style-name';
import areObjectsEqual from 'shallow-equal/objects';
import toQuery from './toQuery';
import Context from './Context';
const matchMedia = require('matchmediaquery');

const makeQuery = (settings: any) => settings.query || toQuery(settings);

const hyphenateKeys = (obj: any) => {
  if (!obj) return null;
  const keys = Object.keys(obj);
  if (keys.length === 0) return null;
  return keys.reduce((result, key) => {
    result[hyphenate(key)] = obj[key];
    return result;
  }, {} as any);
};

const useIsUpdate = () => {
  const ref = React.useRef(false);

  React.useEffect(() => {
    ref.current = true;
  }, []);

  return ref.current;
};

const useDevice = (deviceFromProps: any) => {
  const deviceFromContext = React.useContext(Context);
  const getDevice = () =>
    hyphenateKeys(deviceFromProps) || hyphenateKeys(deviceFromContext);
  const [device, setDevice] = React.useState(getDevice);

  React.useEffect(() => {
    const newDevice = getDevice();
    if (!areObjectsEqual(device, newDevice)) {
      setDevice(newDevice);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceFromProps, deviceFromContext]);

  return device;
};

const useQuery = (settings: any) => {
  const getQuery = () => makeQuery(settings);
  const [query, setQuery] = React.useState(getQuery);

  React.useEffect(() => {
    const newQuery = getQuery();
    if (query !== newQuery) {
      setQuery(newQuery);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  return query;
};

const useMatchMedia = (query: any, device: any) => {
  const getMatchMedia = matchMedia(query, device || {}, !!device);
  const [$mediaQueries, setMq] = React.useState(getMatchMedia);
  const isUpdate = useIsUpdate();

  React.useEffect(() => {
    if (isUpdate) {
      // skip on mounting, it has already been set
      setMq(getMatchMedia());
    }

    return () => {
      $mediaQueries.dispose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, device]);

  return $mediaQueries;
};

const useMatches = (mediaQuery: any) => {
  const [matches, setMatches] = React.useState(mediaQuery.matches);

  React.useEffect(() => {
    const updateMatches = () => {
      setMatches(mediaQuery.matches);
    };
    mediaQuery.addListener(updateMatches);
    updateMatches();

    return () => {
      mediaQuery.removeListener(updateMatches);
    };
  }, [mediaQuery]);

  return matches;
};

const useMediaQuery = (settings: any, device?: any, onChange?: any) => {
  const deviceSettings = useDevice(device);
  const query = useQuery(settings);
  if (!query) throw new Error('Invalid or missing MediaQuery!');
  const $mediaQueries = useMatchMedia(query, deviceSettings);
  const matches = useMatches($mediaQueries);
  const isUpdate = useIsUpdate();

  React.useEffect(() => {
    if (isUpdate && onChange) {
      onChange(matches);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matches]);

  return matches;
};

export default useMediaQuery;
