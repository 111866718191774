/* eslint-disable @typescript-eslint/no-unused-expressions */
import { cordovaAndroid } from './android/cordova';
//import { definitionAndroid } from './android/cordova_plugins';

import { cordovaIos } from './ios/cordova';
//import { definitionIos } from './ios/cordova_plugins';

export const initialize = (os: string) => {
  console.log('INITIALIZING', os);

  os === 'android' ? cordovaAndroid() : os === 'ios' ? cordovaIos() : null;
  /*os === 'android'
    ? definitionAndroid()
    : os === 'ios'
    ? definitionIos()
    : null;*/
};
