export const userMapper = <T>(
  userReceived: T,
  { nifLocationBaseUrl }: any
): T => {
  const user = userReceived as any;
  //Generate nifs urls
  user &&
    user.nif &&
    user.nif.frontImage &&
    (user.nif.frontImage =
      nifLocationBaseUrl + 'uploads/nifs/' + user.nif.frontImage);
  user &&
    user.nif &&
    user.nif.backImage &&
    (user.nif.backImage =
      nifLocationBaseUrl + 'uploads/nifs/' + user.nif.backImage);
  return user;
};
