import { Register } from '../common';

export class StaticsRegister<Statics, Hooks> implements Register {
  private _props: {
    name: keyof (Statics & Hooks);
    prop: any;
  }[] = [];

  addStaticProp<T extends keyof Statics>(name: T, prop: Statics[T]) {
    this._props.push({ name, prop });
  }

  addHook<T extends keyof Hooks>(name: T, prop: Hooks[T]) {
    this._props.push({ name: (name + '_hook$') as any, prop });
  }

  getKeys() {
    return this._props.map(v => v.name);
  }
  getStatic<T extends keyof (Statics & Hooks)>(propName: T) {
    return this._props.find(varDef => varDef.name === propName);
  }
  hasRegistrations() {
    return !!this._props.length;
  }
}
