import { Builder, CoreStateHandler, CoreVariablesHandler } from '../common';
import { FunctionsRegister } from './functionsRegister';
import { FunctionBuilder } from './functionBuilder';

export class FunctionsBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts,
  DispatchTypes
> implements Builder {
  public wrapperName!: string;

  public stateHandler!: CoreStateHandler<State, DispatchTypes>;
  public variablesHandler!: CoreVariablesHandler<
    InternalVariables,
    Variables,
    ExternalVariables
  >;
  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;
  public virtuals!: Virtuals;

  private _functionsRegister!: FunctionsRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    InternalFunctions,
    Functions,
    ExternalFunctions,
    State,
    Virtuals,
    BindedContexts,
    DispatchTypes
  >;
  public set functionsRegister(
    value: FunctionsRegister<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      InternalFunctions,
      Functions,
      ExternalFunctions,
      State,
      Virtuals,
      BindedContexts,
      DispatchTypes
    >
  ) {
    this._functionsRegister = value;
  }

  build() {
    let functionsObject = {} as InternalFunctions &
      Functions &
      ExternalFunctions;
    const functionBuilder = new FunctionBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      InternalFunctions,
      Functions,
      ExternalFunctions,
      State,
      Virtuals,
      BindedContexts,
      DispatchTypes,
      any
    >();
    functionBuilder.variablesHandler = this.variablesHandler;
    functionBuilder.stateHandler = this.stateHandler;
    functionBuilder.componentProps = this.componentProps;
    functionBuilder.bindedContexts = this.bindedContexts;

    this._functionsRegister.getKeys().forEach(varName => {
      functionBuilder.setConstructor(
        this._functionsRegister.getFunctionBuilder(varName as any)
      );
      functionBuilder.coreDynamicFunctionGetter = funcName => {
        if (functionsObject[funcName]) {
          return functionsObject[funcName];
        }
        const dynamicBuilder = new FunctionBuilder<
          ComponentPropsDefinition,
          InternalVariables,
          Variables,
          ExternalVariables,
          InternalFunctions,
          Functions,
          ExternalFunctions,
          State,
          Virtuals,
          BindedContexts,
          DispatchTypes,
          any
        >();

        dynamicBuilder.variablesHandler = this.variablesHandler;
        dynamicBuilder.stateHandler = this.stateHandler;
        dynamicBuilder.componentProps = this.componentProps;
        dynamicBuilder.bindedContexts = this.bindedContexts;
        dynamicBuilder.setConstructor(
          this._functionsRegister.getFunctionBuilder(funcName as any)
        );
        return functionBuilder.build();
      };
      functionsObject[varName] = functionBuilder.build();
    });

    return functionsObject;
  }

  isConfigured() {
    return (
      this._functionsRegister && this._functionsRegister.hasRegistrations()
    );
  }
}
