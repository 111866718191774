import {
  LayoutTypes,
  LayoutStyle,
  LayoutExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { ModalGenerator, LoadingGenerator, AlertGenerator } from '../../common';
import { COMPONENTS } from '../common';
import { HeaderFooterLayoutProps } from './others/HeaderFooterLayout';
import { SimpleLayoutProps } from './others/SimpleLayout';
import { FunctionComponent } from 'react';
export const enumKey = COMPONENTS.layout;

export interface ClassProperties {
  Container: FunctionComponent<{}>;
}

export type ComponentStyle = LayoutStyle;
export type ExpectedStyles = LayoutExpectedStyles;
export type Props = GenericComponentProps<LayoutStyle> & {
  // Indica cual de los layout predefinidos ya creados se quiere utilizar, def: HeaderFooter
  predefinedLayout?: LayoutTypes;

  // Si se especifica, sobreescribe cualquier layout predefinido para usar este, def: undefined
  layoutOverride?: React.ReactNode;

  // Propieades aplicadas al Layout interno (sea uno de los predefinidos o overrided)
  implementationProps?: HeaderFooterLayoutProps &
    SimpleLayoutProps &
    /* ... & others in the future */
    any; // this is for the overrided layouts, any props for them

  // Estilos en linea a aplicar al layout predefinido

  // Función generadora de modales para este layout
  modalGenerator?: ModalGenerator;

  // Función generadora de loading para este layout
  loadingGenerator?: LoadingGenerator;

  // Función generadora de alertas para este layout
  alertGenerator?: AlertGenerator;
};
