import { Logger } from '../../generic/utils';
import { wrapperBuilder } from './build';
import { wrapperIdentifier } from './common';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

const functionsRegister = wrapperBuilder.getFunctionsRegisterInstance();

functionsRegister.addFunction(
  'startPayment',
  ({
    bindedContexts: { getInstanceApi, loadContributions, user },
    variablesHandler: {
      variablesSetter,
      variables: { contribution },
    },
  }) => {
    const api = getInstanceApi();
    return async () => {
      if (!contribution) {
        return;
      }
      try {
        variablesSetter('paymentStatus')('STARTING');
        console.log('ImplementThistoo', api);
        /*const response = await api.payContribution(
          contribution.productParticipationId,
          contribution.id
        );
        if (response.status === 'ERROR') {
          variablesSetter('paymentStatus')('ERROR');
          throw new Error(response.message);
        } else if (response.status === 'SUCCESS') {
          variablesSetter('paymentStatus')('SUCCESS');
        } else {
          variablesSetter('paymentStatus')('CONTINUE');
        }
        variablesSetter('paymentMessage')(response.message);*/
      } catch (e) {
        variablesSetter('paymentStatus')('ERROR');
        variablesSetter('paymentMessage')('Error desconocido');
      }
      try {
        if (loadContributions && user) {
          await loadContributions(user._id || user.id);
        }
      } catch (e) {}
    };
  }
);

functionsRegister.addFunction(
  'continuePayment',
  ({
    bindedContexts: { getInstanceApi, loadContributions, user },
    variablesHandler: {
      variablesSetter,
      variables: { contribution },
    },
  }) => {
    const api = getInstanceApi();
    return async secondFactor => {
      if (!contribution) {
        return;
      }
      try {
        variablesSetter('paymentStatus')('PROCESSING');
        console.log('ImplementThistoo2', api, secondFactor);
        /*const response = await api.payContribution(
          contribution.productParticipationId,
          contribution.id,
          secondFactor && { secondFactor }
        );
        if (response.status === 'ERROR') {
          variablesSetter('paymentStatus')('ERROR');
        } else if (response.status === 'SUCCESS') {
          variablesSetter('paymentStatus')('SUCCESS');
        } else {
          variablesSetter('paymentStatus')('CONTINUE');
        }
        variablesSetter('paymentMessage')(response.message);*/
      } catch (e) {
        variablesSetter('paymentStatus')('ERROR');
        variablesSetter('paymentMessage')('Error desconocido');
      }
      try {
        if (loadContributions && user) {
          await loadContributions(user._id || user.id);
        }
      } catch (e) {}
    };
  }
);
