import { VirtualsGeneratorsRegister } from './virtualsGeneratorsRegister';
import { VirtualsGeneratorBuilder } from './virtualsGeneratorBuilder';
import { Builder } from '../common';

export class VirtualsGeneratorsBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  State,
  Virtuals,
  BindedContexts
> implements Builder {
  public wrapperName!: string;

  public state?: State;
  public variables!: InternalVariables & Variables & ExternalVariables;

  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;

  private _virtualsGeneratorsRegister!: VirtualsGeneratorsRegister<
    ComponentPropsDefinition,
    InternalVariables,
    Variables,
    ExternalVariables,
    State,
    Virtuals,
    BindedContexts
  >;
  public set virtualsGeneratorsRegister(
    value: VirtualsGeneratorsRegister<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      State,
      Virtuals,
      BindedContexts
    >
  ) {
    this._virtualsGeneratorsRegister = value;
  }

  build() {
    const virtualsGeneratorBuilder = new VirtualsGeneratorBuilder<
      ComponentPropsDefinition,
      InternalVariables,
      Variables,
      ExternalVariables,
      State,
      Virtuals,
      BindedContexts
    >();
    return this._virtualsGeneratorsRegister
      .getVirtualsGenerators()
      .reduce((acc, { virtualsGenerator }) => {
        virtualsGeneratorBuilder.variables = this.variables;
        virtualsGeneratorBuilder.state = this.state;
        virtualsGeneratorBuilder.componentProps = this.componentProps;
        virtualsGeneratorBuilder.bindedContexts = this.bindedContexts;
        virtualsGeneratorBuilder.setConstructor(virtualsGenerator);
        return { ...acc, ...virtualsGeneratorBuilder.build() };
      }, {} as Virtuals);
  }

  isConfigured() {
    return (
      this._virtualsGeneratorsRegister &&
      this._virtualsGeneratorsRegister.hasRegistrations()
    );
  }
}
