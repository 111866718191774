/* eslint-disable no-unused-vars */
import React, { FunctionComponent, useState } from 'react';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import {
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
  makeStyles,
} from '@material-ui/core';
import { generateMaterialStyles } from '../../common';
import { InternationalizationWrapper } from '../../../InternationalizationWrapper';

const e = React.createElement;

const useContainerStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
});

const useSelectStyles = makeStyles({
  root: (props: ComponentStyle) =>
    generateMaterialStyles('selectComponent', props),
  icon: (props: ComponentStyle) =>
    generateMaterialStyles('iconComponent', props),
  /*select: (props: ComponentStyle) => {
    return {
      ...props.selectStyle,
    };
  },*/
});

const useLabelStyles = makeStyles({
  // style rule
  root: (props: ComponentStyle) =>
    generateMaterialStyles('labelComponent', props, false, [
      '&.MuiInputLabel-marginDense',
    ]),
});

const useMenuStyles = makeStyles({
  // style rule
  paper: (props: ComponentStyle) =>
    generateMaterialStyles('menuContainerComponent', props),
});

export const InputSelect: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value,
  style,
  disabled,
  label,
  children,
  error,
  autoFocus,
  selectIcon,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  const [focused, setFocused] = useState(false);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    {
      noLabel: !label,
      filled: !!value,
      focused,
      error: !!error,
      disabled,
    }
  );

  // Otros ----------------------------------------------------------------

  // Generación de estilos material basados en estilos UiWrapper
  const materialContainer = useContainerStyles(componentStyle);
  const materialSelect = useSelectStyles(componentStyle);
  const materialLabel = useLabelStyles(componentStyle);
  const materialMenu = useMenuStyles(componentStyle);

  const t =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use().t) ||
    ((k: any) => k);

  // Return -----------------------------------------------------
  return e(
    FormControl,
    {
      ...generateProperties(
        parentReference,
        enumKey,
        componentReference,
        coreId,
        id,
        undefined,
        true
      ),
      classes: materialContainer,
      error: !!error,
      margin: 'dense',
      variant: InputSelect.variant || 'filled',
    },
    label && e(InputLabel, { classes: materialLabel }, t(label)),
    e(
      Select,
      {
        onChange: (e: any) => {
          onChange && onChange(e);
        },
        disabled,
        value,
        disableUnderline: true,
        margin: 'dense',
        autoFocus,
        variant: InputSelect.variant || 'filled',
        classes: materialSelect,
        style: componentStyle.selectContainerComponent,
        IconComponent: selectIcon,
        onFocus: () => {
          setFocused(true);
        },
        onBlur: () => {
          setFocused(false);
        },
        inputProps: {
          ...generateProperties(
            parentReference,
            enumKey,
            'select',
            undefined,
            undefined,
            true
          ),
        },
        MenuProps: {
          ...generateProperties(
            parentReference,
            enumKey,
            'menuContainer',
            undefined,
            undefined,
            undefined,
            true
          ),
          classes: materialMenu,
        },
      },
      children
    ),
    error && e(FormHelperText, {}, t(error))
  );
};

InputSelect.variant = 'filled';
