import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  containerComponent: {
    display: 'flex',
    width: 'auto',
    flexFlow: 'row',
    minWidth: 'fit-content',
    alignItems: 'baseline',
  },
});

export default baseConfiguration;
