export const wrapperIdentifier = 'biometrics';

export interface BiometricsOptions {
  title?: string;
  description?: string;
  subtitle?: string;
  fallbackButtonTitle?: string;
  disableBackup?: boolean;
  cancelButtonTitle?: string;
}

export type BiometricsOptionsBuilder = (
  biometriType: string
) => BiometricsOptions;

export type ShowOptions = BiometricsOptions | BiometricsOptionsBuilder;
