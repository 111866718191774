import {
  OnChange,
  InputValue,
  InputSwipeStyle,
  Disabled,
  InputSwipeExpectedStyles,
  GenericComponentProps,
  InputLabel,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
import { SwipeItem } from '../SwipeItem';
export const enumKey = COMPONENTS.inputSwipe;

export interface ClassProperties {}
export type ComponentStyle = InputSwipeStyle;
export type ExpectedStyles = InputSwipeExpectedStyles;
export type Props = GenericComponentProps<InputSwipeStyle> & {
  disabled?: Disabled;
  onChange?: OnChange;
  value?: InputValue;
  label?: InputLabel;
  children?: typeof SwipeItem[];
  vertical?: boolean;
};
