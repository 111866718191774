/* eslint-disable no-unused-vars */
import React, { FunctionComponent } from 'react';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import { InternationalizationWrapper } from '../../../InternationalizationWrapper';

const e = React.createElement;

export const Input: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  style,
  onChange,
  value,
  defaultValue,
  type,
  disabled,
  placeholder,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled }
  );

  const t =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use().t) ||
    ((k: any) => k);
  // Return -----------------------------------------------------
  return e('input', {
    ...generateProperties(
      parentReference,
      enumKey,
      componentReference,
      coreId,
      id,
      true
    ),
    type: type,
    placeholder: t(placeholder),
    value,
    disabled,
    defaultValue,
    onFocus: (e: any) => {
      // El tipo email no permite seleccionarlo
      if (type !== 'email') {
        //TODO: En escritorio, abre el menu contextual?!?
        e.target.setSelectionRange(0, e.target.value.length);
      }
    },
    onChange: (e: any) => {
      if (type === 'number' || type === 'tel') {
        e.target.value = (isNaN(Number(e.target.value))
          ? ''
          : Number(e.target.value)) as any;
      }
      //setValue(e.target.value);
      onChange && onChange(e);
    },
    class: styleNames,
    style: componentStyle,
  });
};
