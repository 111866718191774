import { useEffect } from 'react';
import { useInitialize } from '../../generic/use';
import { Logger, ExternalVar } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

declare var cordova: ExternalVar;

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export let nativeBridge = false;

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(
    ({
      componentProps: { enableNativeBridge, enableSslPinning },
      bindedContexts: { isNative },
    }) => {
      nativeBridge = enableNativeBridge || false;
      useEffect(() => {
        if (nativeBridge && isNative) {
          cordova.plugin.http.setServerTrustMode(
            enableSslPinning ? 'pinned' : 'default',
            () => console.log('ºº SSL HANDLED'),
            (e: string) => console.log('ºº FAILED HANDLING SSL ', e)
          );
        }
      }, [enableNativeBridge, enableSslPinning, isNative]);
      useInitialize(() => {
        console.log('INITING ' + wrapperIdentifier + ' Wrapper');
      }, true);
    }
  );
