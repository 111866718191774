import { ImagesResolver, ImageUrl } from '../interfaces';

export interface GalleryImage {
  key: string;
  weight: number;
  images: string[];
}

export class LocalGalleryResolver extends ImagesResolver {
  private existenceCache: { [k: string]: boolean } = {};

  constructor(
    public imagesSource: GalleryImage[] = [],
    public galleryLocation: string = '/gallery',
    public checkExistence: boolean = false
  ) {
    super();
  }

  async resolveImages(search: string | undefined): Promise<ImageUrl[]> {
    const terms = search?.split(' ');
    const coincidences = (terms || [])?.flatMap(term =>
      this.imagesSource.filter(
        item =>
          term.trim() &&
          item.key.toLowerCase().includes(term.trim().toLowerCase())
      )
    );
    const results = Object.values(
      coincidences.reduce((res, coincidence) => {
        let key = `${coincidence.key}`;
        res[key] = res[key] || { ...coincidence, count: 0 };
        res[key].count++;
        return res;
      }, {} as { [k: string]: GalleryImage & { count: number } })
    )
      .sort((a, b) => b.count - a.count || b.weight - a.weight)
      .map(result => {
        return result.images.map(image => {
          return `${process.env.PUBLIC_URL}/${this.galleryLocation}/${image}`.replace(
            '//',
            '/'
          );
        });
      })
      .flat()
      .filter(image => {
        if (!this.checkExistence) {
          return true;
        }
        const url = image.toString();
        if (this.existenceCache[url]) {
          return true;
        } else if (this.existenceCache[url] === false) {
          return false;
        } else {
          let exsits = imageExists(url);
          this.existenceCache[url] = exsits;
          // TODO: Como filtrar los que no carga, sin demasiada sobrecarga?
          // this.imagesSource.filter((item) => {
          //   return item.;
          // }
          // console.log(this.imagesSource);
          if (!exsits) {
            console.error('ImageSelector, imagen no existe', image);
          }
          return exsits;
        }
      })
      .flat()
      .map(image => ({ url: image }));

    return results;
  }
}

function imageExists(image_url: string) {
  var http = new XMLHttpRequest();

  http.open('HEAD', image_url, false);
  http.send();

  return http.status !== 404;
}
