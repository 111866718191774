import {
  ImageSelectorStyle,
  OnError,
  OnChange,
  ImageSelectorExpectedStyles,
  GenericComponentProps,
  Disabled,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
import { Props as TextFieldProps } from '../TextField/interface';
import { Props as TextProps } from '../Text/interface';
import { Props as ButtonProps } from '../Button/interface';
import { FunctionComponent } from 'react';
export const enumKey = COMPONENTS.imageSelector;

export interface ClassProperties {}

export type ComponentStyle = ImageSelectorStyle;
export type ExpectedStyles = ImageSelectorExpectedStyles;
export type Props = GenericComponentProps<ImageSelectorStyle> & {
  //Functionality props
  onChange?: OnChange;
  onError?: OnError;
  value?: string;
  disabled?: Disabled;
  //Configuration props
  captions?: {
    buttonLabel?: string;
    defaultSearch?: string;
    noResultsText?: string;
    emptyImagesText?: string;
    headerText?: string;
    defaultImageSuggestionText?: string;
  };
  lengthSearch?: number;
  defaultImages?: string[];

  //Components overridables
  openerComponent?: FunctionComponent<{ open: () => void }>;
  headerComponent?: FunctionComponent<{ close: () => void }>;

  // META Configuration props of internal components
  SearchFieldProps?: TextFieldProps;
  EmptyImagesTextProps?: TextProps;
  DefaultImageSuggestionTextProps?: TextProps;
  NoResultsTextProps?: TextProps;
  SearchButtonProps?: ButtonProps;
};
