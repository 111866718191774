import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  containerComponent: {
    flexShrink: 1,
    color: _theme?.colors?.defaultFontColor,
  },
  suggestionsListComponent: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
  },
  sectionContainerComponent: {
    width: '100%',
  },
  sectionTitleTextComponent: {
    textAlign: 'end',
  },
});

export default baseConfiguration;
