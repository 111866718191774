import {
  OnClick,
  TextStyle,
  TextExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.text;

export interface ClassProperties {}

export type ComponentStyle = TextStyle;
export type ExpectedStyles = TextExpectedStyles;
export type Props = GenericComponentProps<TextStyle> & {
  noT?: boolean;
  onClick?: OnClick;
};
