/* eslint-disable no-undef */
export const init = () => {
  cordova.define('cordova-plugin-iroot.IRoot', function(
    require,
    exports,
    module
  ) {
    var exec = require('cordova/exec');

    module.exports = {
      isRooted: function(onSuccess, onError) {
        exec(onSuccess, onError, 'IRoot', 'isRooted', []);
      },
      isRootedWithBusyBox: function(onSuccess, onError) {
        exec(onSuccess, onError, 'IRoot', 'isRootedWithBusyBox', []);
      },
    };
  });
};
