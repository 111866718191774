import { Logger } from '../../generic/utils';
import { useEffect } from 'react';
import { wrapperIdentifier } from '../../wrappers/ApplicationWrapper/common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
const logic = wrapperBuilder.getCustomLogicRegisterInstance();

logic.setLogic(
  ({
    functions: { loadUserIdentityDocuments },
    bindedContexts: { user },
    variablesHandler: {
      variablesSetter,
      variables: { identityDocuments },
    },
  }) => {
    const userId = user && (user._id || user.id);

    useEffect(() => {
      if (userId) {
        console.log('Loading identityDocuments');
        loadUserIdentityDocuments(userId);
      } else if (!userId) {
        if (identityDocuments) {
          variablesSetter('identityDocuments')(undefined);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);
  }
);
