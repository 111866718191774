import React from 'react';
import { Logger } from '../../generic/utils';
import 'moment/locale/es';
import { NavigationWrapper } from '../NavigationWrapper';
import { Page } from '../NavigationWrapper/export';
import { Redirect } from './others/redirect';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getCustomRenderRegisterInstance()
  .setRenderer(
    ({ componentProps: props, variables: context }) => (e, children) => {
      const navigationContext = NavigationWrapper.use();
      const usedDefinedEntryPoint = props.entryPoint;

      console.log('ºº SessionContext', context.sessionStatus);
      const pageWithRedirectAction =
        navigationContext &&
        usedDefinedEntryPoint &&
        e(Page, {
          path: '/',
          disableRenderNotification: true,
          name: '_base',
          component: () =>
            e(
              Redirect,
              {
                entryPoint: usedDefinedEntryPoint,
                sessionData: context.sessionData,
              },
              null
            ),
        });

      return e(React.Fragment, {}, pageWithRedirectAction, children);
    }
  );
