import { UserWrapper } from '../../entityWrappers/UserWrapper';
import { BackendWrapper } from '../../wrappers/BackendWrapper';
import { InstitutionAccountsWrapper } from '../../entityWrappers/InstitutionAccountsWrapper';
import { InstitutionsWrapper } from '../../entityWrappers/InstitutionsWrapper';
import { OperationsWrapper } from '../../entityWrappers/OperationsWrapper';
import { wrapperIdentifier } from './common';
import { WrapperBuilder } from '../../builders/wrapperBuilder';
import {
  ComponentWrapperProps,
  InternalContextVariables,
  ExternalContextVariables,
  InternalContextFunctions,
  ExternalContextFunctions,
  ContextState,
  Virtuals,
  Statics,
  BindedWrapperProperties,
} from './interfaces';

export const wrapperBuilder = new WrapperBuilder<
  ComponentWrapperProps,
  InternalContextVariables,
  {},
  ExternalContextVariables,
  InternalContextFunctions,
  {},
  ExternalContextFunctions,
  ContextState,
  Virtuals,
  Statics,
  BindedWrapperProperties,
  {}
>(wrapperIdentifier, wrapperIdentifier, [
  UserWrapper,
  BackendWrapper,
  InstitutionAccountsWrapper,
  InstitutionsWrapper,
  OperationsWrapper,
]);
