import { Logger } from '../../../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadVinculation = (idVinculation: string) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadVinculation',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, getInstanceApiForVinculation },
    }) => async idVinculation => {
      const v = await getInstanceApi().getVinculation(idVinculation);
      variablesSetter('vinculation')(v);
      const instance = await getInstanceApiForVinculation(idVinculation);
      variablesSetter('vinculationApiInstance')(instance);
    }
  );
