import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(
    ({ bindedContexts: { ruleDefinitions }, variables: { rules } }) => {
      return {
        rules:
          rules &&
          rules.map(r => {
            r.ruleDefinition = (ruleDefinitions || []).find(
              rd => rd.id === r.ruleDefinitionId
            );
            r.value = (r.ruleValues || [])[0];
            return r;
          }),
      };
    }
  );
