import { Logger } from '../../generic/utils';
import { wrapperIdentifier, Rule, FieldValue, RuleValue } from './common';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

export type LoadRules = () => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loadRules',
    ({
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, user },
    }) => async () => {
      if (!user?.id) {
        return;
      }
      let rules: Rule[] | null;
      try {
        rules = await getInstanceApi().getUserRules(user?.id);
        console.log('Loaded rules ', rules);
      } catch (e) {
        rules = null;
      }
      variablesSetter('rules')(rules);
    }
  );

export type UpsertRule = (
  ruleIdentifier: string,
  userId: string,
  idTarget: string,
  fieldValues: FieldValue[],
  isActive?: boolean
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'upsertRule',
    ({
      stateHandler: { dispatch },
      coreDynamicFunctionGetter,
      variablesHandler: { variablesSetter },
      bindedContexts: { getInstanceApi, ruleDefinitions },
    }) => async (ruleIdentifier, userId, targetId, fieldValues, isActive) => {
      if (ruleDefinitions) {
        const ruleDefinition = (ruleDefinitions || []).find(
          rd => rd.identifier === ruleIdentifier
        );
        if (!ruleDefinition) {
          throw new Error('No rule definition');
        }
        const rule: Rule = await getInstanceApi().upsertRule(
          userId,
          targetId,
          ruleDefinition?.id
        );
        const ruleValue: RuleValue = await getInstanceApi().updateRuleValue(
          rule.id,
          fieldValues
        );
        rule.value = ruleValue;

        if (isActive !== undefined && isActive !== null) {
          if (isActive) {
            await coreDynamicFunctionGetter('activateRule')(
              ruleIdentifier,
              targetId
            );
          } else {
            await coreDynamicFunctionGetter('desactivateRule')(
              ruleIdentifier,
              targetId
            );
          }
        }

        dispatch({
          type: DISPATCH_TYPES.CHANGE_IN_RULES,
          payload: [targetId],
        });
        variablesSetter('rules')(currentRules => {
          const currRul = (currentRules || []).find(r => r.id === rule.id);
          return [
            ...(currentRules || []).filter(r => r.id !== rule.id),
            {
              ...currRul,
              ...rule,
              isActive: isActive ?? currRul?.isActive ?? true,
              ruleValues: [ruleValue, ...(currRul?.ruleValues || [])],
            },
          ];
        });
      }
    }
  );
export type DesactivateRule = (
  ruleIdentifier: string,
  idTarget: string
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'desactivateRule',
    ({
      stateHandler: { dispatch },
      variablesHandler: {
        variablesSetter,
        variables: { rules },
      },
      bindedContexts: { getInstanceApi, ruleDefinitions },
    }) => async (ruleIdentifier, idTarget) => {
      if (ruleDefinitions) {
        const ruleDefinition = (ruleDefinitions || []).find(
          rd => rd.identifier === ruleIdentifier
        );
        if (!ruleDefinition) {
          throw new Error('No rule definition');
        }

        const rule = (rules || []).find(
          r =>
            r.ruleDefinitionId === ruleDefinition.id && r.targetId === idTarget
        );
        if (!rule || !rule.isActive) return;
        await getInstanceApi().desactivateRule(rule.id);

        dispatch({
          type: DISPATCH_TYPES.CHANGE_IN_RULES,
          payload: [idTarget],
        });
        variablesSetter('rules')(currentRules =>
          (currentRules || []).map(r => {
            if (r.id !== rule.id) {
              return r;
            } else {
              r.isActive = false;
              return r;
            }
          })
        );
      }
    }
  );

export type ActivateRule = (ruleIdentifier: string, idTarget: string) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'activateRule',
    ({
      stateHandler: { dispatch },
      variablesHandler: {
        variablesSetter,
        variables: { rules },
      },
      bindedContexts: { getInstanceApi, ruleDefinitions },
    }) => async (ruleIdentifier, idTarget) => {
      if (ruleDefinitions) {
        const ruleDefinition = (ruleDefinitions || []).find(
          rd => rd.identifier === ruleIdentifier
        );

        if (!ruleDefinition) {
          throw new Error('No rule definition');
        }
        const rule = (rules || []).find(
          r =>
            r.ruleDefinitionId === ruleDefinition.id && r.targetId === idTarget
        );
        if (!rule || rule.isActive) return;
        await getInstanceApi().activateRule(rule.id);

        dispatch({
          type: DISPATCH_TYPES.CHANGE_IN_RULES,
          payload: [idTarget],
        });
        variablesSetter('rules')(currentRules =>
          (currentRules || []).map(r => {
            if (r.id !== rule.id) {
              return r;
            } else {
              r.isActive = true;
              return r;
            }
          })
        );
      }
    }
  );

export type GetRule = (
  ruleIdentifier: string,
  idTarget: string
) => Rule | undefined;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'getRule',
    ({
      variablesHandler: {
        variables: { rules },
      },
      bindedContexts: { ruleDefinitions },
    }) => (ruleIdentifier, idTarget) => {
      if (ruleDefinitions) {
        const ruleDefinition = (ruleDefinitions || []).find(
          rd => rd.identifier === ruleIdentifier
        );
        if (!ruleDefinition) {
          throw new Error('No rule definition');
        }

        const rule = (rules || []).find(
          r =>
            r.ruleDefinitionId === ruleDefinition.id && r.targetId === idTarget
        );
        if (rule) {
          rule.value = rule?.ruleValues?.[0];
        }
        return rule;
      }
      return undefined;
    }
  );

export type LaunchEvent = (
  eventIdentifier: string,
  fieldValues: FieldValue[],
  idTarget?: string
) => void;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'launchEvent',
    ({
      stateHandler: { dispatch },
      variablesHandler: {
        variables: { rules },
      },
      bindedContexts: { getInstanceApi, ruleDefinitions, targets },
    }) => async (eventIdentifier, fieldValues, idTarget) => {
      if (ruleDefinitions && targets) {
        const ruleDefinition = (ruleDefinitions || []).find(rd =>
          (rd.eventDefinitions || []).find(
            eD => eD.identifier === eventIdentifier
          )
        );
        if (!ruleDefinition) {
          throw new Error('No rule definition found with the especified event');
        }
        let eventDefinition = ruleDefinition.eventDefinitions?.find(
          eD => eD.identifier === eventIdentifier
        );
        if (!eventDefinition) {
          throw new Error('No event definition founded with that identifier');
        }
        //const isMultiTarget = eventDefinition.isMultiTarget;
        if (eventDefinition.isMultiTarget) {
          const rulesForLaunch = (rules || []).filter(
            r => r.ruleDefinitionId === ruleDefinition.id && r.isActive
          );
          if (!rules || !rules.length) {
            throw new Error('No existe una regla activa');
          }
          await Promise.all(
            rulesForLaunch.map(rule =>
              getInstanceApi().createRuleEvent(
                rule.id,
                eventDefinition?.id as any,
                {
                  fieldValues,
                }
              )
            )
          );
        } else {
          if (!idTarget) {
            throw new Error('A TargetEvent needs a Target id especified');
          }
          const rule = (rules || []).find(
            r =>
              r.ruleDefinitionId === ruleDefinition.id &&
              r.isActive &&
              r.targetId === idTarget
          );
          if (!rule || !rule.isActive) {
            throw new Error('No existe una regla activa');
          }
          await getInstanceApi().createRuleEvent(rule.id, eventDefinition.id, {
            fieldValues,
          });
        }
        dispatch({
          type: DISPATCH_TYPES.CHANGE_IN_RULES,
          payload: idTarget
            ? [idTarget]
            : (rules || [])
                .filter(
                  r => r.ruleDefinitionId === ruleDefinition.id && r.isActive
                )
                .map(r => r.targetId),
        });
      }
    }
  );

export type ExtractValue = (rule: Rule, fieldIdentifier?: string) => any;
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction('extractValue', () => (rule, fieldIdentifier) => {
    return rule?.value?.fieldValues?.find(f => f.identifier === fieldIdentifier)
      ?.data?.value;
  });
