import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';
import { DISPATCH_TYPES } from '../../generic/interfaces';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

// ##### Reducers Definition
wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer(
    'TargetActivationHandler',
    false,
    ({ functions: { loadRules } }) => (action, _, state) => {
      if (action.type === DISPATCH_TYPES.TARGET_ACTIVATED) {
        loadRules();
      }
      return state;
    }
  );
