import {
  EnfatizeStyle,
  EnfatizeExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.enfatize;

export interface ClassProperties {}

export type ComponentStyle = EnfatizeStyle;
export type ExpectedStyles = EnfatizeExpectedStyles;
export type Props = GenericComponentProps<EnfatizeStyle> & {};
