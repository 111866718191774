import { wrapperBuilder } from './build';
import { SessionService } from './common';
import { SESSION_STATUSES } from './others';

wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addStaticProp('SESSION_STATUSES', SESSION_STATUSES);

wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addStaticProp('defineSessionChecker', checker => {
    SessionService.instance.checker = checker;
  });
