import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  sidebarComponent: {
    sidebarContainerComponent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexBasis: '100%',
      width: '100%',
      overflowX: 'hidden',
      backgroundColor: _theme?.colors?.main,
    },
    contentContainerComponent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      flexBasis: '100%',
      width: '100%',
    },
  },
  headerComponent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    minHeight: 60,
    height: 60,
    // Lo he añadido por si no se controla los componentes provoca un scroll rarisimo
    overflow: 'hidden',
  },
  bodyContentComponent: {
    display: 'block',
    width: '100%',
    flexBasis: '100%',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    flexShrink: 1,
    overflowX: 'scroll',
  },
  bodyScrollerComponent: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexBasis: '100%',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    overflow: 'visible',
    minHeight: '100%',
  },
  footerComponent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    minHeight: 50,
    height: 50,
    // Lo he añadido por si no se controla los componentes provoca un scroll rarisimo
    overflow: 'hidden',
  },
});

export default baseConfiguration;
