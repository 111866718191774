const jwt = require('jsonwebtoken');
export let inited = false;

export type ChatApplicationToken = string;

export enum MODES {
  CLIENT = 'CLIENT',
  SERVER = 'SERVER',
}

export enum LOGIN_METHODS {
  // Login as guest
  GUEST = 'GUEST',

  // Must call "loginChat" method explicitly
  PROVIDED = 'PROVIDED',

  // SessionWrapper dependency
  // Session data must contains email, name and id (optional) property
  // The login will be automatically done when session provided
  FROM_SESSION = 'FROM_SESSION',

  // UserWrapper dependency
  // The login will be automatically done when user loaded
  FROM_USER = 'FROM_USER',
}

export type LiveChatInstance = any;
export type OnInitFunction = (liveChat: LiveChatInstance) => void;
export type OnMessagesUnread = (count: number) => void;

const Snippets = {
  tags: function({
    id,
    applicationName,
    autenticathed,
  }: {
    id: string;
    applicationName: string;
    autenticathed: boolean;
  }) {
    if (!id) {
      throw new Error('Chat Id is required.');
    }

    const script = `
    window.$zopim = null;
    window.$zopim = /*window.$zopim||*/( function(d,s) {
      // Default $zopim
      var z = $zopim = function (funcion) { z._.push(funcion) }

      //Check if script actual exists
      var existentScript
      document.head.childNodes.forEach(n => {
        if ( n.id === '_zopimScriptTag') {
          existentScript = n
        }
      })

      $zopim.t = +new Date; 
      $zopim._ = [];
      
      $zopim.set = function (o) {
        $zopim.set._.push(o)
      };
      $zopim.set._ = [];

      // Create zopim script tag
      var zopimScript = $zopim.s = d.createElement(s)
      zopimScript.setAttribute('id', '_zopimScriptTag')
      zopimScript.async = false;
      zopimScript.setAttribute("charset", "utf-8");
      zopimScript.src = "https://v2.zopim.com/?${id}"; 
      zopimScript.type = "text/javascript"; 

      // Replace if exists
      if (existentScript){
        d.head.removeChild(existentScript)
      }
      d.head.insertBefore(zopimScript, d.head.childNodes[0])
      window.$zopim = $zopim
      
      // return
      return $zopim
    })(document,"script");

    window.$zopim(function() {
      window.$zopim.livechat.window.onHide(
        function () {
            window.$zopim.livechat.hideAll();
        })
      // update visitor's path with specific page url and title
      ${
        applicationName
          ? `
      $zopim.livechat.sendVisitorPath({
        url: 'http://${applicationName.toLowerCase()}.com',
        title: '${applicationName}'
      });
      `
          : ''
      }
      ${
        autenticathed
          ? `
      window.$zopim.livechat.authenticate({
        jwtFn: function (callback) {
          window.autenticateZopim = callback
          window.onChatInit(window.$zopim.livechat)
        }
      });
      `
          : ''
      }
      window.$zopim.livechat.hideAll();
      window.$zopim.livechat.setOnConnected(
        function () {
            // $zopim.livechat.window.toggle();
            window.onConnected && window.onConnected()
        })
      window.$zopim.livechat.setOnUnreadMsgs(window.onUnreadMessage)
      ${
        !autenticathed
          ? `
          window.onChatInit(window.$zopim.livechat)
      `
          : ''
      }
    });

    `;

    return {
      script,
    };
  },
};
class ChatInstance {
  private id: string | undefined;
  private autenticathed: boolean | undefined;
  private applicationName: string | undefined;
  private onInit: OnInitFunction | undefined;
  private onUnreadMessage: OnMessagesUnread | undefined;

  chat(args: any) {
    const snippets = Snippets.tags(args);

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.setAttribute('id', '_zopimChatIntegrationNoScript');
      return noscript;
    };

    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = snippets.script;
      script.setAttribute('id', '_zopimChatIntegrationScript');
      return script;
    };

    return {
      noScript,
      script,
    };
  }

  initialize({
    id,
    applicationName,
    onInit,
    onUnreadMessage,
  }: {
    id?: string;
    applicationName?: string;
    onInit?: OnInitFunction;
    onUnreadMessage?: OnMessagesUnread;
  }) {
    this.id =
      id ||
      this.id ||
      (window &&
        window.__CLIENT_ENV &&
        window.__CLIENT_ENV.CLIENT_ENV_CHAT_APPLICATION_ID) ||
      undefined;
    this.autenticathed = true || this.autenticathed;
    this.applicationName = applicationName || this.applicationName;
    this.onInit = onInit || this.onInit;
    this.onUnreadMessage = onUnreadMessage || this.onUnreadMessage;
    (window as any).onChatInit = this.onInit;
    (window as any).onUnreadMessage = this.onUnreadMessage;
    inited = true;
    try {
      const chat = this.chat({
        id: this.id,
        applicationName: this.applicationName,
        autenticathed: this.autenticathed,
      });
      let existentScript;
      let existentNoScript;
      document.head.childNodes.forEach(n => {
        if ((n as any).id === '_zopimChatIntegrationScript') {
          existentScript = n;
        }
      });
      document.body.childNodes.forEach(n => {
        if ((n as any).id === '_zopimChatIntegrationNoScript') {
          existentNoScript = n;
        }
      });
      if (existentScript) {
        document.head.removeChild(existentScript);
      }
      if (existentNoScript) {
        document.body.removeChild(existentNoScript);
      }
      document.head.insertBefore(chat.script(), document.head.childNodes[0]);
      document.body.insertBefore(chat.noScript(), document.body.childNodes[0]);
    } catch (e) {
      console.warn(e.message || 'Chat initialization failed');
    }
  }

  login(
    loginData: { name: string; email: string; id: string },
    chatApplicationToken: string,
    onConnected?: Function
  ) {
    (window as any).onConnected = onConnected;
    if (chatApplicationToken) {
      // this.initialize({ autenticathed: true });
      const payload = {
        name: loginData.name,
        email: loginData.email,
        iat: Math.floor(Date.now() / 1000),
        external_id: loginData.id,
      };
      const generated = jwt.sign(payload, chatApplicationToken);
      (window as any).autenticateZopim &&
        (window as any).autenticateZopim(generated);
    } else {
      (window as any).$zopim.livechat.setEmail(loginData.email);
      (window as any).$zopim.livechat.setName(loginData.name);
    }
  }

  logout(guestData = { email: 'invitado@coinscrap.com', name: 'Invitado' }) {
    (window as any).$zopim.livechat.clearAll();
    if (guestData) {
      (window as any).$zopim.livechat.setEmail(guestData.email);
      (window as any).$zopim.livechat.setName(guestData.name);
    }
  }
}

export const Chat = new ChatInstance();
