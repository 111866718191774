import { WrapperBuilder } from '../../builders/wrapperBuilder';
import { UserWrapper } from '../../entityWrappers';
import { BackendWrapper } from '../BackendWrapper';
import { wrapperIdentifier } from './common';
import {
  BindedWrapperProperties,
  ComponentWrapperProps,
  ContextState,
  ExternalContextFunctions,
  ExternalContextVariables,
  InternalContextFunctions,
  InternalContextVariables,
  Statics,
  Virtuals,
} from './interfaces';

export const wrapperBuilder = new WrapperBuilder<
  ComponentWrapperProps,
  InternalContextVariables,
  {},
  ExternalContextVariables,
  InternalContextFunctions,
  {},
  ExternalContextFunctions,
  ContextState,
  Virtuals,
  Statics,
  BindedWrapperProperties,
  {}
>(wrapperIdentifier, wrapperIdentifier, [UserWrapper, BackendWrapper]);
