import { wrapperBuilder } from './build';

// ##### Reducers Definition
wrapperBuilder
  .getReducersRegisterInstance()
  .addReducer('anotherExampleReducer', true, () => action => {
    return {
      anotherLastActionDispatched:
        typeof action === 'string'
          ? action
          : action.type + '-' + action.payload,
    };
  });
