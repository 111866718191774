// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    __CLIENT_ENV: any;
  }
}
export const environmentBaseUrl =
  window && window.__CLIENT_ENV && window.__CLIENT_ENV.CLIENT_ENV_API_BASE_URL;
/*
"http://api.pixel.coinscrap.com:9001/api/"
"http://localhost:9001/api/"
"http://api.pixel.coinscrap.com:8080/"
*/
