import { UiWrapper } from '../../index';

export default function MediaQuery({
  children,
  device,
  onChange,
  ...settings
}: any) {
  const context = UiWrapper.use();
  if (!context) {
    return children;
  }
  const { matched } = context;
  const matches = Object.keys(settings)
    .filter(x => settings[x])
    .find(x => matched[x]);

  if (typeof children === 'function') {
    return children(matches);
  }
  return matches ? children : null;
}
