import { WrapperBuilder } from '../../builders/wrapperBuilder';
import { ComponentProps, F, IF, IV, S, V, VR, EF, EV, ST } from './interfaces';
import { TestingWrapper } from '../TestingWrapper';
import { AnotherTestingWrapper } from '../AnotherTestingWrapper';

export const wrapperBuilder = new WrapperBuilder<
  ComponentProps,
  IV,
  V,
  EV,
  IF,
  F,
  EF,
  S,
  VR,
  ST,
  typeof TestingWrapper.bindedContext &
    typeof AnotherTestingWrapper.bindedContext,
  {}
>('otherTestingWrapper', 'otherTestingWrapper', [
  TestingWrapper,
  AnotherTestingWrapper,
]);
