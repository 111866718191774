import i18n from 'i18next';
import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';

import { wrapperBuilder } from './build';
const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

wrapperBuilder
  .getVirtualsGeneratorsRegisterInstance()
  .addVirtualsGenerator(({ variables: { currentLang } }) => {
    console.log('ºº LANG', currentLang, i18n.language);
    return {
      currentLang: currentLang || i18n.language,
    };
  });
