export class Branch {
  private _flag = false;
  private _client: any;
  private _onResponse: any;
  constructor(private _properties: any, private _defaultFunc: any) {}

  async init() {
    if ((window as any).Branch) {
      this._client = await (window as any).Branch.createBranchUniversalObject(
        this._properties
      );
    }
  }

  showShareSheet(analytics: any, properties: any, title: string) {
    this._flag = true;
    if (this._client) {
      return this._client.showShareSheet(analytics, properties, title);
    } else {
      if (this._defaultFunc) {
        this._defaultFunc(analytics, properties, title);
      } else {
        window.prompt(title, JSON.stringify(properties));
      }
      // dismissFunction && dismissFunction()
      this._onResponse && this._onResponse();
    }
  }
  // Configuration
  onLinkShareResponse(func: any) {
    const funcFlaged = (...args: any[]) => {
      if (this._flag) {
        this._flag = false;
        func(...args);
      }
    };
    if (this._client) {
      return this._client.onLinkShareResponse(funcFlaged);
    } else {
      this._onResponse = funcFlaged;
    }
  }
  // Configuration
  onShareSheetDismissed(func: any) {
    const funcFlaged = (...args: any[]) => {
      if (this._flag) {
        this._flag = false;
        func(...args);
      }
    };
    if (this._client) {
      return this._client.onShareSheetDismissed(funcFlaged);
    } else {
      // This method its dummy, just no break code when no SDK present
    }
  }
}
