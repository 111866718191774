import { MotionProps } from 'framer-motion';
import {
  OnClick,
  Width,
  Height,
  Hidden,
  Collapsable,
  GenericComponentProps,
  ViewStyle,
  ViewVAlign,
  ViewHAlign,
  ViewExpectedStyles,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.view;

export interface ClassProperties {}

export type ComponentStyle = ViewStyle;
export type ExpectedStyles = ViewExpectedStyles;
export type Props = GenericComponentProps<ViewStyle> & {
  width?: Width;
  height?: Height;
  hidden?: Hidden; // por defecto, sino se manda o hide o hide=true,  se oculta con un Fade
  collapsable?: Collapsable;
  vAlign?: ViewVAlign;
  hAlign?: ViewHAlign;

  animation?: MotionProps & { animationKey?: any };

  onClick?: OnClick;
};
