export const wrapperIdentifier = 'backend';

export interface RequestOptions {
  noPrefix?: boolean;
  formData?: boolean;
  urlencoded?: boolean;
  headers?: object;
}

export type CoreFetch = <T>(
  method: string,
  url: string,
  options?: RequestOptions,
  requestData?: {
    bodyParams?: { [k: string]: any };
    queryParams?: { [k: string]: any };
  }
) => Promise<{ status: number; headers: any; data: T }>;

export class CoreError {
  constructor(public type: string) {}
}

export class CoreRequestError extends CoreError {
  constructor(
    public status: string | number,
    public data: any,
    public headers: any
  ) {
    super('CoreRequestError');
  }
}

export class CoreConnectionError extends CoreError {
  constructor(public reason: string) {
    super('CoreConnectionError');
  }
}
