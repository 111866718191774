import { Builder } from '../common';
import { createElement } from 'react';

export class RenderBuilder<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  InternalFunctions,
  Functions,
  ExternalFunctions,
  State,
  Virtuals,
  BindedContexts
> implements Builder {
  public wrapperName!: string;

  public state!: State;
  public variables!: InternalVariables & Variables & ExternalVariables;
  public componentProps!: ComponentPropsDefinition;
  public bindedContexts!: BindedContexts;
  public functions!: InternalFunctions & Functions & ExternalFunctions;
  public virtuals!: Virtuals;

  private _renderer!: (context: {
    state?: State;
    variables: InternalVariables & Variables & ExternalVariables;
    functions: InternalFunctions & Functions & ExternalFunctions;
    componentProps: ComponentPropsDefinition;
    bindedContexts: BindedContexts;
    virtuals: Virtuals;
  }) => (e: typeof createElement, children: any) => any;

  setRenderer(
    value: (context: {
      state?: State;
      variables: InternalVariables & Variables & ExternalVariables;
      functions: InternalFunctions & Functions & ExternalFunctions;
      componentProps: ComponentPropsDefinition;
      bindedContexts: BindedContexts;
      virtuals: Virtuals;
    }) => (e: typeof createElement, children: any) => any
  ) {
    this._renderer = value;
  }
  isConfigured() {
    return !!this._renderer;
  }
  build() {
    return this._renderer(this);
  }
}
