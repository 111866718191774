/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { Logger, ExternalVar } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { useEffect } from 'react';
import { ApplicationWrapper } from '../ApplicationWrapper';
import { UiWrapper } from '../UiWrapper';
import { NavigationWrapper } from '../NavigationWrapper';
import { DISPATCH_TYPES } from '../../generic/interfaces';
import { wrapperBuilder } from './build';
import { isNative } from '../ApplicationWrapper';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

let pushObject;
let deviceNotificationsToken;

wrapperBuilder
  .getCustomLogicRegisterInstance()
  .setLogic(({ componentProps, variablesHandler: { variablesSetter } }) => {
    const uiContext = UiWrapper.use();
    const applicationContext = ApplicationWrapper.use();
    const dispatch = applicationContext
      ? applicationContext.dispatch
      : () => undefined;
    const deviceReady =
      (applicationContext && applicationContext.deviceReady) || false;
    const navigationContext = NavigationWrapper.use();
    useEffect(() => {
      if (deviceReady && isNative) {
        if ((window as ExternalVar).PushNotification?.init) {
          console.log('REGISTERING FOR PUSH');
          pushObject = (window as ExternalVar).PushNotification.init({
            android: {},
            ios: {
              alert: 'true',
              badge: 'true',
              sound: 'true',
            },
          });

          pushObject.on('registration', (data: ExternalVar) => {
            deviceNotificationsToken = data.registrationId;
            console.log('PUSHTOKEN', deviceNotificationsToken);
            variablesSetter('deviceNotificationsToken')(
              deviceNotificationsToken
            );
            dispatch({
              type: DISPATCH_TYPES.TOKEN_RECEIVED,
              payload: deviceNotificationsToken,
            });
          });
          pushObject.on('notification', (data: ExternalVar) => {
            // data.message,
            // data.title,
            // data.count,
            // data.sound,
            // data.image,
            // data.additionalData
            console.log('ºPUSH RECEIVED', data);
            const redirection =
              data?.additionalData?.__internalPurposesRedirection;
            if (redirection && redirection.key) {
              if (navigationContext) {
                const { navigateTo } = navigationContext;
                navigateTo(redirection.key, {
                  handleRedirection: false,
                  mode: 'replace',
                  ...redirection.redirectOptions,
                });
              } else {
                throw new Error(
                  'Push metadata contains a redirection, cannot redirect because NavigationWrapper context not found'
                );
              }
            }
            const metadata = data?.additionalData?.__internalPurposesMetadata;
            if (
              metadata &&
              Object.keys(metadata).length &&
              componentProps.onMetadataReceived
            ) {
              componentProps.onMetadataReceived(metadata);
            }
            const dataAdapted = data;
            delete data?.additionalData?.__internalPurposesRedirection;
            delete data?.additionalData?.__internalPurposesMetadata;
            if (componentProps.onNotification) {
              componentProps.onNotification(dataAdapted);
            } else if (uiContext) {
              uiContext &&
                uiContext.showAlert(dataAdapted.title, dataAdapted.message);
            } else {
              console.warn(
                'Notification received but no -onNotification- method especified or UiWrapper present'
              );
            }
          });
        } else {
          console.error('No native pushNotification service detected');
        }
      }
    }, [deviceReady]);

    /* PENDING DEFINNING REGISTRATION AND API COMUNICATIONS
    useEffect(() => {
      if (
        session.token &&
        context &&
        context.deviceNotificationsToken
      ) {
        api.registerUserDevice(
          context && context.deviceNotificationsToken,
          // eslint-disable-next-line no-undef
          device && device.platform,
          // eslint-disable-next-line no-undef
          (AppVersion && AppVersion.version) || undefined
        );
      }
    }, [session.token, context && context.deviceNotificationsToken]);
    */
  });
