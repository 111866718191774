import { HookResponse } from '../../generic/interfaces';
import { useEffect, useState } from 'react';
import { BackendWrapper } from '../../wrappers/BackendWrapper';
import { wrapperBuilder } from './build';
import { RecommendedSaving } from './common';

export type UseTargetProductDefinitionsAvailable = (
  entityId: string
) => HookResponse<string[]>;
wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addHook('useTargetProductDefinitionsAvailable', entityId => {
    const { getInstanceApi } = BackendWrapper.use();
    const [items, setItems] = useState<string[] | undefined | null>();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      if (entityId) {
        (async () => {
          try {
            setLoading(true);
            const response = await getInstanceApi().getAvailableProductDefinitions(
              entityId
            );
            setItems(response);
          } catch (e) {
            setItems(null);
          } finally {
            setLoading(false);
          }
        })();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId]);

    return { payload: items, loading };
  });

export type UseTargetRecommendedSaving = (
  entityId: string
) => HookResponse<RecommendedSaving>;
wrapperBuilder
  .getStaticsGeneratorsRegisterInstance()
  .addHook('useTargetRecommendedSaving', entityId => {
    const { getInstanceApi } = BackendWrapper.use();
    const [payload, setPayload] = useState<
      RecommendedSaving | undefined | null
    >();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      if (entityId) {
        (async () => {
          try {
            setLoading(true);
            const response = await getInstanceApi().getRecommended(entityId);
            setPayload(response);
          } catch (e) {
            setPayload(null);
          } finally {
            setLoading(false);
          }
        })();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityId]);

    return { payload, loading: payload === undefined || loading };
  });
