export const wrapperIdentifier = 'ruleDefinitionsWrapper';

export interface FieldDefinition {
  id: string;
  type: 'array_number' | 'array_string' | 'number' | 'text' | 'boolean';
  identifier: string;
  metadata?: any;
}

export interface EventDefinition {
  id: string;
  isMultiTarget?: boolean;
  identifier: string;
  fieldDefinitions?: FieldDefinition[];
}
export interface RuleDefinition {
  identifier: string;
  id: string;
  eventDefinitions?: EventDefinition[];
  fieldDefinitions?: FieldDefinition[];
}
