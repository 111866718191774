import {
  Width,
  ImageStyle,
  Height,
  ImageFilename,
  ImageExt,
  ImageSrc,
  ImageAlt,
  ImageOnClick,
  ImageExpectedStyles,
  GenericComponentProps,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.image;

export interface ClassProperties {}

export type ComponentStyle = ImageStyle;
export type ExpectedStyles = ImageExpectedStyles;

export type Props = GenericComponentProps<ImageStyle> & {
  filename?: ImageFilename;
  onClick?: ImageOnClick;
  ext?: ImageExt;
  src?: ImageSrc;
  alt?: ImageAlt;
  className?: string;
  width?: Width;
  height?: Height;
};
