/* eslint-disable no-unused-vars */
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
  Props,
  ComponentStyle,
  enumKey,
  ClassProperties,
  Suggestion,
  Section,
} from './interface';
import { makeStyles } from '@material-ui/core';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  applyFunctionalityStyles,
} from '../common';
import { generateMaterialStyles } from '../../common';
import Autosuggest from 'react-autosuggest';
import { TextField } from '../TextField';
import { View } from '../View';
import { Text } from '../Text';
import { Logger } from '../../../../generic/utils';
// import { InternationalizationWrapper } from '../../../InternationalizationWrapper';

const console = new Logger('inputSuggest');

if (console) {
  //Just for usage
}

// No es material pero el funcionamiento en este caso aplica
const useSuggestStyles = makeStyles({
  container: (props: ComponentStyle) =>
    generateMaterialStyles('containerComponent', props),
  suggestionsContainer: (props: ComponentStyle) =>
    generateMaterialStyles('suggestionsContainerComponent', props),
  suggestionsList: (props: ComponentStyle) =>
    generateMaterialStyles('suggestionsListComponent', props),
  suggestion: (props: ComponentStyle) =>
    generateMaterialStyles('suggestionComponent', props),
  sectionContainer: (props: ComponentStyle) =>
    generateMaterialStyles('sectionContainerComponent', props),
  sectionTitle: (props: ComponentStyle) =>
    generateMaterialStyles('sectionTitleComponent', props),
});

const e = React.createElement;

export const InputSuggest: FunctionComponent<Props> & ClassProperties = ({
  id,
  parentReference = undefined,
  componentReference = 'root',
  coreId,
  onChange,
  value: idValue,
  style,
  disabled,
  label,
  children,
  error,
  placeholder,
  autoFocus,
  alwaysOpen = false,
  minLengthToSuggest = 2,
  suggestionsSource,
  ...styleKeys
}) => {
  checkStyleNames(enumKey, styleKeys);

  // Obtención de estilos principal --------------------------------------------
  const [mainStyle] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    styleKeys
  );

  // Aplicar Estilos inyectados-------------------------------
  let componentStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { disabled }
  );

  // Otros ----------------------------------------------------------------
  const [suggestions, setSuggestions] = useState(
    typeof suggestionsSource === 'function' ? [] : suggestionsSource || []
  );

  const [lastMatchedSuggestion, setLastMatchedSuggestion] = useState<
    Suggestion | undefined
  >(undefined);

  const currentMatchedSuggestion = (suggestions as any)
    .reduce(
      (acc: Suggestion[], act: any) => [
        ...acc,
        ...(((act as any) as Section).suggestions || [act]),
      ],
      [] as Suggestion[]
    )
    .filter((s: any) => s.id === idValue)[0];

  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (idValue) {
      if (currentMatchedSuggestion) {
        setLastMatchedSuggestion(currentMatchedSuggestion);
        setInputValue(currentMatchedSuggestion.label);
      }
    }
  }, [idValue, currentMatchedSuggestion?.id]);
  console.log('ºº VALUE', idValue, inputValue, currentMatchedSuggestion);

  const suggestTheme = useSuggestStyles(componentStyle);
  /*const t =
    (InternationalizationWrapper.use() &&
      InternationalizationWrapper.use().t) ||
    ((k: any) => k);*/

  // Return -----------------------------------------------------
  return e(Autosuggest, {
    suggestions: suggestions,
    multiSection: !!(suggestions as any).find(
      (s: Section) => !!(s.title && s.suggestions)
    ),
    shouldRenderSuggestions: (value: string) =>
      value.trim().length >= minLengthToSuggest,
    onSuggestionsFetchRequested: async ({
      value: inputStringValue,
    }: {
      value: string;
    }) => {
      console.log(
        'ºº requesting suggests',
        inputStringValue,
        minLengthToSuggest
      );
      if ((inputStringValue || '').length >= minLengthToSuggest) {
        console.log('ºº requesting suggests ACCEPTED', inputStringValue);
        const suggestionsToSet = await getComputedSuggestions(
          inputStringValue,
          suggestionsSource || []
        );
        console.log(
          'ºº requesting suggests ACCEPTED TO SET',
          inputStringValue,
          suggestionsToSet
        );
        setSuggestions(suggestionsToSet);
      }
    },
    onSuggestionsClearRequested: () => setSuggestions([]),
    getSuggestionValue: (s: Suggestion) => s.id,
    getSectionSuggestions: (s: Section) => s.suggestions,
    onSuggestionSelected: (
      _: any,
      { suggestion }: { suggestion: Suggestion }
    ) => {
      setLastMatchedSuggestion(suggestion);
      setInputValue(suggestion.label);
      onChange && onChange(suggestion.id);
    },
    renderSuggestionsContainer: (props: any) =>
      console.logValue(props, 'ººSUG CONT') &&
      e(
        View,
        {
          cleared: true,
          // style: componentStyle.suggestionsContainerComponent, PROVIDED BY THEME
          internalProps: props.containerProps,
        },
        props.children
      ),
    renderSuggestion: (s: Suggestion, props: any) =>
      console.logValue(props, 'ººSUG') &&
      e(
        Text,
        {
          cleared: true,
          style: {
            ...componentStyle.suggestionTextComponent,
            ...(s.id === idValue
              ? componentStyle.suggestionSelectedTextComponent
              : {}),
          },
        },
        s.label
      ),
    renderSectionTitle: (s: Section) =>
      e(
        Text,
        { cleared: true, style: componentStyle.sectionTitleTextComponent },
        s.title
      ),
    inputProps: {
      placeholder,
      onChange: (_: any, { newValue, method }: any) => {
        console.log('ºº NEW VALUE??', newValue);
        if (method === 'type') {
          setInputValue(newValue);
        }
        // onChange && onChange(newValue);
      },
      value: inputValue,
      onBlur: () => {
        lastMatchedSuggestion?.label &&
          setInputValue(lastMatchedSuggestion?.label || '');
      },
    },
    renderInputComponent: (inputProps: any) => {
      return e(TextField, {
        type: 'text',
        internalProps: inputProps,
        error,
        disabled,
        autoFocus,
        label,
        style: componentStyle.textFieldComponent,
        placeholder,
        value: inputValue || '',
        onChange: e => setInputValue(e.target.value),
      });
    },
    alwaysRenderSuggestions: alwaysOpen,
    theme: suggestTheme,
  } as any);
};

const getComputedSuggestions = async (
  value: string,
  suggestionsSource:
    | Suggestion[]
    | Section[]
    | ((inputValue: string) => Promise<Suggestion[] | Section[]>)
) => {
  const inputValue = (value || '').trim().toLowerCase();
  const inputLength = inputValue.length;
  const filtered =
    inputLength === 0
      ? []
      : ((typeof suggestionsSource === 'function'
          ? await suggestionsSource(value)
          : suggestionsSource) as any).reduce(
          (acc: any[], sugOrSect: any) =>
            sugOrSect.suggestions &&
            (sugOrSect as Section).suggestions.filter(
              (s: Suggestion) => s.label.toLowerCase().indexOf(inputValue) >= 0
            ).length
              ? [
                  ...acc,
                  {
                    title: sugOrSect.title,
                    suggestions: (sugOrSect as Section).suggestions.filter(
                      (s: Suggestion) =>
                        s.label.toLowerCase().indexOf(inputValue) >= 0
                    ),
                  },
                ]
              : (sugOrSect as Suggestion).label &&
                (sugOrSect as Suggestion).label
                  .toLowerCase()
                  .indexOf(inputValue) >= 0
              ? [...acc, sugOrSect]
              : acc,
          []
        );
  return filtered;
};
