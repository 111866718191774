import React from 'react';
import { Logger } from '../../generic/utils';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import dateUtils from '@date-io/moment';
import 'moment/locale/es';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import createMuiEstructureFromGenericStyles from './others/config';
import { InternationalizationWrapper } from '../InternationalizationWrapper';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useEffect } from 'react';
import { wrapperIdentifier } from './common';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}

moment.locale('es');

wrapperBuilder
  .getCustomRenderRegisterInstance()
  .setRenderer(
    ({ componentProps: props, variables: context }) => (e, children) => {
      const {
        backgroundColor,
        backgroundKey,
        backgroundPosition,
        windowDimensions,
      } = context;
      const { backgroundImages } = props;
      //    Handler dinamic background
      const backgroundStyles: any = {
        backgroundImage: 'none',
        backgroundPosition: 'top',
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100vw',
        zIndex: -1,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        maxHeight:
          'calc( 100vh - ( env(safe-area-inset-top, "0px") + env(safe-area-inset-bottom, "0px") ) )',
        marginTop: 'env(safe-area-inset-top)',
        marginBottom: 'env(safe-area-inset-bottom)',
      };

      if (windowDimensions) {
        // TODO: check if this is needed
        //backgroundStyles.minHeight = (context.windowDimensions.height) + 'px'
      }
      const imageKey =
        backgroundKey && backgroundImages && backgroundImages[backgroundKey];
      if (imageKey) {
        backgroundStyles.backgroundImage = `url(${imageKey})`;

        if (backgroundPosition) {
          backgroundStyles.backgroundPosition = backgroundPosition;
        }
      } else if (backgroundColor) {
        backgroundStyles.backgroundColor = backgroundColor;
      } else {
        backgroundStyles.backgroundColor = 'transparent';
      }

      const currentLang =
        InternationalizationWrapper.use() &&
        InternationalizationWrapper.use().currentLang;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      useEffect(() => {
        if (currentLang) {
          moment.locale(currentLang);
        }
      }, [currentLang]);

      return e(
        MuiThemeProvider,
        {
          theme: createMuiTheme(
            createMuiEstructureFromGenericStyles(
              props.config,
              props.rawConfig
            ) as any
          ),
        } as any,
        e(
          MuiPickersUtilsProvider,
          {
            libInstance: moment,
            utils: dateUtils,
            locale: currentLang || 'es',
          } as any,
          e(
            React.Fragment,
            {},
            ...Object.keys(backgroundImages || {}).map(key => {
              return e('div', {
                key: key,
                style: {
                  ...backgroundStyles,
                  backgroundImage: `url(${(backgroundImages || {})[key]})`,

                  transition:
                    key === backgroundKey
                      ? 'opacity .25s ease-in-out'
                      : 'opacity 1s ease-in-out',
                  opacity: key === backgroundKey ? '100' : '0',
                },
              });
            }),
            !imageKey &&
              e('div', {
                key: 'backgroundColor',
                style: {
                  ...backgroundStyles,
                  backgroundImage: 'none',
                  backgroundColor: backgroundColor ? backgroundColor : 'white',
                },
              }),
            e(
              AnimatePresence,
              { initial: false, exitBeforeEnter: true, key: 'structure' },
              children
            )
          )
        )
      );
    }
  );
