import { FunctionComponent } from 'react';
import { Logger } from '../../../generic/utils';

const console = new Logger('var');

if (console) {
  //Just for usage
}
//const e = React.createElement;

export const Var: FunctionComponent<{ alias?: string }> = ({
  alias,
  children,
}) => {
  console.log('ALIAS', alias);
  return children as any;
};
