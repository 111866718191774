import { wrapperBuilder } from './build';
import qs from 'qs';
declare var window: any;
wrapperBuilder
  .getVariablesRegisterInstance()
  .addVariable('routeOverride', { startValue: undefined })
  .addVariable('location', { startValue: window?.location?.search || '' })
  .addVariable('params', { startValue: {} })
  .addVariable('query', {
    startValue: qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }),
  });
