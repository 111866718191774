import {
  GenericComponentProps,
  TooltipExpectedStyles,
  TooltipStyle,
} from '../propsDefinition';
import { COMPONENTS } from '../common';
export const enumKey = COMPONENTS.tooltip;

export interface ClassProperties {}

export type ComponentStyle = TooltipStyle;
export type ExpectedStyles = TooltipExpectedStyles;
export type Props = GenericComponentProps<TooltipStyle> & {
  content?: any;
  onOpen?: () => void;
  onClose?: () => void;
  open?: boolean;
  withArrow?: boolean;
  disabled?: boolean;
  enableClick?: boolean;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  transition?: 'grow' | 'fade' | 'zoom';
};
