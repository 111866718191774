import { Register } from '../common';

export class VariablesRegister<
  ComponentPropsDefinition,
  InternalVariables,
  Variables,
  ExternalVariables,
  State
> implements Register {
  private _variables: {
    name: Extract<
      keyof (InternalVariables & Variables & ExternalVariables),
      string
    >;
    definitionBuilder:
      | ((context: {
          state?: State;
          componentProps: ComponentPropsDefinition;
        }) => {
          startValue: any;
          persist?: boolean;
        })
      | {
          startValue: any;
          persist?: boolean;
        };
  }[] = [];

  addVariable<
    T extends Extract<
      keyof (InternalVariables & Variables & ExternalVariables),
      string
    >
  >(
    name: T,
    definitionBuilder:
      | ((context: {
          state?: State;
          componentProps: ComponentPropsDefinition;
        }) => {
          startValue: (InternalVariables & Variables & ExternalVariables)[T];
          persist?: boolean;
        })
      | {
          startValue: (InternalVariables & Variables & ExternalVariables)[T];
          persist?: boolean;
        }
  ) {
    this._variables.push({
      name,
      definitionBuilder,
    });
    return this;
  }

  getKeys(): Extract<
    keyof (InternalVariables & Variables & ExternalVariables),
    string
  >[] {
    return this._variables.map(v => v.name);
  }
  getVariableDefinitionBuilder<
    T extends
      | Extract<keyof InternalVariables, string>
      | Extract<keyof Variables, string>
      | Extract<keyof ExternalVariables, string>
  >(
    varName: T
  ):
    | ((context: {
        state?: State;
        componentProps: ComponentPropsDefinition;
      }) => {
        startValue: T extends Extract<keyof InternalVariables, string>
          ? InternalVariables[T]
          : T extends Extract<keyof Variables, string>
          ? Variables[T]
          : T extends Extract<keyof ExternalVariables, string>
          ? ExternalVariables[T]
          : any;
        persist?: boolean;
      })
    | {
        startValue: T extends Extract<keyof InternalVariables, string>
          ? InternalVariables[T]
          : T extends Extract<keyof Variables, string>
          ? Variables[T]
          : T extends Extract<keyof ExternalVariables, string>
          ? ExternalVariables[T]
          : any;
        persist?: boolean;
      } {
    const def = this._variables.find(varDef => varDef.name === varName);
    if (!def) {
      throw new Error('Variable not found: ' + varName);
    }
    return def.definitionBuilder;
  }

  hasRegistrations() {
    return !!this._variables.length;
  }
}
