import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  labelComponent: {
    color: _theme?.colors?.main,
  },
  thumbComponent: {
    backgroundColor: _theme?.colors?.secondary,
  },
  checkedMode: {
    thumbComponent: {
      backgroundColor: _theme?.colors?.main,
    },
  },
  disabledMode: {
    thumbComponent: {
      backgroundColor: 'rgb(200,200,200)',
    },
    labelComponent: {
      color: _theme?.colors?.main,
    },
  },
});

export default baseConfiguration;
