import { Logger } from '../../generic/utils';
import { wrapperIdentifier } from './common';
import { MODES, LOGIN_METHODS } from './others/Chat';
import { wrapperBuilder } from './build';

const console = new Logger(wrapperIdentifier);

if (console) {
  //Just for usage
}
wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'openChat',
    ({
      componentProps: { mode },
      variablesHandler: { variables, variablesSetter },
    }) => () => {
      console.log('º OPENING CHAT');
      if (!mode || mode === MODES.CLIENT) {
        const liveChat = variables.liveChatInstance;
        if (!liveChat) {
          console.log('ºRETURNING CHAT');
          return;
        }
        console.log(' ZOPIM', liveChat);
        liveChat.window.show();
      }
      variablesSetter('chatOpened')(true);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'closeChat',
    ({
      componentProps: { mode },
      variablesHandler: { variables, variablesSetter },
    }) => () => {
      if (!mode || mode === MODES.CLIENT) {
        const liveChat = variables.liveChatInstance;
        if (liveChat) {
          liveChat.window.hide();
        }
      }
      variablesSetter('chatOpened')(false);
    }
  );

wrapperBuilder
  .getFunctionsRegisterInstance()
  .addFunction(
    'loginChat',
    ({
      componentProps: { mode, loginMethod },
      variablesHandler: { variablesSetter },
    }) => userData => {
      if (loginMethod !== LOGIN_METHODS.PROVIDED) {
        throw new Error(
          'Cannot provide a login. Set a LOGIN_METHODS.PROVIDED to provide a custom login user data.'
        );
      }
      if (!mode || mode === MODES.CLIENT) {
        variablesSetter('userData')(userData);
      }
    }
  );
