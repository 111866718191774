import React, { FunctionComponent } from 'react';

import Collapse from '@material-ui/core/Collapse';
import { motion } from 'framer-motion';
import { Props, ComponentStyle, enumKey, ClassProperties } from './interface';
import {
  getStylesOfUserMergedWithBase,
  mergeStylesWithInlineStyles,
  checkStyleNames,
  generateProperties,
  applyFunctionalityStyles,
} from '../common';
import { componentsOverrides } from '../userOverrides';

const e = React.createElement;

export const View: FunctionComponent<Props> &
  ClassProperties = React.forwardRef(function View(
  {
    id,
    parentReference = undefined,
    componentReference = 'root',
    coreId,
    style,
    width,
    height,
    hidden, // por defecto, sino se manda o hide o hide=true,  se oculta con un Fade
    collapsable,
    hAlign,
    vAlign,
    onClick,
    children,
    animation,
    internalProps,
    ...props
  },
  ref
) {
  const overrides = componentsOverrides[enumKey];
  if (overrides?.full && overrides.component) {
    return overrides.component({
      id,
      parentReference,
      componentReference,
      coreId,
      style,
      width,
      height,
      hidden, // por defecto, sino se manda o hide o hide=true,  se oculta con un Fade
      collapsable,
      hAlign,
      vAlign,
      onClick,
      children,
      animation,
      internalProps,
      ...props,
    });
  }
  checkStyleNames(enumKey, props);
  // Obtención de estilos principal --------------------------------------------
  const [mainStyle, styleNames] = getStylesOfUserMergedWithBase<ComponentStyle>(
    enumKey,
    props
  );

  // Inyectar estilos ad hoc -----------------------------------------
  let containerStyle = applyFunctionalityStyles(
    mergeStylesWithInlineStyles<ComponentStyle>(mainStyle, style || {}),
    { collapsable }
  );
  // Others

  if (width) {
    containerStyle.width = width;
  }

  if (height) {
    containerStyle.flexBasis = height;
  }

  if (
    (
      (containerStyle.flexDirection && containerStyle.flexDirection) ||
      ''
    ).indexOf('column') >= 0
  ) {
    // Current displayed in column
    if (vAlign) {
      switch (vAlign) {
        case 'top':
          containerStyle.justifyContent = 'flex-start';
          break;
        case 'center':
          containerStyle.justifyContent = 'center';
          break;
        case 'bottom':
          containerStyle.justifyContent = 'flex-end';
          break;
      }
    }
    if (hAlign) {
      switch (hAlign) {
        case 'left':
          containerStyle.alignItems = 'flex-start';
          break;
        case 'center':
          containerStyle.alignItems = 'center';
          break;
        case 'right':
          containerStyle.alignItems = 'flex-end';
          break;
      }
    }
  } else {
    // Current displayed in row
    if (vAlign) {
      switch (vAlign) {
        case 'top':
          containerStyle.alignItems = 'flex-start';
          break;
        case 'center':
          containerStyle.alignItems = 'center';
          break;
        case 'bottom':
          containerStyle.alignItems = 'flex-end';
          break;
      }
    }
    if (hAlign) {
      switch (hAlign) {
        case 'left':
          containerStyle.justifyContent = 'flex-start';
          break;
        case 'center':
          containerStyle.justifyContent = 'center';
          break;
        case 'right':
          containerStyle.justifyContent = 'flex-end';
          break;
      }
    }
  }
  // Return ---------------------------------------------------
  if (overrides?.component) {
    return overrides.component({
      _componentStyle: containerStyle,
      styleNames,
      id,
      style,
      width,
      height,
      hidden, // por defecto, sino se manda o hide o hide=true,  se oculta con un Fade
      collapsable,
      hAlign,
      vAlign,
      onClick,
      children,
      animation,
      internalProps,
      ...props,
    });
  }
  return collapsable
    ? e(
        Collapse,
        {
          ...generateProperties(
            parentReference,
            enumKey,
            'collapseContainer',
            undefined,
            undefined,
            true
          ),
          in: !(hidden || false),
          style: { width: '100%' },
        },
        e(
          animation ? motion.div : 'div',
          {
            ...generateProperties(
              parentReference,
              enumKey,
              componentReference,
              coreId,
              id,
              true
            ),
            ...animation,
            key: animation?.animationKey,
            ref,
            style: { ...containerStyle, ...animation?.style },
            onClick,
            className: `${(styleNames || '').trim()}`,
            ...internalProps,
          },
          children
        )
      )
    : e(
        animation ? motion.div : 'div',
        {
          ...generateProperties(
            parentReference,
            enumKey,
            componentReference,
            coreId,
            id,
            true
          ),
          ...animation,
          key: animation?.animationKey,
          ref,
          style: { ...containerStyle, ...animation?.style },
          onClick,
          className: `${(styleNames || '').trim()}`,
          ...internalProps,
        },
        children
      );
});
