import { ComponentStyle, ExpectedStyles } from './interface';

import { CommonUiConfiguration } from '../../interfaces';

const baseConfiguration: (
  styles: CommonUiConfiguration | undefined,
  styleKeys: ExpectedStyles
) => ComponentStyle = (_theme, _styleKeys) => ({
  rootComponent: { width: '100%' },
  dotsContainerComponent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'transparent',
    position: 'fixed',
    bottom: 0,
    zIndex: 1,
  },
  dotComponent: {
    backgroundColor: _theme?.colors?.secondary || 'white',
    borderRadius: '50%',
    width: 8,
    height: 8,
    margin: '0 2px',
  },
  dotActiveComponent: {
    backgroundColor: _theme?.colors?.main || 'black',
  },
});

export default baseConfiguration;
